import { Box, Typography } from "@mui/material";
import { InsertDriveFile } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { styles } from "./styles/DocumentationListGridEmpty";

interface DocumentationListGridEmptyProps {
    emptyResults?: boolean;
}

export const DocumentationListGridEmpty = ({ emptyResults = false }: DocumentationListGridEmptyProps) => {
    const { t } = useTranslation();

    return (
        <Box sx={styles.container}>
            <InsertDriveFile sx={styles.icon} />
            <Typography variant="h4" color="text.secondary">
                {emptyResults ? t("documentation.search.empty") : t("documentation.empty")}
            </Typography>
        </Box>
    );
};
