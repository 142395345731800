import { Fragment } from "react";
import { isRouteErrorResponse, Navigate, Outlet, Route, useRouteError } from "react-router-dom";
import { ProductDetails } from "./ProductDetails/ProductDetails";
import { ProductList } from "./ProductList";
import { UpdateQuestionnaire } from "./ProductForm/UpdateProduct";
import { CreateQuestionnaire } from "./ProductForm/CreateProduct";
import { QuestionnaireUpdate } from "./ProductDetails/Questionnaire/QuestionnaireUpdate/QuestionnaireUpdate";
import { CREATE, LIST, MODIFY, PRODUCT, useFoodpilotContext, VIEW } from "src/context/FoodpilotContext";
import { Forbiddenpage } from "src/components/PageState/ForbiddenPage";
import { Slugs } from "src/context/ProtectedContext";
import { ProductContextProvider } from "./ProductDetails/ProductContext";
import { ProductScores } from "./ProductDetails/ProductScores";
import { Questionnaire } from "./ProductDetails/Questionnaire/Questionnaire";
import { ProductInformations } from "./ProductDetails/ProductInformations";
import { SectionResolver } from "./SectionResolver";
import { ProductScore } from "src/pages/Product/ProductDetails/Scores/ProductScore.tsx";
import { ProductQuestionnaire } from "./ProductDetails/ProductQuestionnaire";
import { Summary } from "./ProductDetails/Summary/Summary";
import { Ingredients } from "./ProductDetails/Ingredients/Ingredients";
import ProductScoreContextProvider from "src/pages/Product/ProductDetails/Scores/ProductScoreContextProvider";

export const useProductRouter = () => {
    return (
        <Fragment>
            <Route path="products" errorElement={<ProductError />}>
                <Route element={<ProductActionProtection slug={LIST} />}>
                    <Route index element={<ProductList />} />
                </Route>

                <Route path={"new"} element={<ProductActionProtection slug={CREATE} />}>
                    <Route element={<CreateQuestionnaire />}>
                        <Route index />
                        <Route path={":startingPageId"} />
                    </Route>
                </Route>

                <Route path=":productId" element={<ExistingProduct />}>
                    <Route path={"edit"} element={<ProductActionProtection slug={MODIFY} />}>
                        <Route element={<UpdateQuestionnaire />}>
                            <Route index />
                            <Route path={":startingPageId"} />
                        </Route>
                    </Route>

                    <Route path="details" element={<ProductActionProtection slug={VIEW} />}>
                        <Route index element={<Navigate to="scores" replace />} />

                        <Route element={<SectionResolver />}>
                            <Route element={<ProductDetails />}>
                                <Route path="scores" element={<Outlet />}>
                                    <Route index element={<ProductScores />} />
                                    <Route path=":scoreId" element={<ExistingProductScore />}>
                                        <Route index element={<ProductScore />} />
                                        <Route path="distribution" element={<ProductScore />} />
                                    </Route>
                                </Route>

                                <Route path="informations" element={<ProductInformations />} />

                                <Route path="questionnaire" element={<ProductQuestionnaire />}>
                                    <Route index element={<Navigate to="product" replace />} />
                                    <Route path="summary" element={<Summary />} />
                                    <Route path="product" element={<Questionnaire />} />
                                    <Route path="ingredients" element={<Ingredients />} />
                                </Route>
                            </Route>
                        </Route>
                        <Route path="questionnaire/edit/:headingId" element={<QuestionnaireUpdate />} />
                    </Route>
                </Route>
            </Route>
        </Fragment>
    );
};

type ProductActionProtectionProps = {
    slug: Slugs;
};
const ProductActionProtection = (props: ProductActionProtectionProps) => {
    const { slug } = props;
    const { canUser } = useFoodpilotContext();

    if (canUser(slug, PRODUCT) === false) {
        return <Forbiddenpage />;
    }

    return <Outlet />;
};

const ExistingProduct = () => {
    return (
        <ProductContextProvider>
            <Outlet />
        </ProductContextProvider>
    );
};

const ExistingProductScore = () => {
    return (
        <ProductScoreContextProvider>
            <Outlet />
        </ProductScoreContextProvider>
    );
};

const ProductError = () => {
    const error = useRouteError();
    if (isRouteErrorResponse(error)) {
        if (error.status === 404) {
            return <div>Page inexistante 404</div>;
        } else if (error.status === 401) {
            return <div>Unauthorized: 401</div>;
        } else if (error.status === 503) {
            return <div>API down: 503</div>;
        } else if (error.status === 500) {
            return <div>API error: 500</div>;
        }
    }
    console.error(error);
    return <div>Hello this is a generic error</div>;
};
