import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import { ListHeader } from "./ListHeader";
import { Fragment } from "react/jsx-runtime";
import { ListRow } from "./ListRow";
import { CsrPillar } from "src/api/client-api/csrStrategy/useGetAllStrategy";
import { useState } from "react";
import { ActionsPopover } from "./ActionsPopover";
import { useTranslation } from "react-i18next";
import { FoodsIcon } from "@foodpilot/foods";
import { URLHandler } from "src/services/URLHandler";
import { useNavigate } from "react-router-dom";

type ListPillarsProps = {
    pillars: CsrPillar[];
};
export const ListPillars = (props: ListPillarsProps) => {
    const { pillars } = props;
    const theme = useTheme();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [selectedPillar, setSelectedPillar] = useState<CsrPillar | null>(null);
    const [anchor, setAnchor] = useState<null | HTMLElement>(null);

    return (
        <Fragment>
            <ActionsPopover anchor={anchor} setAnchor={setAnchor} pillarId={selectedPillar?.id} />
            <Stack flexDirection={"row"} justifyContent={"space-between"}>
                <Typography variant="huge-bold">{t("settings.csr.pillars.title")}</Typography>
                <Button
                    variant="primary"
                    startIcon={<FoodsIcon size={2} icon="plus" />}
                    onClick={() => {
                        const url = URLHandler.settings.csrStrategy.createPillar();
                        navigate(url);
                    }}
                >
                    <Typography variant={"body-medium"} color={"currentColor"}>
                        {t("settings.csr.pillars.addButton", { verb: t("glossary.create") })}
                    </Typography>
                </Button>
            </Stack>
            <Stack
                gap={theme.spacing(3)}
                sx={{
                    display: "grid",
                    gridTemplateColumns: "[a] 1fr [b] 1fr [c] 1fr [d] 60px",
                }}
            >
                <ListHeader />
                <Box borderBottom={`1px solid ${theme.custom.grey[2500]}`} gridColumn={"1 / -1"} />
                {pillars.map((pillar, index) => {
                    return (
                        <Fragment key={index}>
                            <ListRow
                                anchor={anchor}
                                setAnchor={setAnchor}
                                pillar={pillar}
                                action={(pillarClicked) => {
                                    setSelectedPillar(pillarClicked);
                                }}
                            />
                        </Fragment>
                    );
                })}
            </Stack>
        </Fragment>
    );
};
