import { AxiosError } from "axios";
import { Mutation, Query } from "react-query";
import { loggedInApiCall } from "src/api/axiosClient/userApi";
import { ILoginResponse } from "src/api/types";
import { URLHandler } from "src/services/URLHandler";

/*  At the heart of this file, there is an article on how to implement this refreshToken logic.
    https://dev.to/elmehdiamlou/efficient-refresh-token-implementation-with-react-query-and-axios-f8d
*/

type IErrorResponse = {
    message: string;
};
export const refreshTokenHandler = (
    error: unknown,
    query?: Query,
    mutation?: Mutation<unknown, unknown, unknown, unknown>,
    variables?: unknown,
) => {
    const { status, data } = (error as AxiosError<IErrorResponse>).response!;

    if (status === 401) {
        if (mutation) refreshTokenAndRetry(undefined, mutation, variables);
        else refreshTokenAndRetry(query);
    } else console.error(data?.message);
};

const refreshTokenUrl = "/api/token/refresh" as const;

const refreshAccessTokenFn = async () => {
    // Since we're using Cookies, it will automatically reset the Cookie.
    await loggedInApiCall.post<ILoginResponse>(refreshTokenUrl);
};

type FailedQueue = {
    query?: Query;
    mutation?: Mutation<unknown, unknown, unknown, unknown>;
    variables?: unknown;
};

let isRefreshing = false;
let failedQueue: FailedQueue[] = [];
const refreshTokenAndRetry = async (
    query?: Query,
    mutation?: Mutation<unknown, unknown, unknown, unknown>,
    variables?: unknown,
) => {
    try {
        if (isRefreshing) {
            failedQueue.push({ query, mutation, variables });
            return;
        }

        isRefreshing = true;
        failedQueue.push({ query, mutation, variables });
        await refreshAccessTokenFn();
        processFailedQueue();
    } catch (error) {
        // This means a refresh token has failed.
        // Redirect to login;
        const url = URLHandler.login.logout();
        window.location.href = url;
    }
};

const processFailedQueue = () => {
    failedQueue.forEach(({ query, mutation }) => {
        if (mutation) {
            mutation.execute();
        }
        if (query) {
            query.fetch();
        }
    });
    isRefreshing = false;
    failedQueue = [];
};
