import { Box, styled } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Breadcrumb } from "./Breadcrumb";
import { BreadcrumbsGroup } from "src/components/Breadcrumb/BreadcrumbsGroup";
import { greyTheme } from "@foodpilot/foods";

const breadcrumbStyles = {
    padding: "2px 8px",
    background: "#fff",
    border: "1px solid #E7E6E4",
    borderRadius: "8px",
    textDecoration: "none",
    height: "auto",
    minWidth: 0,
    "&:hover": {
        borderColor: "#393A36",
        textDecoration: "none",
    },
    "&:hover .text": {
        color: "#50504D",
    },
};
export const useBreadcrumbStyles = makeStyles({
    breadcrumb: {
        ...breadcrumbStyles,
        justifyContent: "unset",
        marginBottom: ".5rem",
        width: "fit-content",
    },
});

const StyledNav = styled("nav")({
    borderRadius: "50%",
    display: "flex",
    minWidth: 0,
    "& ol": {
        display: "flex",
        listStyle: "none",
        margin: 0,
        padding: 0,
        "& li": {
            display: "flex",
            alignItems: "center",
            "& svg": {
                margin: "0 .25rem",
            },
            "& .breadcrumb": {
                ...breadcrumbStyles,
            },
            "& .breadcrumb-group": {
                borderRadius: "4px",
                padding: "0 4px",
            },
            "&:last-child": {
                "& > .MuiButtonBase-root": {
                    pointerEvents: "none",
                },
                "& > svg": {
                    display: "none",
                },
                "& > .breadcrumb:hover": {
                    cursor: "default",
                    border: `1px solid ${greyTheme[500]}`,
                },
                "& > .breadcrumb > span": {
                    color: greyTheme[2800],
                    fontWeight: 600,
                },
            },
        },
    },
});

export type BreadcrumbType = {
    label: string;
    action?: () => void;
};

type BreadcrumbsProps = {
    items: BreadcrumbType[];
};
/**
 * @TODO: Add hook or something that will create breadcrumbs automatically for the user
 */
export const Breadcrumbs = (props: BreadcrumbsProps) => {
    const { items } = props;
    const breadcrumbsSliceAfter = 4;

    const createSlice = (breadcrumbs: BreadcrumbType[] | undefined) => {
        if (!breadcrumbs) {
            return [];
        }

        if (breadcrumbs.length <= breadcrumbsSliceAfter) {
            return breadcrumbs;
        }

        return [...breadcrumbs.slice(0, 1), breadcrumbs.slice(1, breadcrumbs.length - 2), ...breadcrumbs.slice(-2)];
    };

    const breadcrumbs = createSlice(items);

    const getKey = (item: BreadcrumbType, index: number) => {
        return `breadcrumb-${item.label}-${index}`;
    };

    return (
        <StyledNav>
            <Box component="ol">
                {breadcrumbs?.map((breadcrumb, index) => {
                    if (Array.isArray(breadcrumb)) {
                        return <BreadcrumbsGroup key="breadcrumb-group" breadcrumbs={breadcrumb} />;
                    }

                    return (
                        breadcrumb.label?.length > 0 && (
                            <Breadcrumb key={getKey(breadcrumb, index)} breadcrumb={breadcrumb} />
                        )
                    );
                })}
            </Box>
        </StyledNav>
    );
};
