import { PrimaryDialog, Typography } from "@foodpilot/foods";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { StrategyIndicatorFormFields } from "./StrategyIndicatorFormFields";
import { CMSPageSection, CMSSectionBlocksStrategy } from "src/api/client-api/cms/useGetOneContent";
import { CsrStrategy, useGetAllStrategy } from "src/api/client-api/csrStrategy/useGetAllStrategy";
import { useTheme } from "@mui/material";

type PillarIndex = number;
export type SelectedIndicators = Record<PillarIndex, IRI[] | undefined>;

const initState = (
    section: CMSPageSection<CMSSectionBlocksStrategy>,
    strategy: CsrStrategy | undefined,
): SelectedIndicators => {
    if (strategy === undefined) return {};
    const existingIndicators = section.blocks.map((block) => block.strategyIndicator);

    const acc: SelectedIndicators = {};
    return strategy.pillars.reduce((prevAcc, pillar, pillarIndex) => {
        const selectedIndicatorsInPillar = pillar.indicators.flatMap((ind) => {
            const isIncluded = existingIndicators.includes(ind["@id"]);
            if (isIncluded === false) return [];
            return [ind["@id"]];
        });

        prevAcc[pillarIndex] = selectedIndicatorsInPillar;
        return prevAcc;
    }, acc);
};

type AddIndicatorsModalProps = {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    section: CMSPageSection<CMSSectionBlocksStrategy>;
};
export const AddStrategyIndicatorsModal = (props: AddIndicatorsModalProps) => {
    const { isOpen, setIsOpen, section } = props;
    const { t } = useTranslation();
    const theme = useTheme();

    const { allStrategy, isFetched } = useGetAllStrategy();
    const _strategy = allStrategy ?? [];
    const strategy = _strategy.length > 0 ? _strategy[0] : undefined;

    const [selectedIndicators, setSelectedIndicators] = useState<SelectedIndicators | null>(null);

    const reset = () => {
        const selectedItems = initState(section, strategy);
        setSelectedIndicators(selectedItems);
    };

    useEffect(() => {
        if (isFetched === false && strategy === undefined) return;
        reset();
    }, [allStrategy]);

    if (isFetched === false) return;
    if (strategy === undefined) return;
    if (selectedIndicators === null) return;

    return (
        <PrimaryDialog
            open={isOpen}
            setOpen={setIsOpen}
            options={{
                title: <Typography variant="big-bold">{t("cms.indicators.addModal.title")}</Typography>,
                content: (
                    <StrategyIndicatorFormFields
                        strategy={strategy}
                        resetAction={reset}
                        selectedIndicators={selectedIndicators}
                        setSelectedIndicators={setSelectedIndicators}
                    />
                ),
            }}
            onValidate={() => {
                if (selectedIndicators === null) return;

                const payload = Object.values(selectedIndicators).flatMap((ind) => ind);
                console.log("DOing something with... ", payload);
            }}
            PaperProps={{
                sx: {
                    width: "100%",
                    maxWidth: "1024px",
                },
            }}
            cancelText={t("primaryDialog.cancel")}
            validateText={t("primaryDialog.validate")}
        />
    );
};
