import { useTranslation } from "react-i18next";
import Resources from "src/@types/i18next.resources";
import { defaultNS, default as i18n } from "src/i18n.ts";

const keyCache: { [lang: string]: Set<string> } = {};

function getCachedKeys(language: string, namespace: string): Set<string> {
    const cacheKey = `${language}-${namespace}`;
    if (!keyCache[cacheKey]) {
        const resourceBundle = i18n.getResourceBundle(language, namespace) || {};
        keyCache[cacheKey] = new Set(Object.keys(resourceBundle));
    }
    return keyCache[cacheKey];
}

function doesTranslationKeyExist(key: string): key is keyof Resources[typeof defaultNS] {
    const validKeys = getCachedKeys(i18n.language, defaultNS);
    return validKeys.has(key);
}

export function useVariableTranslation() {
    const { t } = useTranslation();

    return (key?: string, fallback?: string): string => {
        if (key === undefined) {
            return "";
        }

        if (doesTranslationKeyExist(key)) {
            return t(key);
        }

        return fallback || key;
    };
}
