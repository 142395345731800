import { useMutation } from "react-query";
import { loggedInApiCall } from "src/api/axiosClient/userApi";

type CreateSupplier = {
    supplier: SupplierWrite;
};
export const useCreateSupplier = () => {
    const query = useMutation({
        mutationFn: async (options: CreateSupplier) => {
            const { supplier } = options;

            const response =
                supplier.id === undefined ?
                    await loggedInApiCall.post("/api/companies", supplier)
                :   await loggedInApiCall.put("/api/companies/" + supplier.id, supplier);

            return response;
        },
    });

    return query;
};

export type SupplierWriteIngredientSupplier =
    | IRI
    | {
          ingredient: IRI;
          quantity: number | null;
          unit: number | null;
      };

export type SupplierType = "provider" | "main" | "branch";

export type SupplierWrite = {
    // id decides whether it's POST or PUT.
    id?: number;

    name: string | null;
    clientId?: string | null | undefined;
    users?: IRI[];
    ingredientSuppliers?: SupplierWriteIngredientSupplier[];

    type: SupplierType;

    address1?: string;
    address2?: string;
    city?: string;
    state?: string;
    country?: string;
    phone?: string;
    email?: string;
    companySector?: IRI;
    parent?: IRI | null;
};
