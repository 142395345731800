import { WhiteBox } from "@foodpilot/foods";
import { Stack, Typography, useTheme } from "@mui/material";
import { MainContentLayout } from "src/components/Layout/MainContentLayout";
import { useStrategy } from "src/context/Strategy/useStrategy";
import { PillarCard } from "./PillarCard";
import { useTranslation } from "react-i18next";
import { Fragment } from "react/jsx-runtime";

export const StrategyList = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const { strategy } = useStrategy();

    const pillars = strategy?.pillars ?? [];
    return (
        <MainContentLayout>
            <Stack flexDirection={"column"} marginBottom={theme.spacing(2)}>
                <Typography variant="h1">{t("csrStrategy.page.title")}</Typography>
                <Typography variant="body-medium">
                    {t("csrStrategy.page.subtitle", { strategy: strategy?.name ?? "" })}
                </Typography>
            </Stack>
            <WhiteBox>
                <Stack flexDirection={"column"} padding={theme.spacing(5)}>
                    {pillars.length === 0 ?
                        <Fragment>
                            <Stack alignItems={"center"}>
                                <Typography variant="h2">{"csrStrategy.noPillars"}</Typography>
                            </Stack>
                        </Fragment>
                    :   <Fragment>
                            <Stack alignItems="left">
                                <Typography variant="h2">
                                    {t("csrStrategy.page.pillarInfo")}
                                    <span style={{ fontWeight: 400 }}>{` - ${strategy?.pillars.length}`}</span>
                                </Typography>
                            </Stack>
                            <Stack flexDirection={"column"} gap={theme.spacing(2)}>
                                {pillars.map((pillar, index) => {
                                    return <PillarCard pillar={pillar} pillarNumber={index + 1} />;
                                })}
                            </Stack>
                        </Fragment>
                    }
                </Stack>
            </WhiteBox>
        </MainContentLayout>
    );
};
