import { FoodsIcon } from "@foodpilot/foods";
import { Stack, Button, Box, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Fragment } from "react/jsx-runtime";
import { CsrPillarForm, CsrIndicatorForm } from "../../PillarForm";
import { IndicatorHeader } from "./IndicatorHeader";
import { NoIndicators } from "./NoIndicators";
import { IndicatorLine } from "./IndicatorLine";

type IndicatorListProps = {
    form: CsrPillarForm;
    setIndicator: (newIndicators: CsrIndicatorForm[]) => void;
    setSelectedIndicator: (selectedIndicator: CsrIndicatorForm | null) => void;
    openModal: () => void;
};
export const IndicatorList = (props: IndicatorListProps) => {
    const { form, setIndicator, setSelectedIndicator, openModal } = props;
    const theme = useTheme();
    const { t } = useTranslation();

    const createIndicator = () => {
        // Means, it's a creating state
        setSelectedIndicator(null);
        openModal();
    };

    if (form.indicators.length === 0) {
        return <NoIndicators action={createIndicator} />;
    }

    const maxItemsNb = form.indicators.length;
    return (
        <Stack>
            <Stack flexDirection={"row"} justifyContent={"right"} marginBottom={theme.spacing(2)}>
                <Button variant="secondary" startIcon={<FoodsIcon icon="plus" size={2} />} onClick={createIndicator}>
                    {t("settings.csr.form.indicator.addButton", { verb: t("glossary.create") })}
                </Button>
            </Stack>
            <Stack
                gap={theme.spacing(3)}
                sx={{
                    display: "grid",
                    gridTemplateColumns: "[a] 1fr [b] 1fr [c] 1fr [d] 1fr [e] 70px",
                }}
            >
                <IndicatorHeader />
                <Box borderBottom={`1px solid ${theme.custom.grey[2500]}`} gridColumn={"1 / -1"} />
                {form.indicators.map((indicator, index) => {
                    return (
                        <Fragment key={index}>
                            <IndicatorLine
                                indicator={indicator}
                                editAction={(ind) => {
                                    setSelectedIndicator(ind);
                                    openModal();
                                }}
                                deleteAction={() => {
                                    const filteredInd = form.indicators.filter((indicator) => {
                                        return index !== indicator.rowIndex;
                                    });
                                    setIndicator(filteredInd);
                                }}
                            />
                            {index + 1 < maxItemsNb && (
                                <Box gridColumn={`1 / -1`} borderBottom={`1px solid ${theme.custom.grey[2500]}`} />
                            )}
                        </Fragment>
                    );
                })}
            </Stack>
        </Stack>
    );
};
