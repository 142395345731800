import { FoodsBadge } from "@foodpilot/foods";
import { Box, Typography, Stack, useTheme } from "@mui/material";
import { Fragment } from "react/jsx-runtime";
import { CsrPillar } from "src/api/client-api/csrStrategy/useGetAllStrategy";

type ListRowProps = {
    anchor: HTMLElement | null;
    setAnchor: (elementToAttachTo: HTMLElement | null) => void;
    pillar: CsrPillar;
    action?: (pillar: CsrPillar) => void;
};
export const ListRow = (props: ListRowProps) => {
    const { pillar, action, setAnchor } = props;
    const theme = useTheme();

    const indicatorsTitle = pillar.indicators.map((i) => i.name);
    const indicatorsAsString = indicatorsTitle.join(", ");
    return (
        <Fragment>
            <Box sx={{ gridColumn: "a", wordBreak: "break-word" }}>
                <Typography>{pillar.name}</Typography>
            </Box>
            <Box sx={{ gridColumn: "b", wordBreak: "break-word" }}>
                <Stack gap={theme.spacing(0.25)}></Stack>
                <Typography>{pillar.themes}</Typography>
            </Box>
            <Box sx={{ gridColumn: "c", wordBreak: "break-word" }}>
                <Typography>{indicatorsAsString}</Typography>
            </Box>
            <Box sx={{ gridColumn: "d", wordBreak: "break-word" }}>
                <FoodsBadge
                    size={2}
                    icon={"actions"}
                    boxProps={{
                        onClick: (e) => {
                            setAnchor(e.currentTarget);
                            action?.(pillar);
                        },
                        sx: { cursor: "pointer" },
                    }}
                />
            </Box>
        </Fragment>
    );
};
