import { useState } from "react";

export type SavingStatus = {
    isSaving: boolean;
    setSaving: (value: boolean) => void;

    isError: boolean;
    setError: (value: boolean) => void;

    text: {
        syncingMessage: string;
        savingDefaultMessage: string;
        errorDefaultMessage: string;
    };
};

export const useSavingStatus = () => {
    const [isSaving, setSaving] = useState(false);
    const [isError, setError] = useState(false);

    return {
        isSaving: isSaving,
        setSaving: setSaving,
        isError: isError,
        setError: setError,
        text: {
            syncingMessage: "Synchronisation...",
            savingDefaultMessage: "Sauvegarde",
            errorDefaultMessage: "Erreur",
        },
    };
};
