import { useParams } from "react-router-dom";
import { useFoodpilotContext } from "src/context/FoodpilotContext.tsx";
import { useGetOneScore } from "src/api/sure-api/score/useGetOneScore.ts";
import { ScoreDetailContextProvider } from "src/context/ScoreDetail/provider.tsx";
import { doesScoreExist } from "src/api/client-api/post/scoreSchema.ts";
import { useGetOneSupplier } from "src/api/client-api/suppliers/useGetOneSupplier.tsx";
import { Loading } from "src/components/utils/Loading.tsx";
import { useCompanyContext } from "src/context/Company/useCompanyContext.ts";
import { dimensionOrder } from "src/pages/Company/Scores/SecondaryTabs/Distribution.tsx";

type CompanyScoreContextProviderProps = {
    children: JSX.Element | JSX.Element[];
};
const CompanyScoreContextProvider = (props: CompanyScoreContextProviderProps) => {
    const { children } = props;
    const { selectedCompany, currentCampaign, selectedCompanyVariations } = useFoodpilotContext();
    const { scoreId: scoreIdString } = useParams<{ scoreId: string }>();
    const scoreId = Number(scoreIdString);
    const { score: detailedScore } = useGetOneScore(scoreId);
    const {
        ssq: { ssq },
    } = useCompanyContext();

    const { supplier: detailedSupplier } = useGetOneSupplier(String(selectedCompany?.id), {
        queryParams: {
            campaign: currentCampaign?.id,
            dimensions: ["all"],
            variations: selectedCompanyVariations,
        },
        enabled: detailedScore !== undefined,
    });

    const { scores } = detailedSupplier || {};

    if (!detailedSupplier || !detailedScore || !scores) {
        return <Loading />;
    }

    return (
        <ScoreDetailContextProvider
            scores={scores}
            prevScores={selectedCompany?.prevScores}
            ssq={ssq}
            order={dimensionOrder}
        >
            {children}
        </ScoreDetailContextProvider>
    );
};

export default CompanyScoreContextProvider;
