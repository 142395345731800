import { useMutation, useQueryClient } from "react-query";
import { loggedInApiCall } from "src/api/axiosClient/userApi";
import { csrThemeQueryKey } from "./useGetAllCsrTheme";
import { z } from "zod";

type CreateCsrTheme = {
    csrTheme: CsrThemeWrite;
};

export const useCreateCsrTheme = () => {
    const queryClient = useQueryClient();
    const query = useMutation({
        mutationFn: async (options: CreateCsrTheme) => {
            const { csrTheme } = options;

            const response =
                csrTheme.id === undefined ?
                    await loggedInApiCall.post("/api/steering/csr_themes", csrTheme)
                :   await loggedInApiCall.put("/api/steering/csr_themes/" + csrTheme.id, csrTheme);

            return response;
        },
        onSuccess: () => queryClient.invalidateQueries([csrThemeQueryKey]),
    });

    return query;
};

export type CsrThemeWrite = {
    id?: number;
    // code: string;
    title: string;
    description?: string;
    parent?: IRI;
    children?: IRI[];
};

export const CsrThemeWriteSchema: z.ZodSchema<CsrThemeWrite> = z.object({
    id: z.number().optional(),
    // code: z.string(),
    title: z.string(),
    description: z.string().optional(),
    parent: z.string().optional(),
    children: z.string().array().optional(),
});
