import { SxProps, Theme } from "@mui/material";

export const styles = {
    container: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        pt: 4,
        pb: 4,
    } as SxProps<Theme>,
    icon: {
        fontSize: 64,
        color: "grey.400",
        mb: 2,
    } as SxProps<Theme>,
};
