import { BlockItemContainer, BlockListContainer } from "@foodpilot/foods";
import { useActionsContext } from "src/context/Actions/useActionsContext";
import { PropertySwitch, PropertySwitchProps } from "./PropertySwitch";
import { useNavigate, useParams } from "react-router-dom";
import { PlanningLayout } from "../PlanningLayout";
import { ActionService } from "src/context/Actions/ActionService";
import { useTranslation } from "react-i18next";
import { useFoodpilotContext } from "src/context/FoodpilotContext";
import { useCreateActionPost } from "src/api/client-api/actionPost/useCreateActionPost";
import { useResetAction } from "src/context/Actions/useResetAction";
import { Fragment } from "react/jsx-runtime";
import { CustomHeader } from "../Custom/CustomHeader";

export type EditPlanningPropertiesProps = unknown;
export const EditPlanningProperties = (_props: EditPlanningPropertiesProps) => {
    const { actionId } = useParams();
    const { selectedCompany } = useFoodpilotContext();
    const createActionPost = useCreateActionPost();

    const { actions, selectedSimulation, actionsDataByCsr, selectedScore, urls, savingStatus } = useActionsContext();
    const resetAction = useResetAction();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const action = actions?.find((item) => item.id === Number(actionId));

    if (action === undefined) return <>{"Bad action choice !"}</>;
    const actionData = ActionService.getActionById(actionsDataByCsr, Number(actionId));

    if (actionData === null) return <>{"Not found"}</>;
    const propertiesWithElements = actionData.data;

    const blocks = action.ssqProperties.map((ssqProperty, index) => {
        if (propertiesWithElements === undefined) {
            console.error("NOT sure this case can happen");
            return <></>;
        }
        const fieldsProps = ActionService.resolveActionFields(ssqProperty, propertiesWithElements);
        const fields = fieldsProps.map((props: PropertySwitchProps) => <PropertySwitch {...props} />);
        const scoreDiff = propertiesWithElements[ssqProperty.id];
        return (
            <BlockItemContainer
                key={index}
                title={ssqProperty.title ?? ""}
                value={scoreDiff?.score ?? null}
                unit={selectedScore?.unit}
                fields={fields}
            />
        );
    });

    return (
        <PlanningLayout>
            <Fragment>
                <BlockListContainer
                    header={
                        <CustomHeader
                            title={`${action.title}`}
                            cancelButton={{
                                label: t("actionPlan.deleteActionPost"),
                                action: () => {
                                    if (selectedSimulation === null) return;

                                    savingStatus.setSaving(true);
                                    resetAction.mutate(
                                        {
                                            actionId: action.id,
                                            postId: selectedSimulation.id,
                                            allActionsData: actionData,
                                        },
                                        {
                                            onSuccess: () => {
                                                if (selectedCompany === null) return;

                                                const url = urls.planning();
                                                navigate(url);
                                            },
                                            onSettled: () => {
                                                savingStatus.setSaving(false);
                                            },
                                        },
                                    );
                                },
                            }}
                            actionButton={{
                                label: t("actionPlan.goBackToList"),
                                action: () => {
                                    if (selectedCompany === null) return;

                                    const url = urls.planning();
                                    navigate(url);
                                },
                            }}
                        />
                    }
                    blocks={blocks}
                />
            </Fragment>
        </PlanningLayout>
    );
};
