import { MouseEvent, useState } from "react";
import { Box, Button, Typography, Popover, Stack } from "@mui/material";
import { FoodsIcon } from "@foodpilot/foods";
import { BreadcrumbType, useBreadcrumbStyles } from "src/components/Breadcrumb/Breadcrumbs";

type BreadcrumbsGroupProps = {
    breadcrumbs: BreadcrumbType[];
};
export const BreadcrumbsGroup = (props: BreadcrumbsGroupProps) => {
    const { breadcrumbs } = props;
    const classes = useBreadcrumbStyles();

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <li>
            <Button className="breadcrumb breadcrumb-group" onClick={handleClick}>
                <FoodsIcon size={1} icon="actions" />
            </Button>
            <FoodsIcon icon="arrowRightShort" size={2} />
            <Popover
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                sx={{ marginTop: 1 }}
                slotProps={{
                    paper: {
                        sx: {
                            borderRadius: "12px",
                            boxShadow: "0px 4px 20px 0px rgba(81, 78, 78, 0.12)",
                            border: "1px solid #E7E6E4",
                            width: "145px",
                            padding: "1rem",
                        },
                    },
                }}
            >
                <Box display="flex" flexDirection="column">
                    {breadcrumbs.map((breadcrumb, index) => {
                        if (breadcrumb.label.length > 0) {
                            return (
                                <Button key={index} className={classes.breadcrumb} onClick={breadcrumb.action}>
                                    <Typography variant="body" title={breadcrumb.label}>
                                        {breadcrumb.label}
                                    </Typography>
                                </Button>
                            );
                        }
                    })}
                </Box>
            </Popover>
        </li>
    );
};
