import { BlockItemInner, BlockOptions } from "@foodpilot/foods";
import { ActionPropertiesCoreAnswers, ActionService } from "src/context/Actions/ActionService";
import { useActionsContext } from "src/context/Actions/useActionsContext";
import { PostElement } from "src/api/client-api/post/postSchema";
import { SsqPropertyAction } from "src/api/sure-api/actions/useGetOneAction";
import { Stack } from "@mui/material";
import { PropertySwitch } from "../PropertySwitch";
import { useTranslation } from "react-i18next";

type MultivalueProps = {
    propertyWithElements: ActionPropertiesCoreAnswers;
    position?: number;
};
export const Multivalue = (props: MultivalueProps) => {
    const { t } = useTranslation();
    const { startingPost, lastExistingPost, selectedSimulation } = useActionsContext();
    const { propertyWithElements, position = 0 } = props;

    const previousElements = propertyWithElements.previousElements;
    const currentElements = propertyWithElements.currentElements;
    const targetElements = propertyWithElements.targetElements;

    const previousComparisonValues = {
        year: t("actionPlan.value_in_year", { year: startingPost?.post?.campaign?.label }),
        value: ActionService.parseMultivalueElementsForDisplay(previousElements ?? [], propertyWithElements.property),
    };
    const currentComparisonValues =
        lastExistingPost !== undefined ?
            {
                year: t("actionPlan.value_in_year", { year: lastExistingPost.campaign }),
                value: ActionService.parseMultivalueElementsForDisplay(
                    currentElements ?? [],
                    propertyWithElements.property,
                ),
            }
        :   undefined;

    const blockOptions: BlockOptions = {
        title: "",
        rightSubtitle: `${t("Goal")} ${selectedSimulation?.campaign?.label}`,
        comparisonValues: {
            start: previousComparisonValues,
            current: currentComparisonValues,
        },
    };

    const multivalueProperties = propertyWithElements.property.children ?? [];

    return (
        <BlockItemInner blockOptions={blockOptions}>
            <>
                {multivalueProperties.map((property: SsqPropertyAction, index: number) => {
                    const previousElement = previousElements?.find(
                        (element: PostElement) => element.ssqPropertyId === property.id,
                    );
                    const currentElement = currentElements?.find(
                        (element: PostElement) => element.ssqPropertyId === property.id,
                    );
                    const targetElement = targetElements?.find(
                        (element: PostElement) => element.ssqPropertyId === property.id,
                    );

                    const multivaluePropertyWithElements = {
                        // Unsure about position of those three in Multi-value
                        score: -999,
                        unit: "unknown in Multivalue",
                        groupScoreDetails: null,

                        property: property,
                        previousElements: previousElement ? [previousElement] : null,
                        currentElements: currentElement ? [currentElement] : null,
                        targetElements: targetElement ? [targetElement] : null,
                    };

                    return (
                        <Stack key={index}>
                            <PropertySwitch
                                propertyWithElements={multivaluePropertyWithElements}
                                position={position}
                                raw={true}
                            />
                        </Stack>
                    );
                })}
            </>
        </BlockItemInner>
    );
};
