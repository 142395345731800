import { UserContext, UserInitialValues } from "./context";
import { Loading } from "src/components/utils/Loading";
import { useUserNew } from "src/api/client-api/useUserNew";
import { CompaniesService } from "src/services/Companies/CompaniesService";
import { URLHandler } from "src/services/URLHandler";
import { Navigate } from "react-router-dom";
import { Fragment } from "react/jsx-runtime";
import { Typography } from "@mui/material";

type UserProviderProps = {
    children: JSX.Element;
};
export const UserProvider = (props: UserProviderProps) => {
    const { children } = props;
    const { user, isFetched } = useUserNew();

    if (isFetched === false) {
        return <Loading />;
    }

    if (user === undefined) {
        // Not supposed to happen
        return (
            <Fragment>
                <Typography variant={"big-medium"}>{"Unknown Error"}</Typography>
            </Fragment>
        );
    }

    const currentCompany = CompaniesService.selectCurrentCompany(user.companies);

    if (currentCompany === null && user.companies.length === 0) {
        const url = URLHandler.login.noCompany();
        return <Navigate to={url} replace />;
    }

    const selectedCompany = currentCompany === null ? user.companies[0] : currentCompany;
    const items: UserInitialValues = {
        user: user,
        selectedCompany: selectedCompany,
    };

    return <UserContext.Provider value={items}>{children}</UserContext.Provider>;
};
