export interface DownloadFileOptions {
    url: string;
    filename: string;
    mimeType?: string;
}

export class DownloadError extends Error {
    constructor(
        message: string,
        public readonly statusCode?: number,
        public readonly url?: string,
    ) {
        // Get the message from the base class
        super(message);
        this.name = "DownloadError";
    }
}

export const downloadFile = async ({ url, filename, mimeType }: DownloadFileOptions): Promise<void> => {
    if (!window.URL || !window.URL.createObjectURL) {
        throw new Error("Browser does not support required download functionality");
    }

    try {
        const response = await fetch(url);

        if (!response.ok) {
            throw new DownloadError(`Failed to download file: ${response.statusText}`, response.status, url);
        }

        const blob = mimeType ? new Blob([await response.blob()], { type: mimeType }) : await response.blob();

        const objectUrl = window.URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = objectUrl;
        link.download = filename;
        link.target = "_blank";
        link.rel = "noopener noreferrer";

        link.style.display = "none";
        document.body.appendChild(link);
        link.click();

        setTimeout(() => {
            document.body.removeChild(link);
            window.URL.revokeObjectURL(objectUrl);
        }, 100);
    } catch (error) {
        if (error instanceof DownloadError) {
            throw error;
        }
        throw new DownloadError(
            `Failed to download file: ${error instanceof Error ? error.message : "Unknown error"}`,
            undefined,
            url,
        );
    }
};
