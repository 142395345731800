import { useMutation } from "react-query";
import { useUpdateBatchProperties } from "src/api/client-api/property/useUpdateBatchProperties";
import { ActionData } from "./context";
import { UpdateProperty } from "src/api/client-api/property/useUpdateProperty";
import { PropertyFields } from "../Ssq/Properties/PropertyFields";
import { useDeleteActionPost } from "src/api/client-api/actionPost/useDeleteActionPost";

export const translationActionToProperties = (
    postId: number,
    selectedAction: ActionData | undefined,
): UpdateProperty[] => {
    if (selectedAction === undefined) {
        console.warn("Asked to reset an unknown action");
        return [];
    }
    const selectedActionData = selectedAction.data;

    const propertiesToUpdate: UpdateProperty[] = Object.values(selectedActionData).flatMap((property) => {
        if (property === undefined) return [];
        if (property.groupScoreDetails === null) {
            const nonGroupedValue = PropertyFields.sendToCalculate(property.property, property.previousElements);
            const propertyToUpdate: UpdateProperty = {
                postId: postId,
                propertyId: property.property.id,
                value: nonGroupedValue,
            };
            return [propertyToUpdate];
        }

        const groupedProperties = Object.values(property.groupScoreDetails).flatMap((subProperty) => {
            if (subProperty === undefined) return [];
            const groupedValue = PropertyFields.sendToCalculate(subProperty.property, subProperty.previousElements);
            const subPropertyToUpdate: UpdateProperty = {
                postId: postId,
                propertyId: subProperty.property.id,
                value: groupedValue,
            };

            return [subPropertyToUpdate];
        });
        return groupedProperties;
    });

    return propertiesToUpdate;
};

type ResetActionsOptions = {
    actionId: number;
    postId: number;
    allActionsData: ActionData | undefined;
};

export const useResetAction = () => {
    const updateBatchProperties = useUpdateBatchProperties();
    const deleteActionPost = useDeleteActionPost();

    const query = useMutation({
        mutationFn: async (options: ResetActionsOptions) => {
            const { actionId, postId, allActionsData } = options;
            const updateProperties = translationActionToProperties(postId, allActionsData);
            await updateBatchProperties.mutateAsync(updateProperties, {
                onSuccess: async () => {
                    await deleteActionPost.mutate({
                        actionId: actionId,
                        ssqPostId: postId,
                    });
                },
            });
        },
    });

    return query;
};
