import { useTranslation } from "react-i18next";
import { FoodsSelectServerPagination } from "@foodpilot/foods";
import FormControl from "@mui/material/FormControl";
import { useAllUsers } from "src/api/client-api/user/useAllUsers";

import { useDelayedSearch } from "src/utils/useDelayedSearch.tsx";

type UserInterface = {
    "@id": IRI;
    firstname: string;
    lastname: string;
};

type SearchUserBarProps = {
    addUser: (newUser: UserInterface) => void;
    selectedUsers: UserInterface[];
};
export const SearchUserBar = (props: SearchUserBarProps) => {
    const { t } = useTranslation();
    const { addUser, selectedUsers } = props;
    const { search, delayedSearch, setSearch } = useDelayedSearch();
    const { users } = useAllUsers({ search: delayedSearch, itemsPerPage: 100 });

    const selectedUsersId = selectedUsers.map((item) => item["@id"]);
    const unselectedUsers = users?.filter((user) => !selectedUsersId.includes(user["@id"])) ?? [];
    const isSearchFound = (value: string) => {
        if (delayedSearch === "") {
            return true;
        }

        return value.toLowerCase().includes(delayedSearch.toLowerCase());
    };

    return (
        <FormControl size="small">
            <FoodsSelectServerPagination
                selectedOption={null}
                options={unselectedUsers}
                onChange={(selectedUser) => addUser(selectedUser as UserInterface)}
                getId={(user) => user.id}
                getName={(user) => `${user.firstname} ${user.lastname}`}
                closeOnSelect={false}
                textOptions={{
                    placeholder: t("component.select.placeholder"),
                    searchPlaceholder: t("component.select.searchPlaceholder"),
                    emptyOptionsText: t("component.select.noOptions"),
                    defaultValueText: t("component.select.defaultValue"),
                    noResult: t("component.select.noResultFound"),
                }}
                search={search}
                setSearch={setSearch}
                isSearchFound={isSearchFound}
            />
        </FormControl>
    );
};
