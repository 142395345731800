import { useState } from "react";
import { Box, CircularProgress, Stack, Typography, useTheme } from "@mui/material";
import { FormPropertyProps } from "../FormProperty";
import { FoodsRadioList } from "@foodpilot/foods";
import { DataListOption } from "src/api/sure-api/ssq/useGetOneSsq";
import { useTranslation } from "react-i18next";
import { DefaultValueButton } from "src/components/FormProperty/DefaultValueButton.tsx";
import { GenericValueCheckbox } from "src/components/FormProperty/GenericValueCheckbox.tsx";
import { FormTooltip } from "../FormTooltip";
import {
    getPropertyDefaultOption,
    getPropertyGenericOption,
    getOptionValue,
    getOptionIds,
    findOptionByValue,
    findOptionById,
} from "src/utils/dataListOptions";

export const Radio = (props: FormPropertyProps) => {
    const theme = useTheme();
    const { t } = useTranslation();

    const { property, post, element, canEdit = true, isSubproperty } = props;

    const options =
        property.dataList?.options.sort(
            (optionA: DataListOption, optionB: DataListOption) => optionA.position - optionB.position,
        ) ?? [];

    const propertyDefaultOption = getPropertyDefaultOption(property, options);
    const propertyGenericOption = getPropertyGenericOption(property, options);

    // Initialize with either the element value or default value
    const initialValue =
        element && !property.readonly ?
            element.dataListOptionIds?.length ?
                findOptionById(options, element.dataListOptionIds[0])
            :   findOptionByValue(options, element.value)
        :   propertyDefaultOption;

    const [value, setValue] = useState<DataListOption | undefined>(initialValue);
    const [genericValueSelected, setGenericValueSelected] = useState<boolean>(element?.isGenericValue ?? false);

    if (!post) return <CircularProgress />;

    const updatePropertyValue = (value?: DataListOption, isGenericValue?: boolean) => {
        props.onUpdate({
            postId: post.id,
            propertyId: Number(property.id),
            value: getOptionValue(value),
            position: Number(props.position ?? 0),
            isGenericValue: isGenericValue,
            dataListOptionIds: value ? getOptionIds([value]) : [],
        });
    };

    const tooltipText = property.hint ? <div dangerouslySetInnerHTML={{ __html: property.hint }} /> : undefined;
    return (
        <FormTooltip title={tooltipText} arrow placement="right">
            <Box>
                <Stack direction="column">
                    <Stack direction="row" alignItems="center">
                        <FoodsRadioList
                            values={options}
                            selectedItem={value}
                            onChange={(newValue: DataListOption) => {
                                setValue(newValue);
                                updatePropertyValue(newValue);
                            }}
                            getId={(option: DataListOption) => option.id}
                            getName={(option: DataListOption) => option.label}
                            FormControlProps={{
                                sx: {
                                    flexDirection: "column",
                                },
                            }}
                            FormControlLabelProps={{
                                sx: {
                                    gap: "3px",
                                    marginRight: "28px",
                                    ".MuiTypography-root": {
                                        ...theme.typography.body,
                                    },
                                },
                            }}
                            RadioProps={{
                                disabled: !canEdit || property.readonly || genericValueSelected,
                            }}
                        />
                        {!property.readonly && propertyDefaultOption !== undefined && !genericValueSelected && (
                            <DefaultValueButton
                                onClick={() => {
                                    if (value?.id === propertyDefaultOption?.id) {
                                        return;
                                    }

                                    setValue(propertyDefaultOption);
                                    updatePropertyValue(propertyDefaultOption);
                                }}
                            />
                        )}
                    </Stack>
                    {propertyDefaultOption !== undefined && !genericValueSelected && (
                        <Typography variant="caption" color={theme.custom.grey[1500]}>
                            {!property.readonly ?
                                t("Valeur par défaut :") + " " + (propertyDefaultOption?.label ?? "")
                            :   t("Cette valeur est en lecture seule")}
                        </Typography>
                    )}
                    {!property.readonly && propertyGenericOption && (
                        <Box>
                            <GenericValueCheckbox
                                isSelected={genericValueSelected}
                                onChange={() => {
                                    setGenericValueSelected((prevState) => !prevState);
                                    setValue(!genericValueSelected ? propertyGenericOption : undefined);
                                    updatePropertyValue(
                                        !genericValueSelected ? propertyGenericOption : undefined,
                                        !genericValueSelected,
                                    );
                                }}
                            />
                        </Box>
                    )}
                </Stack>
            </Box>
        </FormTooltip>
    );
};
