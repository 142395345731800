import { SxProps, Theme } from "@mui/material";

export const styles = {
    container: {
        padding: "40px 52px",
    } as SxProps<Theme>,
    content: {
        borderRadius: "16px",
        border: "1px solid #E7E6E4",
        background: "#FFF",
        paddingTop: "32px",
        marginTop: "40px",
    } as SxProps<Theme>,
};
