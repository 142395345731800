import { Box, Divider, Stack, Typography, useTheme } from "@mui/material";
import { FoodsIcon, SectionTab, SectionTabs } from "@foodpilot/foods";
import { Outlet, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFoodpilotContext } from "src/context/FoodpilotContext";
import { Header } from "./Header";
import { useActionsContext } from "src/context/Actions/useActionsContext";
import { MainContentLayout } from "src/components/Layout/MainContentLayout";

type Sections = "synthesis" | "planning";
export type MainLayoutProps = {
    selectedSection: Sections;
};

type ActionssSectionTab = SectionTab & { title?: string };
type ActionsSections = Partial<Record<Sections, ActionssSectionTab>>;

export const MainLayout = (props: MainLayoutProps) => {
    const { selectedSection } = props;
    const { t } = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();

    const { urls } = useActionsContext();
    const { selectedCompany } = useFoodpilotContext();

    if (!selectedCompany) return <Typography variant="big-bold">{t("error.wrongCompany")}</Typography>;

    const sections: ActionsSections = {
        synthesis: {
            name: t("bred.synthesis"),
            icon: <FoodsIcon icon="scoresIndicators" size={3} />,
            onClick: () => {
                const url = urls.synthesis();
                navigate(url);
            },
        },
        planning: {
            name: t("actionPlan.tabTitle"),
            icon: <FoodsIcon icon="actionPlan" size={3} />,
            onClick: () => {
                const url = urls.planning();
                navigate(url);
            },
        },
    };

    return (
        <Box component="div" sx={{ marginLeft: "auto", marginRight: "auto" }}>
            <Box
                sx={{
                    width: "auto",
                    background: "#fff",
                    padding: "28px 52px",
                    paddingBottom: 0,
                    position: "relative",
                    borderBottom: `1px solid ${theme.custom.grey[500]}`,
                }}
            >
                <Stack
                    direction="row"
                    alignItems="center"
                    gap="16px"
                    sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}
                >
                    <Header />
                </Stack>

                <Divider sx={{ marginTop: "28px" }} />
                <SectionTabs
                    tabs={Object.values(sections)}
                    value={Object.keys(sections).indexOf(selectedSection)}
                    renderComponents={false}
                    sx={{
                        position: "relative",
                        bottom: "-1px",
                    }}
                />
            </Box>

            <Stack gap="28px" sx={{ padding: "40px 52px" }}>
                <Outlet />
            </Stack>
        </Box>
    );
};
