import { PrimaryDialog, Typography } from "@foodpilot/foods";
import { Button, Stack, useTheme } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { useSectionsConfig } from "src/api/client-api/cms/sections/sectionsConfig";
import { SectionWrite, useCreateOneSection } from "src/api/client-api/cms/sections/useCreateOneSection";
import { sectionAllBlocks } from "src/api/client-api/cms/useGetOneContent";

type AddWidgetModalProps = {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    pageIRI: IRI;
    numberOfExistingElements: number;
};
export const AddSectionModal = (props: AddWidgetModalProps) => {
    const { isOpen, setIsOpen, numberOfExistingElements, pageIRI } = props;
    const { t } = useTranslation();

    return (
        <PrimaryDialog
            open={isOpen}
            setOpen={setIsOpen}
            options={{
                title: <Typography variant="big-bold">{t("settings.cms.addSection")}</Typography>,
                content: (
                    <AddWidgetContent
                        closeModal={() => setIsOpen(false)}
                        numberOfExistingElements={numberOfExistingElements}
                        pageIRI={pageIRI}
                    />
                ),
            }}
            fullWidth
            maxWidth="xs"
            cancelText={t("primaryDialog.cancel")}
            validateText={t("primaryDialog.validate")}
        />
    );
};

type AddWidgetContentProps = {
    closeModal: () => void;
    numberOfExistingElements: number;
    pageIRI: IRI;
};
function AddWidgetContent(props: AddWidgetContentProps) {
    const { closeModal, numberOfExistingElements, pageIRI } = props;
    const theme = useTheme();
    const sectionsConfig = useSectionsConfig();

    const createSection = useCreateOneSection();
    return (
        <Stack padding={theme.spacing(4, 0)} gap={theme.spacing(2)} alignItems={"center"}>
            {sectionAllBlocks.map((sectionType) => {
                const config = sectionsConfig[sectionType];
                if (config.isDisabled === true) return null;

                return (
                    <Button
                        variant="primary"
                        onClick={() => {
                            const newSection: SectionWrite = {
                                id: undefined,
                                type: sectionType,
                                title: config.title,
                                visible: false,
                                position: numberOfExistingElements,
                                page: pageIRI,
                            };
                            createSection.mutate(
                                { section: newSection },
                                {
                                    onSuccess: () => {
                                        closeModal();
                                    },
                                },
                            );
                        }}
                        sx={{ minWidth: "30%" }}
                    >
                        {config.title}
                    </Button>
                );
            })}
        </Stack>
    );
}
