import z from "zod";
import { useQuery } from "react-query";

import { loggedInApiCall } from "src/api/axiosClient/userApi";

import { zodParseWithLog } from "../../apiErrorLogs";

export const getAllBrandsKey = "allBrands" as const;

type Options = {
    itemsPerPage?: number;
    page?: number;
    name?: string;
};

export const useGetAllBrands = (options?: Options) => {
    const { itemsPerPage = 20, page = 1, name = null } = options || {};

    const query = useQuery({
        queryKey: [getAllBrandsKey, { itemsPerPage, page }, name],
        queryFn: async () => {
            const url = `/api/brands`;
            return await loggedInApiCall.get(url, {
                params: {
                    "order[name]": "asc",
                    itemsPerPage: itemsPerPage,
                    page: page,
                    name: name,
                },
            });
        },
        select: (request) => {
            const totalItems = request.data["hydra:totalItems"];
            const brands = request.data["hydra:member"];
            const data = zodParseWithLog(brands, BrandListSchema.array(), getAllBrandsKey);
            return {
                items: data,
                totalItems,
            };
        },
    });

    return {
        allBrands: query.data?.items,
        totalItems: query.data?.totalItems,
        ...query,
    };
};

type BrandCompany = {
    "@id": IRI;
    name: string;
};

export type BrandList = {
    "@id": IRI;
    id: number;
    name: string;
    company: BrandCompany;
    logoUrl?: string | null;
};

export const BrandListSchema: z.ZodSchema<BrandList> = z.object({
    "@id": z.string(),
    id: z.number(),
    name: z.string(),
    company: z.object({
        "@id": z.string(),
        name: z.string(),
    }),
    logoUrl: z.string().optional(),
});
