// This is used to be able to search through the URL,
// and be able to find relevant parts of it;
export const companySlug = "company" as const;
export const productSlug = "products" as const;
export const ingredientSlug = "ingredients" as const;
export const ingredientSupplierSlug = "supplier" as const;
export const supplierSlug = "suppliers" as const;
export const CMSSlug = "pages" as const;
export const SettingsSlug = "settings" as const;
export const CsrStrategySlug = "csrStrategy" as const;

const ProductRoutes = {
    list: (companyId: number) => `/${companySlug}/${companyId}/${productSlug}` as const,

    create: (companyId: number, startingPageId?: number) =>
        `/${companySlug}/${companyId}/${productSlug}/new/${startingPageId ?? 0}` as const,

    edit: (companyId: number, productId: number, startingPageId?: number) =>
        `/${companySlug}/${companyId}/${productSlug}/${productId}/edit/${startingPageId ?? 0}` as const,

    view: (companyId: number, productId: number) =>
        `/${companySlug}/${companyId}/${productSlug}/${productId}/details` as const,

    scores: (companyId: number, productId: number) =>
        `/${companySlug}/${companyId}/${productSlug}/${productId}/details/scores` as const,

    score: (companyId: number, productId: number, scoreId: number) =>
        `/${companySlug}/${companyId}/${productSlug}/${productId}/details/scores/${scoreId}` as const,

    information: (companyId: number, productId: number) =>
        `/${companySlug}/${companyId}/${productSlug}/${productId}/details/informations` as const,

    ssq: (companyId: number, productId: number) =>
        `/${companySlug}/${companyId}/${productSlug}/${productId}/details/questionnaire` as const,

    ssqHeading: (companyId: number, productId: number, headingId: number) =>
        `/${companySlug}/${companyId}/${productSlug}/${productId}/details/questionnaire/edit/${headingId}` as const,
} as const;

const IngredientRoutes = {
    list: (companyId: number) => `/${companySlug}/${companyId}/${ingredientSlug}` as const,

    create: (companyId: number, startingPageId?: number) =>
        `/${companySlug}/${companyId}/${ingredientSlug}/new/${startingPageId ?? 0}` as const,

    edit: (companyId: number, ingredientId: number, startingPageId?: number) =>
        `/${companySlug}/${companyId}/${ingredientSlug}/${ingredientId}/edit/${startingPageId ?? 0}` as const,

    view: (companyId: number, ingredientId: number) =>
        `/${companySlug}/${companyId}/${ingredientSlug}/${ingredientId}` as const,

    scores: (companyId: number, ingredientId: number) =>
        `/${companySlug}/${companyId}/${ingredientSlug}/${ingredientId}/scores` as const,

    activity: (companyId: number, ingredientId: number) =>
        `/${companySlug}/${companyId}/${ingredientSlug}/${ingredientId}/activities` as const,

    information: (companyId: number, ingredientId: number) =>
        `/${companySlug}/${companyId}/${ingredientSlug}/${ingredientId}/informations` as const,

    ssq: (companyId: number, ingredientId: number, supplierId: number | undefined) => {
        const noSupplierUrl = `/${companySlug}/${companyId}/${ingredientSlug}/${ingredientId}/questionnaire` as const;
        if (supplierId === undefined) {
            return noSupplierUrl;
        }

        const supplierUrl = `${noSupplierUrl}/${ingredientSupplierSlug}/${supplierId}` as const;
        return supplierUrl;
    },

    ssqHeading: (companyId: number, ingredientId: number, supplierId: number, headingId: number) =>
        `/${companySlug}/${companyId}/${ingredientSlug}/${ingredientId}/questionnaire/${ingredientSupplierSlug}/${supplierId}/edit/${headingId}` as const,
} as const;

const SupplierRoutes = {
    list: (companyId: number) => `/${companySlug}/${companyId}/${supplierSlug}/` as const,

    create: (companyId: number, startingPageId?: number) =>
        `/${companySlug}/${companyId}/${supplierSlug}/new/${startingPageId ?? 0}` as const,

    edit: (companyId: number, supplierId: number, startingPageId?: number) =>
        `/${companySlug}/${companyId}/${supplierSlug}/${supplierId}/edit/${startingPageId ?? 0}` as const,
};

const CompanyRoutes = {
    scores: (companyId: number) => `/${companySlug}/${companyId}/scores` as const,
    score: (companyId: number, scoreId: number) => `/${companySlug}/${companyId}/scores/${scoreId}` as const,
    synthesis: (companyId: number) => `/${companySlug}/${companyId}/synthese` as const,
    ssq: (companyId: number) => `/${companySlug}/${companyId}/questionnaire` as const,
    ssqHeading: (companyId: number, headingId: number) =>
        `/${companySlug}/${companyId}/questionnaire/edit/${headingId}` as const,
    requirements: (companyId: number) => `/${companySlug}/${companyId}/requirements` as const,
    comparison: (companyId: number) => `/${companySlug}/${companyId}/comparison` as const,
} as const;

const ActionRoutes = {
    list: (companyId: number) => `/${companySlug}/${companyId}/actions` as const,
    synthesis: (companyId: number) => `/${companySlug}/${companyId}/actions/synthesis` as const,
    planning: (companyId: number) => `/${companySlug}/${companyId}/actions/planning` as const,
    editPlanning: (companyId: number, actionId: number) =>
        `/${companySlug}/${companyId}/actions/planning/${actionId}` as const,
    chart: (companyId: number, scoreId: number) => `/${companySlug}/${companyId}/actions/synthesis/${scoreId}` as const,
};

const CMSRoutes = {
    list: () => `/${CMSSlug}` as const,
    view: (blockId: number) => `/${CMSSlug}/${blockId}` as const,
};

const SettingsRoutes = {
    list: () => `/${SettingsSlug}` as const,
    scores: () => `/${SettingsSlug}/scores` as const,
    campaigns: () => `/${SettingsSlug}/campaigns` as const,

    // Gamme
    productLine: {
        list: () => `/${SettingsSlug}/product-line` as const,
        create: () => `/${SettingsSlug}/product-line/create` as const,
        edit: (productId: number) => `/${SettingsSlug}/product-line/${productId}/edit` as const,
    } as const,

    // Fillière
    ingredientLine: {
        list: () => `/${SettingsSlug}/ingredient-line` as const,
        create: () => `/${SettingsSlug}/ingredient-line/create` as const,
        edit: (ingId: number) => `/${SettingsSlug}/ingredient-line/${ingId}/edit` as const,
    } as const,
    companySectors: () => `/${SettingsSlug}/company-sectors` as const,
    csrTheme: {
        list: () => `/${SettingsSlug}/csrTheme` as const,
        create: () => `/${SettingsSlug}/csrTheme/create` as const,
        edit: (csrId: number) => `/${SettingsSlug}/csrTheme/${csrId}/edit` as const,
    } as const,
    companies: {
        view: (companyId: number) => `/${SettingsSlug}/companies/${companyId}` as const,
        list: () => `/${SettingsSlug}/companies` as const,
        create: () => `/${SettingsSlug}/companies/create` as const,
        edit: (companyId: number) => `/${SettingsSlug}/companies/${companyId}/edit` as const,
    } as const,
    providers: {
        list: () => `/${SettingsSlug}/providers` as const,
        create: () => `/${SettingsSlug}/providers/create` as const,
        edit: (providerId: number) => `/${SettingsSlug}/providers/${providerId}/edit` as const,
    } as const,
    users: {
        view: (userId: number) => `/${SettingsSlug}/users/${userId}` as const,
        list: () => `/${SettingsSlug}/users` as const,
        create: () => `/${SettingsSlug}/users/create` as const,
        edit: (userId: number) => `/${SettingsSlug}/users/${userId}/edit` as const,
    } as const,

    infos: () => `/${SettingsSlug}/infos` as const,
    appParams: () => `/${SettingsSlug}/applicationParameters` as const,
    brands: {
        list: () => `/${SettingsSlug}/brands` as const,
        create: () => `/${SettingsSlug}/brands/create` as const,
        edit: (brandId: number) => `/${SettingsSlug}/brands/${brandId}/edit` as const,
    } as const,
    roles: {
        list: () => `/${SettingsSlug}/roles` as const,
        create: () => `/${SettingsSlug}/roles/create` as const,
        edit: (roleId: string) => `/${SettingsSlug}/roles/${roleId}/edit` as const,
    } as const,

    cms: {
        page: (pageId: string) => `/${SettingsSlug}/cms/${pageId}` as const,
        section: (pageId: string, sectionId: string) => `/${SettingsSlug}/cms/${pageId}/sections/${sectionId}` as const,
        block: (pageId: string, sectionId: string, blockId: string) => {
            const url = `/${SettingsSlug}/cms/${pageId}/sections/${sectionId}/blocks/${blockId}` as const;
            return url;
        },
    } as const,

    csrStrategy: {
        general: () => `/${SettingsSlug}/${CsrStrategySlug}/general` as const,
        pillars: () => `/${SettingsSlug}/${CsrStrategySlug}/pillars` as const,
        pillarsEdit: (pillarId: string) => `/${SettingsSlug}/${CsrStrategySlug}/pillars/${pillarId}` as const,
        createPillar: () => `/${SettingsSlug}/${CsrStrategySlug}/pillars/new` as const,
        themes: () => `/${SettingsSlug}/${CsrStrategySlug}/themes` as const,
    },
} as const;

const Strategy = {
    list: () => `/strategy` as const,
} as const;

const LoginRoutes = {
    logout: () => `/logout` as const,
    noCompany: () => `/noCompany` as const,
} as const;

// Here, the routes for Inexweb don't exist because they aren't supposed to be even included
// In the current application.
// At the time, we had reasons to do it. But now, we want to evolve from this situation
// And either include Inexweb in our App, on those existing routes, or take it out entirely.
export const URLHandler = {
    home: () => "/" as const,
    login: LoginRoutes,

    actions: ActionRoutes,
    company: CompanyRoutes,
    product: ProductRoutes,
    ingredient: IngredientRoutes,
    supplier: SupplierRoutes,
    cms: CMSRoutes,
    settings: SettingsRoutes,

    strategy: Strategy,
    documentation: () => `/documentation`,
} as const;
