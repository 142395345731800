import { useContext } from "react";
import { ScoreDetailContext } from "./context";

export const useScoreDetailContext = () => {
    const context = useContext(ScoreDetailContext);

    if (context) {
        return context;
    }

    throw new Error(`useScoreDetailContext must be used within the context`);
};
