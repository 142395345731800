import { DottedBox, FoodsIcon } from "@foodpilot/foods";
import { Button, Stack, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

type NoIndicatorsProps = {
    action: () => void;
};
export const NoIndicators = (props: NoIndicatorsProps) => {
    const { action } = props;
    const { t } = useTranslation();
    const theme = useTheme();

    return (
        <DottedBox>
            <Stack alignItems={"center"} padding={theme.spacing(4)}>
                <Typography variant="big-medium">{t("settings.csr.form.indicator.noItem")}</Typography>
                <Button variant="text" startIcon={<FoodsIcon icon="plus" size={2} />} onClick={action}>
                    {t("settings.csr.form.indicator.addButton", { verb: t("glossary.create") })}
                </Button>
            </Stack>
        </DottedBox>
    );
};
