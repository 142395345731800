import { FC } from "react";
import { Box, BoxProps, useTheme } from "@mui/material";

type ProgressBarProps = BoxProps & {
    progress: number;
    barColor?: string;
};

const ProgressBar: FC<ProgressBarProps> = ({ barColor, progress, sx, ...boxProps }) => {
    const theme = useTheme();

    const clampedProgress = Math.min(Math.max(progress, 0), 100);

    return (
        <Box
            {...boxProps}
            sx={{
                height: 12,
                width: "100%",
                borderRadius: 50,
                overflow: "hidden",
                position: "relative",
                maxWidth: 350,
                ...sx,
            }}
        >
            <Box
                sx={{
                    height: "100%",
                    width: `${clampedProgress}%`,
                    backgroundColor: barColor || theme.palette.primary.main,
                    borderRadius: "inherit",
                    transition: "width 0.3s ease-in-out",
                }}
            />
        </Box>
    );
};

export default ProgressBar;
