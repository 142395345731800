import { SxProps, Theme } from "@mui/material";

export const styles = {
    emptyContainer: {
        border: "1px solid #E7E6E4",
        margin: "2rem",
        display: "flex",
        borderRadius: "0.5rem",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
    } as SxProps<Theme>,
};
