import { LocalStorage } from "./LocalStorage";
import { URLHandler } from "./URLHandler";

export type Token = {
    token: string | null;
    refreshToken: string | null;
    connectedAs: string | null;
};

export class UserService {
    static getFullToken(): Token {
        return {
            token: LocalStorage.getItem("token"),
            refreshToken: LocalStorage.getItem("refresh-token"),
            connectedAs: LocalStorage.getItem("connectedAs"),
        };
    }

    static isImpersonationActive = (): boolean => {
        return LocalStorage.getItem("connectedAs") !== null;
    };

    static unimpersonateUser = (): void => {
        LocalStorage.deleteItem("connectedAs");
        LocalStorage.deleteItem("company");
        window.location.href = URLHandler.home();
    };

    static impersonateUser = (userEmail: string) => {
        LocalStorage.setItem("connectedAs", userEmail);
        LocalStorage.deleteItem("company");
        window.location.href = URLHandler.home();
    };

    static logout = () => {
        LocalStorage.deleteItem("connectedAs");
        // We don't delete the `company` as we want to keep the current
        // State of the select-company;
    };
}
