import { OptionsPopoverOption, WaterfallChart, ValueSwitcher } from "@foodpilot/foods";
import { Divider, Stack, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Breadcrumbs } from "src/components/Breadcrumb/Breadcrumbs";
import { Loading } from "src/components/utils/Loading";
import { ActionService } from "src/context/Actions/ActionService";
import { useActionsContext } from "src/context/Actions/useActionsContext";

export const ScoreWaterfall = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const params = useParams();

    const {
        startingPost: { post, originalPost },
        scoresEnabled,
        selectedScore,
        actionsDataByCsr,
        allScoreWithVariations,
        selectedSimulation,
    } = useActionsContext();

    const [selectedItemId, setSelectedItemId] = useState<number | null>(null);
    const selectedVariationId = selectedScore?.variationId ?? 0;
    const { differences, start, target } = ActionService.computeWaterfallData({
        startingPost: post,
        targetPost: selectedSimulation,
        actionsDataByCsr,
        selectedOption: selectedScore ?? allScoreWithVariations[selectedVariationId],
        scorePrecision: selectedScore?.precision,
    });

    // msa, note to self: ts yells "scores does not exist" why ??!
    // @ts-expect-error someone didnt add the keys to zod
    const refScore = originalPost?.scores?.[selectedScore.id]?.score;
    const refValue = refScore ? Number(refScore.toFixed()) : 0;
    const options = allScoreWithVariations
        .filter((scoreVariation) => scoreVariation.scoreId === Number(params.scoreVariationId))
        .map((score) => ({
            ...score,
            onClick: (option: OptionsPopoverOption) => {
                score.onClick();
                setSelectedItemId(Number(option.id));
            },
        }));

    const selectedItem = options.find((i) => i.id === selectedItemId);

    useEffect(() => {
        const previouslySelectedVariation = options.find((score) => score.variationId === selectedScore?.variationId);
        if (previouslySelectedVariation) {
            return setSelectedItemId(previouslySelectedVariation.id);
        }
        if (allScoreWithVariations.length > 0) {
            return setSelectedItemId(allScoreWithVariations[0].id);
        }
    }, []);

    return (
        <Stack gap={3}>
            <Stack gap={1}>
                <Stack flexDirection={"row"} gap={theme.spacing(2)}>
                    {selectedScore && (
                        <Stack
                            flexDirection={"row"}
                            gap={theme.spacing(1)}
                            alignItems={"center"}
                            divider={<Divider flexItem orientation="vertical" />}
                        >
                            <Typography variant="h2">
                                {scoresEnabled.find((se) => se.id === selectedScore.scoreId)?.title}
                            </Typography>
                            <ValueSwitcher
                                selectedItem={selectedItem}
                                optionsProps={{
                                    content: [{ options }],
                                    onChange: () => {},
                                }}
                            />
                        </Stack>
                    )}
                </Stack>
                <Breadcrumbs items={[{ label: t("bred.synthesis") }, { label: selectedScore?.label ?? "" }]} />
            </Stack>
            <Stack
                border={(theme) => `1px solid ${theme.custom.grey[500]}`}
                borderRadius={3}
                p={3}
                sx={{ backgroundColor: "white" }}
            >
                {differences.length === 0 && <Loading />}
                {differences.length > 0 && (
                    <WaterfallChart
                        reference={{
                            value: refValue,
                            label: originalPost?.campaign.label ?? "",
                        }}
                        start={{ ...start, value: start.value ?? 0 }}
                        values={differences.map((d) => ({ ...d, value: d.value ?? 0 }))}
                        target={target}
                    />
                )}
            </Stack>
        </Stack>
    );
};
