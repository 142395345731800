import { Route, Navigate, Outlet } from "react-router-dom";
import { useProductRouter } from "../Product/productRouter";
import { useIngredientRouter } from "../Ingredient/ingredientRouter";
import { useSupplierRouter } from "../Supplier/supplierRouter";
import { useClientSwitch } from "src/components/ClientSwitch/useClientSwitch";
import { CompanyTabs } from "./CompanyTabs";
import { companySlug, URLHandler } from "src/services/URLHandler";
import { RouterClientSwitch } from "src/components/ClientSwitch/RouterClientSwitch";
import { QuestionnaireUpdate } from "./Questionnaire/QuestionnaireUpdate/QuestionnaireUpdate";
import { MainLayout } from "../Actions/MainLayout";
import { ActionsProvider } from "src/context/Actions/provider";
import { ListPlanning } from "../Actions/Planning/List/ListPlanning";
import { Synthesis } from "../Actions/Synthesis/Synthesis";
import { EditPlanningProperties } from "../Actions/Planning/EditProperties/EditPlanningProperties";
import { useFoodpilotContext } from "src/context/FoodpilotContext";
import { CompanyProvider } from "src/context/Company/provider";
import { useCompanyContext } from "src/context/Company/useCompanyContext";
import { useGetCompanyPosts } from "src/api/client-api/post/useGetAllPosts";
import { Loading } from "src/components/utils/Loading";
import { useGetOnePost } from "src/api/client-api/post/useGetOnePost";
import { ScoreWaterfall } from "../Actions/Chart/ScoreWaterfall";
import { ScoreProvider } from "src/context/ScoreContext";
import CompanyScoreContextProvider from "src/pages/Company/Scores/CompanyScoreContextProvider.tsx";
import { CompanyScore } from "src/pages/Company/Scores/CompanyScore.tsx";

export const useCompanyRouter = () => {
    const productRouter = useProductRouter();
    const ingredientRouter = useIngredientRouter();
    const supplierRouter = useSupplierRouter();
    const clientSwitch = useClientSwitch();

    const cs_defaultRedirect = clientSwitch({
        defaultOption: <Navigate to="questionnaire" replace />,
        bred: <Navigate to="synthese" replace />,
        collective: <Navigate to="scores" replace />,
    });

    return (
        <Route path={`${companySlug}/:companyId`}>
            <Route index element={cs_defaultRedirect} />

            <Route element={<CompanyContext />}>
                <Route
                    path="scores"
                    element={<RouterClientSwitch whitelistedMode={["collective", "foodpilotLustucru"]} />}
                >
                    <Route index element={<CompanyTabs section="scores" />} />
                    <Route path=":scoreId" element={<CompanyScoreContext />}>
                        <Route index element={<CompanyTabs section="scores" />} />
                    </Route>
                </Route>

                <Route path="synthese" element={<RouterClientSwitch whitelistedMode={["bred"]} />}>
                    <Route index element={<CompanyTabs section="synthese" />} />
                </Route>

                <Route path="comparison" element={<CompanyTabs section="comparison" />} />

                <Route
                    path="questionnaire"
                    element={<RouterClientSwitch whitelistedMode={["bred", "collective", "foodpilotLustucru"]} />}
                >
                    <Route index element={<CompanyTabs section="questionnaire" />} />
                    <Route path="edit/:headingId" element={<QuestionnaireUpdate />} />
                </Route>

                <Route path="requirements" element={<RouterClientSwitch whitelistedMode={["bred"]} />}>
                    <Route index element={<CompanyTabs section="exigences" />} />
                </Route>

                <Route path="actions" element={<ActionCompanyContext />}>
                    <Route index element={<Navigate to="synthesis" replace />} />
                    <Route path="synthesis">
                        <Route element={<MainLayout selectedSection="synthesis" />}>
                            <Route index element={<Synthesis />}></Route>
                            <Route path=":scoreVariationId" element={<ScoreWaterfall />} />
                        </Route>
                    </Route>

                    <Route path="planning">
                        <Route element={<MainLayout selectedSection="planning" />}>
                            <Route index element={<ListPlanning />} />
                            <Route path=":actionId" element={<EditPlanningProperties />} />
                        </Route>
                    </Route>
                </Route>
            </Route>
            {productRouter}
            {ingredientRouter}
            {supplierRouter}
        </Route>
    );
};

const CompanyContext = () => {
    return (
        <CompanyProvider>
            <Outlet />
        </CompanyProvider>
    );
};

const CompanyScoreContext = () => {
    return (
        <CompanyScoreContextProvider>
            <Outlet />
        </CompanyScoreContextProvider>
    );
};

const ActionCompanyContext = () => {
    const { targetCampaign } = useFoodpilotContext();
    const { company, companyScores, scoreState, ssq } = useCompanyContext();

    const { dimensions, variations } = scoreState.query;

    // BAU means Business As Usual. I'm not sure yet of the naming, but that's
    // the one we used in our recent conversations about this feature.
    const {
        posts: calculationPosts,
        isFetched: isBAUFetched,
        isRefetching: isBAURefetching,
    } = useGetCompanyPosts({
        entityId: company.id,
        status: "calculation",
        scoreParams: { dimensions, variations },
        extraParams: {
            // We put the specific campaign in a GET ALL, because if we GET ONE,
            // Unfortunately, the backend only allows for GET or Create.
            "campaign.id": targetCampaign?.id,
        },
    });

    const bauPost = calculationPosts === undefined || calculationPosts.length === 0 ? undefined : calculationPosts[0];

    const { post: originalPost } = useGetOnePost({ postId: bauPost?.duplicatedFromId });

    const {
        posts: simulationPosts,
        isFetched,
        isRefetching,
    } = useGetCompanyPosts({
        entityId: company.id,
        status: "simulation",
        scoreParams: { dimensions, variations },
    });

    if (isBAURefetching === false) {
        if (isBAUFetched === false) {
            return <Loading />;
        }
    }

    if (isRefetching === false) {
        if (isFetched === false) {
            return <Loading />;
        }
    }

    if (ssq.isSsqRefetching === false) {
        if (ssq.isSsqFetched === false) {
            return <Loading />;
        }
    }
    if (ssq.isPostRefetching === false) {
        if (ssq.isPostFetched === false) {
            return <Loading />;
        }
    }

    if (ssq.ssq === undefined || ssq.post === undefined) {
        return <>You must answer a questionnaire before entering Trajectory</>;
    }

    if (company.scores === undefined) {
        return <>No Scores !</>;
    }

    return (
        <ActionsProvider
            initialPostId={ssq.post.id}
            startingPost={{
                post: bauPost ?? null,
                originalPost: originalPost ?? null,
            }}
            lastExistingPost={undefined}
            allSimulations={simulationPosts}
            ssq={ssq.ssq}
            scoresEnabled={companyScores}
            scoreState={scoreState.state}
            urls={{
                planning: () => URLHandler.actions.planning(company.id),
                synthesis: () => URLHandler.actions.synthesis(company.id),
                editPlanning: (actionId: number) => URLHandler.actions.editPlanning(company.id, actionId),
                chart: (scoreId: number) => URLHandler.actions.chart(company.id, scoreId),
            }}
        >
            <ScoreProvider type="company" formattedScores={companyScores}>
                <Outlet />
            </ScoreProvider>
        </ActionsProvider>
    );
};
