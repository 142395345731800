import { useQuery } from "react-query";
import { zodParseWithLog } from "src/api/apiErrorLogs";
import { loggedInApiCall } from "src/api/axiosClient/userApi";

import { z } from "zod";
import { SupplierType } from "./useCreateSupplier";
import { Scores, ScoresSchema } from "../post/scoreSchema";

export const getOneSupplierKey = "getOneSupplier" as const;

const makeSectorsFromSuppliers = (supplier: Supplier): Supplier => {
    const sectorSet = new Set<string>([]);
    const sectors = supplier.ingredientSuppliers?.reduce((acc, ingSupplier) => {
        const sector = ingSupplier.ingredient.ingredientLine?.name;
        if (sector === undefined) return acc;

        acc.add(sector);
        return acc;
    }, sectorSet);

    return {
        ...supplier,
        sectors: Array.from(sectors ?? []),
    };
};

type Options = {
    queryParams: AxiosParams;
    enabled?: boolean;
};

export const useGetOneSupplier = (supplierId?: string | number, options?: Options) => {
    const { queryParams, enabled = true } = options || {};

    const query = useQuery({
        queryKey: [getOneSupplierKey, supplierId, queryParams],
        cacheTime: 0,
        enabled: supplierId !== undefined && enabled,
        keepPreviousData: true,
        queryFn: async () => {
            const url = `/api/companies/${supplierId}`;
            const response = await loggedInApiCall.get(url, {
                params: {
                    ...queryParams,
                },
            });
            return response;
        },
        select: (request) => {
            const supplierWithSector = makeSectorsFromSuppliers(request.data);
            const data = zodParseWithLog(supplierWithSector, SupplierZodSchema, getOneSupplierKey);

            return data;
        },
    });

    return {
        supplier: query.data,
        ...query,
    };
};

export type IngredientLine = {
    "@id": IRI;
    id: number;
    name: string;
};

export const IngredientLineZodSchema = z.object({
    "@id": z.string(),
    id: z.number(),
    name: z.string(),
});

export type Domain = {
    "@id": IRI;
    name: string;
};

export const DomainSchema = z.object({
    "@id": z.string(),
    name: z.string(),
});

export type NewIngredientFromSupplier = {
    "@id": IRI;
    id: number;
    name: string;
    ingredientLine?: IngredientLine;
    ingredientReferenceId?: number;
    origin?: string;
};

export type IngredientSupplier = {
    "@id": IRI;
    id: number;
    ingredient: NewIngredientFromSupplier;
};

export type Contributor = {
    "@id": IRI;
    id: number;
    email: string;
    firstname: string;
    lastname: string;
};

export type SupplierCommon = {
    "@id": IRI;
    id: number;
    name: string;
    clientId?: string;
    address1?: string;
    address2?: string;
    city?: string;
    zipcode?: string;
    state?: string;
    country?: string;
    phone?: string;
    email?: string;
    users?: Contributor[];
    domains: Domain[];
    ingredientSuppliers?: IngredientSupplier[];
    sectors?: string[];
    scores?: Scores;
    companySector?: CompanySector;
    type: SupplierType;
};
export type Supplier = SupplierCommon & {
    parent?: SupplierCommon;
    canModify: boolean;
    canDelete: boolean;
};

type CompanySector = {
    "@id": string;
    id: number;
    name?: string;
    ssqId?: number;
};

export const CompanySectorZod: z.ZodSchema<CompanySector> = z.object({
    "@id": z.string(),
    id: z.number(),
    name: z.string(),
    ssqId: z.number().optional(),
});

const SupplierCommonZodSchema: z.ZodSchema<SupplierCommon> = z.object({
    "@id": z.string(),
    id: z.number(),
    name: z.string(),
    clientId: z.string().optional(),
    address1: z.string().optional(),
    address2: z.string().optional(),
    city: z.string().optional(),
    zipcode: z.string().optional(),
    country: z.string().optional(),
    state: z.string().optional(),
    email: z.string().optional(),
    phone: z.string().optional(),
    users: z
        .object({
            "@id": z.string(),
            id: z.number(),
            email: z.string(),
            firstname: z.string(),
            lastname: z.string(),
        })
        .array()
        .optional(),
    domains: DomainSchema.array(),
    ingredientSuppliers: z
        .object({
            "@id": z.string(),
            id: z.number(),
            ingredient: z.object({
                "@id": z.string(),
                id: z.number(),
                name: z.string(),
                ingredientLine: z
                    .object({
                        "@id": z.string(),
                        id: z.number(),
                        name: z.string(),
                    })
                    .optional(),
                ingredientReferenceId: z.number().optional(),
                origin: z.string().optional(),
            }),
        })
        .array()
        .optional(),
    sectors: z.string().array().optional(),
    scores: ScoresSchema.optional(),
    companySector: CompanySectorZod.optional(),
    type: z.enum(["main", "branch", "provider"]),
});

export const SupplierZodSchema: z.ZodSchema<Supplier> = z.intersection(
    SupplierCommonZodSchema,
    z.object({
        parent: SupplierCommonZodSchema.optional(),
        canModify: z.boolean(),
        canDelete: z.boolean(),
    }),
);
