import React, { useRef } from "react";
import { Button, FoodsIcon } from "@foodpilot/foods";
import { useTranslation } from "react-i18next";
import { UploadDocumentMutationPayload } from "src/api/client-api/documentation/types";
import { useDocumentListContext } from "../../DocumentationContext";
import { Loading } from "src/components/utils/Loading";
import { styles } from "./styles/DocumentUpload";

export const DocumentUpload = () => {
    const { t } = useTranslation();
    const { uploadDocument, isUploading } = useDocumentListContext();
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (!file) return;

        const documentData: UploadDocumentMutationPayload = {
            name: file.name,
            mimeType: file.type,
            file,
            tags: [],
        };

        try {
            await uploadDocument(documentData);
            // Reset file input
            if (fileInputRef.current) {
                fileInputRef.current.value = "";
            }
        } catch (error) {
            console.error("Error uploading document:", error);
        }
    };

    const handleClick = () => {
        fileInputRef.current?.click();
    };

    return (
        <>
            <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileChange}
                style={styles.fileInput}
                accept=".pdf,.doc,.docx,.txt,.jpg,.jpeg,.png"
            />
            <Button
                variant="primary"
                onClick={handleClick}
                disabled={isUploading}
                startIcon={isUploading ? <Loading size={14} /> : <FoodsIcon icon="add" size={2} />}
            >
                {isUploading ? t("documentation.uploading") : t("documentation.upload")}
            </Button>
        </>
    );
};
