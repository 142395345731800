import { FoodsIcon } from "@foodpilot/foods";
import { Box, MenuItem, Popover, Stack, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useCsrPillarDelete } from "src/api/client-api/csrStrategy/Pillars/useDeleteCsrPillar";
import { URLHandler } from "src/services/URLHandler";

type Anchor = HTMLElement | null;

type ActionsPopoverProps = {
    anchor: Anchor;
    setAnchor: (elementToAttachTo: Anchor) => void;
    pillarId: string | undefined;
};
export const ActionsPopover = (props: ActionsPopoverProps) => {
    const { anchor, setAnchor, pillarId } = props;

    const isOpen = Boolean(anchor);
    return (
        <Popover
            open={isOpen}
            anchorEl={anchor}
            onClose={() => setAnchor(null)}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            slotProps={{
                paper: {
                    sx: {
                        borderRadius: "12px",
                        boxShadow: "0px 4px 20px 0px rgba(81, 78, 78, 0.12)",
                    },
                },
            }}
        >
            <ActionsPopoverContent pillarId={pillarId} />
        </Popover>
    );
};

type ActionsPopoverContentProps = {
    pillarId: string | undefined;
};
const ActionsPopoverContent = (props: ActionsPopoverContentProps) => {
    const { pillarId } = props;

    const deletePillar = useCsrPillarDelete();

    const navigate = useNavigate();
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <Stack gap={theme.spacing(2)} padding={theme.spacing(2, 4)}>
            {/* <MenuItem
                onClick={() => {
                    if (pillarId === undefined) return;
                    const url = URLHandler.settings.csrStrategy.pillarsEdit(pillarId.toString(), 2);
                    navigate(url);
                }}
                sx={{ padding: theme.spacing(1) }}
            >
                <FoodsIcon size={2} icon="actions" />
                <Typography sx={theme.custom.rowMenuElement}>{t("settings.csr.pillars.modifyIndicators")}</Typography>
            </MenuItem>
            <Box borderBottom={`1px solid ${theme.custom.grey[500]}`} /> */}
            <MenuItem
                onClick={() => {
                    if (pillarId === undefined) return;
                    const url = URLHandler.settings.csrStrategy.pillarsEdit(pillarId.toString());
                    navigate(url);
                }}
                sx={{ padding: theme.spacing(1) }}
            >
                <FoodsIcon size={2} icon="edit" />
                <Typography sx={theme.custom.rowMenuElement}>{t("settings.csr.pillars.modifyPillar")}</Typography>
            </MenuItem>
            {/* <MenuItem
                onClick={() => {
                    const url = URLHandler;
                    navigate(url);
                }}
                sx={{ padding: theme.spacing(1) }}
            >
                <FoodsIcon size={2} icon="actions" />
                <Typography sx={theme.custom.rowMenuElement}>{t("settings.csr.pillars.duplicatePillar")}</Typography>
            </MenuItem> */}
            <MenuItem
                onClick={() => {
                    if (pillarId === undefined) return;
                    deletePillar.mutate({ pillarId: pillarId });
                }}
                sx={{ padding: theme.spacing(1) }}
            >
                <FoodsIcon size={2} icon="delete" />
                <Typography sx={theme.custom.rowMenuElement}>{t("settings.csr.pillars.deletePillar")}</Typography>
            </MenuItem>
        </Stack>
    );
};
