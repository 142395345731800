import { CMSPageSection } from "src/api/client-api/cms/useGetOneContent";
import { useState } from "react";
import { SectionWrite, useCreateOneSection } from "src/api/client-api/cms/sections/useCreateOneSection";
import { RoundInput } from "@foodpilot/foods";
import { Typography } from "@mui/material";
import { t } from "i18next";
import { ConfirmationFooter } from "../ConfirmationFooter";
import { EditLayout } from "../EditLayout";

type SectionForm = {
    id: UUID;
    title: string;
    visible: boolean;
};

const getSectionForm = (section: CMSPageSection): SectionForm => {
    const firstBlock: SectionForm = {
        id: section.id,
        visible: section.visible,
        title: section.title,
    };
    return firstBlock;
};

const sectionFormToWrite = (pageId: IRI, form: SectionForm): SectionWrite => {
    const sectionTitle = form.title === "" ? " " : form.title;
    const section: SectionWrite = {
        id: form.id,
        title: sectionTitle,
        page: pageId,
    };
    return section;
};

type SectionFormProps = {
    section: CMSPageSection;
};
export const SectionForm = (props: SectionFormProps) => {
    const { section } = props;
    const createSection = useCreateOneSection();

    const indicatorForm = getSectionForm(section);
    const [indicatorFormState, setIndicatorFormState] = useState<SectionForm>(indicatorForm);

    return (
        <EditLayout>
            <Typography variant="big-medium">{t("cms.section.articles.addTitle")}</Typography>
            <RoundInput
                value={indicatorFormState.title}
                onChange={(e) => {
                    const newValue = e.currentTarget.value;
                    setIndicatorFormState({
                        ...indicatorFormState,
                        title: newValue,
                    });
                }}
            />
            <ConfirmationFooter
                saveAction={() => {
                    const newSection = sectionFormToWrite(section.page, indicatorFormState);
                    createSection.mutate({ section: newSection });
                }}
                resetContent={() => {}}
            />
        </EditLayout>
    );
};
