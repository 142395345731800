import { useQueries } from "react-query";
import { fetchIngredientPosts } from "./useGetIngredientPosts";

export const useGetIngredientsPosts = (ingredientIds: number[], campaignId?: number) => {
    return useQueries(
        ingredientIds.map((ingredientId) => ({
            queryFn: () => fetchIngredientPosts(ingredientId, campaignId),
            queryKey: ["getIngredientsPosts", ingredientId, campaignId],
        })),
    );
};
