import { Mutation, MutationCache, Query, QueryCache, QueryClient } from "react-query";
import { refreshTokenHandler } from "./refreshToken";

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: 0,
            keepPreviousData: true,

            refetchOnWindowFocus: false, // default is true.
            refetchOnReconnect: true,
        },
        mutations: {
            retry: 0,
        },
    },
    queryCache: new QueryCache({
        onError: (error: unknown, query: Query<unknown, unknown, unknown>) => {
            queryErrorHandler(error, query);
        },
    }),
    mutationCache: new MutationCache({
        onError: (
            error: unknown,
            variables: unknown,
            context: unknown,
            mutation: Mutation<unknown, unknown, unknown, unknown>,
        ) => {
            mutationErrorHandler(error, variables, context, mutation);
        },
    }),
});

const queryErrorHandler = (error: unknown, query: Query) => {
    refreshTokenHandler(error, query);
};

const mutationErrorHandler = (
    error: unknown,
    variables: unknown,
    _context: unknown,
    mutation: Mutation<unknown, unknown, unknown, unknown>,
) => {
    refreshTokenHandler(error, undefined, mutation, variables);
};
