import { Box, Stack, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useGetOneContent } from "src/api/client-api/cms/useGetOneContent";
import { MainContentLayout } from "src/components/Layout/MainContentLayout";
import { Loading } from "src/components/utils/Loading";
import { useFoodpilotContext } from "src/context/FoodpilotContext";
import { HomeCMS_ID } from "../Settings/CMS/Pages/useCreateHomePage";
import { SectionSelector } from "src/components/CMS/Widgets/SectionSelector";
import { useSectionsConfig } from "src/api/client-api/cms/sections/sectionsConfig";

export const Homepage = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { user } = useFoodpilotContext();
    const { content, isFetched } = useGetOneContent({ contentId: HomeCMS_ID });
    const sectionConfig = useSectionsConfig();

    if (isFetched === false) {
        return <Loading />;
    }

    if (content === undefined) {
        return undefined;
    }

    const name = user.trustedUser.firstname;

    return (
        <MainContentLayout>
            <Stack gap={theme.spacing(3)} width={"100%"}>
                <Typography
                    variant={"h1"}
                    marginBottom={theme.spacing(3)}
                >{`${t("glossary.hello")} ${name} !`}</Typography>
                {content.sections.map((section) => {
                    const config = sectionConfig[section.type];
                    if (config.isDisabled === true) return null;
                    if (section.visible === false) return null;
                    return (
                        <Stack gap={theme.spacing(3)} key={section.id}>
                            <SectionSelector section={section} />
                        </Stack>
                    );
                })}
            </Stack>
        </MainContentLayout>
    );
};
