import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useState } from "react";
import { useIngredientColumns } from "./useIngredientColumns";
import { IngredientList, useGetAllIngredient } from "src/api/client-api/ingredients/useGetAllIngredients";
import { useGridColumns, GridColumnsType } from "src/utils/useGridColumns";
import { MRT_PaginationState, MRT_SortingState } from "material-react-table";
import { useDelayedSearch } from "../../../utils/useDelayedSearch";
import { useFoodpilotContext } from "src/context/FoodpilotContext";
import { IngredientReference } from "src/api/sure-api/ingredientReference/useGetAllIngredientReference";

type GammeSelection = string | undefined;

type IngredientListContextInitialValues = {
    mrt_columns: GridColumnsType<IngredientList> & {
        refIngIdToRefIng: Record<number, IngredientReference> | undefined;
    };

    ingredientPage: MRT_PaginationState;
    // It needs to be a Dispatch to go into the library MRT_
    setIngredientPage: Dispatch<SetStateAction<MRT_PaginationState>>;

    sorting: MRT_SortingState;
    setSorting: Dispatch<SetStateAction<MRT_SortingState>>;

    totalItems: number | undefined;
    ingredients: IngredientList[] | undefined;
    isLoading: boolean;

    currentGamme: GammeSelection;
    setCurrentGamme: (arg1: GammeSelection) => void;

    searchIngredient: string;
    delayedSearchIngredient: string;
    setSearchIngredient: (arg1: string) => void;
    resetSearch: () => void;

    gridKey: string;
};
const IngredientListContext = createContext<IngredientListContextInitialValues | null>(null);

type IngredientListContextProviderProps = { children: ReactNode };
export const IngredientListContextProvider = (props: IngredientListContextProviderProps) => {
    const { children } = props;
    const [ingredientPage, setIngredientPage] = useState<MRT_PaginationState>({
        pageIndex: 0,
        pageSize: 20,
    });
    const [sorting, setSorting] = useState<MRT_SortingState>([{ id: "name", desc: false }]);

    const [currentGamme, setCurrentGamme] = useState<GammeSelection>(undefined);

    const { currentCampaign, selectedCompany } = useFoodpilotContext();
    const search = useDelayedSearch();
    const { ingredients, isLoading, totalItems, isRefetching } = useGetAllIngredient({
        cacheTime: 0,
        page: ingredientPage.pageIndex + 1,
        itemsPerPage: ingredientPage.pageSize,
        search: search.delayedSearch,
        ingredientLine: currentGamme,
        sorting: sorting,
        campaignId: currentCampaign?.id,
        companyId: selectedCompany?.id,
    });

    const gridKey = "ingredients-grid";

    const { mrt_ingredientColumns, refIngIdToRefIng } = useIngredientColumns(isRefetching);
    const mrt_columns = useGridColumns(mrt_ingredientColumns, gridKey);

    const items: IngredientListContextInitialValues = {
        mrt_columns: { ...mrt_columns, refIngIdToRefIng },

        ingredientPage,
        setIngredientPage,

        sorting,
        setSorting,

        currentGamme,
        setCurrentGamme,

        totalItems,
        ingredients: ingredients,
        isLoading,

        searchIngredient: search.search,
        delayedSearchIngredient: search.delayedSearch,
        setSearchIngredient: search.setSearch,
        resetSearch: search.resetSearch,

        gridKey: gridKey,
    };

    return <IngredientListContext.Provider value={items}>{children}</IngredientListContext.Provider>;
};

export const useIngredientListContext = () => {
    const context = useContext(IngredientListContext);

    if (context) {
        return context;
    }

    throw new Error(`useIngredientListContext must be used within an IngredientListContextProvider`);
};
