import { useQuery } from "react-query";
import { sureApiCall } from "src/api/axiosClient/sureApi";
import { zodParseWithLog } from "src/api/apiErrorLogs";
import { ActionsSchema } from "./useGetOneAction";

export const getAllActionsKey = "getAllActions" as const;

export type Options = {
    ssqId: number;
};
export const useGetAllActions = (options: Options) => {
    const { ssqId } = options;

    const query = useQuery({
        queryKey: [getAllActionsKey],
        cacheTime: 0,
        queryFn: async () => {
            const url = `/api/actions`;
            return await sureApiCall.get(url, {
                params: {
                    ssqId: ssqId,
                },
            });
        },
        select: (response) => {
            const actions = response.data["hydra:member"];
            const data = zodParseWithLog(actions, ActionsSchema.array(), getAllActionsKey);
            return data;
        },
    });

    return {
        actions: query.data,
        ...query,
    };
};
