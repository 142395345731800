import { useContext } from "react";
import { UserContext } from "./context";

export const useUserContext = () => {
    const context = useContext(UserContext);

    if (context) {
        return context;
    }

    throw new Error(`useUserContext must be used within the context`);
};
