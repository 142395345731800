import { useQuery } from "react-query";
import { sureApiCall } from "src/api/axiosClient/sureApi";
import { zodParseWithLog } from "src/api/apiErrorLogs";
import { SsqListSchema } from "./useGetAllSsq";

export const useGetSomeSsq = (ssqIds: number[] | undefined) => {
    const itemsPerPage = ssqIds?.length;
    const query = useQuery({
        cacheTime: 0,
        enabled: ssqIds !== undefined,
        queryKey: ["getSomeSsq", ssqIds],
        queryFn: async () => {
            return await sureApiCall.get(`/api/ssqs`, {
                params: {
                    id: ssqIds,
                    page: 1,
                    itemsPerPage,
                },
            });
        },
        select: (request) => {
            const ssq = request.data["hydra:member"];
            const data = zodParseWithLog(ssq, SsqListSchema, "getSomeSsq");
            return data;
        },
    });

    return {
        ssq: query.data,
        ...query,
    };
};
