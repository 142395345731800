import { Box, Stack, useTheme } from "@mui/material";
import { ScoreComparisonCard } from "../ScoreComparisonCard/ScoreComparisonCard";
import { useActionsContext } from "src/context/Actions/useActionsContext";

export type PlanningLayoutProps = {
    children: JSX.Element;
};
export const PlanningLayout = (props: PlanningLayoutProps) => {
    const theme = useTheme();
    const { allScoreWithVariations, selectedScore } = useActionsContext();

    if (allScoreWithVariations.length === 0) {
        // No scores.
        // Error page ?
        return;
    }

    return (
        <Stack
            flexDirection={"row"}
            gap={theme.spacing(3)}
            sx={{
                display: "grid",
                gridTemplateColumns: "[left] 1fr [right] 260px",
            }}
        >
            <Box sx={{ gridColumn: "left" }}>{props.children}</Box>
            <Box sx={{ gridColumn: "right" }}>
                <ScoreComparisonCard
                    selectedOption={selectedScore ?? allScoreWithVariations[0]}
                    allOptions={allScoreWithVariations}
                    position={"vertical"}
                    disableFooter={true}
                />
            </Box>
        </Stack>
    );
};
