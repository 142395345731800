import { IconButton, styled } from "@mui/material";

export const StyledIconButton = styled(IconButton)(() => ({
    backgroundColor: "transparent",
    "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.2)",
    },
}));

export const StyledGridText = styled("div")(({ theme }) => ({
    color: theme.palette.text.primary,
    fontSize: "0.75rem",
    fontWeight: 600,
    overflow: "hidden",
    textOverflow: "ellipsis",
}));
