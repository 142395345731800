import { Box, Stack, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { CampaignSelector } from "src/components/Selector";
import { useActionsContext } from "src/context/Actions/useActionsContext";
import { useFoodpilotContext } from "src/context/FoodpilotContext";

type PlanningListHeaderProps = {
    campaignSelectorRef?: React.RefObject<HTMLDivElement | null>;
};
export const PlanningListHeader = (props: PlanningListHeaderProps) => {
    const { campaignSelectorRef } = props;
    const theme = useTheme();
    const { t } = useTranslation();
    const { campaigns, targetCampaign } = useFoodpilotContext();
    const { startingPost } = useActionsContext();

    const campaignsWithoutTarget = campaigns.filter((c) => c.isTarget !== true);
    // const currentCampaignText = currentCampaign === null ? t("actions.noSelectedCampaign") : currentCampaign.label;
    const startingCampaignText =
        startingPost?.originalPost === null ?
            t("actions.noSelectedCampaign")
        :   startingPost?.originalPost?.campaign.label ?? "";

    return (
        <Stack flexDirection={"row"} justifyContent={"space-between"}>
            <Stack flexDirection={"row"} gap={theme.spacing(2)}>
                <Typography variant="h2">{t("actions.planning.title")}</Typography>
                <Box sx={{ height: "40px", borderLeft: `1px solid ${theme.custom.grey[500]}` }} />
                <Stack flexDirection={"row"} gap={theme.spacing(0.5)} alignItems={"center"}>
                    <Typography variant="body">{t("glossary.from")}</Typography>
                    <Typography variant="body-bold">{startingCampaignText}</Typography>
                    <Typography variant="body">{t("glossary.to")}</Typography>
                    <Typography variant="body-bold">{targetCampaign?.label}</Typography>
                </Stack>
            </Stack>
            <Box ref={campaignSelectorRef}>
                <CampaignSelector
                    allCampaigns={campaignsWithoutTarget}
                    disableText={true}
                    BoxPropsOverride={{ width: "260px" }}
                />
            </Box>
        </Stack>
    );
};
