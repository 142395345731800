import { createContext } from "react";
import { ChaptersScoreWithMetadata, Score } from "src/types";
import { ActionUrls, PostExtra, PostWithMetadata } from "./provider";
import { ScoreState } from "../EntityBasedScore/useEntityBasedScoreParams";
import { Post } from "src/api/client-api/post/postSchema";
import { ActionPropertiesWithAnswersMapping, ActionsByCsrThemes } from "./ActionService";
import { ScoreVariation } from "../EntityBasedScore/ScoreService";
import { ActionsType } from "src/api/sure-api/actions/useGetOneAction";
import { ActionSsqPosts } from "src/api/client-api/actionPost/useGetSomeActionPost";
import { SavingStatus } from "src/components/Loader/useSavingStatus";
import { GetOnePost } from "src/api/client-api/post/useGetOnePost";

type ActionID = number;
export type ActionData = {
    totalScore: number | null;
    data: ActionPropertiesWithAnswersMapping;
};
export type ActionsDataMapping = Partial<Record<ActionID, ActionData>>;

export type ActionsCsr = {
    totalScore: number | null;
    data: ActionsDataMapping;
};
export type ActionsCsrMapping = Partial<Record<string, ActionsCsr>>;

export type ScoreWithVariation = {
    score: Score | undefined;
    variation: ChaptersScoreWithMetadata | undefined;
};

export type ActionsContextInitialValues = {
    actions: ActionsType[] | undefined;
    actionsDataByCsr: ActionsCsrMapping;
    initialPostId: number;
    totalNumberOfActions: number;
    savingStatus: SavingStatus;
    actionsByCsrTheme: ActionsByCsrThemes;
    allScoreWithVariations: ScoreVariation[];
    selectedScore: ScoreVariation | undefined;
    selectedSimulation: Post | null;
    startingPost: {
        post: Post | null;
        originalPost: GetOnePost | null;
    };
    lastExistingPost?: PostWithMetadata & Partial<PostExtra>;
    scoresEnabled: Score[];
    scoreOptions: ScoreState;
    urls: ActionUrls;
    actionSsqPosts: ActionSsqPosts[] | undefined;
    isLocked: boolean;
    setIsLocked: (lock: boolean) => void;
};
export const ActionsContext = createContext<ActionsContextInitialValues | null>(null);
