import { useMutation, useQueryClient } from "react-query";
import { loggedInApiCall } from "src/api/axiosClient/userApi";
import { getAllStrategyKey } from "./useGetAllStrategy";

type UpsertPayload = {
    payload: PayloadWrite;
};

export const useUpsertStrategy = () => {
    const queryClient = useQueryClient();
    const query = useMutation({
        mutationFn: async (options: UpsertPayload) => {
            const { payload } = options;

            const url = `/api/steering/strategies`;
            const response =
                payload.id === undefined ?
                    await loggedInApiCall.post(`${url}`, payload, {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    })
                :   await loggedInApiCall.put(`${url}/${payload.id}`, payload);

            return response;
        },
        onSuccess: () => queryClient.invalidateQueries([getAllStrategyKey]),
    });

    return query;
};

export type PayloadWrite = {
    id?: string;
    name: string;
    description?: string;
    campaign: IRI;
};
