import { LightIndicatorBlock } from "@foodpilot/foods";
import { Grid, Stack } from "@mui/material";
import { CMSPageSection, CMSSectionBlocksContent } from "src/api/client-api/cms/useGetOneContent";

type IndicatorBlockProps = {
    section: CMSPageSection<CMSSectionBlocksContent>;
};
export const IndicatorBlock = (props: IndicatorBlockProps) => {
    const { section } = props;
    const { blocks } = section;

    if (blocks.length === 0) {
        return <></>;
    }

    const indicatorNodes = blocks.map(({ title, subtitle, description }, idx) => {
        return (
            description && (
                <Grid item xs={1} p={0} key={`indicator-${idx}`}>
                    <LightIndicatorBlock title={title} subtitle={subtitle} description={description} />
                </Grid>
            )
        );
    });

    return (
        <Stack>
            <Grid container spacing={2} columns={3}>
                {indicatorNodes}
            </Grid>
        </Stack>
    );
};
