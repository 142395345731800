import { StrategyContext, StrategyInitialValues } from "./context";
import { Loading } from "src/components/utils/Loading";
import { useGetAllStrategy } from "src/api/client-api/csrStrategy/useGetAllStrategy";

type StrategyProviderProps = {
    children: JSX.Element;
};
export const StrategyProvider = (props: StrategyProviderProps) => {
    const { children } = props;

    const { allStrategy, isFetched } = useGetAllStrategy();

    if (isFetched === false) {
        return <Loading />;
    }

    // In any case, we only want to ever keep the first one.
    if (allStrategy !== undefined && allStrategy.length > 1) {
        console.warn("You have too much strategy items");
    }

    const _strategy = allStrategy ?? [];
    const strategy = _strategy.length > 0 ? _strategy[0] : undefined;

    const items: StrategyInitialValues = {
        strategy: strategy,
    };

    return <StrategyContext.Provider value={items}>{children}</StrategyContext.Provider>;
};
