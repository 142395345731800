import { BlockOptions, FoodsSelect, SelectBlock } from "@foodpilot/foods";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { UpdateProperty, useUpdateProperty } from "src/api/client-api/property/useUpdateProperty";
import { DataListOption } from "src/api/sure-api/actions/useGetOneAction";
import { ActionPropertiesCoreAnswers } from "src/context/Actions/ActionService";
import { useActionsContext } from "src/context/Actions/useActionsContext";

type SelectProps = {
    disableSubtitle?: boolean;
    propertyWithElements: ActionPropertiesCoreAnswers;
    position?: number;
    raw?: boolean;
};
export const Select = (props: SelectProps) => {
    const { t } = useTranslation();
    const { startingPost, lastExistingPost, selectedSimulation, savingStatus } = useActionsContext();
    const updateProperty = useUpdateProperty();

    const { propertyWithElements, position = 0, raw = false } = props;

    const previousElement = propertyWithElements.previousElements?.find((element) => element.position === position);
    const currentElement = propertyWithElements.currentElements?.find((element) => element.position === position);
    const targetElement = propertyWithElements.targetElements?.find((element) => element.position === position);

    const options = propertyWithElements.property.dataList?.options ?? [];
    options.sort((optionA: DataListOption, optionB: DataListOption) => optionA.position - optionB.position);

    const _previousValue = previousElement?.value;
    const previousValue = options.find((option: DataListOption) => option.value === _previousValue);

    const _lastExistingValue = currentElement?.value;
    const lastExistingValue = options.find((option: DataListOption) => option.value === _lastExistingValue);

    const _initialValue = targetElement?.value;
    const initialValue = options.find((option: DataListOption) => option.value === _initialValue) ?? null;

    const [value, setValue] = useState<DataListOption | null>(initialValue);

    const previousComparisonValue = {
        year: t("actionPlan.value_in_year", { year: startingPost?.post?.campaign?.label }),
        value: previousValue?.label ?? "",
    };
    const currentComparisonValue =
        lastExistingPost !== undefined ?
            {
                year: t("actionPlan.value_in_year", { year: lastExistingPost.campaign }),
                value: lastExistingValue?.label ?? "",
            }
        :   undefined;

    const title = props.disableSubtitle === false ? propertyWithElements.property.title ?? "" : "";
    const blockOptions: BlockOptions = {
        rightSubtitle: `${t("Goal")} ${selectedSimulation?.campaign?.label}`,
        title: title,
        comparisonValues: {
            start: previousComparisonValue,
            current: currentComparisonValue,
        },
    };

    const SelectComponent = raw ? FoodsSelect : SelectBlock;

    return (
        <SelectComponent
            selectedOption={value}
            options={options}
            blockOptions={blockOptions}
            onChange={(newValue) => {
                setValue(newValue);

                if (selectedSimulation === null) return;

                const newCalculateValue = newValue ? newValue.value : "_empty_";
                const payload: UpdateProperty = {
                    postId: selectedSimulation?.id,
                    isGenericValue: false,
                    position: position,
                    propertyId: propertyWithElements.property.id,
                    value: newCalculateValue,
                    calculationType: "action",
                    dataListOptionIds: newValue?.id ? [newValue.id] : [],
                };
                savingStatus.setSaving(true);
                updateProperty.mutate(payload, {
                    onSettled: () => {
                        savingStatus.setSaving(false);
                    },
                });
            }}
            getId={(option) => option.value}
            getName={(option) => option.label}
            textOptions={{
                placeholder: "",
                searchPlaceholder: "",
                emptyOptionsText: "",
                defaultValueText: undefined,
                noResult: "",
            }}
        />
    );
};
