import { Box, Divider } from "@mui/material";
import { MRTGrid } from "@foodpilot/foods";
import { DeleteDocumentMutationPayload, Document } from "src/api/client-api/documentation/types";
import { DocumentationListGridHeader } from "./DocumentationListGridHeader";
import { DocumentationListGridEmpty } from "./DocumentationListGridEmpty";
import { useDocumentListContext } from "../DocumentationContext";
import { columns } from "./DocumentationListGridColumns/DocumentationListGridColumns";
import { styles } from "./styles/DocumentationListGrid";

interface DocumentationListGridProps {
    documents: Document[];
    onDelete: (data: DeleteDocumentMutationPayload) => Promise<void>;
    isLoading?: boolean;
}

export const DocumentationListGrid = ({ documents, onDelete }: DocumentationListGridProps) => {
    const { page, setPage, sorting, setSorting, search, isLoading, totalItems } = useDocumentListContext();

    if (!totalItems && !isLoading) {
        return (
            <>
                <DocumentationListGridHeader />
                <Divider />
                <Box sx={styles.emptyContainer}>
                    <DocumentationListGridEmpty emptyResults={!!search} />
                </Box>
            </>
        );
    }
    return (
        <>
            <DocumentationListGridHeader />
            <MRTGrid
                columns={columns({ onDelete })}
                data={documents}
                state={{
                    isLoading,
                    pagination: page,
                    sorting,
                }}
                manualPagination
                manualSorting
                onPaginationChange={setPage}
                onSortingChange={setSorting}
                enableStickyHeader
                enableTopToolbar={true}
                enableBottomToolbar={true}
                enableColumnResizing={false}
                enablePagination={true}
                enableSorting={true}
                enableMultiSort={false}
                enableRowSelection={false}
                enableRowActions={false}
                enableToolbarInternalActions={false}
                enableColumnActions={false}
                rowCount={totalItems}
            />
        </>
    );
};
