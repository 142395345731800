import { WhiteBox } from "@foodpilot/foods";
import { Stack, useTheme } from "@mui/material";
import { useSettingsStrategy } from "src/context/Settings/CsrStrategy/useSettingsStrategy";
import { NoPillars } from "./ListPillars/NoPillars";
import { ListPillars } from "./ListPillars/ListPillars";

export const Pillars = () => {
    const { strategy } = useSettingsStrategy();
    const theme = useTheme();

    const existingPillars = strategy?.pillars ?? [];
    const doItemsExist = existingPillars.length > 0;

    return (
        <WhiteBox>
            <Stack gap={theme.spacing(4)} padding={theme.spacing(2, 4)}>
                {doItemsExist ?
                    <ListPillars pillars={existingPillars} />
                :   <NoPillars />}
            </Stack>
        </WhiteBox>
    );
};
