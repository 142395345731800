import { useQuery } from "react-query";
import { loggedInApiCall } from "src/api/axiosClient/userApi";

export const getOneCMSContentKey = "getOneContentKey" as const;

type Options = {
    contentId: string | undefined;
};
export const useGetOneContent = (options: Options) => {
    const { contentId } = options;

    const query = useQuery({
        queryKey: [getOneCMSContentKey],
        enabled: contentId !== undefined,
        queryFn: async () => {
            const url = `/api/cms/contents/${contentId}`;

            return await loggedInApiCall.get<CMSContent>(url);
        },
        select: (request) => {
            const data = request.data;
            return data;
        },
    });

    return {
        content: query.data,
        ...query,
    };
};

type CMSBlockCore = {
    "@id": IRI;
    id: UUID;
    position: number;
    section: IRI;
};

export type CMSContentBlock = CMSBlockCore & {
    title: string;
    subtitle?: string;
    description?: string;
    ctaLink: string;
    ctaLabel: string;
    media?: {
        "@id": IRI;
        url: string;
        alt: string;
        dimensions: [number, number];
    };
};

export type CMSStrategyBlock = CMSBlockCore & {
    strategyIndicator: IRI;
};

export type CMSSectionCore = {
    "@id": IRI;
    id: UUID;
    title: string;
    position: number;
    visible: boolean;
    page: IRI;
};

export const sectionBlockContent = ["hero", "indicator", "articles"] as const;
export type CMSSectionBlocksContent = (typeof sectionBlockContent)[number];

export const sectionBlockStrategy = ["strategyIndicators"] as const;
export type CMSSectionBlocksStrategy = (typeof sectionBlockStrategy)[number];

export const sectionAllBlocks = [...sectionBlockContent, ...sectionBlockStrategy] as const;
export type CMSSectionAllBlocks = (typeof sectionAllBlocks)[number];

export type CMSBlockType =
    | { type: CMSSectionBlocksContent; blocks: CMSContentBlock[] }
    | { type: CMSSectionBlocksStrategy; blocks: CMSStrategyBlock[] };

export type CMSPageSection<SectionType extends CMSSectionAllBlocks = CMSSectionAllBlocks> = CMSSectionCore &
    CMSBlockType & {
        type: SectionType;
    };

export type CMSContent = {
    "@id": IRI;
    slug: string;
    title: string;
    sections: CMSPageSection[];
};
