import { FormBox } from "@foodpilot/foods";
import { CsrIndicatorForm, CsrPillarForm } from "../PillarForm";
import { AddIndicatorModal } from "./AddIndicatorModal";
import { useState } from "react";
import { IndicatorList } from "./IndicatorList/IndicatorList";

const getTemporaryForm = (existingForm: CsrPillarForm, form: CsrIndicatorForm | null): CsrIndicatorForm => {
    if (form === null) {
        const newItemRow = existingForm.indicators.length;
        const defaultEmpty: CsrIndicatorForm = {
            rowIndex: newItemRow,

            "@id": undefined,
            name: "",
            abbreviationUnit: "",
            currentValue: "",
            shortTermTarget: "",
            longTermTarget: "",
        };
        return defaultEmpty;
    }

    return form;
};

type IndicatorsProps = {
    form: CsrPillarForm;
    setForm: (form: CsrPillarForm) => void;
};
export const Indicators = (props: IndicatorsProps) => {
    const { form, setForm } = props;
    const [isOpen, setOpen] = useState(false);
    const [selectedIndicator, setSelectedIndicator] = useState<CsrIndicatorForm | null>(null);

    const setIndicator = (indicator: CsrIndicatorForm) => {
        const isNewItem = indicator.rowIndex === form.indicators.length;

        const newIndicators =
            isNewItem ? [...form.indicators, indicator] : form.indicators.with(indicator.rowIndex, indicator);

        const newForm: CsrPillarForm = {
            ...form,
            indicators: newIndicators,
        };
        setForm(newForm);
    };

    const indicatorForm = getTemporaryForm(form, selectedIndicator);
    return (
        <FormBox>
            <AddIndicatorModal
                isOpen={isOpen}
                setOpen={setOpen}
                indicatorToEdit={indicatorForm}
                setIndicator={setIndicator}
            />
            <IndicatorList
                form={form}
                setIndicator={(indicators) => {
                    setForm({
                        ...form,
                        indicators: indicators,
                    });
                }}
                setSelectedIndicator={setSelectedIndicator}
                openModal={() => setOpen(true)}
            />
        </FormBox>
    );
};
