import { BlockListContainer, InitHeader, Typography } from "@foodpilot/foods";
import { Stack, useTheme } from "@mui/material";
import { ActionBlock } from "./ActionBlock";
import { EmptyPlanning } from "./EmptyPlanning";
import { Fragment } from "react/jsx-runtime";
import { PlanningLayout } from "../PlanningLayout";
import { PlanningListHeader } from "./PlanningListHeader";
import { useTranslation } from "react-i18next";
import { useActionsContext } from "src/context/Actions/useActionsContext";

export const ListPlanning = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const {
        actionsDataByCsr,
        startingPost,
        selectedSimulation,
        actionsByCsrTheme,
        totalNumberOfActions,
        actionSsqPosts,
    } = useActionsContext();

    if (selectedSimulation === null || startingPost === null) {
        return <EmptyPlanning />;
    }

    const numberofActions = (actionSsqPosts ?? []).length;

    const actionBlocks = Object.entries(actionsByCsrTheme).map(([csrTitle, actions]) => {
        const actionsData = actionsDataByCsr[csrTitle]?.data;
        const groupActions =
            actions?.map((action) => {
                return {
                    actionId: action.id,
                    title: action.title,
                };
            }) ?? [];
        return <ActionBlock sectionTitle={csrTitle} groupActions={groupActions} actionsData={actionsData} />;
    });

    return (
        <Fragment>
            <PlanningListHeader />
            <PlanningLayout>
                <BlockListContainer
                    header={
                        <InitHeader
                            title={
                                <Stack flexDirection="row" gap={theme.spacing(1)}>
                                    <Typography variant={"h3"}>{t("actionPlan.activeActions")}</Typography>
                                    <Typography variant={"huge"}>{"-"}</Typography>
                                    <Typography
                                        variant={"huge"}
                                    >{`${numberofActions} / ${totalNumberOfActions}`}</Typography>
                                </Stack>
                            }
                            actionButton={{
                                label: t("actionPlan.validate"),
                                onClick: function (): void {
                                    // This trigger will be used for the /validate from SIMULATION to ActionPlan.
                                    // throw new Error("Function not implemented.");
                                },
                            }}
                        />
                    }
                    blocks={[
                        ...actionBlocks,
                        // <SuggestionBlock
                        //     suggestionTitle={<Typography variant="body-bold">{t("glossary.suggestion")}</Typography>}
                        //     text={<Typography variant="body">{"You should really improve !"}</Typography>}
                        // />,
                    ]}
                />
            </PlanningLayout>
        </Fragment>
    );
};
