import { Box, Stack, useTheme } from "@mui/material";
import { ReactNode } from "react";

type MainContentLayoutProps = {
    children: ReactNode;
};
export const MainContentLayout = (props: MainContentLayoutProps) => {
    const theme = useTheme();

    return (
        <Box
            sx={{
                [theme.breakpoints.up(1280)]: {
                    display: "flex",
                    justifyContent: "center",
                },
            }}
            paddingX={theme.spacing(6.5)}
            paddingY={theme.spacing(5)}
            width={"calc(100vw - 80px)"}
        >
            <Box minWidth={"1200px"} maxWidth={"1200px"} paddingInline={"80px"}>
                {props.children}
            </Box>
        </Box>
    );
};
