import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Fragment } from "react/jsx-runtime";

export const ListHeader = () => {
    const { t } = useTranslation();

    return (
        <Fragment>
            <Box sx={{ gridColumn: "a", wordBreak: "break-word" }}>
                <Typography>{t("settings.csr.pillars.fields.name.label")}</Typography>
            </Box>
            <Box sx={{ gridColumn: "b", wordBreak: "break-word" }}>
                <Typography>{t("settings.csr.pillars.fields.themes.label")}</Typography>
            </Box>
            <Box sx={{ gridColumn: "c", wordBreak: "break-word" }}>
                <Typography>{t("settings.csr.form.indicator.title")}</Typography>
            </Box>
        </Fragment>
    );
};
