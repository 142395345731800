import { useQuery } from "react-query";
import { Score } from "src/types";
import { sureApiCall } from "src/api/axiosClient/sureApi";
import { z } from "zod";
import { zodParseWithLog } from "src/api/apiErrorLogs";

export const useGetOneScore = (scoreId: number) => {
    const query = useQuery({
        queryKey: ["getScore", scoreId],
        cacheTime: 0,
        queryFn: async () => {
            return await sureApiCall.get(`/api/scores/${scoreId}`);
        },
        select: (response) => {
            const score = response.data;
            const data = zodParseWithLog(score, ScoreSchema, "getScore");
            return data;
        },
    });

    return {
        score: query.data,
        ...query,
    };
};

const UnitSchema = z.object({
    id: z.number(),
    title: z.string(),
    abbreviation: z.string(),
});

export const ScoreSchema: z.ZodSchema<Score> = z.object({
    id: z.number(),
    title: z.string(),
    type: z.string(),
    displayMode: z.string(),
    unit: UnitSchema.optional(),
    label: z.string().optional(),
    precision: z.number().optional(),
});
