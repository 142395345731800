import { LocalStorage } from "src/services/LocalStorage";

export class CompanyService {
    private static displayRegulatoryInfoBox = true;

    static get displayRegulatoryInfoBoxStatus(): boolean {
        return this.displayRegulatoryInfoBox;
    }

    static set displayRegulatoryInfoBoxStatus(value: boolean) {
        this.displayRegulatoryInfoBox = value;
    }

    static updateRegulatoryInfoBox(value: boolean) {
        LocalStorage.setItem("displayRegulatoryInfoBox", value.toString());
        this.displayRegulatoryInfoBox = value;
    }

    static deleteRegulatoryInfoBox() {
        this.displayRegulatoryInfoBox = true;
        LocalStorage.deleteItem("displayRegulatoryInfoBox");
    }

    static getRegulatoryInfoBoxStatus() {
        const status = LocalStorage.getItem("displayRegulatoryInfoBox");
        return status ? status : null;
    }
}
