import { Text } from "./Properties/Text";
import { Checkbox } from "./Properties/Checkbox";
import { Radio } from "./Properties/Radio";
import { Select } from "./Properties/Select";
import { Numeric } from "./Properties/Numeric";
import { Multivalue } from "./Properties/Multivalue";
import { ActionPropertiesCoreAnswers } from "src/context/Actions/ActionService";

export type PropertySwitchProps = {
    disableSubtitle?: boolean;
    propertyWithElements: ActionPropertiesCoreAnswers | undefined;
    position?: number;
    raw?: boolean;
};
export const PropertySwitch = (props: PropertySwitchProps): JSX.Element | null => {
    const { propertyWithElements, disableSubtitle = false, position = 0, raw = false } = props;

    if (propertyWithElements === undefined) return null;

    const type = propertyWithElements.property.field?.type;
    const multivalue = propertyWithElements.property.multivalue;
    const propertyUnit = propertyWithElements.property.unit;

    const propertyProps = { disableSubtitle, propertyWithElements, position, raw };

    if (type === "text") {
        const isPropertyString = propertyUnit?.title.toLowerCase() === "string";
        if (isPropertyString) {
            return <Text {...propertyProps} />;
        }

        return <Numeric {...propertyProps} />;
    }

    if (type === "checkbox") {
        return <Checkbox {...propertyProps} />;
    }

    if (type === "radio") {
        return <Radio {...propertyProps} />;
    }

    if (type === "select") {
        return <Select {...propertyProps} />;
    }

    if (multivalue === true) {
        return <Multivalue {...propertyProps} />;
    }

    return null;
};
