import { createContext, useContext, useState, ReactNode, useMemo } from "react";
import { MRT_PaginationState, MRT_SortingState } from "material-react-table";
import { useDelayedSearch } from "src/utils/useDelayedSearch";
import { useGetAllDocuments, useDeleteDocument, useUploadDocument } from "src/api/client-api/documentation";
import type {
    DeleteDocumentMutationPayload,
    DocumentListContextType,
    UploadDocumentMutationPayload,
} from "src/api/client-api/documentation/types";

const DocumentListContext = createContext<DocumentListContextType | null>(null);

type DocumentListProviderProps = {
    children: ReactNode;
};

export const DocumentListProvider = ({ children }: DocumentListProviderProps) => {
    const [search, setSearch] = useState("");
    const PAGE_SIZE = 10;

    const [page, setPage] = useState<MRT_PaginationState>({
        pageIndex: 0,
        pageSize: PAGE_SIZE,
    });

    const [sorting, setSorting] = useState<MRT_SortingState>([
        {
            id: "createdAt",
            desc: true,
        },
    ]);

    const delayedSearchQuery = useDelayedSearch();

    const { data, isLoading } = useGetAllDocuments({
        page,
        sorting,
        search: delayedSearchQuery.delayedSearch,
    });
    const uploadMutation = useUploadDocument();
    const deleteMutation = useDeleteDocument();

    const documents = useMemo(() => data, [data]);

    const handleUpload = async (data: UploadDocumentMutationPayload) => {
        await uploadMutation.query.mutateAsync(data);
    };

    const handleDelete = async ({ id }: DeleteDocumentMutationPayload) => {
        await deleteMutation.query.mutateAsync({
            id,
        });
    };

    const value: DocumentListContextType = {
        documents: documents?.list || [],
        isLoading,
        isUploading: uploadMutation.uploading,
        isDeleting: deleteMutation.deleting,
        delayedSearchQuery,
        totalItems: documents?.totalItems || 0,
        page,
        setPage,
        search,
        // setSearch,
        sorting,
        setSorting,
        uploadDocument: handleUpload,
        deleteDocument: handleDelete,
    };

    return <DocumentListContext.Provider value={value}>{children}</DocumentListContext.Provider>;
};

export const useDocumentListContext = () => {
    const context = useContext(DocumentListContext);
    if (!context) {
        throw new Error("useDocumentListContext must be used within a DocumentListProvider");
    }
    return context;
};
