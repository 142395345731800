import { InternalAxiosRequestConfig } from "axios";
import { UserService } from "src/services/UserService";
import { getEnv } from "src/utils/getEnv";

export const getApiUrl = (): string => {
    const hostname = window.location.hostname;
    const protocol = window.location.protocol;
    const subdomain = hostname.split(".")[0];
    const apiUrl = getEnv("VITE_API_URL");

    if (hostname.startsWith("localhost") || hostname === "foodpilot.ngrok.app") {
        return apiUrl;
    }

    if (subdomain === "app") {
        return `${protocol}//api.${hostname.split(".").slice(1).join(".")}`;
    }

    // Only dev environments need the -app suffix
    if (hostname.endsWith("foodpilot.tech")) {
        // Handle the scenario where subdomain finish by -app or -app-{env}, avoiding multiple "app" matching pitfall
        const matches = subdomain.match(/(.+)(-app-)([-\w]*)$/);
        if (matches) {
            const [, schema, , env] = matches;
            return `${protocol}//${schema}-api-${env}.${hostname.split(".").slice(1).join(".")}`;
        }
    }

    // All remaining cases are just about suffixing the subdomain with "-api"
    return `${protocol}//${hostname.split(".")[0]}-api.${hostname.split(".").slice(1).join(".")}`;
};

export const getSureApiUrl = (): string => {
    const protocol = window.location.protocol;
    const hostname = window.location.hostname;
    const subdomain = hostname.split(".")[0];
    const apiUrl = getEnv("VITE_SURE_API_URL");

    if (hostname.startsWith("localhost") || hostname === "foodpilot.ngrok.app") {
        return apiUrl;
    }

    // Only dev environments need the -app suffix
    if (hostname.endsWith("foodpilot.tech")) {
        // Handle the scenario where subdomain finish by -app or -app-{env}, avoiding multiple "app" matching pitfall
        const matches = subdomain.match(/(.+)(-app-)([-\w]*)$/);
        if (matches) {
            const [, , , env] = matches;
            return `${protocol}//sure-api-${env}.${hostname.split(".").slice(1).join(".")}`;
        }
    }

    // All remaining cases are just about replacing the subdomain with "sure-api"
    return `${protocol}//${hostname.replace(subdomain, `sure-api`)}`;
};

export const handleImpersonation = (config: InternalAxiosRequestConfig<any>): InternalAxiosRequestConfig<any> => {
    const token = UserService.getFullToken();

    if (token.connectedAs) {
        config.headers["X-SWITCH-USER"] = token.connectedAs;
    } else {
        // Ensuring the request NEVER impersonates someone if
        // token doesn't exist.
        delete config.headers["X-SWITCH-USER"];
    }
    return config;
};
