import { BlockOptions, CheckboxBlock, FoodsCheckbox } from "@foodpilot/foods";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { UpdateProperty, useUpdateProperty } from "src/api/client-api/property/useUpdateProperty";
import { DataListOption } from "src/api/sure-api/ssq/useGetOneSsq";
import { ActionPropertiesCoreAnswers } from "src/context/Actions/ActionService";
import { useActionsContext } from "src/context/Actions/useActionsContext";

type CheckboxProps = {
    disableSubtitle?: boolean;
    propertyWithElements: ActionPropertiesCoreAnswers;
    position?: number;
    raw?: boolean;
};
export const Checkbox = (props: CheckboxProps) => {
    const { t } = useTranslation();
    const { startingPost, lastExistingPost, selectedSimulation, savingStatus } = useActionsContext();
    const updateProperty = useUpdateProperty();
    const { propertyWithElements, position = 0, raw = false } = props;

    const previousElement = propertyWithElements.previousElements?.find((element) => element.position === position);
    const currentElement = propertyWithElements.currentElements?.find((element) => element.position === position);
    const targetElement = propertyWithElements.targetElements?.find((element) => element.position === position);

    const options = propertyWithElements.property.dataList?.options ?? [];
    options.sort((optionA: DataListOption, optionB: DataListOption) => optionA.position - optionB.position);

    const previousValueIds = previousElement?.value.split("|");
    const previousValues = options.filter(
        (option: DataListOption) => (previousValueIds ?? []).indexOf(option.value) > -1,
    );
    const lastExistingValueIds = currentElement?.value.split("|");
    const lastExistingValues = options.filter(
        (option: DataListOption) => (lastExistingValueIds ?? []).indexOf(option.value) > -1,
    );

    const initialValueIds = targetElement?.value.split("|");
    const initialValue = options.filter((option: DataListOption) => (initialValueIds ?? []).indexOf(option.value) > -1);

    const [value, setValue] = useState<DataListOption[] | undefined>(initialValue);

    const previousComparisonValue = {
        year: t("actionPlan.value_in_year", { year: startingPost?.post?.campaign?.label }),
        value: previousValues.map((i) => i.label),
    };
    const currentComparisonValue =
        lastExistingPost !== undefined ?
            {
                year: t("actionPlan.value_in_year", { year: lastExistingPost.campaign }),
                value: lastExistingValues.map((i) => i.label),
            }
        :   undefined;

    const title = props.disableSubtitle === false ? propertyWithElements.property.title ?? "" : "";
    const blockOptions: BlockOptions = {
        rightSubtitle: `${t("Goal")} ${selectedSimulation?.campaign?.label}`,
        title: title,
        comparisonValues: {
            start: previousComparisonValue,
            current: currentComparisonValue,
        },
    };

    const CheckboxComponent = raw ? FoodsCheckbox : CheckboxBlock;

    return (
        <CheckboxComponent
            blockOptions={blockOptions}
            selectedItems={value}
            values={options}
            onChange={(selectedValues) => {
                setValue(selectedValues);
                const sanitizedValue =
                    selectedValues.length === 0 ?
                        "_empty_"
                    :   selectedValues.map((option: DataListOption) => option.value).join("|");

                if (selectedSimulation === null) return;

                const payload: UpdateProperty = {
                    value: sanitizedValue,
                    postId: selectedSimulation.id,
                    isGenericValue: false,
                    position: position,
                    propertyId: propertyWithElements.property.id,
                    calculationType: "action",
                    dataListOptionIds: selectedValues.length > 0 ? selectedValues.map((v) => v.id) : [],
                };
                savingStatus.setSaving(true);
                updateProperty.mutate(payload, {
                    onSettled: () => {
                        savingStatus.setSaving(false);
                    },
                });
            }}
            getId={(option) => option.value}
            getName={(option) => option.label}
        />
    );
};
