import { sureApiCall } from "./axiosClient/sureApi";
import { loggedInApiCall } from "./axiosClient/userApi";

export const addReportFn = async (report: any) => {
    const response = await sureApiCall.post<any>(`/api/inexweb/feed-report`, report, {
        headers: {
            Accept: "application/json",
        },
    });

    console.log("response", response);

    return response.data;
};

/**
 * @TODO: FIX THIS
 */
/* eslint-disable  @typescript-eslint/no-explicit-any */
export const getReportFn = async (reportToken: string) => {
    const response = await sureApiCall.get<any>(`/api/inexweb/report?report-token=${reportToken}&pause=10`, {
        responseType: "blob",
        headers: {
            Accept: "application/json",
        },
    });

    return response.data;
};

/**
 * @TODO: FIX THIS
 */
/* eslint-disable  @typescript-eslint/no-explicit-any */
export const addReportNewFn = async (report: any) => {
    const response = await sureApiCall.post<any>(`/api/inexweb/feed-report-new`, report);

    return response.data;
};

/**
 * @TODO: FIX THIS
 */
/* eslint-disable  @typescript-eslint/no-explicit-any */
export const addMetricFn = async (metric: any) => {
    const response = await loggedInApiCall.post<any>(`/api/usage_metrics`, metric);

    return response.data;
};
