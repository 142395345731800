import { FoodsIcon } from "@foodpilot/foods";
import { useState } from "react";
import { Loading } from "src/components/utils/Loading";
import { downloadFile } from "src/utils/download";
import { CellTable } from "src/api/client-api/documentation/types";
import { StyledIconButton, StyledGridText } from "./styles/DocumentationListGridColumns";
import { t } from "i18next";

export type ColumnsProps = {
    onDelete: (data: { id: string; url?: string }) => Promise<void>;
};

export const columns = ({ onDelete }: ColumnsProps) => [
    {
        accessorKey: "name",
        id: "name",
        header: t("documentation.list.name"),
        Cell: ({ row }: CellTable) => {
            const name = row.original.name ?? row.original.title ?? "";
            return <StyledGridText>{name.charAt(0).toUpperCase() + name.slice(1)}</StyledGridText>;
        },
    },
    {
        accessorKey: "createdAt",
        id: "createdAt",
        header: t("documentation.list.date"),
        size: 158,
        grow: false,
        enableResizing: false,
        Cell: ({ row }: CellTable) => {
            if (!row.original.createdAt) return "-";
            const date = new Date(row.original.createdAt);
            const currentLanguage = localStorage.getItem("i18nextLng");

            return (
                <StyledGridText>{`${date.toLocaleDateString(currentLanguage ?? undefined)} ${date.toLocaleTimeString(
                    currentLanguage ?? undefined,
                    {
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: false,
                    },
                )}`}</StyledGridText>
            );
        },
    },
    {
        id: "mrt-row-actions",
        header: "",
        size: 128,
        grow: false,
        enableResizing: false,
        Cell: ({ row }: CellTable) => {
            const [isDownloading, setIsDownloading] = useState(false);
            const [isDeleting, setIsDeleting] = useState(false);

            const handleDownload = async () => {
                if (!row.original.url) return;

                setIsDownloading(true);
                await downloadFile({
                    url: row.original.url,
                    filename: row.original.name || row.original.title || "",
                    mimeType: row.original.mimeType,
                }).finally(() => {
                    setIsDownloading(false);
                });
            };

            const handleDelete = async () => {
                setIsDeleting(true);
                await onDelete({
                    id: row.original.id,
                    url: row.original.url,
                }).finally(() => {
                    setIsDeleting(false);
                });
            };

            return (
                <>
                    <StyledIconButton
                        size="small"
                        onClick={() => handleDownload()}
                        disabled={isDownloading || !row.original.url}
                    >
                        {isDownloading ?
                            <Loading size={14} />
                        :   <FoodsIcon icon="exportDownload" size={2} />}
                    </StyledIconButton>
                    <StyledIconButton size="small" color="error" onClick={() => handleDelete()} disabled={isDeleting}>
                        {isDeleting ?
                            <Loading size={14} />
                        :   <FoodsIcon icon="delete" size={2} />}
                    </StyledIconButton>
                </>
            );
        },
    },
];
