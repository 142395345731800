import axios, { CreateAxiosDefaults } from "axios";
import { getApiUrl, handleImpersonation } from "./utils";

const userConfig: CreateAxiosDefaults = {
    baseURL: getApiUrl(),
    withCredentials: true,
    headers: {
        common: {
            "Content-Type": "application/ld+json",
            Accept: "application/ld+json",
        },
    },
};

export const publicApiCall = axios.create({ ...userConfig });

export const loggingOutSession = axios.create({ ...userConfig, withCredentials: true });
loggingOutSession.interceptors.request.use((config) => {
    const _config = handleImpersonation(config);
    return _config;
});

export const loggedInApiCall = axios.create({ ...userConfig, withCredentials: true });
loggedInApiCall.interceptors.request.use((config) => {
    const _config = handleImpersonation(config);
    return _config;
});

// Single concurrent requests
let PENDING_REQUESTS = 0;
export const loggedInApiSingleConcurrentCall = axios.create({ ...userConfig, withCredentials: true });
loggedInApiSingleConcurrentCall.interceptors.request.use((config) => {
    const _config = handleImpersonation(config);

    return new Promise((resolve, _reject) => {
        const interval = setInterval(() => {
            if (PENDING_REQUESTS < 1) {
                PENDING_REQUESTS++;
                clearInterval(interval);
                resolve(_config);
            }
        }, 10);
    });
});

loggedInApiSingleConcurrentCall.interceptors.response.use(
    function (response) {
        PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
        return Promise.resolve(response);
    },
    function (error) {
        PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
        return Promise.reject(error);
    },
);
