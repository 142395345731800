import { useMutation, useQueryClient } from "react-query";
import { loggedInApiCall } from "src/api/axiosClient/userApi";
import { getAllStrategyKey } from "../useGetAllStrategy";

type UpsertPayload = {
    payload: PayloadWrite;
};

export const useUpsertCsrPillar = () => {
    const queryClient = useQueryClient();
    const query = useMutation({
        mutationFn: async (options: UpsertPayload) => {
            const { payload } = options;

            const url = `/api/steering/pillars`;
            const response =
                payload.id === undefined ?
                    await loggedInApiCall.post(url, payload, {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    })
                :   await loggedInApiCall.put(`${url}/${payload.id}`, payload);

            return response;
        },
        onSuccess: () => {
            queryClient.refetchQueries([getAllStrategyKey]);
        },
    });

    return query;
};

export type IndicatorWrite = {
    "@id"?: string;
    name: string;
    abbreviationUnit: string;
    currentValue: string;
    shortTermTarget: string;
    longTermTarget: string;
};

export type PayloadWrite = {
    id?: string;
    name: string;
    icon: string;
    strategy: IRI;
    indicators: IndicatorWrite[];
};
