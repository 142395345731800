import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function ComparisonTable() {
    const { t } = useTranslation();

    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Scope</TableCell>
                        <TableCell align="right">Total emissions 2019 réajusté (tCO2eq)</TableCell>
                        <TableCell align="right">Total emissions 2023 (tCO2eq)</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>Scope 1</TableCell>
                        <TableCell align="right">8152</TableCell>
                        <TableCell align="right">7727</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Scope 2</TableCell>
                        <TableCell align="right">1001</TableCell>
                        <TableCell align="right">1428</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Scope 3</TableCell>
                        <TableCell align="right">125 367</TableCell>
                        <TableCell align="right">118 592</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}
