import { useMutation } from "react-query";

import { loggedInApiCall } from "src/api/axiosClient/userApi";

type CreateProduct = {
    product: ProductWrite;
};
export const useCreateProduct = () => {
    const query = useMutation({
        mutationFn: async (options: CreateProduct) => {
            const { product } = options;

            const response =
                product.id === undefined ?
                    await loggedInApiCall.post("/api/products", product)
                :   await loggedInApiCall.put("/api/products/" + product.id, product);

            return response;
        },
    });

    return query;
};

type PPMWrite = {
    weight: number | null;
    proportion: number | null;
};
export type ProductPackagingMaterialWrite =
    | ({ "@id": IRI } & PPMWrite)
    | ({ packagingMaterialSetId: number } & PPMWrite);

type PPWrite = {
    packagingMaterials: ProductPackagingMaterialWrite[];
};
export type ProductPackagingWrite = ({ "@id": IRI } & PPWrite) | ({ packagingFormId: number } & PPWrite);

type PTWrite = {
    weight: number | null;
};
export type ProductTransformationWrite =
    | ({ "@id": IRI } & PTWrite)
    // Was here previously, but we don't have write access.
    // Backend lacks `product:write`.
    | ({ productTransformationProcessId: number } & PTWrite);

type PIWriteFields = {
    quantity: number | null;
    // proportion: number | null;
    usedQuantity: number | null;
    // usedProportion: number | null;
    isUsedQuantityEqualToQuantity: boolean;
};
export type ProductIngredientWrite = ({ "@id": IRI } & PIWriteFields) | ({ ingredient: IRI } & PIWriteFields);

export type ProductWrite = {
    id: number | undefined;
    brand: IRI | null;
    distributionChannelId: number | null;
    consumptionsIds: number[];
    clientId: string | null;
    productIngredients: ProductIngredientWrite[];
    netWeight: number | null;
    productPackagings: ProductPackagingWrite[];
    productLine: IRI | null;
    productReferenceId: number | null;
    tags: IRI[];
    title: string | null;
    productTransformations: ProductTransformationWrite[];
    ownedBy?: IRI;
};
