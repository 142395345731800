import { Box, Stack, Typography, useTheme } from "@mui/material";
import { Fragment } from "react/jsx-runtime";
import { CsrIndicatorForm } from "../../PillarForm";
import { FoodsBadge } from "@foodpilot/foods";
import { VerticalDivider } from "src/pages/Settings/CMS/Common/VerticalDivider";

type IndicatorLineProps = {
    indicator: CsrIndicatorForm;
    editAction?: (indicator: CsrIndicatorForm) => void;
    deleteAction?: (indicator: CsrIndicatorForm) => void;
};
export const IndicatorLine = (props: IndicatorLineProps) => {
    const { indicator, editAction, deleteAction } = props;
    const theme = useTheme();

    return (
        <Fragment>
            <Box sx={{ gridColumn: "a", wordBreak: "break-word" }}>
                <Typography>{indicator.name}</Typography>
            </Box>
            <Box sx={{ gridColumn: "b", wordBreak: "break-word" }}>
                <Typography>{`${indicator.currentValue} ${indicator.abbreviationUnit}`}</Typography>
            </Box>
            <Box sx={{ gridColumn: "c", wordBreak: "break-word" }}>
                <Typography>{`${indicator.shortTermTarget} ${indicator.abbreviationUnit}`}</Typography>
            </Box>
            <Box sx={{ gridColumn: "d", wordBreak: "break-word" }}>
                <Typography>{`${indicator.longTermTarget} ${indicator.abbreviationUnit}`}</Typography>
            </Box>
            <Box sx={{ gridColumn: "e", wordBreak: "break-word" }}>
                <Stack flexDirection={"row"} gap={theme.spacing(1)}>
                    <FoodsBadge
                        size={2}
                        icon={"edit"}
                        boxProps={{
                            onClick: () => editAction?.(indicator),
                            sx: { cursor: "pointer" },
                        }}
                    />
                    <VerticalDivider />
                    <FoodsBadge
                        size={2}
                        icon={"delete"}
                        boxProps={{
                            onClick: () => deleteAction?.(indicator),
                            sx: { cursor: "pointer" },
                        }}
                    />
                </Stack>
            </Box>
        </Fragment>
    );
};
