import { useQuery } from "react-query";
import { sureApiCall } from "src/api/axiosClient/sureApi";
import { zodParseWithLog } from "src/api/apiErrorLogs";
import { PropertySchema } from "src/api/sure-api/ssq/useGetOneSsq.ts";

export const useGetOneSsqProperty = (propertyId: number) => {
    const query = useQuery({
        cacheTime: 0,
        queryKey: ["getOneSsqProperty", propertyId],
        queryFn: async () => {
            return await sureApiCall.get(`/api/ssq_properties/${propertyId}`);
        },
        select: (response) => {
            const property = response.data;
            return zodParseWithLog(property, PropertySchema, "getOneSsqProperty");
        },
    });

    return {
        ssqProperty: query.data,
        ...query,
    };
};
