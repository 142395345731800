import { Stack, Box } from "@mui/material";
import { useDocumentListContext } from "../DocumentationContext";
import { DocumentUpload } from "./DocumentUpload/DocumentUpload";
import { useTranslation } from "react-i18next";
import { TitleSearch } from "src/components/Grid/TitleSearch";
import { styles } from "./styles/DocumentationListGridHeader";

export const DocumentationListGridHeader = () => {
    const { totalItems, delayedSearchQuery } = useDocumentListContext();
    const { delayedSearch, resetSearch } = delayedSearchQuery;
    const { t } = useTranslation();

    return (
        <Box sx={styles.container}>
            <Stack direction="row" useFlexGap justifyContent="space-between" alignItems="center">
                <Stack direction="row" useFlexGap gap="12px">
                    <TitleSearch
                        title={t("documentation.allDocuments")}
                        search={delayedSearch}
                        totalItems={totalItems ?? 0}
                        resetSearch={resetSearch}
                    />
                </Stack>
                <Stack direction="row" useFlexGap gap="12px">
                    <DocumentUpload />
                </Stack>
            </Stack>

            <Stack
                direction="row"
                useFlexGap
                justifyContent="space-between"
                alignItems="center"
                sx={styles.stackMargin}
            />
        </Box>
    );
};
