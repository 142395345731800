import { useSettingsCMSContext } from "src/context/Settings/CMS/useSettingsCMSContext";
import { EditHero } from "./Sections/Hero/EditHero";
import { EditArticles } from "./Sections/Articles/EditArticles";
import { EditIndicators } from "./Sections/Indicators/EditIndicators";
import { EditStrategyIndicators } from "./Sections/StrategyIndicators/EditStrategyIndicators";

export const EditSections = (): JSX.Element | null => {
    const { content, pageParams } = useSettingsCMSContext();

    if (content === undefined) {
        return null;
    }

    const section = content.sections.find((section) => section.id === pageParams.sectionId);
    if (section === undefined) {
        // No section found;
        return null;
    }

    const sectionType = section.type;
    switch (sectionType) {
        case "hero":
            return <EditHero originalPageId={pageParams.pageId ?? ""} section={section} />;
        case "indicator":
            return <EditIndicators originalPageId={pageParams.pageId ?? ""} section={section} />;
        case "articles":
            return <EditArticles originalPageId={pageParams.pageId ?? ""} section={section} />;
        case "strategyIndicators":
            return <EditStrategyIndicators originalPageId={pageParams.pageId ?? ""} section={section} />;
        default:
            // eslint-disable-next-line
            const _e: never = sectionType;
            return <></>;
    }
};
