import { Button, Stack, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

type ConfirmationFooterProps = {
    saveAction: () => void;
    resetContent?: () => void;
};
export const ConfirmationFooter = (props: ConfirmationFooterProps) => {
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <Stack flexDirection={"row"} padding={theme.spacing(2, 8)} borderTop={`1px solid black`}>
            {props.resetContent && (
                <Button variant="text" onClick={props.resetContent}>
                    {t("glossary.reset")}
                </Button>
            )}
            <Button variant="primary" onClick={props.saveAction}>
                {t("glossary.save")}
            </Button>
        </Stack>
    );
};
