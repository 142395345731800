import { useQuery } from "react-query";
import { sureApiCall } from "src/api/axiosClient/sureApi";
import { zodParseWithLog } from "src/api/apiErrorLogs";
import { z } from "zod";

export const useGetOneSsq = (ssqId: number | undefined) => {
    const query = useQuery({
        cacheTime: 0,
        enabled: ssqId !== undefined,
        queryKey: ["getOneSsq", ssqId],
        queryFn: async () => {
            if (!ssqId) {
                return;
            }

            return await sureApiCall.get(`/api/ssqs/${ssqId}`);
        },
        select: (response) => {
            if (!response) {
                return;
            }

            const ssq = response.data;
            const data = zodParseWithLog(ssq, SsqSchema, "getOneSsq");
            return data;
        },
    });

    return {
        ssq: query.data,
        ...query,
    };
};

const FieldSchema = z.object({
    type: z.union([
        z.literal("group"),
        z.literal("text"),
        z.literal("select"),
        z.literal("radio"),
        z.literal("checkbox"),
        z.literal("date"),
        z.literal("daterange"),
    ]),
});

const UnitSchema = z.object({
    id: z.number(),
    title: z.string(),
    abbreviation: z.string(),
});

export type Unit = z.infer<typeof UnitSchema>;

const DataListOptionSchema = z.object({
    id: z.number(),
    value: z.string(),
    label: z.string(),
    position: z.number(),
    groupOption: z.string().optional(),
});

export type DataListOption = z.infer<typeof DataListOptionSchema>;

const DataListSchema = z.object({
    options: DataListOptionSchema.array(),
});

const FormulaSchema = z.object({
    id: z.number(),
    score: z
        .object({
            id: z.number(),
            title: z.string().optional(),
            type: z.string(),
            displayMode: z.string().optional(),
            unit: z
                .object({
                    id: z.number(),
                    title: z.string(),
                    abbreviation: z.string(),
                })
                .optional(),
            precision: z.number().optional(),
        })
        .optional(),
    formula: z.string().optional(),
    unit: z
        .object({
            id: z.number(),
            title: z.string(),
            abbreviation: z.string(),
        })
        .optional(),
});

export type Formula = z.infer<typeof FormulaSchema>;

const BasePropertySchema = z.object({
    id: z.number(),
    title: z.string(),
    hint: z.string().optional(),
    position: z.number().optional(),
    defaultValue: z.string().optional(),
    genericValue: z.string().optional(),
    defaultValueIds: z.array(z.number()).optional(),
    genericValueIds: z.array(z.number()).optional(),
    multivalue: z.boolean(),
    readonly: z.boolean(),
    hidden: z.boolean().optional(),
    field: FieldSchema.optional(),
    unit: UnitSchema.optional(),
    dataList: DataListSchema.optional(),
    minValue: z.number().optional(),
    maxValue: z.number().optional(),
    formulas: FormulaSchema.array(),
});

export type Property = z.infer<typeof BasePropertySchema> & {
    children: Property[];
};

export const PropertySchema: z.ZodType<Property> = BasePropertySchema.extend({
    children: z.lazy(() => PropertySchema.array()),
});

const SsqHeadingSsqPropertySchema = z.object({
    ssqHeading: z.string(),
    ssqProperty: PropertySchema,
    position: z.number().optional(),
    hidden: z.boolean().optional(),
});

export type SsqHeadingSsqProperty = z.infer<typeof SsqHeadingSsqPropertySchema>;

export const BaseHeadingSchema = z.object({
    id: z.number(),
    title: z.string(),
    hidden: z.boolean().optional(),
    helperText: z.string().optional(),
    position: z.number(),
    level: z.number(),
    properties: PropertySchema.array(),
    ssqHeadingSsqProperties: SsqHeadingSsqPropertySchema.array(),
});

export type Heading = z.infer<typeof BaseHeadingSchema> & {
    children: Heading[];
};

export const HeadingSchema: z.ZodType<Heading> = BaseHeadingSchema.extend({
    children: z.lazy(() => HeadingSchema.array()),
});

export const SsqSchema = z.object({
    id: z.number(),
    title: z.string(),
    headings: HeadingSchema.array(),
    monolithic: z.boolean(),
});

export type Ssq = z.infer<typeof SsqSchema>;
