import { useParams } from "react-router-dom";
import { useFoodpilotContext } from "src/context/FoodpilotContext.tsx";
import { useProductContext } from "src/pages/Product/ProductDetails/ProductContext.tsx";
import { useGetOneProduct } from "src/api/client-api/products/useGetOneProduct.ts";
import { useGetOneScore } from "src/api/sure-api/score/useGetOneScore.ts";
import { Loading } from "src/components/utils/Loading.tsx";
import { ScoreDetailContextProvider } from "src/context/ScoreDetail/provider.tsx";

type ProductScoreContextProviderProps = {
    children: JSX.Element | JSX.Element[];
};
const ProductScoreContextProvider = (props: ProductScoreContextProviderProps) => {
    const { children } = props;

    const { selectedCompany, currentCampaign } = useFoodpilotContext();
    const { product, variations, ssq } = useProductContext();
    const { scoreId: scoreIdString } = useParams<{ scoreId: string }>();
    const scoreId = Number(scoreIdString);
    const { score: detailedScore } = useGetOneScore(scoreId);

    if (!product?.id) {
        throw new Error("No product ID provided. Cannot fetch product details.");
    }

    const { product: detailedProduct } = useGetOneProduct(String(product.id), {
        campaign: currentCampaign?.id,
        company_id: selectedCompany?.id,
        dimensions: [["all"]],
        variations: variations,
        enabled: detailedScore !== undefined,
    });

    const { scores, prevScores } = detailedProduct || {};

    if (!detailedProduct || !detailedScore || !scores) {
        return <Loading />;
    }

    return (
        <ScoreDetailContextProvider scores={scores} prevScores={prevScores} ssq={ssq}>
            {children}
        </ScoreDetailContextProvider>
    );
};

export default ProductScoreContextProvider;
