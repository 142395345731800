import { Box, Typography, useTheme } from "@mui/material";
import { CloudDoneOutlined, LoopOutlined, WarningAmber } from "@mui/icons-material";
import { WhitePopover } from "@foodpilot/foods";

type SavingCloudStatusProps = {
    isSaving: boolean;
    isError: boolean;
    textOptions: {
        syncingMessage: string;
        errorDefaultMessage: string;
        savingDefaultMessage: string;
    };
};
export const SavingCloudStatus = (props: SavingCloudStatusProps) => {
    const { isSaving, isError, textOptions } = props;
    const theme = useTheme();

    const StateIcon =
        isSaving ? LoopOutlined
        : isError ? WarningAmber
        : CloudDoneOutlined;

    const loadingAnimation =
        isSaving ?
            {
                animation: "spin 1s linear infinite",
                "@keyframes spin": {
                    "0%": {
                        transform: "rotate(360deg)",
                    },
                    "100%": {
                        transform: "rotate(0deg)",
                    },
                },
            }
        :   {};
    const Icon = (
        <StateIcon
            sx={{
                width: "24px",
                color: isError ? theme.custom.red[600] : theme.custom.grey[2000],
                ...loadingAnimation,
            }}
        />
    );

    const StatePopoverBody = (
        <Box padding="12px 16px" textAlign="center">
            <Typography variant="body-medium">
                {isSaving ?
                    textOptions.syncingMessage
                : isError ?
                    textOptions.errorDefaultMessage
                :   textOptions.savingDefaultMessage}
            </Typography>
        </Box>
    );

    return Icon;
    // return <WhitePopover icon={Icon} body={StatePopoverBody} size="260px" />;
};
