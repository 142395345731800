import { useQuery } from "react-query";
import { loggedInApiCall } from "src/api/axiosClient/userApi";
import { zodParseWithLog } from "src/api/apiErrorLogs";
import { z } from "zod";
import { MRT_PaginationState, MRT_SortingState } from "material-react-table";

interface GetAllDocumentsParams {
    page: MRT_PaginationState;
    sorting: MRT_SortingState;
    search?: string;
}

export const useGetAllDocuments = ({ page, sorting, search }: GetAllDocumentsParams) => {
    return useQuery({
        queryKey: ["getAllDocuments", page, sorting, search],
        queryFn: async () => {
            const url = `/api/cms/contents`;
            const params = {
                page: page.pageIndex + 1,
                itemsPerPage: page.pageSize,
                order:
                    sorting.length > 0 ? { [sorting[0].id]: sorting[0].desc ? "desc" : "asc" } : { createdAt: "desc" },
                title: search || undefined, // Search by title
                name: search || undefined, // Search by name
            };

            const response = await loggedInApiCall.get(url, { params });
            const documents = response.data["hydra:member"];
            const totalItems = response.data["hydra:totalItems"];

            return {
                list: zodParseWithLog(documents, DocumentSchema.array(), "allDocuments"),
                totalItems,
            };
        },
    });
};

const DocumentSchema = z.object({
    id: z.string(),
    name: z.string().optional(),
    title: z.string().optional(),
    mimeType: z.string().optional(),
    size: z.number().optional(),
    url: z.string().optional(),
    createdAt: z.string(),
    updatedAt: z.string().optional(),
});
