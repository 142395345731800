export const createDistribution = (values: number[]): number[] => {
    const total = values.reduce((sum, value) => sum + value, 0);

    if (total === 0) {
        return Array(values.length).fill(0);
    }

    const proportions = values.map((value) => Math.floor((value / total) * 100));
    let error = 100 - proportions.reduce((sum, value) => sum + value, 0);

    while (error > 0) {
        const maxIndex = proportions.indexOf(Math.max(...proportions));
        proportions[maxIndex]++;
        error--;
    }

    return proportions;
};
