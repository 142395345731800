import { useQuery } from "react-query";
import { zodParseWithLog } from "src/api/apiErrorLogs";
import { loggedInApiCall } from "src/api/axiosClient/userApi";
import { z } from "zod";

export const getAllStrategyKey = "getAllStrategyKey" as const;

export const useGetAllStrategy = () => {
    const query = useQuery({
        queryKey: [getAllStrategyKey],
        queryFn: async () => {
            const url = `/api/steering/strategies`;

            const params = {
                page: 1,
                itemsPerPage: 1,
            };
            return await loggedInApiCall.get(url, {
                params,
            });
        },
        select: (request) => {
            const data = request.data["hydra:member"];
            const zodParsedData = zodParseWithLog(data, CsrStrategySchema.array(), getAllStrategyKey);
            return zodParsedData;
        },
    });

    return {
        allStrategy: query.data,
        ...query,
    };
};

export type CsrIndicator = {
    "@id": IRI;
    name: string;
    abbreviationUnit: string;
    currentValue: string;
    shortTermTarget: string;
    longTermTarget: string;
};

const CsrIndicatorSchema: z.ZodType<CsrIndicator> = z.object({
    "@id": z.string(),
    name: z.string(),
    abbreviationUnit: z.string(),
    currentValue: z.string(),
    shortTermTarget: z.string(),
    longTermTarget: z.string(),
});

export type CsrPillar = {
    "@id": string;
    id: string;
    name: string;
    themes?: number[];
    icon: string;
    indicators: CsrIndicator[];
};

const CsrPillarSchema: z.ZodType<CsrPillar> = z.object({
    "@id": z.string(),
    id: z.string(),
    name: z.string(),
    themes: z.number().array().optional(),
    icon: z.string(),
    indicators: CsrIndicatorSchema.array(),
});

export type CsrStrategy = {
    "@id": IRI;
    id: string;
    name: string;
    description?: string;
    campaign: IRI;
    pillars: CsrPillar[];
};

const CsrStrategySchema: z.ZodType<CsrStrategy> = z.object({
    "@id": z.string(),
    id: z.string(),
    name: z.string(),
    description: z.string().optional(),
    campaign: z.string(),
    pillars: CsrPillarSchema.array(),
});
