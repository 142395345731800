import { useMutation, useQueryClient } from "react-query";
import { loggedInApiCall } from "src/api/axiosClient/userApi";
import { CMSSectionBlocksContent, CMSSectionBlocksStrategy, getOneCMSContentKey } from "../useGetOneContent";

type CreateBlock = {
    newBlock: BlockWrite;
};

export const useCreateOneBlock = () => {
    const queryClient = useQueryClient();

    const query = useMutation({
        mutationFn: async (options: CreateBlock) => {
            const { newBlock } = options;

            const url = `/api/cms/contents/pages/blocks/contents`;

            const response =
                newBlock.id === undefined ?
                    await loggedInApiCall.post(url, newBlock)
                :   await loggedInApiCall.patch(`${url}/${newBlock.id}`, newBlock, {
                        headers: {
                            "Content-Type": "application/merge-patch+json",
                        },
                    });

            return response;
        },
        onSuccess: () => {
            queryClient.invalidateQueries([getOneCMSContentKey]);
        },
    });

    return query;
};

type WriteContentBlock = {
    type: "content";
    title: string;
    subtitle?: string | null;
    description?: string | null;
    ctaLink?: string | null;
    ctaLabel?: string | null;
    media?: IRI | null;
};

// type WriteStrategyBlock = {
//     type: "strategy",
//     indicator: IRI;
// }

type BlockWriteKind = WriteContentBlock;
// | WriteStrategyBlock;

type BlockWriteCore = {
    "@id"?: IRI;
    id: UUID | undefined;
    position: number;
    section: IRI;
};

export type BlockWrite = BlockWriteCore & BlockWriteKind;
