import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "src/utils/useSnackbar";
import { UpdateProperty, updatePropertyFn } from "./useUpdateProperty";
import { getOneSupplierKey } from "../suppliers/useGetOneSupplier";
import { getOneIngredientKey } from "../ingredients/useGetOneIngredient";
import { getOneProductKey } from "../products/useGetOneProduct";
import { userMeKey } from "../useUserNew";
import { anyAllSimulationKey } from "../post/useGetAllPosts";
import { useTranslation } from "react-i18next";

export const updateBatchPropertiesFn = async (options: UpdateProperty[]) => {
    return Promise.all(
        options.map(async (option) => {
            await updatePropertyFn(option);
        }),
    );
};

export const useUpdateBatchProperties = () => {
    const queryClient = useQueryClient();
    const snackbar = useSnackbar();
    const { t } = useTranslation();

    const query = useMutation({
        mutationFn: async (options: UpdateProperty[]) => {
            await updateBatchPropertiesFn(options);
        },
        onSuccess: () => {
            queryClient.invalidateQueries([getOneSupplierKey]);
            queryClient.invalidateQueries([getOneIngredientKey]);
            queryClient.invalidateQueries([getOneProductKey]);

            // This shouldn't exist, as the score for now in the user is for a COMPANY.
            queryClient.invalidateQueries([userMeKey]);

            queryClient.invalidateQueries([anyAllSimulationKey]);
        },
        onError: () => {
            snackbar.forceClose();
            snackbar.setSnackbarProps({
                type: "error",
                message: t("calculation.unexpectedError"),
            });
        },
    });

    return query;
};
