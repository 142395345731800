import { PrimaryDialog, Typography } from "@foodpilot/foods";
import { CsrIndicatorForm } from "../PillarForm";
import { Fragment } from "react/jsx-runtime";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IndicatorFields } from "./IndicatorFields";

type AddIndicatorModalProps = {
    isOpen: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    indicatorToEdit: CsrIndicatorForm;
    setIndicator: (indicator: CsrIndicatorForm) => void;
};
export const AddIndicatorModal = (props: AddIndicatorModalProps) => {
    const { isOpen, setOpen, indicatorToEdit, setIndicator } = props;
    const { t } = useTranslation();

    const onCloseAndReset = () => {
        setOpen(false);
    };

    const [temporaryForm, setTemporaryForm] = useState<CsrIndicatorForm>(indicatorToEdit);

    useEffect(() => {
        setTemporaryForm(indicatorToEdit);
    }, [indicatorToEdit]);

    const isItemNew = indicatorToEdit === null;
    const titleAction = isItemNew ? t("glossary.create") : t("glossary.modify");

    if (temporaryForm === null) return;
    return (
        <Fragment>
            <PrimaryDialog
                open={isOpen}
                setOpen={setOpen}
                options={{
                    title: (
                        <Typography variant="big-bold">
                            {t("settings.csr.form.indicator.modal.title", { verb: titleAction })}
                        </Typography>
                    ),
                    content: <IndicatorFields indicator={temporaryForm} setIndicator={setTemporaryForm} />,
                }}
                onValidate={() => {
                    setIndicator(temporaryForm);
                }}
                onClose={onCloseAndReset}
                onCancel={onCloseAndReset}
                fullWidth
                maxWidth="md"
                cancelText={t("primaryDialog.cancel")}
                validateText={t("primaryDialog.validate")}
            />
        </Fragment>
    );
};
