import { SettingsStrategyContext, SettingsStrategyInitialValues } from "./context";
import { Loading } from "src/components/utils/Loading";
import { useGetAllStrategy } from "src/api/client-api/csrStrategy/useGetAllStrategy";

type SettingsStrategyProviderProps = {
    children: JSX.Element;
};
export const SettingsStrategyProvider = (props: SettingsStrategyProviderProps) => {
    const { children } = props;

    const { allStrategy, isFetched } = useGetAllStrategy();

    if (isFetched === false) {
        return <Loading />;
    }

    // In any case, we only want to ever keep the first one.
    if (allStrategy !== undefined && allStrategy.length > 1) {
        console.warn("You have too much strategy items");
    }

    const _strategy = allStrategy ?? [];
    const strategy = _strategy.length > 0 ? _strategy[0] : undefined;

    const items: SettingsStrategyInitialValues = {
        strategy: strategy,
    };

    return <SettingsStrategyContext.Provider value={items}>{children}</SettingsStrategyContext.Provider>;
};
