import { SeeMoreCard, FoodsIllustrations, FoodsTags } from "@foodpilot/foods";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { CsrPillar } from "src/api/client-api/csrStrategy/useGetAllStrategy";
import { IndicatorList } from "./IndicatorList";
import { useTranslation } from "react-i18next";

type PillarCardProps = {
    pillar: CsrPillar;
    pillarNumber: number;
};
export const PillarCard = (props: PillarCardProps) => {
    const { pillar, pillarNumber } = props;
    const theme = useTheme();
    const { t } = useTranslation();

    const indicators = pillar.indicators;
    if (indicators.length === 0) {
        return;
    }

    const cuttingIndex = 3;
    const previewItems = pillar.indicators.slice(0, cuttingIndex);
    const otherItems = pillar.indicators.slice(cuttingIndex);

    return (
        <Stack
            sx={{
                width: "content-fit",
                height: "content-fit",
                backgroundColor: theme.custom.grey[100],
                padding: theme.spacing(3),
            }}
        >
            <SeeMoreCard
                seeMoreText={t("csrStrategy.seeMoreIndicators")}
                seeLessText={t("csrStrategy.seeLessIndicators")}
                defaultVisible={false}
                hidden={otherItems.length > 0 ? <IndicatorList indicators={otherItems} /> : null}
            >
                <Stack flexDirection={"row"} gap={theme.spacing(2)}>
                    <Box sx={{ display: "flex", maxWidth: "60px" }}>
                        <FoodsIllustrations illustration={"communities"} height={60} backgroundShape="rect" />
                    </Box>
                    <Stack flexDirection={"column"} gap={theme.spacing(0.5)}>
                        <Box width={"fit-content"}>
                            <FoodsTags label={`Pillar ${pillarNumber}`} />
                        </Box>
                        <Typography variant={"h3"}>{pillar.name}</Typography>
                    </Stack>
                </Stack>
                <IndicatorList indicators={previewItems} />
            </SeeMoreCard>
        </Stack>
    );
};
