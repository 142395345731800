import { styled } from "@mui/material/styles";
import {
    Accordion as MuiAccordion,
    AccordionSummary as MuiAccordionSummary,
    Box,
    AccordionProps,
    AccordionSummaryProps,
    BoxProps,
    accordionClasses,
    accordionSummaryClasses,
} from "@mui/material";

export const Accordion = styled((props: AccordionProps) => <MuiAccordion {...props} />)(({ theme }) => ({
    [`&.${accordionClasses.root}`]: {
        border: `1px solid ${theme.custom.grey[500]}`,
        borderRadius: "8px",
        marginBottom: "20px",
    },
    "&::before": {
        display: "none",
    },
    [`&.${accordionClasses.expanded} .${accordionSummaryClasses.root}`]: {
        borderBottom: `1px solid ${theme.custom.grey[500]}`,
        borderRadius: "initial",
    },
}));

export const AccordionSummary = styled((props: AccordionSummaryProps) => <MuiAccordionSummary {...props} />)(
    ({ theme }) => ({
        [`&.${accordionSummaryClasses.root}:hover`]: {
            backgroundColor: theme.custom.grey[400],
            borderRadius: "8px",
        },
        [`&.${accordionSummaryClasses.expanded}:hover`]: {
            backgroundColor: theme.custom.grey[400],
            borderBottomRadius: "0",
        },
        [`&.${accordionSummaryClasses.root}:hover .category`]: {
            backgroundColor: theme.custom.grey[100],
        },
    }),
);

export const imageContainerClasses = {
    root: "MuiImageContainer-root",
    image: "MuiImageContainer-image",
};

export const ImageContainer = styled((props: BoxProps) => <Box className={imageContainerClasses.root} {...props} />)(
    ({ theme }) => ({
        [`&.${imageContainerClasses.root}`]: {
            width: "100%",
            height: 160,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "8px",
            overflow: "hidden",
            transition: theme.transitions.create(["background-color"]),
            "&:hover": {
                backgroundColor: theme.custom.grey[400],
            },
        },
        [`& .${imageContainerClasses.image}`]: {
            maxWidth: 280,
            maxHeight: "100%",
            objectFit: "contain",
            borderRadius: "8px",
            transition: theme.transitions.create(["background-color"]),
            "&:hover": {
                backgroundColor: theme.custom.grey[100],
            },
        },
        [theme.breakpoints.up("md")]: {
            width: 280,
            height: 200,
            [`& .${imageContainerClasses.image}`]: {
                maxWidth: "100%",
            },
        },
    }),
);
