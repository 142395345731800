import { Product } from "src/api/client-api/products/useGetOneProduct";
import {
    ProductFormPackagingMaterial,
    ProductFormProductIngredient,
    ProductFormProductPackaging,
    ProductFormProductTransformations,
    ProductFormType,
    SuppliersName,
} from "./ProductForm/ProductForm.types";
import {
    ProductIngredientWrite,
    ProductPackagingMaterialWrite,
    ProductPackagingWrite,
    ProductTransformationWrite,
    ProductWrite,
} from "src/api/client-api/products/useCreateProduct";

export class ProductService {
    static getProductToCreate = (existingProduct?: Product): ProductFormType => {
        if (existingProduct === undefined) {
            return {
                id: undefined,
                title: null,
                clientId: null,
                productReferenceId: null,
                netWeight: null,
                brand: null,
                productLine: null,
                distributionChannelId: null,
                tags: [],
                productPackagings: [],
                productIngredients: [],
                productTransformations: [],
                productConsumptions: [],
            };
        }

        const existingPIng = existingProduct.productIngredients?.map((pIng) => {
            const suppliersName: SuppliersName[] =
                pIng.ingredient.ingredientSuppliers?.map((ingSup) => {
                    return { id: ingSup.supplier.id, name: ingSup.supplier.name };
                }) ?? [];
            const formattedPIng: ProductFormProductIngredient = {
                "@id": pIng["@id"],
                id: pIng.id,
                ingredient: {
                    "@id": pIng.ingredient["@id"],
                    id: pIng.ingredient.id,
                    name: pIng.ingredient.name,
                    suppliersName: suppliersName,
                },
                quantity: pIng.quantity ?? null,
                // proportion: pIng.proportion ?? null,
                usedQuantity: pIng.usedQuantity ?? null,
                // usedProportion: pIng.usedProportion ?? null,
                // isUsedQuantityEqualToQuantity: pIng.isUsedQuantityEqualToQuantity,
            };
            return formattedPIng;
        });

        const existingPTrans = existingProduct.productTransformations?.map((pTrans) => {
            const formattedPtrans: ProductFormProductTransformations = {
                "@id": pTrans["@id"],
                productTransformationProcessId: pTrans.productTransformationProcessId,
                weight: pTrans.weight ?? null,
            };
            return formattedPtrans;
        });

        const existingPPack = existingProduct.productPackagings?.map((pPack) => {
            const existingPMat = pPack.packagingMaterials.map((PMat) => {
                const formattedPMat: ProductFormPackagingMaterial = {
                    "@id": PMat["@id"],
                    packagingMaterialSetId: PMat.packagingMaterialSetId,
                    proportion: PMat.proportion ?? null,
                    weight: PMat.weight ?? null,
                };
                return formattedPMat;
            });
            const formattedPPack: ProductFormProductPackaging = {
                "@id": pPack["@id"],
                packagingFormId: pPack.packagingFormId,
                packagingMaterials: existingPMat,
            };
            return formattedPPack;
        });

        return {
            id: existingProduct.id,
            title: existingProduct.title,
            clientId: existingProduct.clientId ?? null,
            productReferenceId: existingProduct.productReferenceId ?? null,
            netWeight: existingProduct.netWeight ?? null,
            brand: existingProduct.brand ?? null,
            productLine: existingProduct.productLine ?? null,
            distributionChannelId: existingProduct.distributionChannelId ?? null,
            tags: existingProduct.tags ?? [],
            productIngredients: existingPIng ?? [],
            productConsumptions: existingProduct.consumptionsIds ?? [],
            productTransformations: existingPTrans ?? [],
            productPackagings: existingPPack ?? [],
        };
    };

    private static productIngredientFormToWrite = (
        productIngredient: ProductFormProductIngredient[],
    ): ProductIngredientWrite[] => {
        return productIngredient.map((pi) => {
            if (pi["@id"] === undefined) {
                return {
                    ingredient: pi.ingredient["@id"],
                    quantity: pi.quantity,
                    usedQuantity: pi.usedQuantity,
                    isUsedQuantityEqualToQuantity: false,
                };
            }

            return {
                "@id": pi["@id"],
                quantity: pi.quantity,
                usedQuantity: pi.usedQuantity,
                isUsedQuantityEqualToQuantity: false,
            };
        });
    };

    private static productPackagingMaterialsFormToWrite = (
        packagingMaterials: ProductFormPackagingMaterial[],
    ): ProductPackagingMaterialWrite[] => {
        return packagingMaterials.map((pMat) => {
            if (pMat["@id"] === undefined) {
                return {
                    packagingMaterialSetId: pMat.packagingMaterialSetId,
                    proportion: pMat.proportion,
                    weight: pMat.weight,
                };
            }

            return {
                "@id": pMat["@id"],
                proportion: pMat.proportion,
                weight: pMat.weight,
            };
        });
    };

    private static productPackagingsFormToWrite = (
        productPackagings: ProductFormProductPackaging[],
    ): ProductPackagingWrite[] => {
        return productPackagings.map((pPack) => {
            const newPackMat = ProductService.productPackagingMaterialsFormToWrite(pPack.packagingMaterials);
            if (pPack["@id"] === undefined) {
                return {
                    packagingFormId: pPack.packagingFormId,
                    packagingMaterials: newPackMat,
                };
            }

            return {
                "@id": pPack["@id"],
                packagingMaterials: newPackMat,
            };
        });
    };

    private static productTransformationFormToWrite = (
        productTransformations: ProductFormProductTransformations[],
    ): ProductTransformationWrite[] => {
        return productTransformations.map((pT) => {
            if (pT["@id"] === undefined) {
                return {
                    productTransformationProcessId: pT.productTransformationProcessId,
                    weight: null,
                };
            }

            return {
                "@id": pT["@id"],
                weight: pT.weight,
            };
        });
    };

    static productFormToProductWrite = (productForm: ProductFormType, ownedBy: IRI | null = null): ProductWrite => {
        const newPI = ProductService.productIngredientFormToWrite(productForm.productIngredients);
        const newPack = ProductService.productPackagingsFormToWrite(productForm.productPackagings);
        const newTransformations = ProductService.productTransformationFormToWrite(productForm.productTransformations);

        const productWrite: ProductWrite = {
            id: productForm.id,

            brand: productForm.brand?.["@id"] ?? null,
            distributionChannelId: productForm.distributionChannelId,
            consumptionsIds: productForm.productConsumptions,
            clientId: productForm.clientId,
            productIngredients: newPI,
            netWeight: productForm.netWeight,
            productLine: productForm.productLine?.["@id"] ?? null,
            productReferenceId: productForm.productReferenceId,
            tags: productForm.tags?.map((t) => t["@id"]) ?? [],
            title: productForm.title,

            productPackagings: newPack,
            productTransformations: newTransformations,
        };

        if (ownedBy !== null) {
            productWrite.ownedBy = ownedBy;
        }

        return productWrite;
    };

    static roundTo = (value: number, precision: number): number => {
        const newValue = Math.round(value * Math.pow(10, precision)) / Math.pow(10, precision);
        return newValue;
    };
}
