import { HeroBlock } from "@foodpilot/foods";
import { CMSPageSection, CMSSectionBlocksContent } from "src/api/client-api/cms/useGetOneContent";

type CallToAction =
    | {
          label: string;
          onClick: () => void;
      }
    | undefined;

type HeroProps = {
    section: CMSPageSection<CMSSectionBlocksContent>;
};
export const Hero = (props: HeroProps) => {
    const { section } = props;
    const { blocks } = section;

    if (blocks.length === 0) {
        return <></>;
    }

    const { title, subtitle, description, ctaLink, ctaLabel, media } = blocks[0];

    const handleExternalLink = (link: string) => () => {
        if (!link) return;

        link.startsWith("http") ? window.open(link, "_blank") : window.open(`https://${link}`, "_blank");
    };

    const RenderDescription = (): JSX.Element => {
        return description ? <p dangerouslySetInnerHTML={{ __html: description }} /> : <></>;
    };

    const getCallToActionProps: () => CallToAction = () => {
        const isCallToActionLabelIsEmpty = !ctaLabel;
        const callToActionProps = {
            label: ctaLabel,
            onClick: handleExternalLink(ctaLink),
        };
        return isCallToActionLabelIsEmpty ? undefined : callToActionProps;
    };

    const callToActionProps = getCallToActionProps();
    return (
        <HeroBlock
            title={title}
            subtitle={subtitle}
            description={<RenderDescription />}
            callToAction={callToActionProps}
            imageUrl={media?.url}
        />
    );
};
