import { useQuery } from "react-query";
import { sureApiCall } from "src/api/axiosClient/sureApi";
import { zodParseWithLog } from "src/api/apiErrorLogs";
import { ScoreSchema } from "src/api/sure-api/score/useGetOneScore.ts";

export const getAllScoreKey = "getAllScore" as const;

export const useGetAllScore = () => {
    const query = useQuery({
        queryKey: [getAllScoreKey],
        cacheTime: 0,
        queryFn: async () => {
            return await sureApiCall.get(`/api/scores`);
        },
        select: (response) => {
            const scores = response.data["hydra:member"];
            const data = zodParseWithLog(scores, ScoreSchema.array(), getAllScoreKey);
            return data;
        },
    });

    return {
        scores: query.data,
        ...query,
    };
};
