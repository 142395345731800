import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import { CMSPageSection, CMSSectionBlocksContent } from "src/api/client-api/cms/useGetOneContent";
import { SectionHeader } from "../_Common/SectionForm/SectionHeader";
import { useTranslation } from "react-i18next";
import { EditLayout } from "../_Common/EditLayout";
import { FoodsIcon } from "@foodpilot/foods";
import { useState } from "react";
import { DraggingRows } from "src/components/Dragging";
import { VerticalDivider } from "../../../Common/VerticalDivider";
import { AddArticlesModal } from "./Form/AddArticlesModal";
import { useDeleteOneBlock } from "src/api/client-api/cms/blocks/useDeleteOneBlock";
import { useReorderBlocks } from "src/api/client-api/cms/blocks/useReorderBlocks";
import { SectionForm } from "../_Common/SectionForm/SectionForm";
import { ContentBlockForm, getBlockContentForm } from "../_Common/BlockForm/ContentBlock/contentBlock";

type EditArticlesProps = {
    originalPageId: string;
    section: CMSPageSection<CMSSectionBlocksContent>;
};
export const EditArticles = (props: EditArticlesProps) => {
    const { originalPageId, section } = props;
    const { t } = useTranslation();
    const theme = useTheme();
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const deleteBlock = useDeleteOneBlock();
    const reorderBlock = useReorderBlocks();

    const [selectedExistingBlock, setSelectedExistingBlock] = useState<ContentBlockForm | null>(null);

    const blocks = getBlockContentForm(section);
    const blocksRows = blocks.flatMap((block) => {
        if (block.id === undefined) return [];
        return [
            {
                id: block.id,
                title: block.title,
                link: block.ctaLink,
                position: block.position,
                editAction: () => {
                    setSelectedExistingBlock(block);
                    setIsOpen(true);
                },
                deleteAction: () => {
                    if (block.id) {
                        deleteBlock.mutate({ deleteId: block.id, allBlocksToReorder: section.blocks });
                    }
                },
            },
        ];
    });
    return (
        <Stack gap={theme.spacing(3)}>
            <SectionHeader section={section} originalPageId={originalPageId} />
            <SectionForm section={section} />
            <EditLayout>
                <AddArticlesModal
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    currentSection={{
                        "@id": section["@id"],
                        totalExistingElements: section.blocks.length,
                    }}
                    selectedExistingBlock={selectedExistingBlock}
                />
                <Typography variant="big-medium">{t("cms.section.articles.add.info")}</Typography>
                <DraggingRows
                    rows={blocksRows}
                    renderRow={(row, rowIndex) => {
                        return (
                            <Stack
                                flexDirection={"row"}
                                justifyContent={"space-between"}
                                key={`index-${rowIndex}`}
                                sx={{ border: `2px solid ${theme.custom.grey[2000]}`, width: "100%" }}
                                padding={theme.spacing(1)}
                            >
                                <Stack
                                    flexDirection={"row"}
                                    justifyContent={"space-between"}
                                    alignItems={"center"}
                                    gap={theme.spacing(4)}
                                    marginLeft={theme.spacing(1)}
                                >
                                    <Typography variant="big-bold">{row.title}</Typography>
                                    {/* <Button variant="text" href={row.link} target="_blank" rel="noreferrer">
                                        {t("glossary.externalLink")}
                                    </Button> */}
                                </Stack>
                                <Stack flexDirection={"row"} gap={theme.spacing(2)} alignItems={"center"}>
                                    <Button
                                        variant="text"
                                        startIcon={<FoodsIcon size={2} icon={"edit"} />}
                                        onClick={row.editAction}
                                    >
                                        {"Modifier"}
                                    </Button>
                                    <VerticalDivider />
                                    <Box onClick={row.deleteAction}>
                                        <FoodsIcon size={4} icon="delete" />
                                    </Box>
                                </Stack>
                            </Stack>
                        );
                    }}
                    onHoverDrop={(reorderedRows) => {
                        reorderBlock.mutate({ allBlocksToReorder: reorderedRows });
                    }}
                />
                {section.blocks.length >= 4 ?
                    <Typography variant="big-bold">{t("cms.section.articles.maxlimit")}</Typography>
                :   <Button
                        variant="primary"
                        onClick={() => {
                            setSelectedExistingBlock(null);
                            setIsOpen(true);
                        }}
                    >
                        {t("cms.section.articles.add.button")}
                    </Button>
                }
            </EditLayout>
        </Stack>
    );
};
