import { WhiteBox, FoodsIllustrations } from "@foodpilot/foods";
import { Button, Stack, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { URLHandler } from "src/services/URLHandler";

export const NoCompany = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <WhiteBox>
            <Stack
                flexDirection={"column"}
                padding={theme.spacing(4)}
                gap={theme.spacing(4)}
                textAlign={"center"}
                justifyContent={"center"}
                alignItems={"center"}
            >
                <Typography variant="h2">{t("noCompany.title")}</Typography>
                <FoodsIllustrations illustration={"contactSupport"} />
                <Typography variant="body-medium">{t("noCompany.infoText")}</Typography>
                <Button
                    variant="primary"
                    onClick={() => {
                        const url = URLHandler.login.logout();
                        navigate(url);
                    }}
                >
                    {t("Se déconnecter")}
                </Button>
            </Stack>
        </WhiteBox>
    );
};
