import { useQuery } from "react-query";
import { zodParseWithLog } from "src/api/apiErrorLogs";
import { loggedInApiCall } from "src/api/axiosClient/userApi";
import { z } from "zod";
import { ScoresSchema } from "./scoreSchema";

type Options = {
    postId: number | undefined;
};

export const getOnePostKey = "getOnePost" as const;

export const useGetOnePost = (options: Options) => {
    const { postId } = options;

    const query = useQuery({
        cacheTime: 0,
        queryKey: [getOnePostKey, postId],
        enabled: postId !== undefined,
        keepPreviousData: true,
        queryFn: async () => {
            const url = `/api/ssq_posts/${postId}`;
            return loggedInApiCall.get(url, {
                headers: {
                    Accept: "application/json",
                },
            });
        },
        select: (req) => {
            const post = req.data;
            const data = zodParseWithLog(post, GetOnePostSchema, getOnePostKey);
            return data;
        },
    });

    return {
        post: query.data,
        ...query,
    };
};

export type GetOnePost = {
    id: number;
    campaign: {
        id: number;
        label: string;
    };
};

const GetOnePostSchema: z.ZodType<GetOnePost> = z.object({
    id: z.number(),
    campaign: z.object({
        id: z.number(),
        label: z.string(),
    }),
    scores: ScoresSchema,
});
