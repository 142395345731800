import { Stack, Typography } from "@mui/material";
import { Ingredient, IngredientSupplier } from "src/api/client-api/ingredients/useGetOneIngredient";
import { Post } from "src/api/client-api/post/postSchema";
import { useTranslation } from "react-i18next";
import { QuestionnairesList } from "src/components/QuestionnairesList/QuestionnairesList";
import { ScoresSection } from "src/components/QuestionnairesList/Scores/ScoresSection";
import { useFoodpilotContext } from "src/context/FoodpilotContext";
import { Loading } from "src/components/utils/Loading";
import { ButtonTabs, FoodsToggleButton, FoodsToggleButtonType } from "@foodpilot/foods";
import { NoPostSupplier } from "./IncompleteData/NoPostSupplier";
import { Ssq, Heading } from "src/api/sure-api/ssq/useGetOneSsq";
import { getScoreIdsByType } from "src/utils/scores";
import { ScoreProvider } from "src/context/ScoreContext";
import { useIngredientContext } from "../ExistingIngredientContext";
import { URLHandler } from "src/services/URLHandler";
import { useNavigate } from "react-router-dom";
import { getVisibleHeadings } from "src/utils/ssq";

type IngredientReferenceQuestionnaireProps = {
    ingredient: Ingredient;
    ingSuppliers: IngredientSupplier[];
    ssq: Ssq | undefined;
    posts: Post[] | undefined;
};
export const IngredientReferenceQuestionnaire = (props: IngredientReferenceQuestionnaireProps) => {
    const navigate = useNavigate();
    const { scoreTypes, selectedCompany, isUserOfTypeSupplier } = useFoodpilotContext();
    const { t } = useTranslation();
    const { activeSupplierId, canModifySSQ } = useIngredientContext();

    const ingredientsScoresIds = getScoreIdsByType("ingredient", scoreTypes);

    const { ingredient, ssq, posts, ingSuppliers } = props;

    if (ssq === undefined) {
        return <Loading />;
    }

    if (posts === undefined) {
        return <Loading />;
    }

    if (selectedCompany === null) return;

    const selectedSupplier = ingSuppliers.find((supplier) => supplier.supplier.id === activeSupplierId);
    const selectedPost = posts.find((item) => item.company?.id === activeSupplierId);

    const supplierButtons: FoodsToggleButtonType[] = ingSuppliers.map((value) => {
        // I am ashamed, but shame doesn't kill
        const currentPost = posts.find((item) => item.company?.id === value.supplier.id);
        const total = currentPost?.completions.ssq.result || 0;

        return {
            id: value.id,
            label: `${value.supplier.name} - ${total} %`,
            onClick: () => {
                const url = URLHandler.ingredient.ssq(selectedCompany.id, ingredient.id, value.supplier.id);
                navigate(url);
            },
        };
    });
    const initialSelectedButton = supplierButtons.find((i) => i.id === selectedSupplier?.id);

    const isSupplier = isUserOfTypeSupplier();
    const questionnaireTitle = isSupplier ? t("ssq.ingredient.mainTitle") : t("ssq.ingredient.supplierTitle");
    const visibleHeadings = getVisibleHeadings(ssq, selectedPost?.hiddenElements);

    return (
        <Stack gap="30px">
            <Typography variant="h2">{questionnaireTitle}</Typography>
            {isSupplier === false && (
                <FoodsToggleButton buttons={supplierButtons} initialSelected={initialSelectedButton} />
            )}
            <Stack gap="28px">
                {selectedPost === undefined && isSupplier === false ?
                    <NoPostSupplier />
                :   <QuestionnairesList
                        ssq={ssq}
                        post={selectedPost}
                        isSsqLoading={false}
                        isPostLoading={false}
                        completeQuestionnaireUrl={(heading: Heading) => {
                            const url = URLHandler.ingredient.ssqHeading(
                                selectedCompany.id,
                                ingredient.id,
                                activeSupplierId ?? 0,
                                heading.id,
                            );
                            return url;
                        }}
                        scoresSection={
                            <ScoreProvider type="ingredient" formattedScores={selectedPost?.formattedScores}>
                                <ScoresSection
                                    post={selectedPost}
                                    scoresIds={ingredientsScoresIds}
                                    headings={visibleHeadings}
                                />
                            </ScoreProvider>
                        }
                        canEdit={canModifySSQ}
                    />
                }
            </Stack>
        </Stack>
    );
};
