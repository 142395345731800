import { useApplicationParameters } from "src/api/client-api/appParams/useApplicationParameters";

export const AppParamMode = ["foodpilot", "inexweb", "collective", "bred", "foodpilotLustucru"] as const;
export type AppParamModeKeys = (typeof AppParamMode)[number];

export type OptionMapping<T> = Record<AppParamModeKeys, T>;
export type ClientOptions<T> = {
    defaultOption: T;
} & Partial<OptionMapping<T>>;

export const isModeValid = (value: string | undefined): value is AppParamModeKeys => {
    if (value === undefined) return false;

    return AppParamMode.includes(value as AppParamModeKeys);
};

// To use if you have a need that requires a condition concerning `ApplicationMode`.
export const useClientSwitch = () => {
    const { applicationParameters } = useApplicationParameters();

    const getClientSwitch = <T>(options: ClientOptions<T>): T => {
        const mode = applicationParameters?.mode;
        if (isModeValid(mode)) {
            const chosenOption = options[mode];
            if (chosenOption === undefined) {
                return options.defaultOption;
            }

            return chosenOption;
        }

        return options.defaultOption;
    };

    return getClientSwitch;
};
