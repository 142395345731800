import { ReactNode } from "react";
import { Stack } from "@mui/material";
import { Ssq, Heading } from "src/api/sure-api/ssq/useGetOneSsq";
import { Company } from "src/api/client-api/useUserNew";
import { ScoresSection } from "src/components/QuestionnairesList/Scores/ScoresSection";
import { QuestionnairesList } from "src/components/QuestionnairesList/QuestionnairesList";
import { useFoodpilotContext } from "src/context/FoodpilotContext";
import { getScoreIdsByType } from "src/utils/scores";
import { ScoreProvider } from "src/context/ScoreContext";
import { URLHandler } from "src/services/URLHandler";
import { useGetCompanyPost } from "src/api/client-api/post/useGetCompanyPost";
import { getVisibleHeadings } from "src/utils/ssq";

type QuestionnaireProps = {
    company: Company;
    ssq?: Ssq;
    isSsqLoading: boolean;
};
export const Questionnaire = (props: QuestionnaireProps): ReactNode => {
    const { company, ssq, isSsqLoading } = props;
    const { scoreTypes, selectedCompany, currentCampaign } = useFoodpilotContext();
    const { post, isLoading: isPostLoading } = useGetCompanyPost(selectedCompany?.id, currentCampaign?.id);

    const companyScoresIds = getScoreIdsByType("company", scoreTypes);

    if (selectedCompany === null) return;

    const visibleHeadings = ssq && getVisibleHeadings(ssq, post?.hiddenElements);

    return (
        <Stack gap="60px">
            <Stack gap="28px">
                <QuestionnairesList
                    ssq={ssq}
                    post={post}
                    isSsqLoading={isSsqLoading}
                    isPostLoading={isPostLoading}
                    completeQuestionnaireUrl={(heading: Heading) => {
                        const url = URLHandler.company.ssqHeading(company.id, heading.id);
                        return url;
                    }}
                    scoresSection={
                        <ScoreProvider type="company" formattedScores={post?.formattedScores}>
                            <ScoresSection post={post} headings={visibleHeadings} scoresIds={companyScoresIds} />
                        </ScoreProvider>
                    }
                />
            </Stack>
        </Stack>
    );
};
