import { Box, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { GetValueType, Answer } from "src/components/ScoreDetail/ScoreDetailResponsesModal.tsx";

const BoxStyled = styled(Box)({
    paddingTop: "28px",
    paddingBottom: "28px",
    width: "50%",
});

type GroupProps = {
    list: Answer[];
} & GetValueType;
export const Group = (props: GroupProps) => {
    const { list, getValue } = props;

    const pairs = list.reduce<Answer[][]>((result, _, index) => {
        if (index % 2 === 0) {
            result.push(list.slice(index, index + 2));
        }
        return result;
    }, []);

    return pairs.map((pair, index) => {
        return (
            <Stack
                key={index}
                direction="row"
                sx={{ width: "100%" }}
                divider={
                    <Box
                        sx={{
                            mx: "28px",
                            display: "flex",
                            paddingTop: "28px",
                            paddingBottom: "28px",
                        }}
                    >
                        <Box
                            sx={{
                                borderRight: "1px dashed #e3e2e0",
                                marginLeft: "10px",
                            }}
                        />
                    </Box>
                }
            >
                {pair.map((postElement, i) => {
                    return (
                        <BoxStyled key={i}>
                            <Typography
                                variant="body"
                                component="span"
                                fontWeight={600}
                                fontSize="20px"
                                mr="8px"
                                sx={{
                                    wordBreak: "break-word",
                                }}
                            >
                                {getValue(postElement)}
                            </Typography>
                            <Typography
                                variant="body"
                                component="span"
                                sx={(theme) => ({
                                    color: theme.custom.grey[1400],
                                    fontSize: "12px",
                                    fontWeight: 500,
                                })}
                            >
                                {postElement.unitAbbreviation}
                            </Typography>
                        </BoxStyled>
                    );
                })}
            </Stack>
        );
    });
};
