import { ForgotPasswordInput } from "../pages/Authentication/ForgotPassword";
import { ResetPasswordInput } from "../pages/Authentication/ResetPassword";
import { ILoginResponse, IUserResponse } from "./types";
import { loggedInApiCall, publicApiCall } from "./axiosClient/userApi";
import { LoginInput } from "src/pages/Authentication/Login";

export const loginUserFn = async (user: LoginInput) => {
    await publicApiCall.post<ILoginResponse>("/auth", user);
};

export const loginUserUsingSingleTimeUseJWTFn = async (token: string) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };

    await publicApiCall.post<ILoginResponse>("/auth/jwt_single_login", null, config);
};

export const getMeFn = async () => {
    const response = await loggedInApiCall.get("/api/users/me", {
        headers: {
            Accept: "application/json",
        },
    });

    return response.data;
};

export const resetPasswordFn = async (data: ResetPasswordInput) => {
    const response = await publicApiCall.get<IUserResponse>(`/api/users/reset-password/${data.password}/${data.token}`);

    return response;
};

export const getForgotPasswordFn = async (user: ForgotPasswordInput) => {
    const response = await publicApiCall.get(`/api/users/send-reset-password/${user.email}`);

    return response.data;
};
