import { Post, PostElement } from "src/api/client-api/post/postSchema";
import { ActionsType, DataListOption, SsqPropertyAction, SureCsrTheme } from "src/api/sure-api/actions/useGetOneAction";
import { PropertySwitchProps } from "../../pages/Actions/Planning/EditProperties/PropertySwitch";
import { ScoreByProperties, ScoreService, ScoreVariation } from "../EntityBasedScore/ScoreService";
import { ActionData, ActionsCsrMapping } from "./context";

type FlattenedActionsCsrThemes = Omit<ActionsType, "csrThemes"> & {
    csrTheme: SureCsrTheme;
};
export type ActionsByCsrThemes = Partial<Record<string, FlattenedActionsCsrThemes[]>>;

export type ActionPropertiesCoreAnswers = {
    property: SsqPropertyAction;
    score: number | null;
    previousElements: PostElement[] | null;
    currentElements: PostElement[] | null;
    targetElements: PostElement[] | null;
};

export type ActionSubpropertyMapping = Partial<Record<SsqPropertyId, ActionPropertiesCoreAnswers>>;

export type ActionPropertiesWithAnswers = ActionPropertiesCoreAnswers & {
    groupScoreDetails: ActionSubpropertyMapping | null;
};
type SsqPropertyId = number;

export type ActionPropertiesWithAnswersMapping = Partial<Record<SsqPropertyId, ActionPropertiesWithAnswers>>;

type WaterfallData = {
    label: string;
    value: number | null;
};

type WaterfallDataset = {
    start: WaterfallData;
    differences: WaterfallData[];
    target: {
        label: string;
        overrideValue?: number;
    };
};

export class ActionService {
    static getActionById(actionsByCsrTheme: ActionsCsrMapping, actionIdToFind: number): ActionData | null {
        // Since data is grouped by Csr in Context.
        // All those loops are used to go to the tree, and find it by action Id;
        const actionData = Object.values(actionsByCsrTheme).flatMap((actions) => {
            if (actions === undefined) return [];
            const actionsList = Object.entries(actions.data);
            const foundAction = actionsList.flatMap(([actionId, action]) => {
                if (action === undefined) return [];
                if (Number(actionId) === actionIdToFind) return [action];

                return [];
            });
            if (foundAction.length === 0) return [];

            return [foundAction[0]];
        });
        if (actionData.length === 0) return null;

        return actionData[0];
    }

    static matchByPropertyId(
        scoresByProperties: {
            start: ScoreByProperties;
            target: ScoreByProperties;
        },
        ssqProperties: SsqPropertyAction[],
        previousPost: Post | null,
        targetPost: Post | null,
        currentPost?: Post | null,
        scorePrecision?: number,
    ): ActionPropertiesWithAnswersMapping {
        const acc: ActionPropertiesWithAnswersMapping = {};
        const propertiesWithElements = ssqProperties.reduce((acc, ssqProperty) => {
            const elementsForProperty = this.fetchElementsForProperty(
                scoresByProperties,
                ssqProperty,
                previousPost,
                targetPost,
                currentPost,
                scorePrecision,
            );

            const subPropertyAcc: ActionSubpropertyMapping = {};
            const isMulti = ssqProperty.multivalue;
            const subPropertiesMapping = ssqProperty.children?.reduce((subPropertyAcc, subProperty) => {
                const elementsForSubproperty = this.fetchElementsForProperty(
                    scoresByProperties,
                    subProperty,
                    previousPost,
                    targetPost,
                    currentPost,
                    scorePrecision,
                    isMulti,
                );
                subPropertyAcc[subProperty.id] = elementsForSubproperty;

                return subPropertyAcc;
            }, subPropertyAcc);

            const ensureMappingNeverEmpty =
                subPropertiesMapping === undefined ? null
                : Object.keys(subPropertiesMapping).length === 0 ? null
                : subPropertiesMapping;

            acc[ssqProperty.id] = {
                ...elementsForProperty,
                groupScoreDetails: ensureMappingNeverEmpty,
            };
            return acc;
        }, acc);

        return propertiesWithElements;
    }

    private static fetchElementsForProperty(
        scoreByProperties: {
            start: ScoreByProperties;
            target: ScoreByProperties;
        },
        ssqProperty: SsqPropertyAction,
        previousPost: Post | null,
        targetPost: Post | null,
        currentPost?: Post | null,
        scorePrecision?: number,
        isMultivalue?: boolean,
    ): ActionPropertiesWithAnswers {
        const previousElementsFound = (previousPost?.elements ?? []).filter((e) => e.ssqPropertyId === ssqProperty.id);
        const currentElementsFound = (currentPost?.elements ?? []).filter((e) => e.ssqPropertyId === ssqProperty.id);
        const targetElementsFound = (targetPost?.elements ?? []).filter((e) => e.ssqPropertyId === ssqProperty.id);

        const startScore = scoreByProperties.start[ssqProperty.id]?.score ?? null;
        const endScore = scoreByProperties.target[ssqProperty.id]?.score ?? null;

        const scoreDifference = ScoreService.substractScore(startScore, endScore);

        const defaultPrecision = 3;
        const precision = scorePrecision ?? defaultPrecision;
        const roundedScore = Number(scoreDifference?.toFixed(precision)) ?? null;
        return {
            property: ssqProperty,
            score: roundedScore,
            groupScoreDetails: null,
            previousElements: previousElementsFound ?? null,
            currentElements: currentElementsFound ?? null,
            targetElements: targetElementsFound ?? null,
        };
    }

    static groupByCsrThemes = (actions: ActionsType[]): ActionsByCsrThemes => {
        const flattenActionsByCsrThemes = actions.flatMap((action) => {
            const newActionList = action.csrThemes.reduce<FlattenedActionsCsrThemes[]>((acc, csrTheme) => {
                acc.push({
                    ...action,
                    csrTheme,
                });
                return acc;
            }, []);
            return newActionList;
        });

        const groupedActions = Object.groupBy(flattenActionsByCsrThemes, (action) => action.csrTheme.title);

        return groupedActions;
    };

    static resolveActionFields = (
        ssqProperty: SsqPropertyAction,
        propertiesWithElements: ActionPropertiesWithAnswersMapping,
    ): PropertySwitchProps[] => {
        const type = ssqProperty.field?.type;
        const multivalue = ssqProperty.multivalue;

        const ssqPropertiesElements = propertiesWithElements[ssqProperty.id];

        if (type !== "group") {
            return [
                {
                    disableSubtitle: true,
                    // propertyWithElements: propertiesWithElements[ssqProperty.id],
                    propertyWithElements: ssqPropertiesElements,
                },
            ];
        }

        const groupedSubproperties = ssqPropertiesElements?.groupScoreDetails ?? {};
        if (multivalue === false) {
            const asList = Object.values(groupedSubproperties).map((v) => {
                return {
                    propertyWithElements: v,
                };
            });
            return asList;
        }

        const multivalueWithAllElements = Object.values(groupedSubproperties).reduce<ActionPropertiesWithAnswers[]>(
            (acc, subProperty): ActionPropertiesWithAnswers[] => {
                const previousElementsByPosition = Object.groupBy(
                    subProperty?.previousElements ?? [],
                    (element) => element.position,
                );
                const currentElementsByPosition = Object.groupBy(
                    subProperty?.currentElements ?? [],
                    (element) => element.position,
                );
                const targetElementsByPosition = Object.groupBy(
                    subProperty?.targetElements ?? [],
                    (element) => element.position,
                );

                const previousMultivaluePositions = Object.keys(previousElementsByPosition).map((p) => parseInt(p));
                const currentMultivaluePositions = Object.keys(currentElementsByPosition).map((p) => parseInt(p));
                const targetMultivaluePositions = Object.keys(targetElementsByPosition).map((p) => parseInt(p));

                const allMultivaluePositions = [
                    ...new Set([
                        ...previousMultivaluePositions,
                        ...currentMultivaluePositions,
                        ...targetMultivaluePositions,
                    ]),
                ];
                for (const position of allMultivaluePositions) {
                    acc[position] = {
                        // Unsure about position of those three in Multi-value
                        score: null,
                        groupScoreDetails: null,

                        property: ssqProperty,
                        previousElements: [
                            ...(acc[position]?.previousElements ?? []),
                            ...(previousElementsByPosition[position] ?? []),
                        ],
                        currentElements: [
                            ...(acc[position]?.currentElements ?? []),
                            ...(currentElementsByPosition[position] ?? []),
                        ],
                        targetElements: [
                            ...(acc[position]?.targetElements ?? []),
                            ...(targetElementsByPosition[position] ?? []),
                        ],
                    };
                }

                return acc;
            },
            [],
        );

        const multivalueFields = multivalueWithAllElements.map(
            (data: ActionPropertiesWithAnswers, position: number) => {
                return {
                    propertyWithElements: data,
                    position: position,
                };
            },
        );

        return multivalueFields;
    };

    static parseMultivalueElementsForDisplay(elements: PostElement[], property: SsqPropertyAction): string | string[] {
        const multivalueProperties = property.children ?? [];

        const values = elements.map((element: PostElement) => {
            const property = multivalueProperties.find(
                (multivalueProperty: SsqPropertyAction) => multivalueProperty.id === element.ssqPropertyId,
            );
            const title = property?.title;

            const dataListOption = property?.dataList?.options.find(
                (option: DataListOption) => option.value === element.value,
            );
            const value = property?.field?.type !== "text" ? dataListOption?.label : element.value;

            return `${title}: ${value}`;
        });

        return values.length > 0 ? values : "-";
    }

    static computeWaterfallData(options: {
        startingPost: Post | null;
        targetPost: Post | null;
        actionsDataByCsr: ActionsCsrMapping;
        selectedOption: ScoreVariation;
        scorePrecision?: number;
    }): WaterfallDataset {
        const { actionsDataByCsr, scorePrecision = 3, selectedOption, startingPost, targetPost } = options;
        const _startScore = startingPost?.scores?.[selectedOption.scoreId];
        const _startScoreCampaignLabel = startingPost?.campaign?.label ?? "";
        const c1Label = `${_startScoreCampaignLabel} - BAU`;
        const startScore = ScoreService.getScore(_startScore, c1Label, scorePrecision, selectedOption.unit);

        const targetScoreCampaignLabel = targetPost?.campaign?.label ?? "";

        // Possibly for override value
        // const _targetScore = targetPost?.scores?.[selectedOption.scoreId];
        // const targetScore = ScoreService.getScore(_targetScore, targetScoreCampaignLabel, scorePrecision, selectedOption.unit);

        const allDifferences: WaterfallData[] = Object.entries(actionsDataByCsr).map(([csrKey, actions]) => {
            const v = actions?.totalScore ? Number(actions.totalScore.toFixed()) : null;
            return {
                label: csrKey,
                value: v,
            };
        });

        const startV = startScore.value ? Number(startScore.value.toFixed()) : null;

        return {
            differences: allDifferences,
            start: {
                label: c1Label,
                value: startV,
            },
            target: {
                label: targetScoreCampaignLabel,
                // overrideValue: 0,
            },
        };
    }
}
