import { UserWrite } from "src/api/client-api/user/useCreateUser";
import { User } from "src/api/client-api/user/useGetOneUser";

import { z } from "zod";

export class UserService {
    static getUserToUserForm = (existingUser?: User): UserForm | undefined => {
        if (existingUser === undefined) {
            return undefined;
        }

        return {
            id: existingUser.id,
            lastname: existingUser.lastname,
            firstname: existingUser.firstname,
            email: existingUser.email,
            companies: existingUser.companies.map((company) => {
                return {
                    "@id": company["@id"],
                    name: company.name,
                };
            }),
            phone: existingUser?.phone,
            roles: existingUser?.roles ?? [],
        };
    };

    static getUserFormToUserWrite = (user: UserForm, generatePassword: boolean = false): UserWrite => {
        const pwd = (Math.random() + 1).toString(36);

        const userWrite: UserWrite = {
            ...user,
            companies: user.companies.map((company) => company["@id"]),
            password: generatePassword ? pwd : undefined,
            plainPassword: generatePassword ? pwd : undefined,
        };

        return userWrite;
    };
}

export type UserForm = {
    id?: number;
    firstname: string;
    lastname: string;
    email: string;
    phone?: string;
    companies: {
        "@id": IRI;
        name: string;
    }[];
    roles: Array<string>;
};

export const UserFormSchemaZod = z.object({
    id: z.number().optional(),
    firstname: z.string(),
    lastname: z.string(),
    email: z.string().email(),
    phone: z.string().optional(),
    companies: z
        .array(
            z.object({
                "@id": z.string(),
                name: z.string(),
            }),
        )
        .min(1),
    roles: z.array(z.string()).min(1),
});
