import { FoodsFormBase } from "@foodpilot/foods";
import { Stack, useTheme } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { CsrPillar } from "src/api/client-api/csrStrategy/Pillars/useGetOneCsrPillar";
import { PayloadWrite, useUpsertCsrPillar } from "src/api/client-api/csrStrategy/Pillars/useUpsertCsrPillar";
import { PillarInfo } from "./Pages/PillarInfo";
import { Indicators } from "./Pages/Indicators";
import { useNavigate } from "react-router-dom";
import { URLHandler } from "src/services/URLHandler";
import { useSettingsStrategy } from "src/context/Settings/CsrStrategy/useSettingsStrategy";
import { useSnackbar } from "src/utils/useSnackbar";

const formToCsrPillarWrite = (form: CsrPillarForm, stategyIRI: IRI): PayloadWrite => {
    const payload: PayloadWrite = {
        id: form.id,
        name: form.name,
        icon: form.icon || "wdsq",
        strategy: stategyIRI,
        indicators: form.indicators,
    };
    return payload;
};

const csrPillarToForm = (item: CsrPillar | undefined): CsrPillarForm => {
    if (item === undefined) {
        const defaultForm: CsrPillarForm = {
            id: undefined,
            name: "",
            themes: [],
            icon: "",
            indicators: [],
        };
        return defaultForm;
    }

    const indicatorRows = item.indicators.map((indicator, index) => {
        const row: CsrIndicatorForm = {
            rowIndex: index,
            ...indicator,
        };
        return row;
    });

    const formItem: CsrPillarForm = {
        id: item.id,
        name: item.name,
        themes: item.themes ?? [],
        icon: item.icon,
        indicators: indicatorRows,
    };
    return formItem;
};

export type CsrIndicatorForm = {
    rowIndex: number;

    "@id": string | undefined;
    name: string;
    abbreviationUnit: string;
    currentValue: string;
    shortTermTarget: string;
    longTermTarget: string;
};

export type CsrPillarForm = {
    id: string | undefined;
    name: string;
    themes: number[];
    icon: string;
    indicators: CsrIndicatorForm[];
};

type PillarFormProps = {
    pillar: CsrPillar | undefined;
};
export const PillarForm = (props: PillarFormProps) => {
    const { strategy } = useSettingsStrategy();
    const snackbar = useSnackbar();
    const { t } = useTranslation();
    const theme = useTheme();
    const upsertPillar = useUpsertCsrPillar();
    const navigate = useNavigate();
    const { pillar } = props;

    const initForm = csrPillarToForm(pillar);
    const [form, setForm] = useState(initForm);

    const formTitleAction = pillar === null ? t("glossary.create") : t("glossary.modify");
    return (
        <Stack paddingInline={theme.spacing(3)}>
            <FoodsFormBase
                textOptions={{
                    title: t("settings.csr.form.title ", { verb: formTitleAction }),
                    nextPage: t("next"),
                    previousPage: t("previous"),
                    finalPage: t("Sauvegarder"),
                }}
                confirmExit={false}
                onClose={() => {
                    const url = URLHandler.settings.csrStrategy.pillars();
                    navigate(url);
                }}
                pages={[
                    {
                        title: t("settings.csr.form.info.title"),
                        content: <PillarInfo form={form} setForm={setForm} />,
                    },
                    {
                        title: t("settings.csr.form.indicator.title"),
                        content: <Indicators form={form} setForm={setForm} />,
                    },
                ]}
                onValidate={() => {
                    if (strategy === undefined) return;
                    const pillarPayload = formToCsrPillarWrite(form, strategy["@id"]);
                    upsertPillar.mutate(
                        { payload: pillarPayload },
                        {
                            onSuccess: () => {
                                const url = URLHandler.settings.csrStrategy.pillars();
                                navigate(url);

                                snackbar.forceClose();
                                snackbar.setSnackbarProps({
                                    type: "info",
                                    message: t("settings.success"),
                                });
                            },
                        },
                    );
                }}
            />
        </Stack>
    );
};
