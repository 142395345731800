import { useEffect, useRef, useState } from "react";

export const useIsVariationRefetching = (isRefetching: boolean, variations: Record<number, number>) => {
    const [isVariationRefetching, setIsVariationRefetching] = useState<boolean>(false);
    const previousVariationsRef = useRef<Record<number, number>>(variations);

    useEffect(() => {
        if (!isRefetching) {
            setIsVariationRefetching(false);
            return;
        }

        // Compare previous vs. current variations by value
        const hasVariationsChanged = JSON.stringify(previousVariationsRef.current) !== JSON.stringify(variations);

        if (!hasVariationsChanged) {
            setIsVariationRefetching(true);
        } else {
            setIsVariationRefetching(true);
        }

        previousVariationsRef.current = variations;
    }, [isRefetching, variations]);

    return isVariationRefetching;
};
