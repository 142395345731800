import { Box, Stack } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { ScoreBox } from "src/components/Score/ScoreBox";
import { Score } from "src/types";
import { useTranslation } from "react-i18next";
import { Loading } from "src/components/utils/Loading";
import { useFoodpilotContext } from "src/context/FoodpilotContext";
import { useProductContext } from "./ProductContext";
import { URLHandler } from "src/services/URLHandler.ts";

export const ProductScores = () => {
    const { t } = useTranslation();
    const {
        product,
        variations,
        setVariations,
        productReferenceScores = [],
        isRefetching = false,
    } = useProductContext();
    const { scoreTypes, scores, getScoresByType } = useFoodpilotContext();
    const navigate = useNavigate();
    const { companyId, productId } = useParams();

    if (!scoreTypes || !scores || !product) {
        return <Loading />;
    }

    const productsScores = getScoresByType("product");

    if (productsScores.length === 0) {
        return (
            <Stack gap="28px" sx={{ padding: "44px 52px" }}>
                <Box sx={{ textAlign: "center", padding: "28px" }}>{t("product.no-score")}</Box>
            </Stack>
        );
    }

    const mappedReferenceScores = Object.fromEntries(productReferenceScores.map((score) => [score?.score?.id, score]));

    return (
        <Stack gap="60px">
            <Stack gap="28px">
                <Box
                    sx={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr 1fr",
                        gridAutoRows: "1fr",
                        gap: "28px",
                    }}
                >
                    {productsScores.map((score: Score, index: number) => (
                        <ScoreBox
                            key={index}
                            score={score}
                            onClick={() => {
                                navigate(`${URLHandler.product.score(Number(companyId), Number(productId), score.id)}`);
                            }}
                            value={product.scores[score.id]}
                            variations={variations}
                            setVariations={setVariations}
                            isRefetching={isRefetching}
                            referenceScore={mappedReferenceScores[score.id] ?? null}
                            prevScore={product.prevScores?.[score.id]}
                            prevCampaignName={product?.prevCampaignName}
                            entity="product"
                        />
                    ))}
                </Box>
            </Stack>
        </Stack>
    );
};
