import { PostElement } from "src/api/client-api/post/postSchema";
import { SsqPropertyAction } from "src/api/sure-api/actions/useGetOneAction";

const EMPTY_VALUE = "_empty_" as const;

export class PropertyFields {
    static serialize() {}

    static sendToCalculate(property: SsqPropertyAction, elements: PostElement[] | null): string {
        const type = property.field?.type;
        const isMultivalue = property.multivalue;
        const propertyUnit = property.unit;

        const _singleValue = elements?.find((e) => e.ssqPropertyId === property.id);
        const singleValue = _singleValue?.value ?? EMPTY_VALUE;

        const options = property.dataList?.options ?? [];
        const _valueFromOptions = options.find((option) => {
            // Might want to add the position;
            return option.value === singleValue;
        });
        const valueFromOptions = _valueFromOptions?.label ?? EMPTY_VALUE;

        const MANY_VALUES_SEPARATOR = "|";
        const _manyValues = singleValue.split(MANY_VALUES_SEPARATOR);
        const _valuesFromOptions = options.filter((option) => {
            return (_manyValues ?? []).indexOf(option.value) > -1;
        });
        const manyValuesDeserialized = _valuesFromOptions.join(MANY_VALUES_SEPARATOR);

        if (type === "text") {
            const isPropertyString = propertyUnit?.title.toLowerCase() === "string";
            if (isPropertyString) {
                // Actual string ! Surprise !
                return singleValue;
            }

            return singleValue;
        }

        if (type === "checkbox") {
            return manyValuesDeserialized;
        }

        if (type === "radio") {
            return valueFromOptions;
        }

        if (type === "select") {
            return valueFromOptions;
        }

        if (isMultivalue === true) {
            console.log("PLEASE ", manyValuesDeserialized);
            return EMPTY_VALUE;
            // Don't know what to do in this case;
        }

        return EMPTY_VALUE;
    }
}
