import { HeroBlock, FoodsIcon, RoundInput, RichTextEditor } from "@foodpilot/foods";
import { Stack, Typography, Box, Button, useTheme } from "@mui/material";
import { useState } from "react";
import { useCreateOneBlock } from "src/api/client-api/cms/blocks/useCreateOneBlock";
import { MainContentLayout } from "src/components/Layout/MainContentLayout";
import { SelectMedia } from "../../../Common/SelectMedia";
import { EditLayout } from "../_Common/EditLayout";
import { CMSPageSection, CMSSectionBlocksContent } from "src/api/client-api/cms/useGetOneContent";
import { SectionHeader } from "../_Common/SectionForm/SectionHeader";
import { ConfirmationFooter } from "../_Common/ConfirmationFooter";
import { useSnackbar } from "src/utils/useSnackbar";
import { useTranslation } from "react-i18next";
import { SectionForm } from "../_Common/SectionForm/SectionForm";
import {
    blockContentFormToWrite,
    getBlockContentForm,
    getNewBlockContent,
} from "../_Common/BlockForm/ContentBlock/contentBlock";
import { URLHandler } from "src/services/URLHandler";
import { useNavigate } from "react-router-dom";

type EditHeroProps = {
    originalPageId: string;
    section: CMSPageSection<CMSSectionBlocksContent>;
};
export const EditHero = (props: EditHeroProps) => {
    const { originalPageId, section } = props;
    const { t } = useTranslation();
    const theme = useTheme();
    const snackbar = useSnackbar();
    const navigate = useNavigate();

    const createBlock = useCreateOneBlock();

    const _block = getBlockContentForm(section);
    const block = _block.length === 0 ? getNewBlockContent() : _block[0];
    const [blockState, setBlockState] = useState(block);

    const ctaLink = blockState.ctaLink ?? "";
    const outsideLink = ctaLink.startsWith("https://") ? ctaLink : `https://${ctaLink}`;

    const ctaLabel = blockState.ctaLabel;
    return (
        <Stack gap={theme.spacing(3)}>
            <SectionHeader section={section} originalPageId={originalPageId} />
            <SectionForm section={section} />
            <MainContentLayout>
                <HeroBlock
                    title={blockState.title ?? ""}
                    subtitle={blockState.subtitle ?? ""}
                    description={
                        <Typography variant="body">
                            <Box dangerouslySetInnerHTML={{ __html: blockState.description ?? "" }} />
                        </Typography>
                    }
                    callToAction={
                        ctaLabel ?
                            <Button
                                sx={{
                                    display: "inline-flex",
                                    gap: "12px",
                                    padding: "24px",
                                }}
                                variant="primary"
                                href={outsideLink}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <Typography fontWeight={700} fontSize="0.875rem">
                                    {ctaLabel}
                                </Typography>
                                <FoodsIcon size={2} icon={"arrowRight"} />
                            </Button>
                        :   undefined
                    }
                    imageUrl={blockState.media?.url ?? ""}
                />
            </MainContentLayout>
            <EditLayout>
                <Stack gap={theme.spacing(3)} marginBottom={theme.spacing(1)}>
                    <Stack gap={theme.spacing(1)} maxWidth={"75%"}>
                        <Typography variant="body-medium">{t("glossary.title")}</Typography>
                        <RoundInput
                            value={blockState.title}
                            onChange={(event) => {
                                const newValue = event.currentTarget.value;
                                const newBlock = {
                                    ...blockState,
                                    title: newValue,
                                };
                                setBlockState(newBlock);
                            }}
                        />
                    </Stack>
                    <Stack gap={theme.spacing(1)} maxWidth={"75%"}>
                        <Typography variant="body-medium">{t("glossary.subtitle")}</Typography>
                        <RoundInput
                            value={blockState.subtitle}
                            onChange={(event) => {
                                const newValue = event.currentTarget.value;
                                const newBlock = {
                                    ...blockState,
                                    subtitle: newValue,
                                };
                                setBlockState(newBlock);
                            }}
                        />
                    </Stack>
                    <Stack gap={theme.spacing(1)} maxWidth={"75%"}>
                        <Typography variant="body-medium">{t("glossary.description")}</Typography>
                        <RichTextEditor
                            key={`rich-text-editor-hero-form-${blockState.id}`}
                            initialContent={blockState.description ?? ""}
                            onChange={(newValue) => {
                                const newBlock = {
                                    ...blockState,
                                    description: newValue,
                                };
                                setBlockState(newBlock);
                            }}
                        />
                    </Stack>
                    <Stack gap={theme.spacing(1)} maxWidth={"75%"}>
                        <Typography variant="body-medium">{t("cms.common.ctaLabel")}</Typography>
                        <RoundInput
                            value={blockState.ctaLabel}
                            onChange={(event) => {
                                const newValue = event.currentTarget.value;
                                const newBlock = {
                                    ...blockState,
                                    ctaLabel: newValue,
                                };
                                setBlockState(newBlock);
                            }}
                        />
                    </Stack>
                    <Stack gap={theme.spacing(1)} maxWidth={"50%"}>
                        <Typography variant="body-medium">{t("glossary.externalLink")}</Typography>
                        <RoundInput
                            placeholder="https://"
                            value={blockState.ctaLink}
                            onChange={(event) => {
                                const newValue = event.currentTarget.value;
                                const newBlock = {
                                    ...blockState,
                                    ctaLink: newValue,
                                };
                                setBlockState(newBlock);
                            }}
                        />
                    </Stack>
                    <SelectMedia
                        selectedMediaIRI={blockState.media?.["@id"] ?? ""}
                        selectNewMedia={(newMedia) => {
                            const newBlock = {
                                ...blockState,
                                media: newMedia,
                            };
                            setBlockState(newBlock);
                        }}
                    />
                </Stack>
            </EditLayout>
            <ConfirmationFooter
                saveAction={() => {
                    const blockToWrite = blockContentFormToWrite(section["@id"], blockState);
                    const payload = { newBlock: blockToWrite };
                    createBlock.mutate(payload, {
                        onSuccess: () => {
                            const url = URLHandler.settings.cms.page(originalPageId);
                            navigate(url);

                            snackbar.forceClose();
                            snackbar.setSnackbarProps({
                                type: "info",
                                message: t("snackbar.cms.addHero.success"),
                            });
                        },
                    });
                }}
                resetContent={() => {}}
            />
        </Stack>
    );
};
