import { IndicatorBlock, NumberWithUnit } from "@foodpilot/foods";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { CsrIndicator } from "src/api/client-api/csrStrategy/useGetAllStrategy";

type IndicatorListProps = {
    indicators: CsrIndicator[];
};
export const IndicatorList = (props: IndicatorListProps) => {
    const { indicators } = props;
    const { t } = useTranslation();

    if (indicators.length === 0) {
        return null;
    }

    return (
        <Grid container spacing={1} direction={"row"} sx={{ alignItems: "stretch" }}>
            {indicators.map((ind, index) => {
                return (
                    <Grid item xs={4} key={`indicator-item-${index}`}>
                        <IndicatorBlock
                            title={ind.name}
                            objectives={[
                                {
                                    objective: t("settings.csr.form.indicator.fields.shortTermGoal"),
                                    component: (
                                        <NumberWithUnit value={ind.shortTermTarget} unit={ind.abbreviationUnit} />
                                    ),
                                },
                                {
                                    objective: t("settings.csr.form.indicator.fields.longTermGoal"),
                                    component: (
                                        <NumberWithUnit value={ind.longTermTarget} unit={ind.abbreviationUnit} />
                                    ),
                                },
                            ]}
                        >
                            <NumberWithUnit value={ind.currentValue} unit={ind.abbreviationUnit} />
                        </IndicatorBlock>
                    </Grid>
                );
            })}
        </Grid>
    );
};
