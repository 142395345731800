import { BlockWrite } from "src/api/client-api/cms/blocks/useCreateOneBlock";
import { CMSPageSection, CMSSectionBlocksContent } from "src/api/client-api/cms/useGetOneContent";

type ContentBlockMediaForm = {
    "@id": IRI;
    url: string;
    alt: string;
    dimensions: [number, number];
};

export type ContentBlockForm = {
    "@id": IRI | undefined;
    id: UUID | undefined;
    position: number;

    title: string;
    subtitle?: string | null;
    description?: string | null;
    ctaLink?: string | null;
    ctaLabel?: string | null;
    media?: ContentBlockMediaForm | null;
};

export const getNewBlockContent = (positionBlock?: number): ContentBlockForm => {
    const defaultBlock: ContentBlockForm = {
        "@id": undefined,
        id: undefined,
        position: positionBlock ?? 0,
        title: "",
        subtitle: "",
        description: "",
        ctaLink: "",
        ctaLabel: "",
        media: undefined,
    };
    return defaultBlock;
};

export const getBlockContentForm = (section: CMSPageSection<CMSSectionBlocksContent>): ContentBlockForm[] => {
    const blocks: ContentBlockForm[] = section.blocks.map((block) => {
        const blockForm: ContentBlockForm = {
            "@id": block["@id"],
            id: block.id,
            position: block.position,

            title: block.title,
            subtitle: block.subtitle,
            description: block.description,
            ctaLink: block.ctaLink,
            ctaLabel: block.ctaLabel,
            media: block.media,
        };
        return blockForm;
    });

    return blocks;
};

export const blockContentFormToWrite = (sectionIRI: IRI, block: ContentBlockForm): BlockWrite => {
    const blockWrite: BlockWrite = {
        "@id": block["@id"],
        id: block.id,
        type: "content",
        position: block.position,
        section: sectionIRI,

        title: block.title,
        subtitle: block.subtitle,
        description: block.description,
        ctaLink: block.ctaLink,
        ctaLabel: block.ctaLabel,
        media: block.media?.["@id"] ?? null,
    };
    return blockWrite;
};
