import { useRef, useState } from "react";
import { FoodsSelect } from "@foodpilot/foods";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { DataListOption } from "src/api/sure-api/ssq/useGetOneSsq";
import { formatUnitAbbreviation } from "src/utils/formatting";
import {
    getPropertyDefaultOption,
    getPropertyGenericOption,
    getOptionValue,
    getOptionIds,
    findOptionByValue,
    findOptionById,
} from "src/utils/dataListOptions";
import { FormPropertyProps } from "../../FormProperty";
import { GenericValueCheckbox } from "src/components/FormProperty/GenericValueCheckbox.tsx";
import { DefaultValueButton } from "src/components/FormProperty/DefaultValueButton.tsx";
import { FormTooltip } from "../../FormTooltip";

type SsqSelectProps = FormPropertyProps & {
    options: DataListOption[];
};

export const SsqSelect = (props: SsqSelectProps) => {
    const { t } = useTranslation();
    const { property, post, isSubproperty } = props;

    if (post === undefined) return;

    return (
        <Stack direction="column" gap="8px">
            <_SsqSelect {...props} />
            {property.readonly && (
                <Typography variant="small-medium">{t("Cette valeur est en lecture seule")}</Typography>
            )}
        </Stack>
    );
};

const _SsqSelect = (props: SsqSelectProps) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { property, element, post, canEdit, options } = props;

    const propertyDefaultOption = getPropertyDefaultOption(property, options);
    const propertyGenericOption = getPropertyGenericOption(property, options);

    // Initialize with either the element value or default value
    const initialValue =
        element && !property.readonly ?
            element.dataListOptionIds?.length ?
                findOptionById(options, element.dataListOptionIds[0])
            :   findOptionByValue(options, element.value)
        :   propertyDefaultOption;

    const selectedOption = useRef(initialValue ?? null);

    const [genericValueSelected, setGenericValueSelected] = useState<boolean>(element?.isGenericValue ?? false);
    const [value, setValue] = useState<DataListOption | null>(selectedOption.current);

    const unit = formatUnitAbbreviation(property.unit);

    if (post === undefined) return;

    const updatePropertyValue = (value: DataListOption | null, isGenericValue?: boolean) => {
        props.onUpdate({
            postId: post.id,
            propertyId: Number(property.id),
            value: getOptionValue(value),
            position: Number(props.position ?? 0),
            isGenericValue: isGenericValue,
            dataListOptionIds: value ? getOptionIds([value]) : [],
        });
    };

    if (post === undefined) return;

    const tooltipText = property.hint ? <div dangerouslySetInnerHTML={{ __html: property.hint }} /> : undefined;
    return (
        <FormTooltip title={tooltipText} arrow placement="right">
            <Box>
                <Stack direction="column" gap="1rem">
                    <Stack direction="row" gap="1.5rem" alignItems="center">
                        <FoodsSelect
                            selectedOption={value}
                            options={options}
                            onChange={(selectedValue: DataListOption | null) => {
                                setValue(selectedValue);
                                updatePropertyValue(selectedValue);
                            }}
                            getId={(item) => item.id}
                            getName={(item) => item.label}
                            disabled={canEdit === false || property.readonly || genericValueSelected}
                            textOptions={{
                                placeholder: t("component.select.placeholder"),
                                searchPlaceholder: t("component.select.searchPlaceholder"),
                                emptyOptionsText: t("component.select.noOptions"),
                                defaultValueText: t("component.select.defaultValue"),
                                noResult: t("component.select.noResultFound"),
                            }}
                            noValue={t("component.select.unselectValue")}
                            unit={unit}
                        />
                        {!property.readonly &&
                            propertyDefaultOption !== null &&
                            propertyDefaultOption !== undefined &&
                            !genericValueSelected && (
                                <DefaultValueButton
                                    onClick={() => {
                                        if (value?.id === propertyDefaultOption.id) {
                                            return;
                                        }

                                        setValue(propertyDefaultOption);
                                        updatePropertyValue(propertyDefaultOption);
                                    }}
                                />
                            )}
                    </Stack>
                    {propertyDefaultOption !== null && !genericValueSelected && (
                        <Typography variant="caption" color={theme.custom.grey[1500]}>
                            {!property.readonly ?
                                t("Valeur par défaut :") + " " + (propertyDefaultOption?.label ?? "")
                            :   t("Cette valeur est en lecture seule")}
                        </Typography>
                    )}
                    {!property.readonly && propertyGenericOption && (
                        <Box>
                            <GenericValueCheckbox
                                isSelected={genericValueSelected}
                                onChange={() => {
                                    setGenericValueSelected((prevState) => !prevState);
                                    setValue(!genericValueSelected ? propertyGenericOption : null);
                                    updatePropertyValue(
                                        !genericValueSelected ? propertyGenericOption : null,
                                        !genericValueSelected,
                                    );
                                }}
                            />
                        </Box>
                    )}
                </Stack>
            </Box>
        </FormTooltip>
    );
};
