import { Hero } from "./WidgetBlocks/Hero";
import { CMSPageSection } from "src/api/client-api/cms/useGetOneContent";
import { IndicatorBlock } from "./WidgetBlocks/Indicators";
import { CMSArticles } from "./WidgetBlocks/Articles";
import { StrategyIndicators } from "./WidgetBlocks/StrategyIndicators";
import { Fragment } from "react/jsx-runtime";
import { Typography } from "@foodpilot/foods";
import { useTheme } from "@mui/material";

type SectionSelectorProps = {
    section: CMSPageSection;
};

export const SectionSelector = (props: SectionSelectorProps) => {
    const { section } = props;
    const theme = useTheme();

    const title = section.title.trim() || null;
    return (
        <Fragment>
            {title && (
                <Typography variant={"h2"} marginY={theme.spacing(3.5)}>
                    {title}
                </Typography>
            )}
            <_SectionSelector section={section} />
        </Fragment>
    );
};

const _SectionSelector = (props: SectionSelectorProps) => {
    const { section } = props;

    const sectionType = section.type;
    switch (sectionType) {
        case "hero":
            return <Hero section={section} />;
        case "articles":
            return <CMSArticles section={section} />;
        case "indicator":
            return <IndicatorBlock section={section} />;
        case "strategyIndicators":
            return <StrategyIndicators section={section} />;
        default:
            /* eslint-disable-next-line no-case-declarations */
            const _e: never = sectionType;
            return _e;
    }
};
