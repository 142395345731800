import { FoodsBadge, FoodsIcon } from "@foodpilot/foods";
import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import { VerticalDivider } from "../../../../Common/VerticalDivider";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { useToggleSectionVisibility } from "src/api/client-api/cms/sections/useToggleSection";
import { useSectionsConfig } from "src/api/client-api/cms/sections/sectionsConfig";
import { CMSPageSection } from "src/api/client-api/cms/useGetOneContent";
import { URLHandler } from "src/services/URLHandler";

type SectionHeaderProps = {
    originalPageId: string;
    section: CMSPageSection;
};
export const SectionHeader = (props: SectionHeaderProps) => {
    const { originalPageId, section } = props;

    const theme = useTheme();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const toggleVisibility = useToggleSectionVisibility();

    const blockConfig = useSectionsConfig();
    const config = blockConfig[section.type];
    return (
        <Stack
            flexDirection={"row"}
            padding={theme.spacing(2, 4)}
            justifyContent={"space-between"}
            borderBottom={`1px solid black`}
        >
            <Stack flexDirection={"row"} gap={theme.spacing(2)} alignItems={"center"}>
                <Box
                    onClick={() => {
                        const url = URLHandler.settings.cms.page(originalPageId);
                        navigate(url);
                    }}
                >
                    <FoodsIcon size={3} icon="arrowLeft" />
                </Box>
                <Stack gap={theme.spacing(0.5)}>
                    <Typography variant={"big-bold"}>{config.title}</Typography>
                    <Typography variant={"big-medium"}>{config.subtitle}</Typography>
                </Stack>
            </Stack>
            <Stack flexDirection={"row"} gap={theme.spacing(2)} alignItems={"center"}>
                <FoodsBadge
                    size={4}
                    icon="delete"
                    boxProps={{
                        onClick: () => {
                            alert("cc");
                        },
                    }}
                />
                <VerticalDivider />
                <Button
                    variant="primary"
                    onClick={() => {
                        const payload = {
                            sectionId: section.id,
                            currentSectionState: section.visible,
                        };
                        toggleVisibility.mutate(payload, {
                            onSuccess: () => {
                                queryClient.refetchQueries([section.id]);
                            },
                        });
                    }}
                >
                    {section.visible ? t("glossary.deactivate") : t("glossary.activate")}
                </Button>
            </Stack>
        </Stack>
    );
};
