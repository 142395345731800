import { Box, BoxProps, Stack, Typography, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import { UnitSwitcher } from "src/components/Score/UnitSwitcher.tsx";
import { Breadcrumbs, BreadcrumbType } from "src/components/Breadcrumb/Breadcrumbs.tsx";
import { SecondaryTab, SecondaryTabs } from "@foodpilot/foods";
import { useGetOneScore } from "src/api/sure-api/score/useGetOneScore.ts";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { doesScoreExist, ScoreDimension } from "src/api/client-api/post/scoreSchema.ts";
import { useScoreDetailContext } from "src/context/ScoreDetail/useScoreDetailContext.tsx";
import { URLHandler } from "src/services/URLHandler.ts";
import { useTranslation } from "react-i18next";
import { useFoodpilotContext } from "src/context/FoodpilotContext.tsx";
import { EntityID } from "src/api/apiHelpers.ts";

const TypographyStyled = styled(Typography)(() => ({
    "&::after": {
        content: '" "',
        borderRight: "1px solid #E7E6E4",
        height: "1.4rem",
        margin: "0 10px",
        position: "relative",
        top: "0.2rem",
    },
}));

type Entity = {
    id?: EntityID;
    name: "product" | "company" | "ingredient";
};

type ScoreDetailProps = {
    variations: Record<number, number>;
    setVariations: (id: Record<number, number>) => void;
    boxProps?: BoxProps;
    tabs: SecondaryTab[];
    entity: Entity;
};
export const ScoreDetail = (props: ScoreDetailProps) => {
    const { variations, setVariations, tabs, boxProps, entity } = props;
    const theme = useTheme();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [selectedTab, setSelectedTab] = useState<number>(1);

    const { scoreId: scoreIdString } = useParams<{ scoreId: string }>();
    const scoreId = Number(scoreIdString);

    const { score: detailedScore } = useGetOneScore(scoreId);
    const {
        getMainScore,
        setSelectedScore,
        selectedValueIndex,
        selectedDimensionIndex,
        setSelectedDimensionIndex,
        setSelectedValueIndex,
        setSelectedChartItemIndex,
        reset,
    } = useScoreDetailContext();
    const { selectedCompany } = useFoodpilotContext();

    const variationsStr = JSON.stringify(variations);
    useEffect(() => {
        reset();
    }, [reset, variationsStr]);

    const buildBreadcrumbs = (i: number = 0, dimensions: ScoreDimension[] = [], breadcrumbs: BreadcrumbType[] = []) => {
        const items = breadcrumbs.length > 0 ? breadcrumbs : [];
        const dimensionsList = dimensions.length > 0 ? dimensions : getMainScore()?.dimensions;
        const selectedDimension = selectedDimensionIndex[i];
        const selectedValue = selectedValueIndex[i];

        if (selectedValue) {
            const dimension = dimensionsList?.[selectedDimension];

            items.push({
                label: dimension?.values[selectedValue]?.label || `Breadcrumb ${i + 1}`,
                action: () => {
                    const score = dimension?.values[selectedValue];
                    setSelectedValueIndex(selectedValueIndex.slice(0, i + 1));
                    setSelectedScore(score);
                },
            });

            if (
                Array.isArray(dimension?.values[selectedValue]?.dimensions) &&
                dimension.values[selectedValue].dimensions.length > 0
            ) {
                return buildBreadcrumbs(i + 1, dimension.values[selectedValue].dimensions, items);
            }
        }

        return items;
    };

    const breadcrumbs: BreadcrumbType[] = [
        {
            label: t("breadcrumbs.scores_and_indicators"),
            action: () => {
                if (!selectedCompany) {
                    return;
                }

                if (entity.name === "company") {
                    setSelectedChartItemIndex(undefined);
                    const productScoreUrl = URLHandler.company.scores(selectedCompany.id);
                    return navigate(`${productScoreUrl}`);
                }

                if (entity.name === "product") {
                    setSelectedChartItemIndex(undefined);
                    const productScoreUrl = URLHandler.product.scores(selectedCompany.id, Number(entity.id));
                    return navigate(`${productScoreUrl}`);
                }
            },
        },
        {
            label: detailedScore?.title || "",
            action: () => {
                setSelectedValueIndex([]);
                setSelectedDimensionIndex([selectedDimensionIndex[0]]);
                const mainScore = getMainScore();
                setSelectedScore(doesScoreExist(mainScore) ? mainScore : undefined);
                setSelectedChartItemIndex(undefined);
            },
        },
        ...buildBreadcrumbs(),
    ];

    return (
        <Box sx={boxProps}>
            <Stack gap={1} mb={3}>
                <Box display="flex">
                    <TypographyStyled variant="h2">{detailedScore?.title}</TypographyStyled>
                    {detailedScore && (
                        <UnitSwitcher
                            score={detailedScore}
                            value={getMainScore()}
                            displayedVariations={variations}
                            setDisplayedVariations={setVariations}
                            boxProps={{
                                sx: {
                                    display: "flex",
                                    "& .MuiStack-root": {
                                        gap: "22px",
                                    },
                                },
                            }}
                            typographyProps={{
                                variant: "big",
                                color: theme.custom.grey[2500],
                            }}
                        />
                    )}
                </Box>
                <Box display="flex">
                    <Breadcrumbs items={breadcrumbs} />
                </Box>
            </Stack>
            <SecondaryTabs
                tabs={tabs.map((tab) => {
                    return {
                        ...tab,
                        onClick: () => {
                            setSelectedTab(tab.id);
                            tab.onClick();
                        },
                    };
                })}
                selectedTab={(tab) => tab.id === selectedTab}
            />
        </Box>
    );
};
