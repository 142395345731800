import { useState } from "react";
import { Stack, useTheme } from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import { AnchoredPopover, OptionsPopover, OptionsPopoverOption } from "@foodpilot/foods";
import { useTranslation } from "react-i18next";
import { Score } from "src/types";
import { getFormattedScoreByScoreId } from "src/utils/ssq";
import { useScoreContext } from "src/context/ScoreContext";

type ScoresVariationsPopoverProps = {
    scores: Score[];
};
export const ScoresVariationsPopover = (props: ScoresVariationsPopoverProps) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const { scores } = props;
    const {
        displayedScoreId,
        setDisplayedScoreId,
        displayedVariationId = 0,
        setDisplayedVariationId,
        formattedScores,
    } = useScoreContext();

    const [anchor, setAnchor] = useState<null | HTMLElement>(null);

    const isOpen = Boolean(anchor);
    const isActive = Object.keys(formattedScores).length > 0;

    const displayedFormattedScore = getFormattedScoreByScoreId(formattedScores, displayedScoreId);
    const displayedScoreVariations = displayedFormattedScore?.scoreVariations ?? {};
    const displayedVariation = displayedScoreVariations?.[displayedVariationId];

    const defaultIndex = displayedScoreId + "_" + (displayedVariation ? displayedVariationId : 0);

    const scoresWithoutRegulation = scores.filter((score: Score) => score.displayMode !== "regulation");

    if (!scoresWithoutRegulation.length) return;

    return (
        <>
            {isActive && (
                <AnchoredPopover
                    variant="headless"
                    anchor={anchor}
                    open={isOpen}
                    onClose={() => setAnchor(null)}
                    anchorPosition="rightside"
                >
                    <OptionsPopover
                        content={scoresWithoutRegulation.map((score: Score) => {
                            const scoreLabel = score.label ?? score.title;
                            const formattedScore = getFormattedScoreByScoreId(formattedScores, score.id);

                            if (!formattedScore) {
                                return {
                                    header: score.title,
                                    options: [
                                        {
                                            id: score.id + "_0",
                                            label: scoreLabel,
                                            disabled: true,
                                        },
                                    ],
                                };
                            }

                            const scoreVariations = formattedScore?.scoreVariations ?? {};

                            return {
                                header: score.title,
                                options: Object.entries(scoreVariations).map(
                                    ([variationKey, variation], index: number) => {
                                        return {
                                            id: score.id + "_" + variationKey,
                                            label:
                                                !index ? scoreLabel : (
                                                    variation.label ?? t("Variante") + " " + (index + 1)
                                                ),
                                        };
                                    },
                                ),
                            };
                        })}
                        onChange={(option: OptionsPopoverOption) => {
                            const [scoreId, variationKey] = option.id.toString().split("_");

                            setDisplayedScoreId(Number(scoreId));
                            setDisplayedVariationId(Number(variationKey));

                            setAnchor(null);
                        }}
                        defaultIndex={defaultIndex}
                        minWidth="287px"
                    />
                </AnchoredPopover>
            )}

            <Stack
                onClick={(e) => {
                    if (!isActive) return;

                    setAnchor(e.currentTarget);
                }}
                sx={{
                    color: isActive ? theme.custom.grey[2800] : theme.custom.grey[1200],
                    cursor: isActive ? "pointer" : "default",
                }}
            >
                <KeyboardArrowDown />
            </Stack>
        </>
    );
};
