import { useMutation, useQueryClient } from "react-query";
import { loggedInApiCall } from "src/api/axiosClient/userApi";
import { useSnackbar } from "src/utils/useSnackbar";
import { CMSSectionAllBlocks, getOneCMSContentKey } from "../useGetOneContent";
import { useTranslation } from "react-i18next";

type CreateSection = {
    section: SectionWrite;
};

export const useCreateOneSection = () => {
    const queryClient = useQueryClient();
    const snackbar = useSnackbar();
    const { t } = useTranslation();

    const query = useMutation({
        mutationFn: async (options: CreateSection) => {
            const { section } = options;

            const url = `/api/cms/contents/pages/sections`;

            const response =
                section.id === undefined ?
                    await loggedInApiCall.post(url, section)
                :   await loggedInApiCall.patch(`${url}/${section.id}`, section, {
                        headers: {
                            "Content-Type": "application/merge-patch+json",
                        },
                    });

            return response;
        },
        onSuccess: () => {
            queryClient.invalidateQueries([getOneCMSContentKey]);
            snackbar.forceClose();
            snackbar.setSnackbarProps({
                type: "info",
                message: t("snackbar.section.add.success"),
            });
        },
    });

    return query;
};

export type SectionWrite =
    | ({ id: string; type?: CMSSectionAllBlocks } & Partial<SectionCore> & MandatoryFields)
    | ({ id: string | undefined; type: CMSSectionAllBlocks } & Partial<SectionCore> & MandatoryFields);

type MandatoryFields = {
    page: IRI;
};

type SectionCore = {
    position: number;
    visible: boolean;
    title: string;
};
