import { RoundInput, FoodsIcon } from "@foodpilot/foods";
import { Stack, Typography, Button, Box, useTheme } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDeleteOneBlock } from "src/api/client-api/cms/blocks/useDeleteOneBlock";
import { useReorderBlocks } from "src/api/client-api/cms/blocks/useReorderBlocks";
import { useSectionsConfig } from "src/api/client-api/cms/sections/sectionsConfig";
import { SectionWrite, useCreateOneSection } from "src/api/client-api/cms/sections/useCreateOneSection";
import { useToggleSectionVisibility } from "src/api/client-api/cms/sections/useToggleSection";
import { CMSContentBlock, CMSPageSection, CMSSectionBlocksStrategy } from "src/api/client-api/cms/useGetOneContent";
import { DraggingRows } from "src/components/Dragging";
import { URLHandler } from "src/services/URLHandler";
import { VerticalDivider } from "../../../Common/VerticalDivider";
import { ConfirmationFooter } from "../_Common/ConfirmationFooter";
import { EditLayout } from "../_Common/EditLayout";
import { AddIndicatorsModal } from "../Indicators/Form/AddIndicatorsModal";
import { SectionHeader } from "../_Common/SectionForm/SectionHeader";
// import { getStrategyBlockForm, StrategyBlockForm } from "../_Common/BlockForm/StrategyBlock/strategyBlock";
import { SectionForm } from "../_Common/SectionForm/SectionForm";
import { AddStrategyIndicatorsModal } from "./AddStrategyIndicatorModal";

type EditStrategyIndicatorsProps = {
    originalPageId: string;
    section: CMSPageSection<CMSSectionBlocksStrategy>;
};
export const EditStrategyIndicators = (props: EditStrategyIndicatorsProps) => {
    const { originalPageId, section } = props;

    const { t } = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();

    // const deleteBlock = useDeleteOneBlock();
    // const reorderBlock = useReorderBlocks();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    // const [selectedExistingBlock, setSelectedExistingBlock] = useState<StrategyBlockForm | null>(null);

    // const toggleVisibility = useToggleSectionVisibility();
    // const createSection = useCreateOneSection();

    // const strategyIndicator = getStrategyBlockForm(section);
    // const [indicatorFormState, setIndicatorFormState] = useState(strategyIndicator);

    // const blocksRows = section.blocks.map((block) => {
    //     return {
    //         id: block.id,
    //         title: block.title,
    //         link: block.ctaLink,
    //         position: block.position,
    //         editAction: () => {
    //             setSelectedExistingBlock(block);
    //             setIsOpen(true);
    //         },
    //         deleteAction: () => {
    //             deleteBlock.mutate({ deleteId: block.id, allBlocksToReorder: section.blocks });
    //         },
    //     };
    // });
    const blockConfig = useSectionsConfig();
    const config = blockConfig[section.type];

    return (
        <Stack gap={theme.spacing(3)}>
            <SectionHeader originalPageId={section.page} section={section} />
            <SectionForm section={section} />
            <EditLayout>
                <AddStrategyIndicatorsModal
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    section={section}
                    // currentSection={{
                    //     "@id": section["@id"],
                    //     totalExistingElements: section.blocks.length,
                    // }}
                    // selectedExistingBlock={section}
                />
                <Typography variant="big-medium">{t("cms.section.indicators.add.info")}</Typography>
                {/* <DraggingRows
                    rows={blocksRows}
                    renderRow={(row, rowIndex) => {
                        return (
                            <Stack
                                flexDirection={"row"}
                                justifyContent={"space-between"}
                                key={`index-${rowIndex}`}
                                sx={{ border: `2px solid ${theme.custom.grey[2000]}`, width: "100%" }}
                                padding={theme.spacing(1)}
                            >
                                <Stack
                                    flexDirection={"row"}
                                    justifyContent={"space-between"}
                                    alignItems={"center"}
                                    gap={theme.spacing(4)}
                                    marginLeft={theme.spacing(1)}
                                >
                                    <Typography variant="big-bold">{row.title}</Typography>
                                </Stack>
                                <Stack flexDirection={"row"} gap={theme.spacing(2)} alignItems={"center"}>
                                    <Button
                                        variant="text"
                                        startIcon={<FoodsIcon size={2} icon={"edit"} />}
                                        onClick={row.editAction}
                                    >
                                        {"Modifier"}
                                    </Button>
                                    <VerticalDivider />
                                    <Box onClick={row.deleteAction}>
                                        <FoodsIcon size={4} icon="delete" />
                                    </Box>
                                </Stack>
                            </Stack>
                        );
                    }}
                    onHoverDrop={(reorderedRows) => {
                        reorderBlock.mutate({ allBlocksToReorder: reorderedRows });
                    }}
                /> */}
                {section.blocks.length >= 6 ?
                    <Typography variant="big-bold">{t("cms.section.indicators.maxlimit")}</Typography>
                :   <Button
                        variant="primary"
                        onClick={() => {
                            // setSelectedExistingBlock(null);
                            setIsOpen(true);
                        }}
                    >
                        {t("cms.section.indicators.add.button")}
                    </Button>
                }
            </EditLayout>
        </Stack>
    );
};
