import { useTranslation } from "react-i18next";
import { CMSSectionAllBlocks } from "../useGetOneContent";

type SectionConfig = {
    title: string;
    subtitle: string;
    isDisabled: boolean;
};

export const useSectionsConfig = () => {
    const { t } = useTranslation();

    const sectionsConfig: Record<CMSSectionAllBlocks, SectionConfig> = {
        hero: {
            isDisabled: false,
            title: t("cms.section.hero.title"),
            subtitle: t("cms.section.hero.subtitle"),
        },
        indicator: {
            isDisabled: false,
            title: t("cms.section.indicator.title"),
            subtitle: t("cms.section.indicator.subtitle"),
        },
        articles: {
            isDisabled: false,
            title: t("cms.section.articles.title"),
            subtitle: t("cms.section.articles.subtitle"),
        },
        strategyIndicators: {
            isDisabled: true,
            title: t("cms.section.strategyIndicators.title"),
            subtitle: t("cms.section.strategyIndicators.subtitle"),
        },
    } as const;

    return sectionsConfig;
};
