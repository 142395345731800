import z from "zod";
import { useQuery } from "react-query";
import { loggedInApiCall } from "src/api/axiosClient/userApi";

import { zodParseWithLog } from "src/api/apiErrorLogs.ts";
import { ReferenceScore } from "src/types/applicationTypes/referenceScore.ts";

export const ingredientReferenceScoresKey = "ingredientReferenceScores" as const;

export const useGetIngredientReferenceScores = (ingredientId: number | undefined) => {
    const query = useQuery({
        queryKey: ["getIngredientReferenceScores", ingredientId],
        cacheTime: 0,
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        enabled: ingredientId !== undefined,
        queryFn: async () => {
            const url = `/api/ingredients/${ingredientId}/reference_scores`;

            return await loggedInApiCall.get<IngredientReferenceScores>(url);
        },
        select: (request) => {
            const ingredientReferenceScores = request?.data;

            return zodParseWithLog(
                ingredientReferenceScores,
                IngredientReferenceScoresSchema,
                ingredientReferenceScoresKey,
            );
        },
    });

    return {
        ingredientReferenceScores: query.data,
    };
};

export interface IngredientReferenceScore extends ReferenceScore {
    ingredientReference: {
        name: string;
    };
}

export interface IngredientReferenceScores extends Array<IngredientReferenceScore> {}

const IngredientReferenceScoresSchema: z.ZodSchema<IngredientReferenceScores> = z.array(
    z.object({
        id: z.number(),
        ingredientReference: z.object({
            name: z.string(),
        }),
        score: z
            .object({
                id: z.number(),
            })
            .optional(),
        value: z.number().optional(),
    }),
);
