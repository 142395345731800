import { useQuery } from "react-query";
import { loggedInApiCall } from "src/api/axiosClient/userApi";
import { zodParseWithLog } from "src/api/apiErrorLogs";
import { PostSchema } from "./postSchema";
import { isAxiosError } from "axios";
import { useSnackbarWrapperApiGenericValidationErrorHandler } from "src/api/axiosClient/errorHandler.ts";

export const fetchIngredientPost = async (
    ingredientId: number | undefined,
    companyId: number | undefined | null,
    campaignId: number | undefined,
) => {
    const url = `/api/ingredients/${ingredientId}/ssq_post`;
    const params: { [key: string]: string | string[] } = {
        dimensions: ["main"],
    };

    if (companyId !== null && companyId !== undefined) {
        params["company_id"] = companyId.toString();
    }

    if (campaignId) {
        params["campaign"] = campaignId.toString();
    }

    return await loggedInApiCall.get(url, {
        headers: {
            Accept: "application/json",
        },
        params: params,
    });
};

export const useGetIngredientPost = (
    ingredientId: number | undefined,
    companyId: number | null,
    campaignId?: number,
) => {
    const apiErrorHandler = useSnackbarWrapperApiGenericValidationErrorHandler();

    const query = useQuery({
        queryKey: ["getIngredientPost", ingredientId, companyId, campaignId],
        cacheTime: 0,
        enabled: ingredientId !== undefined,
        queryFn: () => fetchIngredientPost(ingredientId, companyId, campaignId),
        select: (response) => {
            if (!response) {
                return;
            }

            const post = response.data;
            const data = zodParseWithLog(post, PostSchema, "getIngredientPost");
            return data;
        },
        onError: (error) => {
            if (isAxiosError(error)) {
                apiErrorHandler(error);
            }
        },
    });

    return {
        post: query.data,
        ...query,
    };
};
