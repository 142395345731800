import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FoodsIcon, SecondaryTab } from "@foodpilot/foods";
import { Distribution } from "src/pages/Product/ProductDetails/Scores/SecondaryTabs/Distribution.tsx";
import { useNavigate } from "react-router-dom";
import { useProductContext } from "src/pages/Product/ProductDetails/ProductContext.tsx";
import { useScoreDetailContext } from "src/context/ScoreDetail/useScoreDetailContext.tsx";
import { ScoreDetail } from "src/components/ScoreDetail/ScoreDetail.tsx";
import { Loading } from "src/components/utils/Loading.tsx";
import { useIsVariationRefetching } from "src/utils/variationRefetching.ts";

const ProductScore = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { product, variations, setVariations, isRefetching: isProductRefetching } = useProductContext();

    const { selectedScore, selectedValueIndex } = useScoreDetailContext();
    const isVariationRefetching = useIsVariationRefetching(isProductRefetching, variations);

    if (!product?.id) {
        throw new Error("No product ID provided. Cannot fetch product details.");
    }

    const getComponent = () => {
        if (isVariationRefetching) {
            return <Loading />;
        }

        return selectedScore ?
                <Distribution
                    selectedDimensionId={selectedValueIndex[selectedValueIndex.length - 1]}
                    selectedValueIndex={selectedValueIndex}
                />
            :   <Typography variant="body-medium">{t("glossary.noScoreDetails")}</Typography>;
    };

    const tabs: SecondaryTab[] = [
        {
            id: 1,
            name: t("distribution_variant"),
            component: getComponent(),
            onClick: () => {
                navigate("?dimensions=headings");
            },
            icon: <FoodsIcon size={2} icon="repartition" />,
        },
    ];

    return (
        <ScoreDetail
            variations={variations}
            setVariations={setVariations}
            tabs={tabs}
            entity={{ name: "product", id: product.id }}
        />
    );
};

export { ProductScore };
