import { ArticleProps, ArticlesBox } from "@foodpilot/foods";
import { CMSContentBlock, CMSPageSection, CMSSectionBlocksContent } from "src/api/client-api/cms/useGetOneContent";

const blockToArticle = (blocks: CMSContentBlock[]): ArticleProps[] => {
    const blocksForArticles = blocks.map((block, idx) => {
        const isFirst = idx === 0;

        const articleBlock: ArticleProps = {
            title: block.title,
            description: block.description ?? "",
            imageSrc: block.media?.url ?? "",
            isFirst: isFirst,
        };

        return articleBlock;
    });

    return blocksForArticles;
};

type CMSArticlesProps = {
    section: CMSPageSection<CMSSectionBlocksContent>;
};
export const CMSArticles = (props: CMSArticlesProps) => {
    const { section } = props;
    const { blocks } = section;

    const articleBlocks = blockToArticle(blocks);
    return <ArticlesBox articles={articleBlocks} />;
};
