import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useScoreDetailContext } from "src/context/ScoreDetail/useScoreDetailContext.tsx";
import { FoodsIcon, SecondaryTab } from "@foodpilot/foods";
import { Distribution } from "src/pages/Company/Scores/SecondaryTabs/Distribution.tsx";
import { Company } from "src/api/client-api/useUserNew.ts";
import { ScoreDetail } from "src/components/ScoreDetail/ScoreDetail.tsx";
import { Loading } from "src/components/utils/Loading.tsx";

export type CompanyScoreProps = {
    company: Company;
    variations: Record<number, number>;
    setVariations: (variations: Record<number, number>) => void;
    isRefetching?: boolean;
};
export const CompanyScore = (props: CompanyScoreProps) => {
    const { variations, setVariations, isRefetching } = props;

    const { t } = useTranslation();
    const navigate = useNavigate();

    const { selectedScore, selectedValueIndex } = useScoreDetailContext();

    const getComponent = () => {
        if (isRefetching) {
            return <Loading />;
        }

        return selectedScore ?
                <Distribution
                    selectedDimensionId={selectedValueIndex[selectedValueIndex.length - 1]}
                    selectedValueIndex={selectedValueIndex}
                    isRefetching={isRefetching}
                    variations={variations}
                />
            :   <Typography variant="body-medium">{t("glossary.noScoreDetails")}</Typography>;
    };

    const tabs: SecondaryTab[] = [
        {
            id: 1,
            name: t("distribution_variant"),
            component: getComponent(),
            onClick: () => {
                navigate("?dimensions=headings");
            },
            icon: <FoodsIcon size={2} icon="repartition" />,
        },
    ];

    return (
        <ScoreDetail variations={variations} setVariations={setVariations} tabs={tabs} entity={{ name: "company" }} />
    );
};
