import { FoodsIcon } from "@foodpilot/foods";
import { Stack, Typography, Button, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { URLHandler } from "src/services/URLHandler";

export const NoPillars = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();

    return (
        <Stack>
            <Stack flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                <Typography variant="big-bold">{t("settings.csr.pillars.title")}</Typography>
                <Button
                    variant="primary"
                    startIcon={<FoodsIcon icon="plus" size={2} />}
                    onClick={() => {
                        const url = URLHandler.settings.csrStrategy.pillarsEdit("new");
                        navigate(url);
                    }}
                >
                    {t("settings.csr.pillars.addButton", { verb: t("glossary.create") })}
                </Button>
            </Stack>
            <Stack justifyContent={"center"} alignItems={"center"} minHeight={theme.spacing(16)}>
                <Typography variant="big-medium">{t("settings.csr.pillars.noItem")}</Typography>
            </Stack>
        </Stack>
    );
};
