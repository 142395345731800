import { BlockListContainer, EmptyForm, InfoHeaderWithIllustration, InitHeader, Typography } from "@foodpilot/foods";
import { Button, Stack, useTheme } from "@mui/material";
import { PlanningListHeader } from "./PlanningListHeader";
import { PlanningLayout } from "../PlanningLayout";
import { useTranslation } from "react-i18next";
import { useActionsContext } from "src/context/Actions/useActionsContext";
import { useDuplicatePost } from "src/api/client-api/post/duplicatePost";
import { useSnackbar } from "src/utils/useSnackbar";
import { useFoodpilotContext } from "src/context/FoodpilotContext";
import { MutableRefObject, useRef } from "react";
import { anyAllSimulationKey } from "src/api/client-api/post/useGetAllPosts";
import { useQueryClient } from "react-query";

export type EmptyPlanningProps = unknown;
export const EmptyPlanning = (_props: EmptyPlanningProps) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const snackbar = useSnackbar();
    const queryClient = useQueryClient();
    const duplicatePost = useDuplicatePost();
    const { initialPostId, startingPost, isLocked, setIsLocked } = useActionsContext();
    const { targetCampaign } = useFoodpilotContext();
    const campaignSelectorRef = useRef<HTMLDivElement | null>(null);

    const infoStatus: HelpStatus = "campaignSelection";
    const numberOfActions = 0;

    const emptyActionsText =
        startingPost.post === null ? t("actionPlan.noSimulation.initText") : t("actionPlan.noActionPlan.initText");
    const emptyActionsButton =
        startingPost.post === null ? t("actionPlan.noSimulation.initButton") : t("actionPlan.noActionPlan.initButton");

    return (
        <Stack gap={theme.spacing(3)}>
            <InfoHeaderStatus campaignSelectorRef={campaignSelectorRef} status={infoStatus} />
            <PlanningListHeader campaignSelectorRef={campaignSelectorRef} />
            <PlanningLayout>
                <BlockListContainer
                    header={
                        <InitHeader
                            title={
                                <Stack flexDirection="row" gap={theme.spacing(1)}>
                                    <Typography variant={"h3"}>{t("actionPlan.activeActions")}</Typography>
                                    <Typography variant={"huge"}>{"-"}</Typography>
                                    <Typography variant={"huge"}>{numberOfActions}</Typography>
                                </Stack>
                            }
                            actionButton={
                                <Button
                                    variant="primary"
                                    disabled={isLocked}
                                    onClick={() => {
                                        if (targetCampaign === undefined) {
                                            snackbar.forceClose();
                                            snackbar.setSnackbarProps({
                                                type: "warning",
                                                message:
                                                    "You have no configured Target campaign. You can do that in Settings",
                                            });
                                            return;
                                        }

                                        if (startingPost.post === null) {
                                            setIsLocked(true);
                                            duplicatePost.mutate(
                                                {
                                                    toCampaignID: targetCampaign.id,
                                                    fromPostID: initialPostId,
                                                    status: "calculation",
                                                },
                                                {
                                                    onSuccess: () => {
                                                        snackbar.forceClose();
                                                        snackbar.setSnackbarProps({
                                                            type: "info",
                                                            message: t("actionPlan.snackbar.creationSuccess"),
                                                        });
                                                        queryClient.invalidateQueries([anyAllSimulationKey]);
                                                    },
                                                    onSettled: () => {
                                                        setIsLocked(false);
                                                    },
                                                },
                                            );
                                            return;
                                        }

                                        const postId = startingPost.post?.id;
                                        if (postId === undefined || postId === null) {
                                            return;
                                        }

                                        setIsLocked(true);
                                        duplicatePost.mutate(
                                            {
                                                toCampaignID: targetCampaign.id,
                                                fromPostID: postId,
                                                status: "simulation",
                                            },
                                            {
                                                onSuccess: () => {
                                                    snackbar.forceClose();
                                                    snackbar.setSnackbarProps({
                                                        type: "info",
                                                        message: t("actionPlan.snackbar.creationSuccess"),
                                                    });
                                                    queryClient.invalidateQueries([anyAllSimulationKey]);
                                                },
                                                onSettled: () => {
                                                    setIsLocked(false);
                                                },
                                            },
                                        );
                                    }}
                                >
                                    {emptyActionsButton}
                                </Button>
                            }
                        />
                    }
                    blocks={[
                        <EmptyForm
                            illustration={"actions"}
                            emptyText={<Typography variant="body-bold">{emptyActionsText}</Typography>}
                        />,
                    ]}
                />
            </PlanningLayout>
        </Stack>
    );
};

type HelpStatus = "campaignSelection" | "createSimulation" | "planningActions";
type InfoHeaderStatusProps = {
    status: HelpStatus;
    campaignSelectorRef: MutableRefObject<HTMLDivElement | null>;
};
const InfoHeaderStatus = (props: InfoHeaderStatusProps) => {
    const { campaignSelectorRef, status } = props;
    const { t } = useTranslation();

    switch (status) {
        case "campaignSelection":
            return (
                <InfoHeaderWithIllustration
                    boldText={t("actionPlan.startingInfoTitle")}
                    smallInfoText={t("actionPlan.startingInfoDescription")}
                    illustration={"selectStartingYear"}
                    buttonAction={{
                        action: () => {
                            campaignSelectorRef.current?.querySelector("button")?.click();
                        },
                        label: t("actionPlan.startCampaignButton"),
                    }}
                />
            );
        case "createSimulation":
            return (
                <InfoHeaderWithIllustration
                    boldText={t("actionPlan.startingInfoTitle")}
                    smallInfoText={t("actionPlan.startingInfoDescription")}
                    illustration={"selectStartingYear"}
                    buttonAction={{
                        action: () => {
                            campaignSelectorRef.current?.querySelector("button")?.click();
                        },
                        label: t("actionPlan.startCampaignButton"),
                    }}
                />
            );
        case "planningActions":
            return (
                <InfoHeaderWithIllustration
                    boldText={t("actionPlan.startingInfoTitle")}
                    smallInfoText={t("actionPlan.startingInfoDescription")}
                    illustration={"selectStartingYear"}
                    buttonAction={{
                        action: () => {
                            campaignSelectorRef.current?.querySelector("button")?.click();
                        },
                        label: t("actionPlan.startCampaignButton"),
                    }}
                />
            );
        default:
            // eslint-disable-next-line
            const _e: never = status;
            return <></>;
    }
};
