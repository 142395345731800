import { useParams } from "react-router-dom";
import { Post } from "src/api/client-api/post/postSchema";
import { Ssq, Heading } from "src/api/sure-api/ssq/useGetOneSsq";
import { Ingredient } from "src/api/client-api/ingredients/useGetOneIngredient";
import { FoodsPage } from "@foodpilot/foods";
import { useQuestionnaireUpdatePages } from "src/components/QuestionnaireUpdate/useQuestionnaireUpdatePages";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { QuestionnaireUpdateFormBase } from "./QuestionnaireUpdateFormBase";
import { getVisibleHeadings } from "src/utils/ssq";
import { getScoreIdsByType } from "src/utils/scores";
import { useFoodpilotContext } from "src/context/FoodpilotContext";
import { formatDateCampaign } from "src/utils/campaigns";
import { useIngredientContext } from "../../ExistingIngredientContext";
import { URLHandler } from "src/services/URLHandler";

type QuestionnaireUpdateFormSsqPostProps = {
    ingredient: Ingredient;
    ssq: Ssq;
    post?: Post;
    isPostLoading: boolean;
    changePost: (callback: (post: Post) => void) => void;
};
export const QuestionnaireUpdateFormSsqPost = (props: QuestionnaireUpdateFormSsqPostProps) => {
    const { headingId } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { scoreTypes, scores, currentCampaign, selectedCompany } = useFoodpilotContext();
    const { fetchPosts, activeSupplierId, canModifySSQ } = useIngredientContext();
    const { ingredient, ssq, post, isPostLoading, changePost } = props;

    const headings: Heading[] = getVisibleHeadings(ssq, post?.hiddenElements);
    const ingredientsScoresIds = getScoreIdsByType("ingredient", scoreTypes);

    const pages: FoodsPage[] = useQuestionnaireUpdatePages({
        headings,
        post,
        isPostLoading,
        scores,
        scoresIds: ingredientsScoresIds,
        canEdit: canModifySSQ,
        changePost,
    });

    const headingsIds: number[] = headings.map((heading) => Number(heading.id));
    const headingIndex = headingsIds.findIndex((id) => id === Number(headingId));

    if (headingIndex < 0) return t("Thématique non trouvée dans le questionnaire");
    if (selectedCompany === null) return;

    const formattedSsqTitle =
        currentCampaign ?
            ssq.title.concat(" - ").concat(formatDateCampaign(currentCampaign.startDate, currentCampaign.endDate, "/"))
        :   ssq.title;

    const ingredientSSQUrl = URLHandler.ingredient.ssq(selectedCompany.id, ingredient.id, activeSupplierId ?? 0);
    return (
        <QuestionnaireUpdateFormBase
            ingredient={ingredient}
            title={formattedSsqTitle}
            pages={pages}
            startingPage={headingIndex ?? 0}
            onPageChange={(newPageIndex: number, previousPageIndex: number) => {
                if (!newPageIndex && !previousPageIndex) {
                    /**
                     * [vma]: Update the posts in the ingredient context
                     * Allowing the user to see his progression in the questionnaire list
                     * after submitting the qss. As no parametters changes in the context,
                     * the useQuery hooks isn´t triggered
                     */
                    fetchPosts();
                    navigate(ingredientSSQUrl);

                    return;
                }

                const headingPage = headingsIds[newPageIndex];
                const url = URLHandler.ingredient.ssqHeading(
                    selectedCompany.id,
                    ingredient.id,
                    activeSupplierId ?? 0,
                    headingPage,
                );

                navigate(url);
            }}
            onValidate={() => {
                fetchPosts();
                navigate(ingredientSSQUrl);
            }}
            onClose={() => {
                fetchPosts();
                navigate(ingredientSSQUrl);
            }}
        />
    );
};
