import { useContext } from "react";
import { SettingsStrategyContext } from "./context";

export const useSettingsStrategy = () => {
    const context = useContext(SettingsStrategyContext);

    if (context) {
        return context;
    }

    throw new Error(`useSettingsStrategyContext must be used within the context`);
};
