import { useQuery } from "react-query";
import { zodParseWithLog } from "src/api/apiErrorLogs";
import { loggedInApiCall } from "src/api/axiosClient/userApi";

import { z } from "zod";

export const oneCsrThemeQueryKey = "getOneCsrTheme" as const;

type Options = {
    enabled: boolean;
};
export const useGetOneCsrTheme = (csrThemeId: string, _options?: Options) => {
    const query = useQuery({
        queryKey: [oneCsrThemeQueryKey],
        cacheTime: 0,
        queryFn: async () => {
            const url = `/api/steering/csr_themes/${csrThemeId}`;
            const req = loggedInApiCall.get(url);
            return req;
        },
        select: (request) => {
            const data = zodParseWithLog(request.data, CsrThemeSchema, oneCsrThemeQueryKey);
            return data;
        },
    });

    return {
        csrTheme: query.data,
        ...query,
    };
};

type CsrThemeParent = {
    "@id": IRI;
    title: string;
};

type CsrThemeChildren = {
    "@id": IRI;
    title: string;
};

export type CsrTheme = {
    "@id": IRI;
    id: number;
    code: string;
    title: string;
    description?: string;
    parent?: CsrThemeParent;
    children?: CsrThemeChildren[];
};

export const CsrThemeSchema: z.ZodSchema<CsrTheme> = z.object({
    "@id": z.string(),
    id: z.number(),
    code: z.string(),
    title: z.string(),
    description: z.string().optional(),
    parent: z
        .object({
            "@id": z.string(),
            title: z.string(),
        })
        .optional(),
    children: z
        .object({
            "@id": z.string(),
            title: z.string(),
        })
        .array()
        .optional(),
});
