import { useMutation } from "react-query";
import { loggedInApiCall } from "src/api/axiosClient/userApi";

type CreateIngredient = {
    ingredient: IngredientWrite;
};
export const useCreateIngredient = () => {
    const query = useMutation({
        mutationFn: async (options: CreateIngredient) => {
            const { ingredient } = options;

            const response =
                ingredient.id === undefined ?
                    await loggedInApiCall.post("/api/ingredients", ingredient)
                :   await loggedInApiCall.put("/api/ingredients/" + ingredient.id, ingredient);

            return response;
        },
    });

    return query;
};

export type IngredientWriteIngredientSupplier =
    | IRI
    | {
          "@id"?: string;
          id?: number;
          supplier: IRI | undefined;
          quantity: number | null | undefined;
          grossSales: number | null | undefined;
          unit: number | null | undefined;
      };

export type IngredientWrite = {
    id?: number;
    name: string | null | undefined;
    clientId: string | null | undefined;
    ingredientReferenceId: number | null | undefined;
    organic: boolean | null | undefined;
    ingredientLine: IRI | null | undefined;
    ingredientSuppliers: IngredientWriteIngredientSupplier[];
};
