import { ApiResponse, FieldError } from "src/api/axiosClient/errorHandler.ts";
import { t } from "i18next";
import { Path, UseFormSetError } from "react-hook-form";

/*  eslint-disable-next-line @typescript-eslint/no-explicit-any --
    There probably is a type to be able to use this setError.
    The problem here I think is that this `handleFieldErrors` is
    coupling dependencies : react-form - zod - snackbar message
*/
type FormError = Record<string, any>;

export const handleFieldErrors = <T extends FormError>(response: ApiResponse, setError: UseFormSetError<T>) => {
    response.fieldErrors.errors.forEach((fieldError: FieldError) => {
        // @ts-expect-error translation may not exists, I don't know yet how to solve this properly
        setError(fieldError.fieldPath as Path<T>, { type: "server", message: t(fieldError.message) });
    });
};
