import { useQuery } from "react-query";
import { loggedInApiCall } from "src/api/axiosClient/userApi";
import { zodParseWithLog } from "src/api/apiErrorLogs";
import { PostSchema } from "./postSchema";
import { useSnackbarWrapperApiGenericValidationErrorHandler } from "src/api/axiosClient/errorHandler.ts";
import { isAxiosError } from "axios";

export const useGetCompanyPost = (companyId: number | undefined, campaignId: number | undefined) => {
    const apiErrorHandler = useSnackbarWrapperApiGenericValidationErrorHandler();

    const query = useQuery({
        queryKey: ["getCompanyPost", companyId, campaignId],
        enabled: companyId !== undefined && campaignId !== undefined,
        queryFn: async () => {
            const url = `/api/companies/${companyId}/ssq_post`;

            return await loggedInApiCall.get(url.toString(), {
                headers: {
                    Accept: "application/json",
                },
                params: {
                    campaign: campaignId,
                    dimensions: ["main"],
                },
            });
        },
        select: (response) => {
            if (!response) {
                return;
            }

            const post = response.data;
            const data = zodParseWithLog(post, PostSchema, "getCompanyPost");
            return data;
        },
        onError: (error) => {
            if (isAxiosError(error)) {
                apiErrorHandler(error);
            }
        },
    });

    return {
        post: query.data,
        ...query,
    };
};
