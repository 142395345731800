import { useGetOneCsrPillar } from "src/api/client-api/csrStrategy/Pillars/useGetOneCsrPillar";
import { useParams } from "react-router-dom";
import { Loading } from "src/components/utils/Loading";
import { PillarForm } from "./PillarForm";

export const EditPillar = () => {
    const { pillarId } = useParams();
    const { csrPillar, isFetched } = useGetOneCsrPillar(pillarId);

    if (isFetched === false) {
        return <Loading />;
    }

    if (csrPillar === undefined) {
        // Something bad happened ? Wrong URL ?
        // No Item Found page;
        return;
    }

    return <PillarForm pillar={csrPillar} />;
};
