import { Box, Divider, Stack, Typography, useTheme } from "@mui/material";
import { FoodsIcon, SectionTab, SectionTabs } from "@foodpilot/foods";
import { Outlet, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFoodpilotContext } from "src/context/FoodpilotContext";
import { URLHandler } from "src/services/URLHandler";

type Sections = "general" | "pillars";
export type MainLayoutTabsProps = {
    selectedSection: Sections;
};

type TabsSections = Partial<Record<Sections, SectionTab>>;

export const MainLayoutTabs = (props: MainLayoutTabsProps) => {
    const { selectedSection } = props;
    const { t } = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();

    const { selectedCompany } = useFoodpilotContext();

    if (!selectedCompany) return <Typography variant="big-bold">{t("error.wrongCompany")}</Typography>;

    const sections: TabsSections = {
        general: {
            name: t("settings.csr.info.title"),
            icon: <FoodsIcon icon="scoresIndicators" size={3} />,
            onClick: () => {
                const url = URLHandler.settings.csrStrategy.general();
                navigate(url);
            },
        },
        pillars: {
            name: t("settings.csr.pillars.title"),
            icon: <FoodsIcon icon="actionPlan" size={3} />,
            onClick: () => {
                const url = URLHandler.settings.csrStrategy.pillars();
                navigate(url);
            },
        },
    };

    return (
        <Box component="div" sx={{ marginLeft: "auto", marginRight: "auto" }}>
            <Box
                sx={{
                    width: "auto",
                    background: "#fff",
                    padding: "28px 52px",
                    paddingBottom: 0,
                    position: "relative",
                    borderBottom: `1px solid ${theme.custom.grey[500]}`,
                }}
            >
                <Stack gap={theme.spacing(1)}>
                    <Typography variant="huge-bold">{t("settings.csr.title")}</Typography>
                    <Typography variant="big-medium">{t("settings.csr.subtitle")}</Typography>
                </Stack>

                <Divider sx={{ marginTop: "28px" }} />
                <SectionTabs
                    tabs={Object.values(sections)}
                    value={Object.keys(sections).indexOf(selectedSection)}
                    renderComponents={false}
                    sx={{
                        position: "relative",
                        bottom: "-1px",
                    }}
                />
            </Box>

            <Stack gap="28px" sx={{ padding: "40px 52px" }}>
                <Outlet />
            </Stack>
        </Box>
    );
};
