import { useMutation, useQueryClient } from "react-query";
import { DocumentMediaContentWrite } from "./types";
import { loggedInApiCall } from "src/api/axiosClient/userApi";
import { useState } from "react";
import { z } from "zod";
import { zodParseWithLog } from "src/api/apiErrorLogs";

export const useUploadDocument = () => {
    const [uploading, setUploading] = useState(false);
    const queryClient = useQueryClient();

    const query = useMutation({
        mutationKey: ["uploadDocument"],
        mutationFn: async (media: DocumentMediaContentWrite) => {
            const url = `/api/cms/contents/medias`;
            setUploading(true);

            const payload = new FormData();
            payload.append("file", media.file);
            payload.append("name", media.name);

            const response = await loggedInApiCall.post(url, payload, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });

            return zodParseWithLog(response.data, DocumentMediaResponseSchema, "uploadDocument");
        },
        onError: () => {
            setUploading(false);
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["getAllDocuments"]);
            queryClient.invalidateQueries(["uploadDocument"]);
            setUploading(false);
        },
    });

    return {
        query,
        uploading,
    };
};

// Schema for validating the server response
const DocumentMediaResponseSchema = z.object({
    id: z.string(),
    name: z.string(),
    mimeType: z.string(),
    url: z.string().optional(),
    size: z.number().optional(),
    createdAt: z.string(),
    updatedAt: z.string().optional(),
});
