import { Fragment } from "react";
import { Outlet, Route, isRouteErrorResponse, useRouteError } from "react-router-dom";
import SettingsLayout from "./SettingsLayout";
import SettingsHome from "./SettingsHome";
import { Scores } from "./Scores";
import { Campaigns } from "./Campaigns";
import { ProductLineList } from "./ProductLineList";
import { CompanySectorList } from "./CompanySectorList";
import { CompaniesList } from "./Company/CompaniesList";
import { Company } from "./Company/Company";
import { ProvidersList } from "./Supplier/ProvidersList";
import { UsersList } from "./Users/UsersList";
import { User } from "./Users/User";
import { UserCreate } from "./Users/UserCreate";
import { UserEdit } from "./Users/UserEdit";
import { SettingsInfos } from "./SettingsInfos";
import { CsrTheme } from "./CsrTheme/List";
import { CsrThemeCreate } from "./CsrTheme/Create";
import { CsrThemeEdit } from "./CsrTheme/Edit";
import { BrandList } from "./Brand/List";
import { BrandCreate } from "./Brand/Create";
import { BrandEdit } from "./Brand/Edit";
import { IngredientLineList } from "src/pages/Settings/IngredientLine/List";
import { IngredientLineCreate } from "src/pages/Settings/IngredientLine/Create";
import { IngredientLineEdit } from "src/pages/Settings/IngredientLine/Edit";
import { RolesList } from "src/pages/Settings/Roles/List";
import { RoleCreate } from "src/pages/Settings/Roles/Create";
import { RoleEdit } from "src/pages/Settings/Roles/Edit";
import { CompanyCreate } from "./Company/CompanyCreate";
import { CompanyEdit } from "./Company/CompanyEdit";
import { AppParamsSettings } from "./AppParams/AppParam";
import { CMSPageSettings } from "./CMS/Pages/CMSHomeSettings";
import { EditSections } from "./CMS/Pages/EditSections";
import { SettingsCMSProvider } from "src/context/Settings/CMS/provider";
import { MediaSettings } from "./CMS/MediaSettings";
import { MainLayoutTabs } from "./CsrStrategy/MainLayoutTabs";
import { GeneralInfo } from "./CsrStrategy/General/GeneralInfo";
import { Pillars } from "./CsrStrategy/Pillars/Pillars";
import { SettingsStrategyProvider } from "src/context/Settings/CsrStrategy/provider";
import { NewPillar } from "./CsrStrategy/Pillars/EditPillarForm/NewPillar";
import { EditPillar } from "./CsrStrategy/Pillars/EditPillarForm/EditOnePillar";

export const useSettingsRouter = () => {
    return (
        <Fragment>
            <Route id="settings" element={<SettingsLayout />} errorElement={<SettingsError />}>
                <Route path="settings">
                    <Route index element={<SettingsHome />} />

                    <Route path="scores" element={<Scores />} />
                    <Route path="campaigns" element={<Campaigns />} />
                    <Route path="product-line" element={<ProductLineList />} />
                    <Route path="company-sectors" element={<CompanySectorList />} />

                    <Route path="csrTheme">
                        <Route index element={<CsrTheme />} />
                        <Route path="create" element={<CsrThemeCreate />} />
                        <Route path=":csrThemeId/edit" element={<CsrThemeEdit />} />
                    </Route>

                    <Route path="companies">
                        <Route index element={<CompaniesList type={["main", "branch"]} />} />
                        <Route path=":id" element={<Company />} />
                        <Route path="create" element={<CompanyCreate type={["main", "branch"]} />} />
                        <Route path=":id/edit" element={<CompanyEdit type={["main", "branch"]} />} />
                    </Route>

                    <Route path="providers">
                        <Route index element={<ProvidersList type={["provider"]} />} />
                        <Route path="create" element={<CompanyCreate type={["provider"]} />} />
                        <Route path=":id/edit" element={<CompanyEdit type={["provider"]} />} />
                    </Route>

                    <Route path="users">
                        <Route index element={<UsersList />} />
                        <Route path=":id" element={<User />} />
                        <Route path="create" element={<UserCreate />} />
                        <Route path=":id/edit" element={<UserEdit />} />
                    </Route>

                    <Route path="infos" element={<SettingsInfos />} />
                    <Route path="applicationParameters" element={<AppParamsSettings />} />

                    <Route path="brands">
                        <Route index element={<BrandList />} />
                        <Route path="create" element={<BrandCreate />} />
                        <Route path=":brandId/edit" element={<BrandEdit />} />
                    </Route>

                    <Route path="ingredient-line">
                        <Route index element={<IngredientLineList />} />
                        <Route path="create" element={<IngredientLineCreate />} />
                        <Route path=":ingredientLineId/edit" element={<IngredientLineEdit />} />
                    </Route>

                    <Route path="roles">
                        <Route index element={<RolesList />} />
                        <Route path="create" element={<RoleCreate />} />
                        <Route path=":roleCode/edit" element={<RoleEdit />} />
                    </Route>

                    <Route path="cms/media" element={<MediaSettings />} />
                    <Route path="cms" element={<CMSSettingsContext />}>
                        <Route path=":pageId" element={<CMSPageSettings />} />
                        <Route path=":pageId/sections/:sectionId" element={<EditSections />} />
                    </Route>

                    <Route path="csrStrategy" element={<StrategySettingsContext />}>
                        <Route path="general" element={<MainLayoutTabs selectedSection="general" />}>
                            <Route index element={<GeneralInfo />} />
                        </Route>
                        <Route path="pillars" element={<MainLayoutTabs selectedSection="pillars" />}>
                            <Route index element={<Pillars />} />
                        </Route>

                        <Route path="pillars/new" element={<NewPillar />} />
                        <Route path="pillars/:pillarId" element={<EditPillar />} />
                    </Route>
                </Route>
            </Route>
        </Fragment>
    );
};

const StrategySettingsContext = () => {
    return (
        <SettingsStrategyProvider>
            <Outlet />
        </SettingsStrategyProvider>
    );
};

const CMSSettingsContext = () => {
    return (
        <SettingsCMSProvider>
            <Outlet />
        </SettingsCMSProvider>
    );
};

const SettingsError = () => {
    const error = useRouteError();
    if (isRouteErrorResponse(error)) {
        if (error.status === 404) {
            return <div>Page inexistante 404</div>;
        } else if (error.status === 401) {
            return <div>Unauthorized: 401</div>;
        } else if (error.status === 503) {
            return <div>API down: 503</div>;
        } else if (error.status === 500) {
            return <div>API error: 500</div>;
        }
    }
    console.error(error);
    return <div>Hello this is an Error from settings</div>;
};
