import { ReactNode, createContext, useContext, useEffect, Dispatch, SetStateAction, useState } from "react";
import { useParams } from "react-router-dom";
import { Ingredient, useGetOneIngredient } from "src/api/client-api/ingredients/useGetOneIngredient";
import { useGetIngredientPosts } from "src/api/client-api/post/useGetIngredientPosts";
import { useGetOneIngredientReference } from "src/api/sure-api/ingredientReference/useGetOneIngredientReference";
import { useGetOneSsq } from "src/api/sure-api/ssq/useGetOneSsq";
import { Post } from "src/api/client-api/post/postSchema";
import { Ssq } from "src/api/sure-api/ssq/useGetOneSsq";
import { useFoodpilotContext } from "src/context/FoodpilotContext";
import { IngredientReference } from "src/api/sure-api/ingredientReference/useGetAllIngredientReference";

type IngredientContextInitialValues = {
    ingredient: Ingredient | undefined;
    ingredientReference: IngredientReference | undefined;
    variations: Record<number, number>;
    setVariations: (variations: Record<number, number>) => void;
    isLoading: boolean;
    isRefetching: boolean;

    ssq: Ssq | undefined;
    isIngRefLoading: boolean;
    isPostsLoading: boolean;
    posts: Post[] | undefined;

    canModifySSQ: boolean;
    activeSupplierId: number | null;
    setActiveSupplierId: Dispatch<SetStateAction<number | null>>;

    fetchPosts: () => void;
};
const IngredientContext = createContext<IngredientContextInitialValues | null>(null);

type IngredientContextProviderProps = { children: ReactNode };
export const IngredientContextProvider = (props: IngredientContextProviderProps) => {
    const { children } = props;
    const { ingredientId, supplierId } = useParams();
    const { isUserOfTypeSupplier } = useFoodpilotContext();
    const { ...ingredientsData } = useAllIngredientData(ingredientId);

    const canModifySSQ = (ingredientsData.ingredient?.canModify ?? false) && isUserOfTypeSupplier();
    const [activeSupplierId, setActiveSupplierId] = useState<number | null>(null);

    useEffect(() => {
        if (supplierId === undefined) return;
        setActiveSupplierId(Number(supplierId));
    }, [supplierId]);

    const items: IngredientContextInitialValues = {
        ...ingredientsData,
        canModifySSQ,
        activeSupplierId,
        setActiveSupplierId,
    };

    return <IngredientContext.Provider value={items}>{children}</IngredientContext.Provider>;
};

export const useIngredientContext = () => {
    const context = useContext(IngredientContext);

    if (context) {
        return context;
    }

    throw new Error(`useIngredientContext must be used within an IngredientContextProvider`);
};

const useAllIngredientData = (ingredientId: string | undefined) => {
    const [variations, setVariations] = useState<Record<number, number>>({});

    const { currentCampaign, selectedCompany } = useFoodpilotContext();
    const { ingredient, isLoading, isRefetching } = useGetOneIngredient(ingredientId, {
        campaign: currentCampaign?.id,
        company_id: selectedCompany?.id,
        dimensions: [["suppliers"]],
        variations: variations,
    });

    const { ingredientReference, isLoading: isIngRefLoading } = useGetOneIngredientReference(
        ingredient?.ingredientReferenceId,
    );

    const { ssq, isLoading: isPostsLoading } = useGetOneSsq(ingredientReference?.ssq?.id);

    const { posts, refetch: fetchPosts } = useGetIngredientPosts(ingredient?.id, currentCampaign?.id);

    return {
        ingredient,
        isLoading,
        isRefetching,
        ingredientReference,
        variations,
        setVariations,
        ssq,
        posts,
        isIngRefLoading,
        isPostsLoading,
        fetchPosts,
    };
};
