import { DataListOption } from "src/api/sure-api/ssq/useGetOneSsq";
import { Property } from "src/api/sure-api/ssq/useGetOneSsq";

// Option finder functions
export const findOptionById = (options: DataListOption[], id: number | undefined): DataListOption | undefined => {
    if (!id) return undefined;
    return options.find((option) => option.id === id) ?? undefined;
};

export const findOptionsByIds = (options: DataListOption[], ids: number[] = []): DataListOption[] => {
    return options.filter((option) => ids.includes(option.id));
};

export const findOptionByValue = (options: DataListOption[], value: string | undefined): DataListOption | undefined => {
    if (!value) return undefined;
    return options.find((option) => option.value === value) ?? undefined;
};

export const findOptionsByValues = (options: DataListOption[], values: string[]): DataListOption[] => {
    if (!values.length) return [];
    return options.filter((option) => values.includes(option.value));
};

// Property utility functions
export const getPropertyDefaultOption = (property: Property, options: DataListOption[]): DataListOption | undefined => {
    if (!property.defaultValue) {
        return undefined;
    }
    return findOptionById(options, Number(property.defaultValue));
};

export const getPropertyDefaultOptions = (property: Property, options: DataListOption[]): DataListOption[] => {
    if (!property.defaultValue) {
        return [];
    }

    const ids = property.defaultValue.split("|").map((id) => Number(id));

    return findOptionsByIds(options, ids);
};

export const getPropertyGenericOption = (property: Property, options: DataListOption[]): DataListOption | undefined => {
    if (!property.genericValue) {
        return undefined;
    }
    return findOptionById(options, Number(property.genericValue));
};

export const getPropertyGenericOptions = (property: Property, options: DataListOption[]): DataListOption[] => {
    if (!property.genericValue) {
        return [];
    }

    const ids = property.genericValue.split("|").map((id) => Number(id));

    return findOptionsByIds(options, ids);
};

// Option value utility functions
export const getOptionValue = (option: DataListOption | null | undefined): string => {
    return option?.value ?? "_empty_";
};

export const getOptionValues = (options: DataListOption[]): string => {
    return options.map((opt) => opt.value).join("|");
};

export const getOptionIds = (options: DataListOption[]): number[] => {
    return options.map((opt) => opt.id);
};
