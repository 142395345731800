import { Fragment } from "react";
import { Navigate, Outlet, Route, isRouteErrorResponse, useRouteError } from "react-router-dom";
import { IngredientList } from "./IngredientsList";
import { CreateIngredientPage } from "./IngredientForm/CreateIngredient";
import { UpdateQuestionnaire } from "./IngredientForm/UpdateIngredient";
import { useTranslation } from "react-i18next";
import { QuestionnaireUpdate } from "./IngredientDetails/Questionnaire/QuestionnaireUpdate/QuestionnaireUpdate";
import { IngredientContextProvider, useIngredientContext } from "./IngredientDetails/ExistingIngredientContext";
import { IngredientPrimaryTabs } from "./IngredientDetails/IngredientPrimaryTabs";
import { Forbiddenpage } from "src/components/PageState/ForbiddenPage";
import { CREATE, INGREDIENT, LIST, MODIFY, useFoodpilotContext, VIEW } from "src/context/FoodpilotContext";
import { Slugs } from "src/context/ProtectedContext";
import { URLHandler } from "src/services/URLHandler";
import { NoSupplier } from "./IngredientDetails/Questionnaire/IncompleteData/NoSupplier";
import { IngredientScores } from "./IngredientDetails/IngredientScores";
import { Questionnaire } from "./IngredientDetails/Questionnaire/Questionnaire";
import { IngredientActivitiesData } from "./IngredientDetails/IngredientActivitiesData";
import { Informations } from "./IngredientDetails/Informations";

export const useIngredientRouter = () => {
    return (
        <Fragment>
            <Route path="ingredients" errorElement={<IngredientError />}>
                <Route element={<IngredientActionProtection slug={LIST} />}>
                    <Route index element={<IngredientList />} />
                </Route>

                <Route path={"new"} element={<IngredientActionProtection slug={CREATE} />}>
                    <Route element={<CreateIngredientPage />}>
                        <Route index />
                        <Route path={":startingPageId"} />
                    </Route>
                </Route>

                <Route path=":ingredientId" element={<ExistingIngredientLayout />}>
                    <Route path={"edit"} element={<IngredientActionProtection slug={MODIFY} />}>
                        <Route element={<UpdateQuestionnaire />}>
                            <Route index />
                            <Route path={":startingPageId"} />
                        </Route>
                    </Route>

                    <Route element={<IngredientActionProtection slug={VIEW} />}>
                        <Route index element={<IngredientViewRedirect />} />

                        <Route path={"scores"} element={<IngredientPrimaryTabs selectedSection={"scores"} />}>
                            <Route index element={<IngredientScores />} />
                        </Route>

                        <Route
                            path={"questionnaire"}
                            element={<IngredientPrimaryTabs selectedSection={"questionnaire"} />}
                        >
                            <Route index element={<QuestionnaireRedirect />} />
                            <Route path="supplier/:supplierId">
                                <Route index element={<Questionnaire />} />
                            </Route>
                        </Route>
                        <Route
                            path="questionnaire/supplier/:supplierId/edit/:headingId"
                            element={<QuestionnaireUpdate />}
                        />

                        <Route path={"activities"}>
                            <Route element={<IngredientPrimaryTabs selectedSection={"activities"} />}>
                                <Route index element={<IngredientActivitiesData />} />
                            </Route>
                        </Route>

                        <Route path={"informations"}>
                            <Route element={<IngredientPrimaryTabs selectedSection={"informations"} />}>
                                <Route index element={<Informations />} />
                            </Route>
                        </Route>
                    </Route>
                </Route>
            </Route>
        </Fragment>
    );
};

const IngredientViewRedirect = () => {
    const { selectedCompany } = useFoodpilotContext();
    const { ingredient, activeSupplierId } = useIngredientContext();

    if (ingredient === undefined) return;
    if (selectedCompany === null) return;

    const suppliers = ingredient.ingredientSuppliers ?? [];
    const supplierId = suppliers.length > 0 ? activeSupplierId ?? suppliers[0].supplier.id : null;

    const url =
        supplierId === null ?
            URLHandler.ingredient.scores(selectedCompany.id, ingredient.id)
        :   URLHandler.ingredient.ssq(selectedCompany.id, ingredient.id, supplierId);

    return <Navigate to={url} replace />;
};

const QuestionnaireRedirect = () => {
    const { ingredient } = useIngredientContext();

    if (ingredient === undefined) return;

    const ingredientSuppliers = ingredient?.ingredientSuppliers ?? [];
    if (ingredientSuppliers.length === 0) {
        return (
            <Fragment>
                <Outlet />
                <NoSupplier />
            </Fragment>
        );
    }

    return <Outlet />;
};

type IngredientActionProtectionProps = {
    slug: Slugs;
};
const IngredientActionProtection = (props: IngredientActionProtectionProps) => {
    const { slug } = props;
    const { canUser } = useFoodpilotContext();

    if (canUser(slug, INGREDIENT) === false) {
        return <Forbiddenpage />;
    }

    return <Outlet />;
};

const ExistingIngredientLayout = () => {
    return (
        <IngredientContextProvider>
            <Outlet />
        </IngredientContextProvider>
    );
};

const IngredientError = () => {
    const error = useRouteError();
    const { t } = useTranslation();

    if (isRouteErrorResponse(error)) {
        if (error.status === 404) {
            return <div>{t("Page inexistante")} 404</div>;
        } else if (error.status === 401) {
            return <div>{t("Unauthorized")}: 401</div>;
        } else if (error.status === 503) {
            return <div>API down: 503</div>;
        } else if (error.status === 500) {
            return <div>API error: 500</div>;
        }
    }
    console.error(error);
    return <div>Hello this is an Error from Ingredient</div>;
};
