import { Outlet, Route } from "react-router-dom";
import { Fragment } from "react/jsx-runtime";
import { StrategyProvider } from "src/context/Strategy/provider";
import { StrategyList } from "./List/StrategyList";

export const useStrategyRouter = () => {
    return (
        <Fragment>
            <Route path="strategy" element={<_StrategyProvider />}>
                <Route index element={<StrategyList />} />
            </Route>
        </Fragment>
    );
};

const _StrategyProvider = () => {
    return (
        <StrategyProvider>
            <Outlet />
        </StrategyProvider>
    );
};
