import { FoodsCheckbox } from "@foodpilot/foods";
import { Stack, Typography, useTheme } from "@mui/material";
import { useEffect } from "react";
import { CsrStrategy } from "src/api/client-api/csrStrategy/useGetAllStrategy";
import { SelectedIndicators } from "./AddStrategyIndicatorModal";

type IndicatorFormFieldsProps = {
    strategy: CsrStrategy;
    resetAction: () => void;
    selectedIndicators: SelectedIndicators;
    setSelectedIndicators: (newIndicators: SelectedIndicators) => void;
};
export const StrategyIndicatorFormFields = (props: IndicatorFormFieldsProps) => {
    const theme = useTheme();
    const { strategy, resetAction, selectedIndicators, setSelectedIndicators } = props;

    useEffect(() => {
        // Everytime we reopen the Modal, this useEffect is triggered.
        // And we use it to reset the original state;
        resetAction();
    }, []);

    return (
        <Stack gap={theme.spacing(2)} padding={theme.spacing(2, 4)}>
            {strategy.pillars.flatMap((pillar, pillarIndex) => {
                if (pillar.indicators.length === 0) return [];

                const selectedItems = pillar.indicators.filter((ind) =>
                    selectedIndicators[pillarIndex]?.includes(ind["@id"]),
                );
                return (
                    <Stack key={`pillar-${pillarIndex}`}>
                        <Typography variant="big-bold">{pillar.name}</Typography>
                        <FoodsCheckbox
                            selectedItems={selectedItems}
                            values={pillar.indicators}
                            getId={(item) => item["@id"]}
                            getName={(item) => item["name"]}
                            onChange={(selectedValues) => {
                                const newValues = selectedValues.map((item) => item["@id"]);
                                setSelectedIndicators({
                                    ...selectedIndicators,
                                    [pillarIndex]: newValues,
                                });
                            }}
                        />
                    </Stack>
                );
            })}
        </Stack>
    );
};
