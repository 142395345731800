import { useQuery } from "react-query";
import { loggedInApiCall } from "src/api/axiosClient/userApi";
import { z } from "zod";
import { zodParseWithLog } from "../../apiErrorLogs";

export const getSomeActionPost = "getSomeActionPost" as const;

type Options = {
    ids: number[];
    ssqPostId: number | undefined;
};

export const useGetSomeActionPost = (options: Options) => {
    const { ids, ssqPostId } = options;

    const paginationParams = { page: 1, itemsPerPage: ids.length * 100 };
    const query = useQuery({
        queryKey: [getSomeActionPost, ids, ssqPostId],
        enabled: ssqPostId !== undefined,
        queryFn: async () => {
            const url = `/api/action_ssq_posts`;

            return await loggedInApiCall.get(url, {
                params: {
                    ...paginationParams,
                    actionId: ids,
                    "ssqPost.id": ssqPostId,
                },
            });
        },
        select: (request) => {
            const actionSsqPosts = request.data["hydra:member"];
            return zodParseWithLog(actionSsqPosts, ActionSsqPostsSchema.array(), getSomeActionPost);
        },
    });

    return {
        actionSsqPosts: query.data,
        ...query,
    };
};

export type ActionSsqPosts = {
    actionId: number;
    ssqPost: {
        id: number;
    };
};

const ActionSsqPostsSchema: z.ZodType<ActionSsqPosts> = z.object({
    actionId: z.number(),
    ssqPost: z.object({
        id: z.number(),
    }),
});
