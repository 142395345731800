import { ActionsBlock, ActionsBlockProps, BlockItemContainer } from "@foodpilot/foods";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useCreateActionPost } from "src/api/client-api/actionPost/useCreateActionPost";
import { ActionsDataMapping } from "src/context/Actions/context";
import { useActionsContext } from "src/context/Actions/useActionsContext";
import { useResetAction } from "src/context/Actions/useResetAction";
import { ScoreService } from "src/context/EntityBasedScore/ScoreService";
import { useFoodpilotContext } from "src/context/FoodpilotContext";
import { useSnackbar } from "src/utils/useSnackbar";

export type ActionBlockProps = {
    sectionTitle: string;
    groupActions: {
        actionId: number;
        title: string;
    }[];
    actionsData: ActionsDataMapping | undefined;
};
export const ActionBlock = (props: ActionBlockProps) => {
    const { groupActions, sectionTitle, actionsData } = props;
    const navigate = useNavigate();
    const { t } = useTranslation();
    const createActionPost = useCreateActionPost();
    const resetAction = useResetAction();
    const snackbar = useSnackbar();
    const { selectedCompany } = useFoodpilotContext();
    const { urls, selectedSimulation, actionSsqPosts, selectedScore, savingStatus } = useActionsContext();

    const acc: { totalScore: number | null; actionBlocks: ActionsBlockProps["actionBlocks"] } = {
        actionBlocks: [],
        totalScore: null,
    };
    const actionSsqPostsById = Object.groupBy(actionSsqPosts ?? [], (item) => item.actionId);

    // Kilian note to self: I think instead of this groupBy above and all
    // we can/should use actionsDataByCsr
    const actionBlocksWithTotal = groupActions.reduce((previousAcc, actionItem) => {
        const item = actionSsqPostsById?.[actionItem.actionId];
        const isFound = item !== undefined && item.length > 0;

        const scoreBlock = actionsData?.[actionItem.actionId]?.totalScore ?? null;
        const unitToDisplay = scoreBlock === null || selectedScore?.unit === undefined ? "" : selectedScore.unit;

        const actionBlock = {
            id: actionItem.actionId,
            actionTitle: actionItem.title,
            value: scoreBlock,
            unit: unitToDisplay,
            isCreated: isFound,
        };
        previousAcc["actionBlocks"].push(actionBlock);

        //
        previousAcc["totalScore"] = ScoreService.addScore(previousAcc["totalScore"], scoreBlock);

        return previousAcc;
    }, acc);

    return (
        <BlockItemContainer
            title={sectionTitle}
            value={actionBlocksWithTotal.totalScore}
            unit={selectedScore?.unit}
            fields={[
                <ActionsBlock
                    actionBlocks={actionBlocksWithTotal.actionBlocks}
                    addAction={{
                        helperText: t("action.addAction"),
                        action: (option) => {
                            if (selectedSimulation === null) return;
                            // Backend doesn't send it...
                            const hardcodedIRI = `/api/ssq_posts/${selectedSimulation.id}`;
                            createActionPost.mutate(
                                {
                                    actionId: Number(option.id),
                                    ssqPostIRI: hardcodedIRI,
                                },
                                {
                                    onSuccess: () => {
                                        const url = urls.editPlanning(option.id);
                                        navigate(url);
                                    },
                                    onError: () => {
                                        snackbar.forceClose();
                                        snackbar.setSnackbarProps({
                                            type: "error",
                                            message: t("actionPlan.takeAction.error"),
                                        });
                                    },
                                },
                            );
                        },
                    }}
                    editAction={{
                        helperText: t("action.modifyAction"),
                        action: (option) => {
                            const url = urls.editPlanning(option.id);
                            navigate(url);
                        },
                    }}
                    deleteAction={{
                        helperText: t("action.deleteAction"),
                        action: (option) => {
                            if (selectedSimulation === null) return;

                            const actionData = actionsData?.[option.id];
                            savingStatus.setSaving(true);
                            resetAction.mutate(
                                {
                                    actionId: option.id,
                                    postId: selectedSimulation.id,
                                    allActionsData: actionData,
                                },
                                {
                                    onSuccess: () => {
                                        if (selectedCompany === null) return;

                                        const url = urls.planning();
                                        navigate(url);
                                    },
                                    onSettled: () => {
                                        savingStatus.setSaving(false);
                                    },
                                },
                            );
                        },
                    }}
                />,
            ]}
        />
    );
};
