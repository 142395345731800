import { BlockOptions, RoundInput, TextBlock } from "@foodpilot/foods";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { UpdateProperty, useUpdateProperty } from "src/api/client-api/property/useUpdateProperty";
import { ActionPropertiesCoreAnswers } from "src/context/Actions/ActionService";
import { useActionsContext } from "src/context/Actions/useActionsContext";

export type TextProps = {
    disableSubtitle?: boolean;
    propertyWithElements: ActionPropertiesCoreAnswers;
    position?: number;
    raw?: boolean;
};
export const Text = (props: TextProps) => {
    const { t } = useTranslation();
    const { propertyWithElements, position = 0, raw = false } = props;
    const { startingPost, lastExistingPost, selectedSimulation, savingStatus } = useActionsContext();
    const updateProperty = useUpdateProperty();

    const previousElement = propertyWithElements.previousElements?.find((element) => element.position === position);
    const currentElement = propertyWithElements.currentElements?.find((element) => element.position === position);
    const targetElement = propertyWithElements.targetElements?.find((element) => element.position === position);

    const initialValue = targetElement?.value;
    const [value, setValue] = useState(initialValue);

    const previousComparisonValue = {
        year: t("actionPlan.value_in_year", { year: startingPost?.post?.campaign?.label }),
        value: previousElement?.value ?? "",
    };
    const currentComparisonValue =
        lastExistingPost !== undefined ?
            {
                year: t("actionPlan.value_in_year", { year: lastExistingPost.campaign }),
                value: currentElement?.value ?? "",
            }
        :   undefined;

    const title = props.disableSubtitle === false ? propertyWithElements.property.title ?? "" : "";
    const blockOptions: BlockOptions = {
        rightSubtitle: `${t("Goal")} ${selectedSimulation?.campaign?.label}`,
        title: title,
        comparisonValues: {
            start: previousComparisonValue,
            current: currentComparisonValue,
        },
    };

    const TextComponent = raw ? RoundInput : TextBlock;

    return (
        <TextComponent
            blockOptions={blockOptions}
            value={value}
            onChange={(e) => {
                setValue(e.target.value);
            }}
            onBlur={(e) => {
                const relatedTarget = e.nativeEvent.relatedTarget as Element;
                const isTargetPopover = relatedTarget && relatedTarget.classList.contains("MuiPopover-paper");

                if ((e.nativeEvent.type === "focusout" && isTargetPopover) || value === initialValue) {
                    return;
                }

                if (selectedSimulation === null) return;
                const payload: UpdateProperty = {
                    postId: selectedSimulation?.id,
                    isGenericValue: false,
                    position: position,
                    propertyId: propertyWithElements.property.id,
                    value: value ?? "",
                    calculationType: "action",
                };
                savingStatus.setSaving(true);
                updateProperty.mutate(payload, {
                    onSettled: () => {
                        savingStatus.setSaving(false);
                    },
                });
            }}
        />
    );
};
