import { z } from "zod";

export type ScoreVariation = {
    id: number;
    label: string;
    unit: string;
    default_display: boolean;
};

export type ScoreDimensionBaseValue = {
    score: number | null;
    label: string;
};

export type ScoreDimensionValue = ScoreDimensionBaseValue & {
    dimensions?: ScoreDimension[];
};

export type ScoreDimension = {
    label: string;
    values: Record<string, ScoreDimensionValue>;
};

export type Score = {
    score: number | null;
    unit: string;
    label: string;
    default_display: boolean;
    variations: ScoreVariation[];
    dimensions: ScoreDimension[];
};

export type DeprecatedEmptyScore = Record<string, never>;
export type ScoreOrEmpty = Score | null | DeprecatedEmptyScore;
export type NonEmptyScore = Score & { score: number };

export type Scores = Partial<{ [scoreId: string]: Score | null | DeprecatedEmptyScore }>;

export const doesScoreExist = (score?: ScoreOrEmpty): score is NonEmptyScore => {
    return score !== undefined && score !== null && "score" in score && score.score !== null;
};

export const ScoreVariationSchema = z.object({
    id: z.number(),
    label: z.string(),
    unit: z.string(),
    default_display: z.boolean(),
});

export const ScoreDimensionBaseValueSchema = z.object({
    score: z.number().nullable(),
    label: z.string(),
});

export const ScoreDimensionValueSchema: z.ZodType<ScoreDimensionValue> = ScoreDimensionBaseValueSchema.extend({
    dimensions: z.array(z.lazy(() => ScoreDimensionSchema)).optional(),
});

export const ScoreDimensionSchema = z.object({
    label: z.string(),
    values: z.record(z.string(), ScoreDimensionValueSchema),
});

export const ScoreSchema = z.object({
    score: z.number().nullable(),
    unit: z.string(),
    label: z.string(),
    default_display: z.boolean(),
    variations: ScoreVariationSchema.array(),
    dimensions: ScoreDimensionSchema.array(),
});

export const DeprecatedEmptyScoreSchema = z.record(z.never());
export const ScoreOrEmptySchema = ScoreSchema.or(z.null()).or(DeprecatedEmptyScoreSchema);
export const ScoresSchema = z.record(z.string(), ScoreOrEmptySchema);

// const score = {
//     "XX" : {
//         score: 11,
//         unit: "mPt/kg",
//         label: "par kg de produit"
//         default_display: true,
//         dimensions: [],
//         variations: [
//             { id: 1, default_display: true, label: "" }
//         ]
//     },
// }

// const LastLayerDimensionScore = {
//     label: "dimensionLayerName",
//     values: {
//         199: {
//             label: "human-readable string",
//             score: 1212,
//         }
//     }
// }

// const DimensionScoreWithInnerLayers = {
//     label: "dimensionLayerName",
//     values: {
//         199: {
//             label: "human-readable string",
//             score: 1212,
//             dimensions: [
//                 LastLayerDimensionScore,
//             ]
//         }
//     }
// }

// const scoresWithDimensions = {
//     "XX": {
//         unit: "mPt/kg",
//         score: 11,

//         // label is human-readable string;
//         label: "par kg de produits",
//         default_display: true,
//         dimensions: [ DimensionScore ],
//         variations: [
//             { id: 1, default_display: true, label: "" },
//         ],
//     }
// }
