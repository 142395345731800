import { ScoreDetailContext } from "./context";
import { useCallback, useEffect, useState } from "react";
import {
    DeprecatedEmptyScore,
    doesScoreExist,
    Score,
    ScoreDimension,
    ScoreDimensionBaseValue,
    ScoreDimensionValue,
} from "src/api/client-api/post/scoreSchema.ts";
import { useParams, useSearchParams } from "react-router-dom";
import { Ssq } from "src/api/sure-api/ssq/useGetOneSsq.ts";
import { useFoodpilotContext } from "src/context/FoodpilotContext.tsx";

const additionalDimensions = "components";
const additionalDimensionValueName = "ingredients";

const makeListFromEntries = (entries: [string, ScoreDimensionValue][]): Record<string, ScoreDimensionValue> => {
    return Object.fromEntries(entries);
};

const filterNullElements = (list: Record<string, ScoreDimensionValue>): Record<string, ScoreDimensionValue> => {
    return makeListFromEntries(Object.entries(list).filter(([_, value]) => value.score !== null));
};

const sortDimensions = (order?: string[], dimensions?: ScoreDimension[]) => {
    if (order && dimensions) {
        dimensions.sort((a, b) => {
            const indexA = order.indexOf(a.label);
            const indexB = order.indexOf(b.label);
            return indexA - indexB;
        });
    }

    return dimensions || [];
};

type ScoreDetailContextProviderProps = {
    children: JSX.Element | JSX.Element[];
    scores: Partial<Record<string, Score | null | DeprecatedEmptyScore>>;
    score?: ScoreDimensionValue;
    prevScores?: Partial<Record<string, Score | null | DeprecatedEmptyScore>>;
    ssq?: Ssq;
    order?: string[];
};
export const ScoreDetailContextProvider = (props: ScoreDetailContextProviderProps) => {
    const { children, scores = {}, score, prevScores = {}, ssq, order } = props;

    const [searchParams] = useSearchParams();
    const urlRequestedDimension = new URLSearchParams(searchParams).get("dimensions");

    const [selectedDimensionIndex, setSelectedDimensionIndex] = useState<number[]>([0]);
    const [selectedValueIndex, setSelectedValueIndex] = useState<string[]>([]);
    const [selectedChartItemIndex, setSelectedChartItemIndex] = useState<number | undefined>();
    const [selectedDimension, setSelectedDimension] = useState<string | undefined>();
    const [selectedScore, setSelectedScore] = useState<ScoreDimensionValue | undefined>(score);

    const { scores: scoreDefinitions = [] } = useFoodpilotContext();
    const { scoreId: scoreIdString } = useParams<{ scoreId: string }>();

    if (!scoreIdString) {
        throw new Error("Missing 'scoreId' parameter in the URL.");
    }

    const scoreId = Number(scoreIdString);

    if (!scores[scoreId]) {
        throw new Error(`Score with id ${scoreId} does not exist`);
    }

    useEffect(() => {
        if (scores[scoreId] !== undefined && doesScoreExist(scores[scoreId])) {
            setSelectedScore(scores[scoreId]);
        }
    }, [scoreId, scores]);

    useEffect(() => {
        if (urlRequestedDimension) {
            const dimensionIndex = sortDimensions(order, scores[scoreId]?.dimensions).findIndex(
                (dimension) => dimension.label === urlRequestedDimension,
            );

            dimensionIndex > -1 ? setSelectedDimensionIndex([dimensionIndex]) : setSelectedDimensionIndex([0]);
            setSelectedValueIndex([]);
        }

        if (scores[scoreId] !== undefined && doesScoreExist(scores[scoreId])) {
            setSelectedScore(scores[scoreId]);
        }
    }, [order, scoreId, scores, urlRequestedDimension]);

    useEffect(() => {
        const selection = urlRequestedDimension || sortDimensions(order, scores[scoreId]?.dimensions)[0]?.label;
        setSelectedDimension(selection);
    }, [scores, scoreId, urlRequestedDimension, order]);

    const addSelectedValue = (
        valueIndex: string,
        dimensionIndex: number,
        score: ScoreDimensionValue,
        itemIdx: number,
    ) => {
        setSelectedValueIndex([...selectedValueIndex, valueIndex]);
        setSelectedDimensionIndex([...selectedDimensionIndex, dimensionIndex]);
        setSelectedScore(score);
        setSelectedChartItemIndex(itemIdx);
    };

    const getDimension = (i: number = 0, dimensions: ScoreDimension[] = []): ScoreDimension | null => {
        if (!selectedScore) {
            return null;
        }

        const dimensionsList = dimensions.length > 0 ? dimensions : sortDimensions(order, scores[scoreId]?.dimensions);
        const selectedDimension = selectedDimensionIndex[i];
        const selectedValue = selectedValueIndex[i];

        if (selectedDimension === undefined) {
            return null;
        }

        const dimension = dimensionsList?.[selectedDimension];

        if (Array.isArray(dimension?.values[selectedValue]?.dimensions)) {
            return getDimension(i + 1, dimension.values[selectedValue].dimensions);
        }

        return dimension ?? null;
    };

    const filterHiddenElements = (
        list: Record<string, ScoreDimensionBaseValue>,
    ): Record<string, ScoreDimensionBaseValue> => {
        if (selectedDimension === "headings") {
            // filter hidden headings
            if (selectedValueIndex.length === 0) {
                return makeListFromEntries(
                    Object.entries(list).filter(([headingId]) => {
                        const heading = ssq?.headings.find((heading) => heading.id === Number(headingId));
                        return heading?.hidden === false;
                    }),
                );
            }

            // filter hidden properties
            if (selectedValueIndex.length === 1) {
                const headingId = Number(selectedValueIndex.at(0));

                const heading = ssq?.headings.find((heading) => heading.id === headingId);

                const hiddenProperties =
                    heading?.ssqHeadingSsqProperties.reduce<number[]>((prev, curr) => {
                        if (curr.hidden) {
                            prev.push(curr.ssqProperty.id);
                        }

                        return prev;
                    }, []) || [];

                return makeListFromEntries(
                    Object.entries(list).filter(([key]) => {
                        return !hiddenProperties.includes(Number(key));
                    }),
                );
            }
        }

        return list;
    };

    const filterMultivalueElements = (
        list: Record<string, ScoreDimensionValue>,
    ): Record<string, ScoreDimensionValue> => {
        if (selectedDimension === "headings" && selectedValueIndex.length === 1) {
            const headingId = Number(selectedValueIndex.at(0));

            const heading = ssq?.headings.find((heading) => heading.id === headingId);

            const excluded =
                heading?.properties
                    .filter((property) => {
                        return property.multivalue === true || property.field?.type === "group";
                    })
                    .map((property) => {
                        return property.children.map((child) => child.id);
                    })
                    .flat() || [];

            return makeListFromEntries(Object.entries(list).filter(([key, _]) => !excluded.includes(Number(key))));
        }

        return list;
    };

    const getPrevScore = (i: number = 0, score?: ScoreDimensionValue): ScoreDimensionValue | null => {
        const prevScore = score || prevScores[scoreId];
        if (!prevScore) {
            return null;
        }

        const selectedDimension = selectedDimensionIndex[i];
        const selectedValue = selectedValueIndex[i];

        if (selectedValue !== undefined && prevScore.dimensions !== undefined) {
            const dimension = prevScore.dimensions[selectedDimension];

            if (
                Array.isArray(dimension?.values[selectedValue]?.dimensions) &&
                dimension.values[selectedValue].dimensions.length > 0
            ) {
                return getPrevScore(i + 1, dimension.values[selectedValue]);
            }
        }

        return prevScore as ScoreDimensionValue;
    };

    const findAdditionalScore = () => {
        const additionalScore = getMainScore()?.dimensions.find(
            (dimension) => dimension.label === additionalDimensions,
        );

        return additionalScore && additionalDimensionValueName in additionalScore.values ?
                additionalScore.values[additionalDimensionValueName]
            :   null;
    };

    const findPrecision = () => {
        return scoreDefinitions.find((score) => score.id === scoreId)?.precision;
    };

    const getMainScore = (): Score | DeprecatedEmptyScore | undefined | null => {
        return scores[scoreId];
    };

    const getScoreValue = (): number => {
        if (selectedDimension === "lca") {
            const dimension = getMainScore()?.dimensions.find((dimension) => dimension.label === "lca");

            if (dimension) {
                return Number(
                    Object.values(dimension.values)
                        .reduce((acc, value) => acc + (value.score || 0), 0)
                        .toFixed(findPrecision()),
                );
            }

            return 0;
        }

        return Number((selectedScore?.score ?? 0).toFixed(findPrecision()));
    };

    const getPrevScoreValue = (): number => {
        return Number((getPrevScore()?.score || 0).toFixed(findPrecision()));
    };

    const getUnit = () => {
        return getMainScore()?.unit;
    };

    const depthLevel = selectedValueIndex.length;
    const shouldIncludeAdditionalScore = () => {
        return !ssq?.monolithic && depthLevel === 0 && selectedDimension === "headings";
    };

    const reset = useCallback(() => {
        setSelectedValueIndex([]);
        setSelectedDimensionIndex([0]);
        setSelectedDimension(undefined);
        setSelectedScore(score);
        setSelectedChartItemIndex(undefined);
    }, [score]);

    const additionalScore = shouldIncludeAdditionalScore() ? findAdditionalScore() : null;

    const dimension = getDimension();

    const filteredList =
        dimension !== null ? filterNullElements(filterHiddenElements(filterMultivalueElements(dimension.values))) : {};

    return (
        <ScoreDetailContext.Provider
            value={{
                dimension,
                addSelectedValue,
                depthLevel: selectedValueIndex.length,
                selectedDimensionIndex,
                selectedValueIndex,
                selectedChartItemIndex,
                setSelectedChartItemIndex,
                urlRequestedDimension,
                list: filteredList,
                setSelectedDimensionIndex,
                setSelectedValueIndex,
                prevScore: getPrevScore(),
                scorePrecision: findPrecision(),
                selectedDimension,
                setSelectedDimension,
                selectedScore,
                setSelectedScore,
                getMainScore,
                getScoreValue,
                getPrevScoreValue,
                getUnit,
                ssq,
                additionalScore,
                reset,
            }}
        >
            {children}
        </ScoreDetailContext.Provider>
    );
};
