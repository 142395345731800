import { useQuery } from "react-query";
import { loggedInApiCall } from "src/api/axiosClient/userApi";
import { zodParseWithLog } from "src/api/apiErrorLogs";
import { PostSchema } from "./postSchema";

export const fetchIngredientPosts = async (ingredientId: number, campaignId?: number) => {
    const url = `/api/ingredients/${ingredientId}/ssq_posts`;

    const params: { [key: string]: string | string[] } = {
        dimensions: ["main"],
    };

    if (campaignId) {
        params["campaign"] = campaignId.toString();
    }

    return await loggedInApiCall.get(url, {
        headers: {
            Accept: "application/json",
        },
        params: params,
    });
};

export const useGetIngredientPosts = (ingredientId: number | undefined, campaignId?: number) => {
    const query = useQuery({
        cacheTime: 0,
        queryKey: ["getIngredientPosts", ingredientId, campaignId],
        enabled: ingredientId !== undefined,
        queryFn: () => fetchIngredientPosts(ingredientId!, campaignId),
        select: (response) => {
            if (!response) {
                return;
            }

            const posts = response.data;
            const data = zodParseWithLog(posts, PostSchema.array().optional(), "getIngredientPosts");
            return data;
        },
    });

    return {
        posts: query.data,
        ...query,
    };
};
