import { SxProps, Theme } from "@mui/material";

export const styles = {
    container: {
        padding: "0px 32px",
    } as SxProps<Theme>,
    stackMargin: {
        marginTop: "32px",
    } as SxProps<Theme>,
};
