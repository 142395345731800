import { PrimaryDialog, Typography } from "@foodpilot/foods";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ArticleFormFields } from "./ArticleFormFields";
import { useCreateOneBlock } from "src/api/client-api/cms/blocks/useCreateOneBlock";
import {
    blockContentFormToWrite,
    ContentBlockForm,
    getNewBlockContent,
} from "../../_Common/BlockForm/ContentBlock/contentBlock";

type AddArticlesModalProps = {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    currentSection: {
        "@id": IRI;
        totalExistingElements: number;
    };
    selectedExistingBlock: ContentBlockForm | null;
};
export const AddArticlesModal = (props: AddArticlesModalProps) => {
    const { isOpen, setIsOpen, selectedExistingBlock, currentSection } = props;
    const { t } = useTranslation();

    const createBlock = useCreateOneBlock();
    const articleBlock = selectedExistingBlock ?? getNewBlockContent(currentSection.totalExistingElements);
    const [articleBlockForm, setArticleBlockForm] = useState(articleBlock);

    useEffect(() => {
        const articleBlock = selectedExistingBlock ?? getNewBlockContent(currentSection.totalExistingElements);
        setArticleBlockForm(articleBlock);
    }, [selectedExistingBlock]);

    return (
        <PrimaryDialog
            open={isOpen}
            setOpen={setIsOpen}
            PaperProps={{
                sx: {
                    width: "100%",
                    maxWidth: "1024px",
                },
            }}
            options={{
                title: <ModalTitle />,
                content: (
                    <ArticleFormFields articleBlockForm={articleBlockForm} setArticleBlockForm={setArticleBlockForm} />
                ),
            }}
            onValidate={() => {
                if (articleBlockForm === null) {
                    // Invalid data. Snackbar ?
                    return;
                }
                const newBlock = blockContentFormToWrite(currentSection["@id"], articleBlockForm);
                createBlock.mutate({ newBlock: newBlock });
            }}
            cancelText={t("primaryDialog.cancel")}
            validateText={t("primaryDialog.validate")}
        />
    );
};

function ModalTitle() {
    const { t } = useTranslation();
    return <Typography variant="big-bold">{t("cms.articles.addModal.title")}</Typography>;
}
