import { createContext } from "react";
import { Supplier } from "src/api/client-api/suppliers/useGetOneSupplier";
import { EntityBasedScoreArgs } from "../EntityBasedScore/useEntityBasedScoreParams";
import { ScoreVariation } from "../EntityBasedScore/ScoreService";
import { Score } from "src/types";
import { Ssq } from "src/api/sure-api/ssq/useGetOneSsq";
import { Post } from "src/api/client-api/post/postSchema";

export type CompanyContextInitialValues = {
    company: Supplier;
    ssq: {
        post: Post | undefined;
        isPostFetched: boolean;
        isPostRefetching: boolean;
        ssq: Ssq | undefined;
        isSsqFetched: boolean;
        isSsqRefetching: boolean;
    };
    companyScores: Score[];
    scoreState: EntityBasedScoreArgs;
    allScoreWithVariations: ScoreVariation[];
};
export const CompanyContext = createContext<CompanyContextInitialValues | null>(null);
