import { FoodsIcon } from "@foodpilot/foods";
import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import { SavingCloudStatus } from "src/components/Loader/SavingCloudStatus";
import { useActionsContext } from "src/context/Actions/useActionsContext";

export type CustomHeaderProps = {
    title: string;
    disableStatus?: boolean;
    cancelButton: {
        label: string;
        action: () => void;
    };
    actionButton: {
        label: string;
        action: () => void;
    };
};

export const CustomHeader = (props: CustomHeaderProps) => {
    const { title, disableStatus = false, actionButton, cancelButton } = props;
    const { savingStatus, isLocked } = useActionsContext();

    const theme = useTheme();

    return (
        <Stack
            flexDirection={"row"}
            gap={theme.spacing(4)}
            sx={{
                justifyContent: "space-between",
                alignItems: "center",
                padding: theme.spacing(3, 4, 3, 4),
            }}
        >
            <Typography variant="h3">{title}</Typography>
            <Box sx={{ display: "flex", gap: theme.spacing(2), alignItems: "center" }}>
                {disableStatus === false && (
                    <SavingCloudStatus
                        isSaving={savingStatus.isSaving}
                        isError={savingStatus.isError}
                        textOptions={{
                            syncingMessage: "A",
                            errorDefaultMessage: "B",
                            savingDefaultMessage: "C",
                        }}
                    />
                )}
                {cancelButton && (
                    <Button variant="ternary" disabled={isLocked} onClick={cancelButton.action}>
                        {cancelButton.label}
                    </Button>
                )}
                <Button
                    variant="validate"
                    onClick={actionButton.action}
                    disabled={isLocked}
                    startIcon={<FoodsIcon size={2} icon={"check"} />}
                >
                    {actionButton.label}
                </Button>
            </Box>
        </Stack>
    );
};
