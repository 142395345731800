import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Answer, GetValueType } from "src/components/ScoreDetail/ScoreDetailResponsesModal.tsx";
import { useTranslation } from "react-i18next";
import { ScoreDimensionValue } from "src/api/client-api/post/scoreSchema.ts";
import { formatNumber } from "src/utils/formatting.ts";
import { useScoreDetailContext } from "src/context/ScoreDetail/useScoreDetailContext.tsx";

const BoxStyled = styled(Box)(({ theme }) => ({
    overflowX: "auto",
    width: "100%",
    "& table": {
        border: "1px solid #e3e2e0",
        width: "100%",
        borderCollapse: "collapse",
        background: theme.palette.background.paper,
        "& th": {
            padding: "8px",
            border: "1px dashed #e3e2e0",
            background: theme.palette.background.paper,
            textAlign: "left",
        },
        "& td": {
            textAlign: "left",
            padding: "8px",
            border: "1px dashed #e3e2e0",
            background: theme.palette.background.paper,
        },
    },
}));

type Header = {
    id: number;
    title: string;
    position?: number;
};
type MultivalueGroupProps = {
    list: Answer[];
    headers: Header[];
    multivalueScore?: ScoreDimensionValue;
} & GetValueType;

export const MultivalueGroup = (props: MultivalueGroupProps) => {
    const { headers = [], list = [], getValue, multivalueScore } = props;

    const { t } = useTranslation();

    const { getUnit } = useScoreDetailContext();
    const precision = 3;

    const getMultivalueScore = () => {
        if (!multivalueScore || !multivalueScore.dimensions) {
            return;
        }

        const data = multivalueScore.dimensions.find((dimension) => dimension.label === "multivalues");

        return Object.values(data?.values || {});
    };

    headers.sort((a, b) => {
        const positionA = a.position ?? Number.MAX_SAFE_INTEGER;
        const positionB = b.position ?? Number.MAX_SAFE_INTEGER;

        return positionA - positionB;
    });

    const rows: (Answer | null)[][] = [];
    const headersLength = headers.length;
    let rowIndex = 0;
    let left = list.length;
    while (left > 0) {
        rows[rowIndex] = [];

        for (let i = 0; i < headersLength; i++) {
            const item = list.find((item) => item.position === rowIndex && item.ssqPropertyId === headers[i].id);

            if (item) {
                left--;
                rows[rowIndex].push(item);
            } else {
                rows[rowIndex].push(null);
            }
        }
        rowIndex++;
    }

    const multivalueScoreInner = getMultivalueScore();
    const unit = getUnit();

    return (
        <>
            <BoxStyled>
                <table>
                    <thead>
                        <tr>
                            {headers.map((header) => {
                                return (
                                    <th key={header.id}>
                                        <Typography variant="h5">{header.title}</Typography>
                                    </th>
                                );
                            })}
                            {multivalueScoreInner && (
                                <th key="score">
                                    <Typography variant="h5">{t("Score")}</Typography>
                                </th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {rows.map((row, i) => {
                            return (
                                <tr key={i}>
                                    {row.map((question, j) => {
                                        return (
                                            <td key={j}>
                                                <Typography variant="body-medium">
                                                    {question ? getValue(question) : "-"}
                                                </Typography>
                                            </td>
                                        );
                                    })}
                                    {multivalueScoreInner && (
                                        <td key="score">
                                            <Typography variant="body-medium">
                                                {multivalueScoreInner[i] && multivalueScoreInner[i].score ?
                                                    formatNumber(multivalueScoreInner[i].score.toFixed(precision))
                                                :   0}{" "}
                                                {unit}
                                            </Typography>
                                        </td>
                                    )}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </BoxStyled>
            {multivalueScore && multivalueScoreInner && multivalueScore.score !== null && (
                <Box mt="28px">
                    <Typography variant="h5" sx={{ display: "inline" }}>
                        {t("questionnaire.score_total")}:
                    </Typography>{" "}
                    <Typography variant="h4" sx={{ display: "inline" }}>
                        {formatNumber(multivalueScore.score.toFixed(precision))} {unit}
                    </Typography>
                </Box>
            )}
        </>
    );
};
