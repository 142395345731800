import { FoodsSelect, FoodsSelectProps } from "@foodpilot/foods";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Campaign } from "src/api/client-api/useCampaign";
import { useFoodpilotContext } from "src/context/FoodpilotContext";

type CampaignSelectorProps = {
    disableText?: boolean;
    BoxPropsOverride?: FoodsSelectProps<unknown>["BoxProps"];
    allCampaigns?: Campaign[];
};
export const CampaignSelector = (props: CampaignSelectorProps) => {
    const { disableText = false, BoxPropsOverride = {}, allCampaigns } = props;
    const { t } = useTranslation();

    const { campaigns, currentCampaign, setCurrentCampaign } = useFoodpilotContext();

    const campaignsToSelectFrom = allCampaigns ? allCampaigns : campaigns;
    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                gap: "1rem",
            }}
        >
            {disableText === false && (
                <Typography
                    variant="h5"
                    sx={{
                        whiteSpace: "nowrap",
                    }}
                >
                    {t("campaignSelect.label")} :
                </Typography>
            )}
            <FoodsSelect
                selectedOption={currentCampaign}
                options={campaignsToSelectFrom}
                onChange={(selectedCampaign) => setCurrentCampaign(selectedCampaign)}
                getId={(campaign) => campaign.id}
                getName={(campaign) => campaign.label}
                closeOnSelect
                disableSearch
                textOptions={{
                    placeholder: t("component.select.placeholder"),
                    searchPlaceholder: t("component.select.searchPlaceholder"),
                    emptyOptionsText: t("component.select.noOptions"),
                    defaultValueText: t("component.select.defaultValue"),
                    noResult: t("component.select.noResultFound"),
                }}
                BoxProps={{
                    width: "130px",
                    ...BoxPropsOverride,
                }}
            />
        </Box>
    );
};
