import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { GridPageHeader } from "src/components/Grid/PageGridHeader";
import { DocumentationListGrid } from "./DocumentationListGrid/DocumentationListGrid";
import { DocumentListProvider, useDocumentListContext } from "./DocumentationContext";
import { styles } from "./styles/DocumentationList";

const DocumentationListContent = () => {
    const { t } = useTranslation();
    const { documents, delayedSearchQuery, deleteDocument } = useDocumentListContext();

    const { search } = delayedSearchQuery;

    return (
        <Box sx={styles.container}>
            <GridPageHeader
                title={t("documentation.title")}
                filter={search}
                // setFilter={setSearch}
            />

            <Box component="div" sx={styles.content}>
                <DocumentationListGrid documents={documents} onDelete={deleteDocument} isLoading={false} />
            </Box>
        </Box>
    );
};

export const DocumentationList = () => {
    return (
        <DocumentListProvider>
            <DocumentationListContent />
        </DocumentListProvider>
    );
};
