import z from "zod";
import { useQuery } from "react-query";

import { loggedInApiCall } from "src/api/axiosClient/userApi";

import { parseAndSelect } from "src/api/apiHelpers.ts";

export const getAllPermissionsKey = "allPermissions" as const;

type Options = {
    itemsPerPage?: number;
    page?: number;
};

export const useGetAllPermissions = (options?: Options) => {
    const { itemsPerPage = 20, page = 1 } = options || {};

    const query = useQuery({
        queryKey: getAllPermissionsKey,
        queryFn: async () => {
            const pagination = `itemsPerPage=${itemsPerPage}&page=${page}`;

            const url = `/api/permissions?${pagination}`;
            return await loggedInApiCall.get(url);
        },
        select: (request) => parseAndSelect(request, PermissionSchema.array(), getAllPermissionsKey),
    });

    return {
        allPermissions: query.data?.items,
        totalItems: query.data?.totalItems,
        ...query,
    };
};

export type Permission = {
    "@id": IRI;
    slug: string;
    object: string;
};

export const PermissionSchema: z.ZodSchema<Permission> = z.object({
    "@id": z.string(),
    slug: z.string(),
    object: z.string(),
});
