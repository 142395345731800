import { ProductFormBrand } from "../../../ProductForm.types";
import { useTranslation } from "react-i18next";
import { FormControl, Typography } from "@mui/material";
import { FoodsSelectServerPagination } from "@foodpilot/foods";
import { useGetAllBrands } from "src/api/client-api/brand/useGetAllBrand";
import { useDelayedSearch } from "src/utils/useDelayedSearch";

type BrandFieldProps = {
    brandIRI: IRI | null;
    setBrand: (newBrand: ProductFormBrand | null) => void;
};
export const BrandField = (props: BrandFieldProps) => {
    const { brandIRI, setBrand } = props;
    const { t } = useTranslation();

    const { search, setSearch, delayedSearch } = useDelayedSearch();
    const { allBrands = [] } = useGetAllBrands({
        name: delayedSearch,
    });
    const isSearchFound = (value: string) => {
        if (delayedSearch === "") {
            return true;
        }

        return value.toLowerCase().includes(delayedSearch.toLowerCase());
    };

    const selectedBrand = allBrands.find((brand) => brand["@id"] === brandIRI) ?? null;

    return (
        <FormControl size="small">
            <Typography variant="h5" sx={{ paddingBottom: "0.5rem" }}>
                {`${t("Marque")}:`}
            </Typography>
            <FoodsSelectServerPagination
                onChange={(data) => {
                    setBrand(data);
                }}
                options={allBrands}
                selectedOption={selectedBrand}
                getId={(item) => item["@id"]}
                getName={(item) => item.name}
                textOptions={{
                    placeholder: t("component.select.placeholder"),
                    searchPlaceholder: t("component.select.searchPlaceholder"),
                    emptyOptionsText: t("component.select.noOptions"),
                    defaultValueText: t("component.select.defaultValue"),
                    noResult: t("component.select.noResultFound"),
                }}
                search={search}
                setSearch={setSearch}
                isSearchFound={isSearchFound}
                BoxProps={{ width: "75%" }}
                BoxOptionsProps={{ width: "450px" }}
            />
        </FormControl>
    );
};
