import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { loggedInApiCall } from "src/api/axiosClient/userApi";
import { DeleteDocumentMutationPayload } from "./types";
import { useSnackbar } from "src/utils/useSnackbar.tsx";

export const useDeleteDocument = () => {
    const [deleting, setDeleting] = useState(false);
    const queryClient = useQueryClient();

    const snackbar = useSnackbar();

    const query = useMutation({
        mutationKey: ["deleteDocument"],
        mutationFn: async ({ id }: DeleteDocumentMutationPayload) => {
            const url = `/api/cms/contents/${id}`;

            setDeleting(true);
            const response = await loggedInApiCall.delete(url, {});

            return response;
        },
        onError: () => {
            snackbar.forceClose();
            snackbar.setSnackbarProps({
                type: "error",
                message: "Error deleting document",
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries(["getAllDocuments"]);
        },
        onSettled: () => {
            setDeleting(false);
        },
    });

    return {
        query,
        deleting,
    };
};
