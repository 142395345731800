import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useCreateHomePage } from "./useCreateHomePage";
import { useApplicationParameters, useUpsertAppParam } from "src/api/client-api/appParams";
import { FoodsIcon } from "@foodpilot/foods";
import { DraggingRows } from "src/components/Dragging";
import { useNavigate } from "react-router-dom";
import { URLHandler } from "src/services/URLHandler";
import { VerticalDivider } from "../Common/VerticalDivider";
import { useSettingsCMSContext } from "src/context/Settings/CMS/useSettingsCMSContext";
import { ToggleButton } from "./Sections/_Common/ToggleButton";
import { useToggleSectionVisibility } from "src/api/client-api/cms/sections/useToggleSection";
import { AddSectionModal } from "../Common/AddSectionModal";
import { useSectionsConfig } from "src/api/client-api/cms/sections/sectionsConfig";
import { useTranslation } from "react-i18next";
import { useDeleteOneSection } from "src/api/client-api/cms/sections/useDeleteOneSection";
import { useReorderSections } from "src/api/client-api/cms/sections/useReorderSections";
import { CMSSectionAllBlocks } from "src/api/client-api/cms/useGetOneContent";

export const CMSPageSettings = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const sectionsConfig = useSectionsConfig();
    const deleteSection = useDeleteOneSection();
    const reorderSections = useReorderSections();
    const { applicationParameters, isFetched } = useApplicationParameters();
    const { content, isContentFetched, pageParams } = useSettingsCMSContext();
    const { createHomepage } = useCreateHomePage();
    const toggleVisibility = useToggleSectionVisibility();
    const upsertAppParam = useUpsertAppParam();

    const [isModalOpened, setIsModalOpened] = useState<boolean>(false);

    if (applicationParameters === undefined) return;
    const isHomepageActivated = applicationParameters["FS-homepage"] === "true";

    useEffect(() => {
        if (isFetched === false) return;
        if (applicationParameters === undefined) {
            // Snackbar error message ?
            return;
        }

        const isHomepageActivated = applicationParameters["FS-homepage"] === "true";
        if (isHomepageActivated === false) return;

        if (isContentFetched === false) return;
        const isHomePageCreated = content === undefined ? false : true;
        if (isHomePageCreated) return;
        createHomepage();
    }, [isFetched, content, applicationParameters]);

    if (content === undefined) return;

    const sectionList = content.sections.flatMap((section) => {
        const config = sectionsConfig[section.type];

        if (config.isDisabled === true) return [];
        const sects = content.sections;
        return [
            {
                id: section.id,
                sectionId: section.id,
                title: config.title,
                subtitle: config.subtitle,
                visible: section.visible,
                position: section.position,
                onDelete: () => {
                    deleteSection.mutate({
                        deleteId: section.id,
                        allSectionsToReorder: sects,
                    });
                },
                onClick: () => {
                    if (pageParams.pageId === undefined) return;

                    const url = URLHandler.settings.cms.section(pageParams.pageId, section.id.toString());
                    navigate(url);
                },
            },
        ];
    });

    const numberOfVisibleWidgets = content.sections.reduce((acc, section) => {
        if (section.visible) return acc + 1;
        return acc;
    }, 0);
    const totalWidgets = content.sections.length;

    return (
        <Stack flexDirection={"column"} padding={theme.spacing(6, 8)} gap={theme.spacing(2)}>
            <AddSectionModal
                isOpen={isModalOpened}
                setIsOpen={setIsModalOpened}
                pageIRI={content["@id"]}
                numberOfExistingElements={totalWidgets}
            />
            <Stack direction={"row"} spacing={theme.spacing(1)}>
                <Stack flexDirection={"column"} gap={theme.spacing(0.5)} alignItems="start">
                    <Typography variant="big-bold" sx={{ color: theme.custom.grey[2000] }}>
                        {t("settings.infos")}
                    </Typography>
                    <Typography variant="huge-bold">{t("settings.cms.homepage.title")}</Typography>
                    <Typography variant="big-medium">{t("settings.cms.homepage.info")}</Typography>
                </Stack>
            </Stack>

            <Stack
                flexDirection={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                sx={{ border: `2px solid ${theme.custom.grey[2000]}` }}
                padding={theme.spacing(2)}
            >
                <Typography variant="big-bold">{t("settings.cms.homepage.statusText")}</Typography>
                <ToggleButton
                    isActivated={isHomepageActivated}
                    onClick={() => {
                        const wasValueOriginallySet = applicationParameters["FS-homepage"];
                        const toggledVisibility = !isHomepageActivated;
                        upsertAppParam.mutate({
                            isNew: wasValueOriginallySet === undefined ? true : false,
                            key: "FS-homepage",
                            value: String(toggledVisibility),
                        });
                    }}
                />
            </Stack>

            <Stack
                flexDirection={"column"}
                gap={theme.spacing(2)}
                sx={{ border: `2px solid ${theme.custom.grey[2000]}` }}
                padding={theme.spacing(2)}
            >
                <Stack flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                    <Stack flexDirection={"row"} gap={theme.spacing(1)}>
                        <Typography variant="big-bold">{t("settings.cms.sections.title")}</Typography>
                        <Typography variant="big-medium">{`- ${numberOfVisibleWidgets}/${totalWidgets}`}</Typography>
                    </Stack>
                    <Button
                        variant={"primary"}
                        startIcon={<FoodsIcon size={2} icon="plus" />}
                        onClick={() => setIsModalOpened(true)}
                    >
                        {t("setting.cms.sections.addButton")}
                    </Button>
                </Stack>

                <DraggingRows
                    rows={sectionList}
                    renderRow={(row, rowIndex) => {
                        return (
                            <Stack
                                flexDirection={"row"}
                                justifyContent={"space-between"}
                                key={`index-${rowIndex}`}
                                sx={{ border: `2px solid ${theme.custom.grey[2000]}`, width: "100%" }}
                                padding={theme.spacing(1)}
                            >
                                <Stack gap={theme.spacing(1)}>
                                    <Typography variant="huge-bold">{row.title}</Typography>
                                    <Typography variant="big-bold">{row.subtitle}</Typography>
                                </Stack>
                                <Stack flexDirection={"row"} gap={theme.spacing(2)} alignItems={"center"}>
                                    <Button
                                        variant="text"
                                        startIcon={<FoodsIcon size={2} icon={"edit"} />}
                                        onClick={row.onClick}
                                    >
                                        {t("glossary.modify")}
                                    </Button>
                                    <VerticalDivider />
                                    <Box onClick={row.onDelete}>
                                        <FoodsIcon size={4} icon="delete" />
                                    </Box>
                                    <VerticalDivider />
                                    <ToggleButton
                                        isActivated={row.visible}
                                        onClick={() => {
                                            toggleVisibility.mutate({
                                                sectionId: row.sectionId.toString(),
                                                currentSectionState: row.visible,
                                            });
                                        }}
                                    />
                                </Stack>
                            </Stack>
                        );
                    }}
                    onHoverDrop={(reorderedRows) => {
                        reorderSections.mutate({ allSectionsToReorder: reorderedRows });
                    }}
                />
            </Stack>
        </Stack>
    );
};
