import { useQuery } from "react-query";
import { loggedInApiCall } from "src/api/axiosClient/userApi";
import { PostSchema } from "./postSchema";
import { EntityBasedScoreArgs } from "src/context/EntityBasedScore/useEntityBasedScoreParams";
import { zodParseWithLog } from "src/api/apiErrorLogs";
import { EntityID } from "src/api/apiHelpers.ts";

export const postStatus = {
    calculation: 0,
    simulation: 1,
    objective: 2,
} as const;
export type postStatusKeys = keyof typeof postStatus;

type Options = {
    queryKey: string;
    enabled?: boolean;
    params?: AxiosParams;
};

export const anyAllSimulationKey = "getAllSimulations" as const;

const useGetAllPosts = (options: Options) => {
    const { queryKey, enabled = true, params = {} } = options;

    const keyParams = Object.values(params);
    const query = useQuery({
        cacheTime: 0,
        queryKey: [anyAllSimulationKey, queryKey, ...keyParams],
        enabled: enabled,
        keepPreviousData: true,
        queryFn: async () => {
            const url = `/api/ssq_posts`;
            return loggedInApiCall.get(url, {
                headers: {
                    Accept: "application/json",
                },
                params: {
                    ...params,
                },
            });
        },
        select: (req) => {
            const post = req.data;
            const data = zodParseWithLog(post, PostSchema.array(), queryKey);
            return data;
        },
    });

    return {
        posts: query.data,
        ...query,
    };
};

export type CompanyPostsOptions = {
    entityId: EntityID;
    status: postStatusKeys;
    scoreParams: Omit<EntityBasedScoreArgs["query"], "campaign">;
    extraParams?: AxiosParams;
};
export const useGetCompanyPosts = (options: CompanyPostsOptions) => {
    const { entityId, status, scoreParams, extraParams = {} } = options;

    const statusNumber = postStatus[status];
    return useGetAllPosts({
        queryKey: "company",
        enabled: entityId !== undefined,
        params: {
            "company.id": entityId,
            status: statusNumber,
            ...scoreParams,
            ...extraParams,
        },
    });
};
