import { SupplierType, SupplierWrite } from "src/api/client-api/suppliers/useCreateSupplier";
import { Supplier } from "src/api/client-api/suppliers/useGetOneSupplier";

import { z } from "zod";

export class CompanySettingsForm {
    static getSupplierToSupplierGridForm = (existingSupplier: Supplier | undefined): SupplierGridForm => {
        if (!existingSupplier) return { name: "", type: "provider", email: "" };

        return {
            id: existingSupplier.id,
            name: existingSupplier.name,
            type: existingSupplier.type,
            address1: existingSupplier.address1,
            address2: existingSupplier.address2,
            city: existingSupplier.city,
            state: existingSupplier.state,
            country: existingSupplier.country,
            phone: existingSupplier.phone,
            email: existingSupplier.email ?? "",
            companySector: existingSupplier.companySector?.["@id"],
            parent: existingSupplier.parent?.["@id"],
        };
    };

    static getSupplierGridFormToSupplierWrite = (supplier: SupplierGridForm): SupplierWrite => {
        return {
            id: supplier.id,
            name: supplier.name ?? null,
            type: supplier.type,
            address1: supplier.address1,
            address2: supplier.address2,
            city: supplier.city,
            state: supplier.state,
            country: supplier.country,
            phone: supplier.phone,
            email: supplier.email,
            companySector: supplier.companySector,
            parent: supplier.parent ?? null,
        };
    };
}

export type SupplierGridForm = {
    "@id"?: IRI;
    id?: number;
    name: string;
    type: SupplierType;
    address1?: string;
    address2?: string;
    city?: string;
    state?: string;
    country?: string;
    phone?: string;
    email: string;
    companySector?: string;
    parent?: string;
};

export const SupplierGridFormZodSchema: z.ZodSchema<SupplierGridForm> = z.object({
    "@id": z.string().optional(),
    id: z.number().optional(),
    name: z.string(),
    type: z.enum(["provider", "main", "branch"]),
    address1: z.string().optional(),
    address2: z.string().optional(),
    city: z.string().optional(),
    state: z.string().optional(),
    country: z.string().optional(),
    phone: z.string().optional(),
    email: z.string().email(),
    companySector: z.string().optional(),
    parent: z.string().optional(),
});
