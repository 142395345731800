import z from "zod";
import { useQuery } from "react-query";
import { sureApiCall } from "../axiosClient/sureApi";

import { zodParseWithLog } from "../apiErrorLogs";

export const useMaterialReference = () => {
    const query = useQuery({
        cacheTime: 0,
        staleTime: 0,
        queryKey: "allMaterialSet",
        queryFn: async () => {
            return await sureApiCall.get("/api/packaging_material_sets");
        },
        select: (request) => {
            const materials = request.data["hydra:member"];
            const data = zodParseWithLog(materials, MaterialSetSchema.array(), "allMaterialRef");
            return data;
        },
    });

    return {
        materialsReference: query.data ?? [],
        ...query,
    };
};

export type Material = {
    "@id": IRI;
    id: number;
    name: string;
};

export const MaterialSetSchema: z.ZodSchema<Material> = z.object({
    "@id": z.string(),
    id: z.number(),
    name: z.string(),
});
