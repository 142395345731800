import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import { ScoreComparisonCard } from "../ScoreComparisonCard/ScoreComparisonCard";
import { useTranslation } from "react-i18next";
import { useActionsContext } from "src/context/Actions/useActionsContext";
import { useGetAllCampaigns } from "src/api/client-api/useCampaign";
import { InfoHeaderWithIllustration } from "@foodpilot/foods";
import { useNavigate } from "react-router-dom";

export const Synthesis = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { scoresEnabled, allScoreWithVariations, scoreOptions, selectedSimulation, urls } = useActionsContext();
    const { campaigns } = useGetAllCampaigns();

    const targetCampaign = campaigns.find((campaign) => campaign.isTarget === true);
    const targetYear = (selectedSimulation ? selectedSimulation.campaign : targetCampaign)?.label ?? "-";

    return (
        <Stack gap={theme.spacing(3)}>
            {selectedSimulation === null && (
                <InfoHeaderWithIllustration
                    boldText={t("actionPlan.welcome.header")}
                    smallInfoText={t("actionPlan.welcome.description")}
                    illustration={"selectStartingYear"}
                    buttonAction={{
                        action: () => {
                            const url = urls.planning();
                            navigate(url);
                        },
                        label: t("here_we_go"),
                    }}
                />
            )}
            <Typography variant="h2">{t("actions.synthesis.title", { year: targetYear })}</Typography>
            <Stack flexDirection={"row"} flexWrap={"wrap"} gap={theme.spacing(1)}>
                {scoresEnabled.map((score) => {
                    const scoreOpts = allScoreWithVariations.filter(
                        (scoreVariation) => scoreVariation.group === score.title,
                    );
                    const selectedScore = scoreOpts.find(
                        (filteredScore) => filteredScore.id === scoreOptions.selectedVariations[score.id],
                    );

                    if (scoreOpts.length === 0) return;
                    return (
                        <Box
                            key={`score-synthesis-${score.id}`}
                            sx={{
                                display: "flex",
                                width: "49%",
                                flexDirection: "column",
                            }}
                        >
                            <ScoreComparisonCard
                                position={"horizontal"}
                                allOptions={scoreOpts}
                                selectedOption={selectedScore ?? scoreOpts[0]}
                                precision={score.precision}
                            />
                        </Box>
                    );
                })}
            </Stack>
        </Stack>
    );
};
