import { useMutation, useQueryClient } from "react-query";
import { loggedInApiCall } from "src/api/axiosClient/userApi";
import { getSomeActionPost } from "./useGetSomeActionPost";

type CreateActionPost = {
    actionId: number;
    ssqPostIRI: IRI;
};

export const useCreateActionPost = () => {
    const queryClient = useQueryClient();

    const query = useMutation({
        mutationFn: async (options: CreateActionPost) => {
            const { actionId, ssqPostIRI } = options;

            const url = `/api/action_ssq_posts`;
            const payload = {
                actionId: actionId,
                ssqPost: ssqPostIRI,
            };

            const response = await loggedInApiCall.post(url, payload);
            return response;
        },
        onSuccess: () => queryClient.invalidateQueries([getSomeActionPost]),
    });

    return query;
};
