import { Navigate, Route, Routes } from "react-router-dom";
import { useClientSwitch } from "src/components/ClientSwitch/useClientSwitch";
import { BredHome } from "./CustomHome/BredHome";
import { URLHandler } from "src/services/URLHandler";
import { Homepage } from "../CMS/Homepage";
import { useGetOneContent } from "src/api/client-api/cms/useGetOneContent";
import { useApplicationParameters } from "src/api/client-api/appParams";
import { HomeCMS_ID } from "../Settings/CMS/Pages/useCreateHomePage";
import { useUserContext } from "src/context/User/useUserContext";

export const HomeSwitch = () => {
    const { selectedCompany } = useUserContext();
    const clientSwitch = useClientSwitch();
    const { applicationParameters } = useApplicationParameters();
    const { content, isFetched: isContentFetched } = useGetOneContent({ contentId: HomeCMS_ID });

    if (isContentFetched === false) return;

    const newClientSwitchForHome = clientSwitch({
        defaultOption: <Homepage />,
        bred: <BredHome />,
        inexweb: <Navigate to={"/inexweb"} replace />,
    });

    const routeElement = clientSwitch({
        defaultOption: undefined,
        bred: <BredHome />,
        foodpilot: (
            <Navigate
                to={
                    ["supplier", "provider"].includes(selectedCompany.type ?? "CannotMatch") ?
                        URLHandler.ingredient.list(selectedCompany.id)
                    :   URLHandler.product.list(selectedCompany.id)
                }
                replace
            />
        ),
        inexweb: <Navigate to={"/inexweb"} replace />,
        collective: <Navigate to={URLHandler.company.ssq(selectedCompany.id)} replace />,
    });

    const routerToUse =
        content === undefined || applicationParameters?.["FS-homepage"] !== "true" ?
            routeElement
        :   newClientSwitchForHome;

    return (
        <Routes>
            <Route path="" element={routerToUse} />
        </Routes>
    );
};
