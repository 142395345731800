import { useGetOneSupplier } from "src/api/client-api/suppliers/useGetOneSupplier";
import { CompanyContext, CompanyContextInitialValues } from "./context";
import { useFoodpilotContext } from "../FoodpilotContext";
import { ScoreService } from "../EntityBasedScore/ScoreService";
import { useGetOneSsq } from "src/api/sure-api/ssq/useGetOneSsq";
import { useGetCompanyPost } from "src/api/client-api/post/useGetCompanyPost";
import { useEntityBasedScoreParams } from "../EntityBasedScore/useEntityBasedScoreParams";

type CompanyProviderProps = {
    children: JSX.Element;
};
export const CompanyProvider = (props: CompanyProviderProps) => {
    const { children } = props;
    const { currentCampaign, selectedCompany, getScoresByType } = useFoodpilotContext();
    const companyScores = getScoresByType("company");

    const entityParams = useEntityBasedScoreParams({
        campaign: currentCampaign?.id,
        initDimensions: {
            dimensions: [["main"], ["properties"]],
        },
        initVariations: {
            defaultScoreId:
                companyScores.length === 0 ?
                    undefined
                :   {
                        scoreId: companyScores[0].id,
                        scoreVariationId: null,
                    },
        },
    });
    const { campaign, dimensions, variations } = entityParams.query;

    const { supplier, isFetched, isRefetching } = useGetOneSupplier(selectedCompany?.id, {
        queryParams: {
            campaign,
            dimensions,
            variations,
        },
    });

    const ssqId = supplier?.companySector?.ssqId;
    const { ssq, isFetched: isSsqFetched, isRefetching: isSsqRefetching } = useGetOneSsq(ssqId);

    const {
        post,
        isFetched: isPostFetched,
        isRefetching: isPostRefetching,
    } = useGetCompanyPost(supplier?.id, currentCampaign?.id);

    if (isRefetching === false) {
        if (isFetched === false) {
            // It's loading, cheers.
            return;
        }
    }

    if (supplier === undefined) {
        // Error ? The company must exist;
        return;
    }

    const allScoreWithVariations = ScoreService.getAllScoreVariations(
        companyScores,
        supplier.scores,
        (scoreId, scoreVariationId) => {
            entityParams.state.selectVariation(scoreId, scoreVariationId);
        },
    );

    const items: CompanyContextInitialValues = {
        company: supplier,
        ssq: {
            post,
            isPostFetched: isPostFetched,
            isPostRefetching: isPostRefetching,
            ssq: ssq,
            isSsqFetched: isSsqFetched,
            isSsqRefetching: isSsqRefetching,
        },
        companyScores,
        scoreState: entityParams,
        allScoreWithVariations,
    };

    return <CompanyContext.Provider value={items}>{children}</CompanyContext.Provider>;
};
