import { useMutation, useQueryClient } from "react-query";
import { loggedInApiCall } from "src/api/axiosClient/userApi";
import { getAllStrategyKey } from "../useGetAllStrategy";

export type CsrPillarsDelete = {
    pillarId: number | string;
};

export const useCsrPillarDelete = () => {
    const queryClient = useQueryClient();

    const query = useMutation({
        mutationFn: async (options: CsrPillarsDelete) => {
            const { pillarId } = options;

            const response = await loggedInApiCall.delete(`/api/steering/pillars/${pillarId}`);
            return response;
        },
        onSuccess: () => queryClient.invalidateQueries([getAllStrategyKey]),
    });

    return query;
};
