import { useMutation, useQueryClient } from "react-query";
import { useSnackbar } from "src/utils/useSnackbar";
import { getOneCMSContentKey } from "../useGetOneContent";
import { loggedInApiCall } from "src/api/axiosClient/userApi";

const url = `/api/cms/contents/pages/blocks/contents` as const;

type CMSContentBlockToReorder = {
    id: string | number;
    position: number;
};

type ReorderPayload = {
    allBlocksToReorder: CMSContentBlockToReorder[];
};

export const reorderBlocksReqs = async (allSections: CMSContentBlockToReorder[]) => {
    const sectionsWithIndex = allSections.map((value, index) => {
        return {
            ...value,
            position: index,
        };
    });

    await Promise.all([
        sectionsWithIndex.map(async (section) => {
            const basePatchUrl = `${url}/${section.id}`;
            const payload = {
                position: section.position,
            };
            return await loggedInApiCall.patch(basePatchUrl, payload, {
                headers: {
                    "Content-Type": "application/merge-patch+json",
                },
            });
        }),
    ]);
};

export const useReorderBlocks = () => {
    const queryClient = useQueryClient();
    const snackbar = useSnackbar();

    const query = useMutation({
        mutationFn: async (options: ReorderPayload) => {
            const { allBlocksToReorder } = options;

            try {
                await reorderBlocksReqs(allBlocksToReorder);
            } catch (err) {
                //
            }
        },
        onSuccess: () => {
            queryClient.invalidateQueries([getOneCMSContentKey]);

            snackbar.forceClose();
            snackbar.setSnackbarProps({
                type: "info",
                message: "Your blocks are re-ordered !",
            });
        },
    });

    return query;
};
