import { Box, Divider, Stack, Typography } from "@mui/material";
import { useProductContext } from "../ProductContext";
import { useFoodpilotContext } from "src/context/FoodpilotContext";
import { Loading } from "src/components/utils/Loading";
import { DoughnutChart, FoodsIcon, LinearProgress, ScoreCard, ScoreCardProps } from "@foodpilot/foods";
import { IngredientPost } from "src/api/client-api/post/useGetSomeIngredientPosts";
import { t } from "i18next";
import { useEffect } from "react";
import { URLHandler } from "src/services/URLHandler";
import { NavLink } from "react-router-dom";
import { useIngredientPosts } from "src/api/client-api/ingredients/useIngredientPosts";
import { doesScoreExist } from "src/api/client-api/post/scoreSchema";
import { Ingredient } from "src/api/client-api/products/useGetOneProduct";
import { useTranslation } from "react-i18next";
import { FormTooltip } from "src/components/FormProperty/FormTooltip.tsx";
import { IngredientReference } from "src/api/sure-api/ingredientReference/useGetAllIngredientReference.ts";

const DIMENSION = "ingredients";

const supplierCompletionRates = (ingredient: Ingredient, ingredientPosts: IngredientPost[]) => {
    const ingredientPostAllCompany = ingredientPosts.filter((ip) => ip.ingredient.id === ingredient.id);
    const supplierQuestionnaire = ingredient.ingredientSuppliers?.map((is) => {
        const ipac = ingredientPostAllCompany.find((ip) => ip.company?.id === is.supplier.id);
        return ipac ? ipac.completions.ssq.result : 0;
    });

    return supplierQuestionnaire;
};

export const completionRate = (ingredient: Ingredient, ingredientPosts: IngredientPost[]) => {
    const supplierRates = supplierCompletionRates(ingredient, ingredientPosts);
    const totalCompletion = supplierRates?.reduce((acc, sq) => acc + sq, 0) ?? 0;

    const completion =
        ingredient.ingredientSuppliers && ingredient.ingredientSuppliers.length ?
            totalCompletion / ingredient.ingredientSuppliers.length
        :   0;
    return completion;
};

export const Ingredients = () => {
    const { product, productScores, allScoreWithVariations, scoreState, switchToDimension, ingredientPosts } =
        useProductContext();

    useEffect(() => {
        switchToDimension(DIMENSION);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const ingredientIds = product?.productIngredients?.map((p) => p.ingredient.id) || [];
    const posts = useIngredientPosts(ingredientIds);

    if (!product || posts.some((p) => p.isLoading)) return <Loading />;

    const selectedItemId = scoreState.lastSelectedVariation?.scoreId ?? allScoreWithVariations[0].id;
    const scoreCardHeader: ScoreCardProps["header"] = {
        selectedItemId,
        options: allScoreWithVariations,
    };
    const score = product.scores[selectedItemId];
    const precision = productScores.find((ps) => ps.id === selectedItemId)?.precision;

    const totalCompletionRate = (product.productIngredients ?? []).reduce(
        (acc, pi) => acc + completionRate(pi.ingredient, ingredientPosts),
        0,
    );
    const ingredientCount = (product.productIngredients ?? []).length;
    const totalIngredientCompletion = totalCompletionRate / (ingredientCount ?? 1);

    const dimensionScores = score?.dimensions.filter((d) => d.label === DIMENSION).map((d) => d.values);
    const chartData = (product.productIngredients ?? []).map((pi) => {
        const pid = pi.ingredient.id;
        const dim = dimensionScores?.find((value) => value[pid])?.[pid];

        return dim ?? { score: 0, label: pi.ingredient.name };
    });

    const chartValues = chartData.map((cd) => Number((cd.score ?? 0).toFixed(precision)));
    const chartLabels = chartData.map((cd) => cd.label);
    const sum = chartValues.reduce((acc, v) => acc + v, 0);

    return (
        <Stack gap={3.5} py={3.5} divider={<Divider flexItem />} borderRadius={2} sx={{ backgroundColor: "white" }}>
            <Stack direction={"row"} px={4} gap={2.5} width={"100%"} alignItems="center">
                <Stack
                    alignItems={"center"}
                    justifyContent={"center"}
                    sx={{ backgroundColor: (theme) => theme.custom.grey[400], borderRadius: "28px", padding: "8px" }}
                    width={"44px"}
                    height={"44px"}
                >
                    <FoodsIcon icon="ingredients" size={3.5} />
                </Stack>
                <Stack>
                    <Stack direction={"row"} alignItems={"baseline"}>
                        <Typography variant="h3">{t("Ingredients")}</Typography>
                        <Typography variant="huge">&nbsp;-&nbsp;{product.productIngredients?.length ?? 0}</Typography>
                    </Stack>
                    <Stack direction={"row"} gap={1.5} alignItems={"flex-start"}>
                        <Typography variant="body-medium">{t("Complétion totale")} :</Typography>
                        <Box width="200px">
                            <LinearProgress value={totalIngredientCompletion} />
                        </Box>
                    </Stack>
                </Stack>
            </Stack>
            <Stack direction={"row"} px={4} gap={4} sx={{ backgroundColor: "white" }}>
                <Stack flex={"70%"} divider={<Divider />} gap={3}>
                    {product?.productIngredients?.map((pi, i) => {
                        return (
                            <Line
                                key={i}
                                ingredient={pi.ingredient}
                                scoreId={selectedItemId}
                                unit={doesScoreExist(score) ? score.unit : ""}
                                posts={posts.filter((p) => p.data?.data.ingredient.id === pi.ingredient.id)}
                            />
                        );
                    })}
                </Stack>

                <Box flex={"30%"}>
                    <ScoreCard header={scoreCardHeader} disableFooter>
                        <DoughnutChart
                            legend={{ position: "bottom" }}
                            values={chartValues}
                            labels={chartLabels}
                            colors={["#9de1f3", "#ff9343"]}
                        >
                            <Stack>
                                <Typography variant={"h1"} fontWeight={800}>
                                    {doesScoreExist(score) ? Number(sum.toFixed(precision)) : null}
                                </Typography>
                                <Typography variant="body2" sx={{ color: (theme) => theme.custom.grey[1400] }}>
                                    {doesScoreExist(score) ? score.unit : ""}
                                </Typography>
                            </Stack>
                        </DoughnutChart>
                    </ScoreCard>
                </Box>
            </Stack>
        </Stack>
    );
};

type LineProps = {
    ingredient: Ingredient;
    scoreId: number;
    key: number;
    unit: string;
    posts: any[];
};

const Line = (props: LineProps) => {
    const { ingredient, scoreId: sureScoreId, unit, posts } = props;
    const { selectedCompany } = useFoodpilotContext();
    const { ingredientPosts, ingredientReferences: ingredientReferences } = useProductContext();
    const { t } = useTranslation();

    const supplierRates = supplierCompletionRates(ingredient, ingredientPosts);
    const supplierQuestionnaireFilled = supplierRates?.reduce((acc, sq) => (sq === 100 ? acc + 1 : acc), 0);
    const completion = completionRate(ingredient, ingredientPosts);

    const supplierCount = ingredient.ingredientSuppliers?.length ?? 0;

    if (posts.length === 0) {
        const url = URLHandler.ingredient.scores(selectedCompany?.id ?? 0, ingredient.id);
        const ingredientReference = (ingredientReferences ?? []).find(
            (ingredientReference: IngredientReference) => ingredient.ingredientReferenceId === ingredientReference.id,
        );

        const referenceScore = ingredientReference?.scores.find((score) => score.score?.id === sureScoreId);

        let message = "";
        let tooltipTitle = "";
        let tooltipBody = "";
        if (ingredientReference === undefined) {
            // No reference
            message = t("product.ingredient_nonmonolithic_no_reference.message");
            tooltipTitle = t("product.ingredient_nonmonolithic_no_reference.title");
            tooltipBody = t("product.ingredient_nonmonolithic_no_reference.body");
        } else if (referenceScore === undefined) {
            // Reference exists but without score
            message = t("product.ingredient_nonmonolithic_reference_without_score.message");
            tooltipTitle = t("product.ingredient_nonmonolithic_reference_without_score.title");
            tooltipBody = t("product.ingredient_nonmonolithic_reference_without_score.body");
        } else {
            // Reference exists with current score
            message = t("product.ingredient_nonmonolithic_reference_with_score.message");
            tooltipTitle = t("product.ingredient_nonmonolithic_reference_with_score.title");
            tooltipBody = t("product.ingredient_nonmonolithic_reference_with_score.body", {
                ingredient: ingredientReference.name,
                value: referenceScore.value,
                unit: unit,
            });
        }

        return (
            <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} gap={5}>
                <Typography flex={1} variant="h4">
                    {ingredient.name}
                </Typography>
                <Stack direction={"row"} justifyContent={"center"} gap={1}>
                    <Typography variant="body">{message}</Typography>
                    <FormTooltip
                        arrow
                        placement="top"
                        title={
                            <Box padding="8px" textAlign="center">
                                <Typography variant="h5">{tooltipTitle}</Typography>
                                <Typography variant="body">{tooltipBody}</Typography>
                            </Box>
                        }
                    >
                        <Stack width="36px" height="36px">
                            <FoodsIcon icon="information" size={3} />
                        </Stack>
                    </FormTooltip>
                    <NavLink to={url} color="black" target="_blank">
                        <FoodsIcon icon="openNewTab" size={3} />
                    </NavLink>
                </Stack>
            </Stack>
        );
    }

    const url = URLHandler.ingredient.ssq(selectedCompany?.id ?? 0, ingredient.id, undefined);

    return (
        <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} gap={5}>
            <Stack flex={2}>
                <Typography flex={1} variant="h4">
                    {ingredient.name}
                </Typography>
                <Stack direction={"row"} alignItems={"center"} gap={0.5}>
                    <FoodsIcon icon="inProgress" size={2} />
                    <Typography variant="small-bold">
                        {supplierQuestionnaireFilled}/{supplierCount} {t("Suppliers")}
                    </Typography>
                </Stack>
            </Stack>
            <Stack direction={"row"} flex={1}>
                <Box flex={1}>
                    <LinearProgress value={completion} />
                </Box>
                <NavLink to={url} color="black" target="_blank">
                    <FoodsIcon icon="openNewTab" size={3} />
                </NavLink>
            </Stack>
        </Stack>
    );
};
