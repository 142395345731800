import "typeface-montserrat";

import * as Sentry from "@sentry/react";
import { Fragment } from "react";
import { CssBaseline } from "@mui/material";
import {
    createBrowserRouter,
    createRoutesFromElements,
    Outlet,
    Route,
    RouterProvider,
    ScrollRestoration,
} from "react-router-dom";
import { Head } from "./components/Head";
import { BaseTemplate } from "./pages/BaseLayout/BaseTemplate.tsx";
import { useAuthRouter } from "./pages/Authentication/AuthRouter";
import { useInexwebRouter } from "./pages/Inexweb/InexwebRouter.tsx";
import { GenericError } from "./components/GenericError.tsx";
import { t } from "i18next";
import { removeInexwebInfo } from "./utils/token.ts";
import { ProtectedContextProvider } from "src/context/ProtectedContext";
import { useSettingsRouter } from "./pages/Settings/useSettingsRouter.tsx";

import { useApplicationParameters } from "./api/client-api/appParams/useApplicationParameters.ts";
import { useCompanyRouter } from "./pages/Company/companyRouter.tsx";
import { usePagesRouter } from "./pages/CMS/usePagesRouter.tsx";
import { HomeSwitch } from "./pages/HomeSwitch/HomeSwitch.tsx";
import { LocalStorage } from "./services/LocalStorage.ts";
import Documentation from "./pages/Documentation/Documentation.tsx";
import { useStrategyRouter } from "./pages/Strategy/strategyRouter.tsx";
import { UserProvider } from "./context/User/provider.tsx";

const App = () => {
    // https://github.com/remix-run/react-router/discussions/10160
    // This thread explains why we use the form of a react hook.
    // This message specifically :
    // https://github.com/remix-run/react-router/discussions/10160#discussioncomment-5958171
    const authRouter = useAuthRouter();
    const inexwebRouter = useInexwebRouter();
    const companyRouter = useCompanyRouter();
    const pagesRouter = usePagesRouter();
    const settingsRouter = useSettingsRouter();
    const strategyRouter = useStrategyRouter();

    const { applicationParameters, isError } = useApplicationParameters();

    if (isError) {
        // This means you're trying to access an environment that doesn't exist.
        return <GenericError message={t("Page inexistante")} />;
    }
    if (applicationParameters === undefined) {
        return <div>loading ...</div>;
    }

    const currentLanguage = LocalStorage.getItem("language");
    if (currentLanguage !== null) {
        document.documentElement.lang = currentLanguage;
    }

    if (applicationParameters.tabTitle !== undefined && applicationParameters.tabTitle !== "") {
        document.title = `${applicationParameters.tabTitle}`;
    }

    if (applicationParameters.tabIcon !== undefined) {
        const favIcon = document.getElementById("favicon");
        favIcon?.setAttribute("href", applicationParameters.tabIcon);
    }

    const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

    const appRouter = sentryCreateBrowserRouter(
        createRoutesFromElements(
            <Route path="/" element={<GlobalLayout />}>
                {authRouter}
                <Route element={<AuthProvider />}>
                    <Route element={<ProtectedRoute />}>
                        <Route index element={<HomeSwitch />} />
                        {companyRouter}
                        {pagesRouter}
                        {settingsRouter}
                        {strategyRouter}

                        <Route path="documentation" element={<Documentation />} />
                        {/* This is the only exception we want to accept in this application
                            so far. Everything else must be part of the One App philosophy */}
                        {inexwebRouter}
                    </Route>
                </Route>
            </Route>,
        ),
    );

    if (applicationParameters.mode === "inexweb") {
        removeInexwebInfo();
    }

    return (
        <Fragment>
            <Head />
            <CssBaseline />
            <RouterProvider router={appRouter} />
        </Fragment>
    );
};

export default Sentry.withProfiler(App);

const AuthProvider = () => {
    return (
        <UserProvider>
            <Outlet />
        </UserProvider>
    );
};

const ProtectedRoute = () => {
    return (
        <ProtectedContextProvider>
            <BaseTemplate>
                <Outlet />
            </BaseTemplate>
        </ProtectedContextProvider>
    );
};

const GlobalLayout = () => {
    return (
        <Fragment>
            <ScrollRestoration />
            <Outlet />
        </Fragment>
    );
};
