import { useMutation, useQueryClient } from "react-query";
import { loggedInApiCall } from "src/api/axiosClient/userApi";
import { getSomeActionPost } from "./useGetSomeActionPost";

type DeleteActionPost = {
    actionId: number;
    ssqPostId: number;
};
export const useDeleteActionPost = () => {
    const queryClient = useQueryClient();
    const query = useMutation({
        mutationFn: async (options: DeleteActionPost) => {
            const { actionId, ssqPostId } = options;
            const url = `/api/action_ssq_posts/${actionId}/${ssqPostId}`;
            const response = await loggedInApiCall.delete(url);
            return response;
        },
        onSuccess: () => queryClient.invalidateQueries([getSomeActionPost]),
    });

    return query;
};
