import { Box, Typography } from "@mui/material";
import ComparisonTable from "./ComparisonTable";
import { useTranslation } from "react-i18next";

export default function Comparison() {
    const { t } = useTranslation();

    return (
        <Box>
            <Typography variant="h4" gutterBottom>
                Synthèse 2019 VS 2023
            </Typography>
            <ComparisonTable />
        </Box>
    );
}
