import { useGetAllActions } from "src/api/sure-api/actions/useGetAllActions";
import {
    ActionsContext,
    ActionsContextInitialValues,
    ActionsDataMapping,
    ActionsCsrMapping,
    ActionData,
    ActionsCsr,
} from "./context";
import { Post } from "src/api/client-api/post/postSchema";
import { Score } from "src/types";
import { Scores } from "src/api/client-api/post/scoreSchema";
import { ScoreState } from "../EntityBasedScore/useEntityBasedScoreParams";
import { ScoreService } from "../EntityBasedScore/ScoreService";
import { Ssq } from "src/api/sure-api/ssq/useGetOneSsq";
import { useEffect, useMemo, useState } from "react";
import { ActionService } from "./ActionService";
import { useGetSomeActionPost } from "src/api/client-api/actionPost/useGetSomeActionPost";
import { useSavingStatus } from "src/components/Loader/useSavingStatus";
import { GetOnePost } from "src/api/client-api/post/useGetOnePost";

export type PostWithMetadata = {
    campaign: string;
    post: Post;
};
export type PostExtra = {
    scores: Scores;
};

export type ActionUrls = {
    planning: () => string;
    synthesis: () => string;
    editPlanning: (actionId: number) => string;
    chart: (scoreId: number) => string;
};

type ActionsProviderProps = {
    children: JSX.Element;

    initialPostId: number;
    allSimulations: Post[] | undefined;
    startingPost: {
        post: Post | null;
        originalPost: GetOnePost | null;
    };
    lastExistingPost?: PostWithMetadata & Partial<PostExtra>;
    ssq: Ssq;
    scoresEnabled: Score[];
    scoreState: ScoreState;
    urls: ActionUrls;
};
export const ActionsProvider = (props: ActionsProviderProps) => {
    const { startingPost, lastExistingPost, allSimulations, scoresEnabled, scoreState, ssq, urls } = props;

    const [isLocked, setIsLocked] = useState<boolean>(false);
    const savingState = useSavingStatus();

    useEffect(() => {
        setIsLocked(savingState.isSaving);
    }, [savingState.isSaving]);

    const { actions } = useGetAllActions({ ssqId: ssq.id });
    const allActionIds = actions?.map((item) => item.id) ?? [];

    const _allSimulations = allSimulations ?? [];
    const firstSimulation = _allSimulations.length > 0 ? _allSimulations[0] : null;
    const [selectedSimulation, _setSelectedSimulation] = useState<Post | null>(firstSimulation);

    const { actionSsqPosts } = useGetSomeActionPost({ ids: allActionIds, ssqPostId: selectedSimulation?.id });

    useEffect(() => {
        if (_allSimulations.length > 0) {
            _setSelectedSimulation(_allSimulations[0]);
            return;
        }
        _setSelectedSimulation(null);
    }, [allSimulations]);

    const simulationScores = selectedSimulation?.scores ?? {};
    const scoreIdForProperty = scoreState.lastSelectedVariation?.scoreId;
    const scoreByProperties = ScoreService.getScoreProperties(scoreIdForProperty, simulationScores);
    const scoreByProperties_start = ScoreService.getScoreProperties(
        scoreIdForProperty,
        startingPost?.post?.scores ?? {},
    );

    const allScoresByProperties = {
        start: scoreByProperties_start,
        target: scoreByProperties,
    };

    const allScoreWithVariations = ScoreService.getAllScoreVariations(
        scoresEnabled,
        selectedSimulation?.scores,
        (scoreId, scoreVariationId) => {
            scoreState.selectVariation(scoreId, scoreVariationId);
        },
    );

    const selectedScore = allScoreWithVariations.find((score) => {
        const doesScoreMatch = score.scoreId === scoreState.lastSelectedVariation?.scoreId;
        const doesVariationMatch = score.variationId === scoreState.lastSelectedVariation?.scoreVariationId;
        return doesScoreMatch && doesVariationMatch;
    });
    const selectedScoreDetail = scoresEnabled.find((s) => s.id === selectedScore?.scoreId);

    const actionsByCsrTheme = ActionService.groupByCsrThemes(actions ?? []);

    const csrAcc: ActionsCsrMapping = {};
    const allActionsDataByCsr = Object.entries(actionsByCsrTheme).reduce((csrAcc, [csrThemeKey, actions]) => {
        if (actions === undefined) return csrAcc;

        const csrData: ActionsCsr = { totalScore: null, data: {} };
        const allActionsFromCsrTheme = actions.reduce<ActionsDataMapping>((actionsAcc, action) => {
            const allElementsForPropertyID = ActionService.matchByPropertyId(
                allScoresByProperties,
                action.ssqProperties,
                startingPost.post,
                selectedSimulation,
                lastExistingPost?.post,
                selectedScoreDetail?.precision,
            );

            const scoreBlock = Object.values(allElementsForPropertyID).reduce<number | null>((total, ssqProperty) => {
                const newTotal = ScoreService.addScore(total, ssqProperty?.score ?? null);
                return newTotal;
            }, null);

            csrData["totalScore"] = ScoreService.addScore(csrData["totalScore"], scoreBlock);

            const actionData: ActionData = {
                totalScore: scoreBlock,
                data: allElementsForPropertyID,
            };
            actionsAcc[action.id] = actionData;
            return actionsAcc;
        }, {});

        csrData["data"] = allActionsFromCsrTheme;
        csrAcc[csrThemeKey] = csrData;
        return csrAcc;
    }, csrAcc);

    const totalNumberOfActions = (actions ?? []).length;
    const items: ActionsContextInitialValues = {
        actions,
        actionsByCsrTheme,
        actionSsqPosts,
        actionsDataByCsr: allActionsDataByCsr,
        allScoreWithVariations,
        initialPostId: props.initialPostId,
        isLocked,
        setIsLocked,
        lastExistingPost,
        savingStatus: savingState,
        startingPost,
        selectedSimulation,
        scoresEnabled,
        scoreOptions: scoreState,
        selectedScore,
        totalNumberOfActions,
        urls,
    };

    return <ActionsContext.Provider value={items}>{props.children}</ActionsContext.Provider>;
};
