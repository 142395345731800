import { useScoreDetailContext } from "src/context/ScoreDetail/useScoreDetailContext.tsx";
import { ScoreDimensionValue } from "src/api/client-api/post/scoreSchema.ts";
import { useState } from "react";

/**
 * @TODO possible refactor of donut chart input
 * @param includeAdditionalScore
 */
export const useChartActions = (
    includeAdditionalScore = false,
): {
    actions: ((() => void) | undefined)[];
    selectedScore: ScoreDimensionValue | undefined;
    dialogOpen: boolean;
    dialogTitle: string;
    propertyId: number | undefined;
    setDialogOpen: (open: boolean) => void;
} => {
    const [selectedScore, setSelectedScore] = useState<ScoreDimensionValue>();
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [dialogTitle, setDialogTitle] = useState<string>("");
    const [propertyId, setPropertyId] = useState<number>();

    const { list, addSelectedValue, setSelectedScore: setScore, dimension } = useScoreDetailContext();

    const actions: ((() => void) | undefined)[] = Object.entries(list || {}).map(([key, value], index) => {
        if (
            Array.isArray(value.dimensions) &&
            value.dimensions.filter((dimension) => dimension.label !== "multivalues").length > 0
        ) {
            return () => {
                addSelectedValue(key, 0, value, includeAdditionalScore ? index + 1 : index);
                setScore(value);
            };
        } else if (dimension?.label === "properties") {
            return () => {
                setSelectedScore(value);
                setPropertyId(Number(key));
                setDialogTitle(dimension.values[key].label);
                setDialogOpen(true);
            };
        }
    });

    return {
        actions,
        selectedScore,
        dialogOpen,
        setDialogOpen,
        dialogTitle,
        propertyId,
    };
};
