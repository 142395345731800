import { FoodsSelect } from "@foodpilot/foods";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useUpsertAppParam } from "src/api/client-api/appParams";
import { AppParamMode, AppParamModeKeys } from "src/components/ClientSwitch/useClientSwitch";

type ModeProps = {
    currentMode: string | null;
};
export const Mode = (props: ModeProps) => {
    const { currentMode } = props;
    const upsertAppParam = useUpsertAppParam();
    const { t } = useTranslation();

    return (
        <Box>
            <Typography variant="big-bold">{`Application Mode`}</Typography>
            <FoodsSelect
                selectedOption={currentMode}
                options={["bred", "collective", "foodpilot", "inexweb", "foodpilotLustucru"] as AppParamModeKeys[]}
                onChange={(selectedItem) => {
                    if (selectedItem === null) return;

                    upsertAppParam.mutate({
                        isNew: currentMode === null ? true : false,
                        key: "mode",
                        value: selectedItem,
                    });
                }}
                getId={(item) => item}
                getName={(item) => item}
                textOptions={{
                    placeholder: t("component.select.placeholder"),
                    searchPlaceholder: t("component.select.searchPlaceholder"),
                    emptyOptionsText: t("component.select.noOptions"),
                    defaultValueText: t("component.select.defaultValue"),
                    noResult: t("component.select.noResultFound"),
                }}
            />
        </Box>
    );
};
