import { useMutation, useQueryClient } from "react-query";
import { loggedInApiCall } from "src/api/axiosClient/userApi";
import { z } from "zod";
import { getAllBrandsKey } from "./useGetAllBrand";

type CreateBrand = {
    brand: BrandWrite;
};

export const useCreateBrand = () => {
    const queryClient = useQueryClient();
    const query = useMutation({
        mutationFn: async (options: CreateBrand) => {
            const { brand } = options;

            const response =
                brand.id === undefined ?
                    await loggedInApiCall.post("/api/brands", brand)
                :   await loggedInApiCall.put("/api/brands/" + brand.id, brand);

            return response;
        },
        onSuccess: () => queryClient.invalidateQueries([getAllBrandsKey]),
    });

    return query;
};

export type BrandWrite = {
    id?: number;
    name: string;
    company: IRI;
    logoUrl?: string | null;
};

export const BrandWriteSchema: z.ZodSchema<BrandWrite> = z.object({
    id: z.number().optional(),
    name: z.string(),
    company: z.string(),
    logoUrl: z.string().optional(),
});
