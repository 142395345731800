import { useMutation, useQueryClient } from "react-query";
import { loggedInApiSingleConcurrentCall } from "src/api/axiosClient/userApi";
import { z } from "zod";
import { PostSchema } from "src/api/client-api/post/postSchema";
import { getOneSupplierKey } from "../suppliers/useGetOneSupplier";
import { getOneIngredientKey } from "../ingredients/useGetOneIngredient";
import { getOneProductKey } from "../products/useGetOneProduct";
import { userMeKey } from "../useUserNew";
import { anyAllSimulationKey } from "../post/useGetAllPosts";
import { useSnackbar } from "src/utils/useSnackbar";
import { useTranslation } from "react-i18next";

type CalculationType = "action" | "questionnaire";

export type UpdateProperty = {
    postId: number;
    propertyId: number;
    value: number | string;
    position?: number;
    isGenericValue?: boolean;
    calculationType?: CalculationType;
    dataListOptionIds?: number[];
};

export const updatePropertyFn = async (options: UpdateProperty) => {
    const {
        postId,
        propertyId,
        value,
        isGenericValue = null,
        position = 0,
        calculationType = "questionnaire",
        dataListOptionIds = null,
    } = options;

    return await loggedInApiSingleConcurrentCall.post(
        `/api/ssq_posts/${postId}/property/${propertyId}/calculate`,
        {
            value: value,
            position: position,
            isGenericValue: isGenericValue,
            calculationType: calculationType,
            dataListOptionIds: dataListOptionIds,
        },
        {
            headers: {
                Accept: "application/json",
            },
        },
    );
};

export const useUpdateProperty = () => {
    const queryClient = useQueryClient();
    const snackbar = useSnackbar();
    const { t } = useTranslation();

    const query = useMutation({
        mutationFn: updatePropertyFn,
        onSuccess: (result) => {
            // Don't remove this log as it serves the product team
            console.log("Calculation result", result.data);
            queryClient.invalidateQueries([getOneSupplierKey]);
            queryClient.invalidateQueries([getOneIngredientKey]);
            queryClient.invalidateQueries([getOneProductKey]);

            // This shouldn't exist, as the score for now in the user is for a COMPANY.
            queryClient.invalidateQueries([userMeKey]);

            queryClient.invalidateQueries([anyAllSimulationKey]);
        },
        onError: () => {
            snackbar.forceClose();
            snackbar.setSnackbarProps({
                type: "error",
                message: t("calculation.unexpectedError"),
            });
        },
    });

    return query;
};

export const PostElementWriteSchema = z.object({
    value: z.string(),
    position: z.number(),
    ssqPropertyId: z.number().optional(),
    name: z.string().optional(),
    unit: z.string().optional(),
    isGenericValue: z.boolean().optional(),
    ssqHeadingId: z.number().optional(),
    dataListOptionIds: z.array(z.number()).optional(),
});

export type PostElementWrite = z.infer<typeof PostElementWriteSchema>;

export const PostWithWriteableElementsSchema = PostSchema.extend({
    elements: PostElementWriteSchema.array().optional(),
});

export type PostWithWriteableElements = z.infer<typeof PostWithWriteableElementsSchema>;
