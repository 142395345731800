/* eslint-disable --
    Disable everything. This file is garbage.
*/
import React, { ReactElement, FC, useEffect, useState } from "react";
import { getSureApiUrl } from "../api/axiosClient/utils";
import { sureApiCall } from "../api/axiosClient/sureApi";
import {
    Button,
    Box,
    Container,
    Typography,
    TextField,
    OutlinedInput,
    MenuItem,
    Divider,
    Grid,
    Stack,
    SvgIcon,
} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useNavigate, useRouteLoaderData, useSearchParams } from "react-router-dom";
//import { OAuthPopup, useOAuth2 } from "@tasoskakour/react-use-oauth2";
import { useOAuth2 } from "../utils/useOAuth2";
import OAuthPopup from "../components/OAuth2Popup";
import { FormProvider, useForm } from "react-hook-form";
import { coerce, infer as Infer, TypeOf, number, object, string, enum as zodEnum } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { IPeriodEntries, IExtraFinancialReport } from "../api/types";
import Logo from "../assets/icons/positive-logo.svg?react";
import { useTranslation } from "react-i18next";
import { addReportFn, addReportNewFn, getReportFn } from "../api/inexwebApi";
import TableDisplay from "../components/TableDisplay";

import { AccountingSelection } from "../components/Inexweb/AccountingSelection";
import { InexwebHome } from "./Inexweb/InexwebHome";
import useLocalStorageState from "use-local-storage-state";
import { getInexwebInfo, setInexwebInfo } from "src/utils/token";
import { getEnv } from "src/utils/getEnv";
import { useFoodpilotContext } from "src/context/FoodpilotContext";

/**
 * Achats d'énergie ? (606.1) => liste des comptes disponibles avec montant.
   - abonnement énergie (montant mensuel)
   - fluides (recharges de fluide frigorigène pour les clims) ? (603.1) + afficher la liste issue du grand livre qu'il peut sélectionner.
   - combustibles (carburant voiture ou camion, fenwick, etc.) donner proportion et les sous-comptes?(602.21 ou 606.1)
   - Bouteilles ou conteneurs de gaz ? proportion ?
   - Panneaux solaires ? (production en kWh) => En suspens

____________ Compte de fin d'exercice nécessaire pour pouvoir réaliser le rapport ____________
   - CA validé sur l'exercice => saisie
   - Production en tonnes s'il y a lieu
   - Effectif => saisie
 */
const schema = object({
    id: coerce.number().optional(),
    energie: string().optional(),
    abonnement: string().optional(),
    fluides: string().optional(),
    combustibles: string().optional(),
    gaz: string().optional(),
    panneaux: string().optional(),
    ca: number().optional(),
    production: number().optional(),
    effectif: number().optional(),
});

export type InexwebInput = TypeOf<typeof schema>;

export const Inexweb: FC<any> = (): ReactElement => {
    const navigate = useNavigate();
    const { user } = useFoodpilotContext();
    const { t, i18n } = useTranslation();
    const { data, loading, error, getAuth, logout } = useOAuth2({
        authorizeUrl: "https://store.inexweb.fr/authorize",
        clientId: getEnv("VITE_INEXWEB_CLIENT_ID"),
        redirectUri: `${document.location.origin}/api/auth/callback`,
        scope: "email",
        responseType: "code",
        exchangeCodeForTokenServerURL: `${getSureApiUrl()}/api/auth/callback`,
        exchangeCodeForTokenMethod: "POST",
        onSuccess: (payload: any) => {
            console.log("Success2", payload);
            const json = JSON.parse(payload);
            setAccessToken(json.access_token);
            setInexwebInfo(
                json.access_token,
                json.company,
                json.company_corporate_name,
                json.company_identification_number,
            );
            navigate("/inexweb/form/0");
        },
        onError: (error_: any) => console.log("Error", error_),
    });

    const [accessToken, setAccessToken] = React.useState<string | null>(null);
    const [searchParams, setSearchParams] = useSearchParams();

    const { token } = getInexwebInfo();

    // Handle the logic when user is redirected from the form after clicking "Changer d'entreprise"
    const auth = !!searchParams.get("auth");
    const [getAuthHasBeenTriggered, setgetAuthHasBeenTriggered] = useState(false);

    useEffect(() => {
        if (auth && !getAuthHasBeenTriggered) {
            getAuth();
            setgetAuthHasBeenTriggered(true);
        }
    }, [auth, getAuthHasBeenTriggered]);

    useEffect(() => {
        if (token) navigate("/inexweb/form/0");
    }, [token]);
    const [accounts, setAccounts] = React.useState<any>([]);
    const [fiscalYears, setFiscalYears] = React.useState<any>([]);
    const [fiscalYear, setFiscalYear] = React.useState<any>(null);
    const [selectedFiscalYearIndex, setSelectedFiscalYearIndex] = React.useState<string>("");
    const [previousFiscalYear, setPreviousFiscalYear] = React.useState<any>(null);
    const [energyTypes, setEnergyTypes] = React.useState<any>([]);
    const [turnover, setTurnover] = React.useState<number>(0);
    const [production, setProduction] = React.useState<number>(0);
    const [headCount, setHeadCount] = React.useState<number>(0);
    const [previousTurnover, setPreviousTurnover] = React.useState<number>(0);
    const [previousProduction, setPreviousProduction] = React.useState<number>(0);
    const [previousHeadCount, setPreviousHeadCount] = React.useState<number>(0);
    const [displayReport, setDisplayReport] = React.useState<boolean>(false);
    const [result, setResult] = React.useState<any>(null);
    const [displayResult, setDisplayResult] = React.useState<boolean>(false);
    const [reportUrl, setReportUrl] = React.useState<string>("");

    const [pdfString, setPdfString] = React.useState("");
    const [numPages, setNumPages] = React.useState(null);
    const [pageNumber, setPageNumber] = React.useState(1);

    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    // [
    //   {
    //     'type': 'account',
    //     'subtype': '606.1',
    //     'account': '606.1',
    //     'label': 'Achats d\'énergie',
    //     'amount': 0
    //   }
    // ]

    const [periodEntries, setPeriodEntries] = React.useState<IPeriodEntries>({});

    const methods = useForm<InexwebInput>({
        mode: "onBlur",
        resolver: zodResolver(schema),
    });

    const {
        formState: { errors },
        register,
        control,
        reset,
        handleSubmit,
        formState: { isSubmitSuccessful },
    } = methods;

    const fetchEntries = async (
        type: string,
        subtype: string,
        energyAccount: string,
        dateStart: string,
        dateEnd: string,
    ) => {
        console.log("dateStart", dateStart, "dateEnd", dateEnd);
        if (typeof dateStart === "undefined" || typeof dateEnd === "undefined") {
            return [];
        }
        const url = `/api/inexweb/accounting/entries?account=${energyAccount}&date_start=${dateStart}&date_end=${dateEnd}`;
        console.log("url", url);

        const response = await sureApiCall.get<any>(url, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-Company": JSON.parse(localStorage.getItem("inexweb_company") || '""'),
                "X-Token": accessToken,
            },
        });

        const entries = response.data.entries.map((entry: any) => ({
            type: type,
            subtype: subtype,
            ...entry,
        }));

        return entries;
    };

    const checkToken = async (accessToken: string) => {
        try {
            const response = await sureApiCall.get<any>(`/api/inexweb/accounting/preferences`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "X-Company": JSON.parse(localStorage.getItem("inexweb_company") || '""'),
                    "X-Token": accessToken,
                },
            });

            console.log("response", response.data);

            setAccessToken(JSON.parse(localStorage.getItem("inexweb_token") || '""'));
        } catch (error) {
            console.log("error checkToken", error);
            localStorage.removeItem("inexweb_token");
            localStorage.removeItem("inexweb_company");
            setAccessToken(null);

            return;
        }
    };

    useEffect(() => {
        if (!accessToken) {
            if (JSON.parse(localStorage.getItem("inexweb_token") || '""')) {
                checkToken(JSON.parse(localStorage.getItem("inexweb_token") || '""') as string);
            }

            return;
        }

        const fetchAccounts = async () => {
            const response = await sureApiCall.get<any>(`/api/inexweb/accounting/accounts`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "X-Company": JSON.parse(localStorage.getItem("inexweb_company") || '""'),
                    "X-Token": accessToken,
                },
            });

            console.log("response", response.data);
            const accounts = response.data.map((account: any) => ({
                id: account.number,
                label: `${account.number} - ${account.label}`,
            }));
            console.log("accounts", accounts);

            setAccounts(accounts);
        };

        const fetchFiscalyears = async () => {
            const response = await sureApiCall.get<any>(`/api/inexweb/accounting/fiscal_years`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "X-Company": JSON.parse(localStorage.getItem("inexweb_company") || '""'),
                    "X-Token": accessToken,
                },
            });

            console.log("response", response.data);
            setFiscalYears(response.data.exercices);
        };

        const energyTypes = async () => {
            const response = await sureApiCall.get<any>(`/api/energy_types`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            });

            console.log("response", response.data);
            setEnergyTypes(response.data);
        };

        energyTypes();
        fetchFiscalyears();
        fetchAccounts();
    }, [accessToken]);

    const handleFiscalChange = (event: SelectChangeEvent) => {
        console.log("event.target.value", event.target.value);
        const selectedFiscalYear = {
            start: fiscalYears[event.target.value]?.datestart,
            end: fiscalYears[event.target.value]?.dateend,
        };
        const previousFiscalYear = {
            start: fiscalYears[event.target.value + 1]?.datestart,
            end: fiscalYears[event.target.value + 1]?.dateend,
        };
        setSelectedFiscalYearIndex(event.target.value);
        setFiscalYear(selectedFiscalYear);
        setPreviousFiscalYear(previousFiscalYear);
    };

    const handleDelete = async (type: string, subtype: string, account: string, value: string | null) => {
        const val = value as unknown as any;
        console.log("val", val);
        console.log("handleDelete", type, subtype, account, value);
        const newPeriodEntries = { ...periodEntries };
        const newPeriodEntriesType = newPeriodEntries[subtype] || {};
        if (newPeriodEntriesType["entries"]) {
            newPeriodEntriesType["entries"] = newPeriodEntriesType["entries"].filter(
                (entry: any) => entry.account !== account,
            );
        }
        if (newPeriodEntriesType["previousEntries"]) {
            newPeriodEntriesType["previousEntries"] = newPeriodEntriesType["previousEntries"].filter(
                (entry: any) => entry.account !== account,
            );
        }
        newPeriodEntries[subtype] = newPeriodEntriesType;
        setPeriodEntries(newPeriodEntries);
    };

    const handleChange = async (
        type: string,
        subtype: string,
        event: React.SyntheticEvent<Element, Event>,
        value: string | null,
    ) => {
        const val = value as unknown as any;
        console.log("val", val);

        const newPeriodEntries = { ...periodEntries };

        console.log("newPeriodEntries", newPeriodEntries);

        const newPeriodEntriesType = newPeriodEntries[subtype] || {};
        if (val === null) {
            if (!newPeriodEntries[subtype]) return;
            delete newPeriodEntries[subtype];
            setPeriodEntries(newPeriodEntries);
            return;
        }

        const entries = await fetchEntries(type, subtype, val.id, fiscalYear?.start, fiscalYear?.end);
        const previousEntries = await fetchEntries(
            type,
            subtype,
            val.id,
            previousFiscalYear?.start,
            previousFiscalYear?.end,
        );
        newPeriodEntriesType["account"] = val.id;
        if (newPeriodEntries[subtype] && newPeriodEntries[subtype]["entries"]) {
            newPeriodEntriesType["entries"] = [...newPeriodEntries[subtype]["entries"], ...entries];
        } else {
            newPeriodEntriesType["entries"] = entries;
        }

        if (newPeriodEntries[subtype] && newPeriodEntries[subtype]["previousEntries"]) {
            newPeriodEntriesType["previousEntries"] = [
                ...newPeriodEntries[subtype]["previousEntries"],
                ...previousEntries,
            ];
        } else {
            newPeriodEntriesType["previousEntries"] = previousEntries;
        }
        newPeriodEntries[subtype] = newPeriodEntriesType;

        setPeriodEntries(newPeriodEntries);
    };

    const prepareData = async () => {
        setIsLoading(true);
        const reportData: { [key: string]: any } = {};
        reportData["xCompany"] = JSON.parse(localStorage.getItem("inexweb_company") || "");
        reportData["period"] = {};
        reportData["period"]["datestart"] = fiscalYear?.start;
        reportData["period"]["dateend"] = fiscalYear?.end;
        reportData["period"]["headCount"] = headCount;
        reportData["period"]["turnover"] = turnover;
        reportData["period"]["production"] = production;
        let entries: any = [];
        for (const [key, value] of Object.entries(periodEntries)) {
            console.log("value", value);
            entries = [...entries, ...value["entries"]];
        }
        reportData["period"]["entries"] = entries;
        reportData["previousPeriod"] = {};
        reportData["previousPeriod"]["datestart"] = previousFiscalYear?.start;
        reportData["previousPeriod"]["dateend"] = previousFiscalYear?.end;
        reportData["previousPeriod"]["headCount"] = previousHeadCount;
        reportData["previousPeriod"]["turnover"] = previousTurnover;
        reportData["previousPeriod"]["production"] = previousProduction;
        let previouEntries: any = [];
        for (const [key, value] of Object.entries(periodEntries)) {
            console.log("value", value);
            previouEntries = [...previouEntries, ...value["previousEntries"]];
        }
        reportData["previousPeriod"]["entries"] = previouEntries;
        console.log("reportData", reportData);

        //await addExtraFinancialReportFn(report);
        const result = await addReportFn(reportData);
        if (result && result.token) {
            const reportDone = await getReportFn(result.token);
            //console.log("reportDone", reportDone);
            if (reportDone) {
                setIsLoading(false);
                setPdfString(URL.createObjectURL(reportDone));
                setDisplayReport(true);
                // const url = window.URL.createObjectURL(reportDone);
                // const a = document.createElement('a');
                // a.style.display = 'none';
                // a.href = url;
                // a.download = 'report.pdf';
                // a.id = 'downloadPdf';
                // document.body.appendChild(a);
                // a.click();
                // window.URL.revokeObjectURL(url);
                // document.getElementById('downloadPdf')?.remove();
            }
        }
    };

    const prepareDataNew = async () => {
        setIsLoading(true);
        const reportData: { [key: string]: any } = {};
        reportData["xCompany"] = JSON.parse(localStorage.getItem("inexweb_company") || "");
        reportData["period"] = {};
        reportData["period"]["datestart"] = fiscalYear?.start;
        reportData["period"]["dateend"] = fiscalYear?.end;
        reportData["period"]["headCount"] = headCount;
        reportData["period"]["turnover"] = turnover;
        reportData["period"]["production"] = production;
        let entries: any = [];
        for (const [key, value] of Object.entries(periodEntries)) {
            console.log("value", value);
            entries = [...entries, ...value["entries"]];
        }
        reportData["period"]["entries"] = entries;
        reportData["previousPeriod"] = {};
        reportData["previousPeriod"]["datestart"] = previousFiscalYear?.start;
        reportData["previousPeriod"]["dateend"] = previousFiscalYear?.end;
        reportData["previousPeriod"]["headCount"] = previousHeadCount;
        reportData["previousPeriod"]["turnover"] = previousTurnover;
        reportData["previousPeriod"]["production"] = previousProduction;
        let previouEntries: any = [];
        for (const [key, value] of Object.entries(periodEntries)) {
            console.log("value", value);
            previouEntries = [...previouEntries, ...value["previousEntries"]];
        }
        reportData["previousPeriod"]["entries"] = previouEntries;
        console.log("reportData", reportData);

        const report: IExtraFinancialReport = {
            user: `/api/users/${user.trustedUser.id}`,
            externalAccountId: JSON.parse(localStorage.getItem("inexweb_company") || "") ?? "",
            parameters: reportData,
        };

        const result = await addReportNewFn(report);
        console.table(result.report.previousPeriod);
        console.table(result.report.period);
        setResult(result.report);
        setIsLoading(false);
        setDisplayResult(true);
        setReportUrl(`${getSureApiUrl()}/${result.filename}`);
    };

    // standardFontDataUrl: 'standard_fonts/',
    const options = {
        cMapUrl: "cmaps/",
        cMapPacked: true,
    };

    return (
        <Container component="main" maxWidth={false} sx={{ m: "0", p: "0 !important", flex: "1 1 auto" }}>
            {!accessToken && <InexwebHome onStart={getAuth} />}
            {accessToken && (
                <>
                    {isLoading && (
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "100%",
                            }}
                        >
                            <Typography variant="h2" sx={{ mt: "2rem" }}>
                                Votre rapport est en cours de préparation.
                            </Typography>
                            <Typography variant="body1" sx={{ mt: "2rem" }}>
                                Nous réalisons les calculs et analysons les résultats pour vous...
                            </Typography>
                        </Box>
                    )}
                    {!isLoading && displayReport && (
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "100%",
                            }}
                        >
                            <Typography variant="h2" sx={{ mt: "2rem" }}>
                                Votre rapport est prêt !
                            </Typography>
                            <Typography variant="body1" sx={{ mt: "2rem" }}>
                                Vous pouvez le télécharger en cliquant sur le lien ci-dessous :
                            </Typography>
                            <a href={pdfString} target="_blank" rel="noreferrer">
                                Ouvrir mon rapport extra-financier
                            </a>
                            <Box sx={{ pt: 4, mt: 2 }} />
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{ height: "50px" }}
                                onClick={() => {
                                    setDisplayReport(false);
                                    localStorage.removeItem("inexweb_token");
                                    localStorage.removeItem("inexweb_company");
                                    setAccessToken(null);
                                }}
                            >
                                Retourner sur mon formulaire de saisie
                            </Button>
                            <Box sx={{ pt: 4, mt: 2 }} />
                        </Box>
                    )}
                    {!isLoading && displayResult && (
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "100%",
                            }}
                        >
                            <Box sx={{ pt: 4, mt: 2 }} />
                            <Typography variant="h2" sx={{ mt: "2rem" }}>
                                {result.previousPeriod.year}
                            </Typography>
                            <TableDisplay data={result.previousPeriod} />
                            <Box sx={{ pt: 4, mt: 2 }} />
                            <Typography variant="h2" sx={{ mt: "2rem" }}>
                                {result.period.year}
                            </Typography>
                            <TableDisplay data={result.period} />

                            <Box sx={{ pt: 4, mt: 2 }} />
                            <a href={reportUrl} target="_blank" rel="noreferrer">
                                Télécharger mon rapport
                            </a>

                            <Box sx={{ pt: 4, mt: 2 }} />
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{ height: "50px" }}
                                onClick={() => {
                                    setDisplayResult(false);
                                }}
                            >
                                Retourner sur mon formulaire de saisie
                            </Button>
                            <Box sx={{ pt: 4, mt: 2 }} />
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{ height: "50px" }}
                                onClick={() => {
                                    setDisplayResult(false);
                                    localStorage.removeItem("inexweb_token");
                                    localStorage.removeItem("inexweb_company");
                                    setPeriodEntries({});
                                    setAccessToken(null);
                                }}
                            >
                                Changer de société
                            </Button>
                            <Box sx={{ pt: 4, mt: 2 }} />
                        </Box>
                    )}
                    {!isLoading && !displayReport && !displayResult && (
                        <>
                            <Box sx={{ p: 5 }}>
                                <Box>
                                    <Typography variant="h2">
                                        Le compte de votre entreprise est désormais connecté.
                                    </Typography>
                                    <Typography variant="body1" sx={{ mt: "2rem" }}>
                                        Nous allons maintenant procéder à la configuration de votre rapport
                                        extra-financier. Pour ce faire, nous allons avoir besoin de récolter les comptes
                                        de votre entreprise liés à l'un des chapitres du bilan extra-financier.
                                    </Typography>
                                </Box>
                                <FormProvider {...methods}>
                                    <Box>
                                        <Typography variant="body1" sx={{ mt: 2, mb: 2 }}>
                                            Veuillez sélectionner l'exercice comptable pour lequel vous souhaitez
                                            réaliser le rapport :
                                        </Typography>
                                        <Select
                                            input={<OutlinedInput />}
                                            sx={{
                                                width: "200px",
                                                height: "60px",
                                                "& .MuiSelect-outlined": { border: "none" },
                                            }}
                                            label="Année fiscale"
                                            onChange={handleFiscalChange}
                                            value={selectedFiscalYearIndex}
                                        >
                                            {fiscalYears
                                                .filter((year: any) => {
                                                    const lastYear = year.code.split("-").shift().trim();
                                                    return lastYear >= 2020;
                                                })
                                                .map((year: any, index: number) => {
                                                    return (
                                                        <MenuItem key={year.code} value={index}>
                                                            {year.code}
                                                        </MenuItem>
                                                    );
                                                })}
                                        </Select>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            width: "49%",
                                            pt: 5,
                                        }}
                                    >
                                        <Typography variant="h6" sx={{ mb: 1, pr: 5, fontWeight: 700, flex: 1 }}>
                                            Achats de carburant
                                        </Typography>
                                        <Divider sx={{ mb: 2 }} />
                                        {energyTypes
                                            .filter(
                                                (energyType: any) =>
                                                    energyType.category === "fuel" &&
                                                    (energyType.code == "fuel" || energyType.code == "gazole"),
                                            )
                                            .map((energyType: any, index: number) => (
                                                <AccountingSelection
                                                    key={index}
                                                    accounts={accounts}
                                                    periodEntries={periodEntries}
                                                    handleChange={handleChange}
                                                    handleDelete={handleDelete}
                                                    type={energyType.category}
                                                    subtype={energyType.code}
                                                    title={energyType.label}
                                                />
                                            ))}
                                    </Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            width: "49%",
                                            pt: 5,
                                        }}
                                    >
                                        <Typography variant="h6" sx={{ mb: 1, pr: 5, fontWeight: 700, flex: 1 }}>
                                            Achats d'énergie
                                        </Typography>
                                        <Divider sx={{ mb: 2 }} />
                                        {energyTypes
                                            .filter((energyType: any) => energyType.category === "gaz")
                                            .map((energyType: any, index: number) => (
                                                <AccountingSelection
                                                    key={index}
                                                    accounts={accounts}
                                                    periodEntries={periodEntries}
                                                    handleChange={handleChange}
                                                    handleDelete={handleDelete}
                                                    type={energyType.category}
                                                    subtype={energyType.code}
                                                    title={energyType.label}
                                                />
                                            ))}
                                    </Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            width: "49%",
                                            pt: 5,
                                        }}
                                    >
                                        <Typography variant="h6" sx={{ mb: 1, pr: 5, fontWeight: 700, flex: 1 }}>
                                            Achats d'électricité
                                        </Typography>
                                        <Divider sx={{ mb: 2 }} />
                                        {energyTypes
                                            .filter((energyType: any) => energyType.category === "electricity")
                                            .map((energyType: any, index: number) => (
                                                <AccountingSelection
                                                    key={index}
                                                    accounts={accounts}
                                                    periodEntries={periodEntries}
                                                    handleChange={handleChange}
                                                    handleDelete={handleDelete}
                                                    type={energyType.category}
                                                    subtype={energyType.code}
                                                    title={energyType.label}
                                                />
                                            ))}
                                    </Box>
                                    {/* <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "49%",
                      pt: 5,
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{ mb: 1, pr: 5, fontWeight: 700, flex: 1 }}
                    >
                      Achats de fluides frigorigènes
                    </Typography>
                    <Divider sx={{ mb: 2 }} />
                    {energyTypes
                      .filter(
                        (energyType: any) =>
                          energyType.category === "refrigerant"
                      )
                      .map((energyType: any, index: number) => (
                        <AccountingSelection
                          key={index}
                          accounts={accounts}
                          periodEntries={periodEntries}
                          handleChange={handleChange}
                          handleDelete={handleDelete}
                          type={energyType.category}
                          subtype={energyType.code}
                          title={energyType.label}
                        />
                      ))}
                  </Box> */}
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            width: "49%",
                                            pt: 5,
                                        }}
                                    >
                                        <Typography variant="h6" sx={{ mb: 1, pr: 5, fontWeight: 700, flex: 1 }}>
                                            Achats d'eau
                                        </Typography>
                                        <Divider sx={{ mb: 2 }} />
                                        {energyTypes
                                            .filter((energyType: any) => energyType.category === "water")
                                            .map((energyType: any, index: number) => (
                                                <AccountingSelection
                                                    key={index}
                                                    accounts={accounts}
                                                    periodEntries={periodEntries}
                                                    handleChange={handleChange}
                                                    handleDelete={handleDelete}
                                                    type={energyType.category}
                                                    subtype={energyType.code}
                                                    title={energyType.label}
                                                />
                                            ))}
                                    </Box>

                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            width: "49%",
                                            pt: 5,
                                        }}
                                    >
                                        <Typography variant="body1" sx={{ mb: 1, pr: 5, fontWeight: 700, flex: 1 }}>
                                            Quel a été votre CA validé sur l'exercice ? :
                                        </Typography>
                                        <TextField
                                            type="text"
                                            helperText="Exprimé en euros"
                                            onChange={(e) => setTurnover(e.target.value as unknown as number)}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={turnover}
                                        />
                                        <Typography variant="body1" sx={{ mb: 1, pr: 5, fontWeight: 700, flex: 1 }}>
                                            Quel a été votre CA validé sur l'exercice N-1 ? :
                                        </Typography>
                                        <TextField
                                            type="text"
                                            helperText="Exprimé en euros"
                                            onChange={(e) => setPreviousTurnover(e.target.value as unknown as number)}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={previousTurnover}
                                        />
                                    </Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            width: "49%",
                                            pt: 5,
                                        }}
                                    >
                                        <Typography variant="body1" sx={{ mb: 1, pr: 5, fontWeight: 700, flex: 1 }}>
                                            Votre production :
                                        </Typography>
                                        <TextField
                                            type="text"
                                            helperText="Exprimée en unités d'oeuvre"
                                            onChange={(e) => setProduction(e.target.value as unknown as number)}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={production}
                                        />
                                        <Typography variant="body1" sx={{ mb: 1, pr: 5, fontWeight: 700, flex: 1 }}>
                                            Votre production année N-1:
                                        </Typography>
                                        <TextField
                                            type="text"
                                            helperText="Exprimée en unités d'oeuvre"
                                            onChange={(e) => setPreviousProduction(e.target.value as unknown as number)}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={previousProduction}
                                        />
                                    </Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            width: "49%",
                                            pt: 5,
                                        }}
                                    >
                                        <Typography variant="body1" sx={{ mb: 1, pr: 5, fontWeight: 700, flex: 1 }}>
                                            Effectif de vos salariés (en ETP):
                                        </Typography>
                                        <TextField
                                            type="text"
                                            helperText="Y compris vos CDD et apprentis"
                                            onChange={(e) => setHeadCount(e.target.value as unknown as number)}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={headCount}
                                        />
                                        <Typography variant="body1" sx={{ mb: 1, pr: 5, fontWeight: 700, flex: 1 }}>
                                            Effectif de vos salariés (en ETP) N-1:
                                        </Typography>
                                        <TextField
                                            type="text"
                                            helperText="Y compris vos CDD et apprentis"
                                            onChange={(e) => setPreviousHeadCount(e.target.value as unknown as number)}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={previousHeadCount}
                                        />
                                    </Box>
                                </FormProvider>
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    width: "49%",
                                    p: 5,
                                    textAlign: "right",
                                }}
                            >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{ height: "50px" }}
                                    onClick={() => prepareDataNew()}
                                >
                                    Préparer le rapport
                                </Button>
                            </Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    width: "49%",
                                    p: 5,
                                    textAlign: "right",
                                }}
                            >
                                {/* <Button
                  variant="contained"
                  color="primary"
                  sx={{ height: "50px" }}
                  onClick={() => prepareData()}
                >
                  Préparer le rapport
                </Button> */}
                            </Box>
                        </>
                    )}
                </>
            )}
        </Container>
    );
};
