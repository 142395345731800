import { OptionsPopoverOption, ScoreCard, TrajectoryCards, TrajectoryCardsProps } from "@foodpilot/foods";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useActionsContext } from "src/context/Actions/useActionsContext";
import { useGetAllCampaigns } from "src/api/client-api/useCampaign";
import { ScoreService } from "src/context/EntityBasedScore/ScoreService";
import { useNavigate } from "react-router-dom";

export type ScoreOptions = {
    id: number;
    label: string;
    unit: string;
    group: string;
    scoreId: number;
    onClick: () => void;
};

export type ScoreComparisonCardProps = {
    selectedOption: ScoreOptions;
    allOptions: ScoreOptions[];

    position: TrajectoryCardsProps["position"];
    disableFooter?: boolean;
    precision?: number;
};
export const ScoreComparisonCard = (props: ScoreComparisonCardProps) => {
    const { position, disableFooter = false, allOptions, selectedOption, precision } = props;

    const navigate = useNavigate();
    const { startingPost, lastExistingPost, selectedSimulation, urls } = useActionsContext();
    const { campaigns } = useGetAllCampaigns();

    const [selectedItemId, setSelectedItemId] = useState<number | null>(null);

    const targetCampaign = campaigns.find((campaign) => campaign.isTarget === true);

    const data = allOptions.map((score) => {
        return {
            ...score,
            onClick: (option: OptionsPopoverOption) => {
                score.onClick();
                setSelectedItemId(Number(option.id));
            },
        };
    });

    useEffect(() => {
        const previouslySelectedVariation = allOptions.find((score) => score.id === selectedOption.id);
        if (previouslySelectedVariation) {
            return setSelectedItemId(previouslySelectedVariation.id);
        }
        if (data.length > 0) {
            return setSelectedItemId(data[0].id);
        }
    }, []);

    const _startScore = startingPost?.post?.scores?.[selectedOption.scoreId];
    const _startScoreCampaignLabel = startingPost?.post?.campaign?.label ?? "";
    const c1Label = `${_startScoreCampaignLabel} - BAU`;
    const startScore = ScoreService.getScore(_startScore, c1Label, precision, selectedOption.unit);

    const _currentScore = lastExistingPost?.scores?.[selectedOption.scoreId];
    const currentScoreCampaignLabel = lastExistingPost?.campaign ?? "";
    const currentScore = ScoreService.getScore(
        _currentScore,
        currentScoreCampaignLabel,
        precision,
        selectedOption.unit,
    );

    const _targetScore = selectedSimulation?.scores?.[selectedOption.scoreId];
    const targetScoreCampaignLabel = selectedSimulation?.campaign?.label ?? targetCampaign?.label ?? "";
    const targetScore = ScoreService.getScore(_targetScore, targetScoreCampaignLabel, precision, selectedOption.unit);
    const handleClick = () => {
        const url = urls.chart(selectedOption.id);
        selectedOption.onClick();
        navigate(url);
    };

    return (
        <Box sx={{ width: "100%" }}>
            <ScoreCard
                header={{
                    selectedItemId: selectedItemId,
                    options: data,
                }}
                disableFooter={disableFooter}
                moreDetailAction={handleClick}
            >
                <TrajectoryCards
                    steps={{
                        start: startScore,
                        current: _currentScore ? currentScore : null,
                        target: targetScore,
                    }}
                    position={position}
                    improvement={"decrease"}
                />
            </ScoreCard>
        </Box>
    );
};
