type TokenKeys = "token" | "refresh-token";
type ImpersonationKey = "connectedAs";
type LanguageSelectorKey = "language";
type SelectedCompany = "company";
type CompanyInfoBox = "displayRegulatoryInfoBox";

type LocalStorateKeys = TokenKeys | ImpersonationKey | LanguageSelectorKey | SelectedCompany | CompanyInfoBox;

export class LocalStorage {
    static getItem(key: LocalStorateKeys): string | null {
        const item = localStorage.getItem(key);
        return item;
    }

    static setItem(key: LocalStorateKeys, value: string) {
        localStorage.setItem(key, value);
    }

    static deleteItem(key: LocalStorateKeys) {
        localStorage.removeItem(key);
    }
}
