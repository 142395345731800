import { useMutation, useQueryClient } from "react-query";
import { loggedInApiCall } from "src/api/axiosClient/userApi";

type CreateUser = {
    user: UserWrite;
};
export const useCreateUser = () => {
    const queryClient = useQueryClient();

    const query = useMutation({
        mutationFn: async (options: CreateUser) => {
            const { user } = options;

            const response =
                user.id === undefined ?
                    await loggedInApiCall.post("/api/users", user)
                :   await loggedInApiCall.put("/api/users/" + user.id, user);

            return response;
        },
        onSuccess: () => queryClient.invalidateQueries(["allUsers"]),
    });

    return query;
};

export type UserWrite = {
    id?: number;
    firstname: string;
    lastname: string;
    email: string;
    password?: string;
    plainPassword?: string;
    phone?: string;
    company?: IRI;
    companies?: Array<IRI>;
};
