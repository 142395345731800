import { useParams } from "react-router-dom";
import { Post } from "src/api/client-api/post/postSchema";
import { Ssq, Heading } from "src/api/sure-api/ssq/useGetOneSsq";
import { FoodsPage } from "@foodpilot/foods";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { QuestionnaireUpdateFormBase } from "./QuestionnaireUpdateFormBase";
import { getVisibleHeadings } from "src/utils/ssq";
import { Product } from "src/api/client-api/products/useGetOneProduct";
import { useQuestionnaireUpdatePages } from "src/components/QuestionnaireUpdate/useQuestionnaireUpdatePages";
import { useFoodpilotContext } from "src/context/FoodpilotContext";
import { formatDateCampaign } from "src/utils/campaigns";
import { getScoreIdsByType } from "src/utils/scores";
import { URLHandler } from "src/services/URLHandler";

type QuestionnaireUpdateFormSsqPostProps = {
    product: Product;
    ssq: Ssq;
    post?: Post;
    isPostLoading: boolean;
    changePost: (callback: (post: Post) => void) => void;
};
export const QuestionnaireUpdateFormSsqPost = (props: QuestionnaireUpdateFormSsqPostProps) => {
    const { headingId } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { product, ssq, post, isPostLoading, changePost } = props;
    const { scoreTypes, scores, currentCampaign, selectedCompany } = useFoodpilotContext();

    const headings: Heading[] = getVisibleHeadings(ssq, post?.hiddenElements);
    const productsScoresIds = getScoreIdsByType("product", scoreTypes);

    const formattedSsqTitle =
        currentCampaign ?
            ssq.title.concat(" - ").concat(formatDateCampaign(currentCampaign.startDate, currentCampaign.endDate, "/"))
        :   ssq.title;

    const pages: FoodsPage[] = useQuestionnaireUpdatePages({
        headings,
        post,
        isPostLoading,
        scores,
        scoresIds: productsScoresIds,
        changePost,
    });

    const headingsIds: number[] = headings.map((heading) => Number(heading.id));
    const headingIndex = headingsIds.findIndex((id) => id === Number(headingId));

    if (selectedCompany === null) {
        console.error("You have no selected company...");
        return;
    }

    if (headingIndex < 0) return t("Thématique non trouvée dans le questionnaire");

    return (
        <QuestionnaireUpdateFormBase
            product={product}
            title={formattedSsqTitle}
            pages={pages}
            startingPage={headingIndex ?? 0}
            onPageChange={(newPageIndex: number, previousPageIndex: number) => {
                if (!newPageIndex && !previousPageIndex) {
                    const url = URLHandler.product.ssq(selectedCompany.id, product.id);
                    navigate(url);

                    return;
                }

                const newHeadingPage = headingsIds[newPageIndex];
                const url = URLHandler.product.ssqHeading(selectedCompany.id, product.id, newHeadingPage);
                navigate(url);
            }}
            onValidate={() => {
                const url = URLHandler.product.ssq(selectedCompany.id, product.id);
                navigate(url);
            }}
        />
    );
};
