import { useNavigate, useParams } from "react-router-dom";
import { useProductContext } from "src/pages/Product/ProductDetails/ProductContext.tsx";
import { DistributionImpactsDetails } from "src/pages/Product/ProductDetails/Scores/SecondaryTabs/DistributionImpactsDetails.tsx";
import { DistributionDetails } from "src/components/ScoreDetail/SecondaryTabs/DistributionDetails.tsx";
import { useIsVariationRefetching } from "src/utils/variationRefetching.ts";
import { Box } from "@mui/material";
import { URLHandler } from "src/services/URLHandler.ts";
import { useFoodpilotContext } from "src/context/FoodpilotContext.tsx";
import { ScoreDetailResponsesModal } from "src/components/ScoreDetail/ScoreDetailResponsesModal.tsx";
import { useScoreDetailContext } from "src/context/ScoreDetail/useScoreDetailContext.tsx";
import { useChartActions } from "src/components/ScoreDetail/useChartActions.ts";
import { ScoreDetailGlossary } from "src/components/ScoreDetail/ScoreDetailGlossary.tsx";
import { useGetProductPost } from "src/api/client-api/post/useGetProductPost.ts";

const dimensionOrder = ["headings", "lca", "pef_chapters"];

type DistributionProps = {
    selectedDimensionId?: string;
    selectedValueIndex: string[];
};
export const Distribution = (props: DistributionProps) => {
    const { selectedDimensionId } = props;

    const navigate = useNavigate();
    const { product, productReferenceScores, isRefetching, variations } = useProductContext();
    const { selectedCompany, currentCampaign } = useFoodpilotContext();
    const { list, scorePrecision, selectedDimension, additionalScore } = useScoreDetailContext();
    const { post } = useGetProductPost(product?.id, selectedCompany?.id ?? null, currentCampaign?.id);

    const { scoreId: scoreIdString } = useParams<{ scoreId: string }>();
    const scoreId = Number(scoreIdString);
    const isVariationRefetching = useIsVariationRefetching(isRefetching, variations);

    const { actions, selectedScore, dialogOpen, dialogTitle, propertyId, setDialogOpen } = useChartActions(
        additionalScore !== null,
    );

    const values = Object.values(list || {});

    if (additionalScore) {
        actions.unshift(undefined);
        values.unshift(additionalScore);
    }

    const mappedReferenceScores = Object.fromEntries(productReferenceScores.map((score) => [score?.score?.id, score]));
    const referenceScore = mappedReferenceScores[Number(scoreId)];

    return (
        <Box>
            <ScoreDetailGlossary
                dimensionOrder={dimensionOrder}
                baseUrl={selectedCompany ? URLHandler.product.score(selectedCompany.id, product!.id, scoreId) : ""}
                additionalDimensions="components"
            />
            {selectedDimension === "pef_chapters" ?
                <DistributionImpactsDetails
                    referenceScoreValue={
                        referenceScore?.value !== undefined ?
                            Number(referenceScore.value.toFixed(scorePrecision))
                        :   undefined
                    }
                    isVariationRefetching={isVariationRefetching}
                    prevCampaignName={product?.prevCampaignName}
                    labels={values.map((value) => value.label || "-- no label --")}
                    actions={actions}
                    values={values.map((value) =>
                        value.score !== null ? Number((value.score ?? 0).toFixed(scorePrecision)) : 0,
                    )}
                />
            :   <>
                    <DistributionDetails
                        referenceScoreValue={
                            referenceScore?.value !== undefined ?
                                Number(referenceScore.value.toFixed(scorePrecision))
                            :   undefined
                        }
                        isVariationRefetching={isVariationRefetching}
                        prevCampaignName={product?.prevCampaignName}
                        labels={values.map((value) => value.label || "-- no label --")}
                        chartValues={values.map((value) => Number((value.score ?? 0).toFixed(scorePrecision)))}
                        actions={actions}
                    />
                    {dialogOpen && propertyId && (
                        <ScoreDetailResponsesModal
                            post={post}
                            title={dialogTitle}
                            open={dialogOpen}
                            propertyId={propertyId}
                            score={selectedScore}
                            setOpen={setDialogOpen}
                            selectedDimensionId={selectedDimensionId}
                            onModifyAnswersClick={() => {
                                if (!selectedCompany || !product || !selectedDimensionId) {
                                    return;
                                }

                                const url = URLHandler.product.ssqHeading(
                                    selectedCompany.id,
                                    product?.id,
                                    Number(selectedDimensionId),
                                );
                                navigate(url);
                            }}
                        />
                    )}
                </>
            }
        </Box>
    );
};
