import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import { CMSPageSection, CMSSectionBlocksContent } from "src/api/client-api/cms/useGetOneContent";
import { SectionHeader } from "../_Common/SectionForm/SectionHeader";
import { useTranslation } from "react-i18next";
import { EditLayout } from "../_Common/EditLayout";
import { FoodsIcon } from "@foodpilot/foods";
import { useState } from "react";
import { DraggingRows } from "src/components/Dragging";
import { VerticalDivider } from "../../../Common/VerticalDivider";
import { AddIndicatorsModal } from "./Form/AddIndicatorsModal";
import { useDeleteOneBlock } from "src/api/client-api/cms/blocks/useDeleteOneBlock";
import { useReorderBlocks } from "src/api/client-api/cms/blocks/useReorderBlocks";
import { SectionForm } from "../_Common/SectionForm/SectionForm";
import { ContentBlockForm } from "../_Common/BlockForm/ContentBlock/contentBlock";

type EditIndicatorsProps = {
    originalPageId: string;
    section: CMSPageSection<CMSSectionBlocksContent>;
};
export const EditIndicators = (props: EditIndicatorsProps) => {
    const { originalPageId, section } = props;
    const { t } = useTranslation();
    const theme = useTheme();

    const deleteBlock = useDeleteOneBlock();
    const reorderBlock = useReorderBlocks();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [selectedExistingBlock, setSelectedExistingBlock] = useState<ContentBlockForm | null>(null);

    const blocksRows = section.blocks.map((block) => {
        return {
            id: block.id,
            title: block.title,
            link: block.ctaLink,
            position: block.position,
            editAction: () => {
                setSelectedExistingBlock(block);
                setIsOpen(true);
            },
            deleteAction: () => {
                deleteBlock.mutate({ deleteId: block.id, allBlocksToReorder: section.blocks });
            },
        };
    });

    return (
        <Stack gap={theme.spacing(3)}>
            <SectionHeader section={section} originalPageId={originalPageId} />
            <SectionForm section={section} />
            <EditLayout>
                <AddIndicatorsModal
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    currentSection={{
                        "@id": section["@id"],
                        totalExistingElements: section.blocks.length,
                    }}
                    selectedExistingBlock={selectedExistingBlock}
                />
                <Typography variant="big-medium">{t("cms.section.indicators.add.info")}</Typography>
                <DraggingRows
                    rows={blocksRows}
                    renderRow={(row, rowIndex) => {
                        return (
                            <Stack
                                flexDirection={"row"}
                                justifyContent={"space-between"}
                                key={`index-${rowIndex}`}
                                sx={{ border: `2px solid ${theme.custom.grey[2000]}`, width: "100%" }}
                                padding={theme.spacing(1)}
                            >
                                <Stack
                                    flexDirection={"row"}
                                    justifyContent={"space-between"}
                                    alignItems={"center"}
                                    gap={theme.spacing(4)}
                                    marginLeft={theme.spacing(1)}
                                >
                                    <Typography variant="big-bold">{row.title}</Typography>
                                </Stack>
                                <Stack flexDirection={"row"} gap={theme.spacing(2)} alignItems={"center"}>
                                    <Button
                                        variant="text"
                                        startIcon={<FoodsIcon size={2} icon={"edit"} />}
                                        onClick={row.editAction}
                                    >
                                        {"Modifier"}
                                    </Button>
                                    <VerticalDivider />
                                    <Box onClick={row.deleteAction}>
                                        <FoodsIcon size={4} icon="delete" />
                                    </Box>
                                </Stack>
                            </Stack>
                        );
                    }}
                    onHoverDrop={(reorderedRows) => {
                        reorderBlock.mutate({ allBlocksToReorder: reorderedRows });
                    }}
                />
                {section.blocks.length >= 6 ?
                    <Typography variant="big-bold">{t("cms.section.indicators.maxlimit")}</Typography>
                :   <Button
                        variant="primary"
                        onClick={() => {
                            setSelectedExistingBlock(null);
                            setIsOpen(true);
                        }}
                    >
                        {t("cms.section.indicators.add.button")}
                    </Button>
                }
            </EditLayout>
        </Stack>
    );
};
