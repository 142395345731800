import { FoodsIcon, FoodsIllustrations, WhiteBox } from "@foodpilot/foods";
import { AccordionDetails, Box, Button, Divider, Stack, Typography, Pagination, PaginationItem } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import { useGetCompanyPost } from "src/api/client-api/post/useGetCompanyPost";
import { Heading, Property, useGetOneSsq } from "src/api/sure-api/ssq/useGetOneSsq";
import { useFoodpilotContext } from "src/context/FoodpilotContext";
import { getVisibleHeadings } from "src/utils/ssq";
import { URLHandler } from "src/services/URLHandler";
import { ChangeEvent, useState } from "react";
import { Loading } from "src/components/utils/Loading";
import { Post } from "src/api/client-api/post/postSchema";
import { CompanyService } from "../CompanyService";
import {
    Accordion,
    AccordionSummary,
    ImageContainer,
    imageContainerClasses,
} from "./components/LegalRequirementsStyles";

const ITEMS_COUNT = 5;
const DISPLAY_MODE = "regulation";

const QuestionnaireNotice = () => {
    const navigate = useNavigate();
    const { selectedCompany } = useFoodpilotContext();

    return (
        <Stack spacing={3} mb={0}>
            {/* Warning message section */}
            <Box
                sx={{
                    padding: "20px 32px",
                    borderRadius: "8px",
                    border: (theme) => `1px solid ${theme.custom.grey[500]}`,
                    background: (theme) => theme.custom.grey[200],
                }}
            >
                <Stack direction="row" spacing={2} alignItems="center">
                    <FoodsIcon icon="warning" size={6} />
                    <Typography variant="body2" flex={1} fontSize={12} fontWeight={600}>
                        {t("bred.requirement.questionnaire_partial")}
                    </Typography>
                    {selectedCompany && (
                        <Button
                            variant="text"
                            color="primary"
                            onClick={() => navigate(URLHandler.company.ssq(selectedCompany.id))}
                            sx={{ whiteSpace: "nowrap" }}
                        >
                            {t("questionnaire.complete-questionnaire")}
                        </Button>
                    )}
                </Stack>
            </Box>
        </Stack>
    );
};

type Required = {
    required: true;
    title: string;
    description: string;
    tags: string[];
    imageUrl?: string;
    url?: string;
};
type NotRequired = { required: false };
type RuleContent = Required | NotRequired;

const isRequired = (rc: RuleContent): rc is Required => {
    return (rc as Required).required;
};

const extractRulesScore = (post: Post | undefined, rulesScoreId: number | undefined) =>
    post && rulesScoreId && typeof post.formattedScores[rulesScoreId]?.chaptersScore.score === "object" ?
        (Object.values(post.formattedScores[rulesScoreId]?.chaptersScore.score) as RuleContent[]).filter(isRequired)
    :   [];

export const useIsRulesPostCompleted = (companyId?: number, campaignId?: number, rulesScoreId?: number) => {
    const { data: post } = useGetCompanyPost(companyId ?? undefined, campaignId ?? undefined);
    const { ssq } = useGetOneSsq(post?.ssqId);

    if (!companyId || !campaignId || !rulesScoreId || !post?.elements || !ssq) return null;

    // Find all properties in headings that have formulas with the given rulesScoreId
    const findPropertiesWithRuleId = (heading: Heading): number[] => {
        const result: number[] = [];

        const checkProperty = (prop: Property) => {
            if (prop.formulas?.some((formula) => formula.score && formula.score.id === rulesScoreId)) {
                result.push(prop.id);
            }
        };

        heading.properties?.forEach(checkProperty);
        heading.properties?.forEach((prop) => {
            prop.children?.forEach(checkProperty);
        });
        heading.children?.forEach((child) => result.push(...findPropertiesWithRuleId(child)));
        return result;
    };

    const rulePropertyIds = ssq.headings.flatMap(findPropertiesWithRuleId);

    // Check if we have answers for all these properties
    const answeredPropertyIds = post.elements
        .filter((element) => element.value !== "")
        .map((element) => element.ssqPropertyId);

    const unansweredProperties = rulePropertyIds.filter((id) => !answeredPropertyIds.includes(id));

    // Rule is completed if all required properties have answers
    return unansweredProperties.length === 0;
};

export const LegalRequirements = () => {
    const { currentCampaign, selectedCompany, getScoresByType } = useFoodpilotContext();
    const navigate = useNavigate();
    const { post, isLoading } = useGetCompanyPost(selectedCompany?.id, currentCampaign?.id);
    const rulesScoreId = getScoresByType("company").find((st) => st.displayMode === DISPLAY_MODE)?.id;

    const contents = extractRulesScore(post, rulesScoreId);

    const ssqId = selectedCompany?.companySector?.ssqId;
    const { ssq } = useGetOneSsq(ssqId);
    const headings = ssq ? getVisibleHeadings(ssq, post?.hiddenElements) : [];
    const total = post?.completions.ssq.result ?? 0;

    const [page, setPage] = useState(0);
    const handlePageChange = (event: ChangeEvent<unknown>, value: number) => {
        setPage(value - 1);
    };

    const isRulesPostCompleted = useIsRulesPostCompleted(selectedCompany?.id, currentCampaign?.id, rulesScoreId);

    if (total === 0 && headings[0]) {
        return (
            <Stack>
                <QuestionnaireNeeded companyId={selectedCompany?.id} headingId={headings[0].id} />
            </Stack>
        );
    }

    const handleCompleteQuestionnaireClick = () => {
        if (selectedCompany === null) return;
        if (headings.length === 0) return;

        const headingId = headings[0].id;
        const url = URLHandler.company.ssqHeading(selectedCompany.id, headingId);
        navigate(url);
    };

    return (
        <Stack gap={3.5}>
            {isLoading && <Loading />}
            <NoneRequired />
            {!isLoading && contents.length > 0 && (
                <>
                    <InfoBox />
                    <Stack sx={{ py: 3, backgroundColor: "white" }} borderRadius={2} divider={<Divider />} gap={2}>
                        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} px={4}>
                            <Stack direction={"row"} alignItems={"baseline"}>
                                <Typography variant="h3">{t("bred.requirement")}</Typography>
                                <Typography variant="huge">&nbsp;-&nbsp;{contents.length}</Typography>
                            </Stack>
                            <Button variant="primary" onClick={handleCompleteQuestionnaireClick}>
                                {t("questionnaire.modify_answers")}
                            </Button>
                        </Stack>
                        <Box px={4}>
                            {isRulesPostCompleted === false && <QuestionnaireNotice />}
                            {contents.slice(page * ITEMS_COUNT, (page + 1) * ITEMS_COUNT).map((p, i) => (
                                <Content key={i} content={p} defaultExpanded />
                            ))}

                            <Stack direction={"row"} justifyContent={"end"}>
                                {contents.length > ITEMS_COUNT && (
                                    <Pagination
                                        count={Math.ceil(contents.length / ITEMS_COUNT)}
                                        disabled={contents.length < ITEMS_COUNT}
                                        page={page + 1}
                                        onChange={handlePageChange}
                                        color="primary"
                                        renderItem={(item) => (
                                            <PaginationItem
                                                slots={{
                                                    previous: () => <FoodsIcon icon="arrowLeft" size={1} />,
                                                    next: () => <FoodsIcon icon="arrowRight" size={1} />,
                                                }}
                                                {...item}
                                            />
                                        )}
                                    />
                                )}
                            </Stack>
                        </Box>
                    </Stack>
                </>
            )}
        </Stack>
    );
};

const InfoBox = () => {
    const { currentCampaign, selectedCompany } = useFoodpilotContext();
    const { isLoading } = useGetCompanyPost(selectedCompany?.id, currentCampaign?.id);
    const [isVisible, setIsVisible] = useState(() => {
        const status = CompanyService.getRegulatoryInfoBoxStatus();
        return status === null || status === "true";
    });

    if (!isVisible || isLoading) return null;

    const handleClick = () => {
        CompanyService.updateRegulatoryInfoBox(false);
        setIsVisible(false);
    };

    return (
        <Stack sx={{ backgroundColor: "white", borderRadius: "8px" }} px={3} py={2.5}>
            <Stack direction={"row"} justifyContent={"space-between"}>
                <Stack direction={"row"} alignItems={"center"} gap={2.5}>
                    <Stack>
                        <FoodsIcon icon="information" size={3} />
                    </Stack>
                    <Typography variant="h5">{t("bred.requirement.info")}</Typography>
                </Stack>
                <Button onClick={handleClick}>J&apos;ai compris</Button>
            </Stack>
        </Stack>
    );
};

type QuestionnaireNeededProps = {
    companyId?: number;
    headingId: number;
};

export const QuestionnaireNeeded = (props: QuestionnaireNeededProps) => {
    const { companyId, headingId } = props;
    const navigate = useNavigate();
    const handleClick = () => {
        if (!companyId) return;
        const url = URLHandler.company.ssqHeading(companyId, headingId);
        navigate(url);
    };

    return (
        <WhiteBox sx={{ p: 3 }}>
            <Stack
                direction={"row"}
                justifyContent={"space-between"}
                divider={<Divider orientation="vertical" flexItem />}
            >
                <Stack flex={3} direction={"row"} gap={2} justifySelf={"start"}>
                    <FoodsIllustrations illustration="questionnaire" />
                    <Stack justifyContent={"center"} gap={1}>
                        <Typography variant="body-bold">
                            {t("bred.requirements.info.complete_questionnaire")}
                        </Typography>
                        <Typography variant="body">{t("bred.requirements.info.questionnaire_completed")}</Typography>
                    </Stack>
                </Stack>
                <Stack flex={1} justifyContent={"center"} alignItems={"center"}>
                    <Button variant="primary" onClick={handleClick}>
                        {t("questionnaire.complete-questionnaire")}
                    </Button>
                </Stack>
            </Stack>
        </WhiteBox>
    );
};

const NoneRequired = () => {
    const { currentCampaign, selectedCompany, getScoresByType } = useFoodpilotContext();
    const { post, isLoading } = useGetCompanyPost(selectedCompany?.id, currentCampaign?.id);
    const rulesScoreId = getScoresByType("company").find((st) => st.displayMode === DISPLAY_MODE)?.id;
    const contents = extractRulesScore(post, rulesScoreId);
    const isRulesPostCompleted = useIsRulesPostCompleted(selectedCompany?.id, currentCampaign?.id, rulesScoreId);

    if (isLoading || contents.length > 0) return null;

    return (
        <Stack sx={{ backgroundColor: "white" }} borderRadius={2} p={4} gap={4}>
            {isRulesPostCompleted === false && <QuestionnaireNotice />}
            <Stack direction={"column"} alignItems={"center"} justifyContent={"center"} gap={3}>
                <Stack
                    borderRadius={9999}
                    sx={{ backgroundColor: (theme) => theme.custom.green[100] }}
                    justifyContent={"center"}
                    alignItems={"center"}
                    width={"56px"}
                    height={"56px"}
                >
                    <FoodsIcon icon="check" size={4} />
                </Stack>
                <Typography variant="h4">{t("bred.requirement.none_required")}</Typography>
            </Stack>
        </Stack>
    );
};

type ContentProps = {
    content: Required;
    defaultExpanded: boolean;
};
const Content = (props: ContentProps) => {
    const { content, defaultExpanded } = props;

    return (
        <Accordion elevation={0} defaultExpanded={defaultExpanded}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Stack direction={"row"} alignItems={"baseline"} gap={2}>
                    <Typography variant="h4">{content.title}</Typography>
                    {content.tags.map((t, itags) => (
                        <Typography
                            className="category"
                            key={itags}
                            sx={{ backgroundColor: (theme) => theme.custom.grey[400] }}
                            px={1}
                            py={0.5}
                            borderRadius={1}
                            variant="small-bold"
                        >
                            {t}
                        </Typography>
                    ))}
                </Stack>
            </AccordionSummary>
            <AccordionDetails>
                <Stack pt={1} direction={{ xs: "column", md: "row" }} spacing={2} alignItems="flex-start">
                    {content.imageUrl && (
                        <ImageContainer>
                            <img src={content.imageUrl} alt={content.title} className={imageContainerClasses.image} />
                        </ImageContainer>
                    )}
                    <Box flex={1}>
                        <Box pb={2} fontSize={12} fontWeight={500} lineHeight={2}>
                            {content.description}
                        </Box>
                        {content.url && (
                            <Button variant="ternary" sx={{ width: "fit-content" }} href={content.url} target="_blank">
                                <Stack direction={"row"} gap={1} alignItems={"center"}>
                                    <Typography fontSize={12} fontWeight={700}>
                                        {t("bred.requirement.info.more_info")}
                                    </Typography>
                                    <FoodsIcon icon="openNewTab" size={1} />
                                </Stack>
                            </Button>
                        )}
                    </Box>
                </Stack>
            </AccordionDetails>
        </Accordion>
    );
};
