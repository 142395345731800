import { z } from "zod";
import i18next from "i18next";
import { useQuery } from "react-query";
import { loggedInApiCall } from "src/api/axiosClient/userApi";

import { zodParseWithLog } from "src/api/apiErrorLogs";

type Options = {
    name?: string;
    page?: number;
    itemsPerPage?: number;
};

export const useGetAllProductLines = (options?: Options) => {
    const { name = null, page = 1, itemsPerPage = 100 } = options || {};

    const query = useQuery({
        queryKey: ["allProductLines", { page, itemsPerPage }, name],
        queryFn: async () => {
            const url = `/api/product_lines`;
            const response = await loggedInApiCall.get(url, {
                params: {
                    "order[name]": "asc",
                    name,
                    page,
                    itemsPerPage,
                },
                headers: {
                    "X-Locale": i18next.language,
                },
            });
            return response;
        },
        select: (request) => {
            const productLine = request.data["hydra:member"];
            const data = zodParseWithLog(productLine, ProductLineZodSchema.array(), "allProductLines");
            return data;
        },
    });

    return {
        productLines: query.data,
        ...query,
    };
};

export type ProductLine = {
    "@id": IRI;
    id: number;
    name: string;
    products: Product[];
};

export type Product = {
    "@id": string;
    id: number;
};

export const ProductSchema: z.ZodSchema<Product> = z.object({
    "@id": z.string(),
    id: z.number(),
});

export const ProductLineZodSchema: z.ZodSchema<ProductLine> = z.object({
    "@id": z.string(),
    id: z.number(),
    name: z.string(),
    products: ProductSchema.array(),
});

export type ProductLineForm = {
    id?: number;
    name: string;
    products?: Product[];
};
