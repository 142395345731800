import { useMutation } from "react-query";
import { loggedInApiCall } from "src/api/axiosClient/userApi";
import { postStatus, postStatusKeys } from "./useGetAllPosts";
import { useSnackbar } from "src/utils/useSnackbar";
import { useTranslation } from "react-i18next";

type DuplicateOptions = {
    fromPostID: number;
    toCampaignID: string | number;
    status: postStatusKeys;
};

export const useDuplicatePost = () => {
    const snackbar = useSnackbar();
    const { t } = useTranslation();

    const query = useMutation({
        mutationFn: async (options: DuplicateOptions) => {
            const { toCampaignID, fromPostID, status } = options;

            const url = `/api/ssq_posts/${fromPostID}/clone`;
            const payload = {
                campaignId: toCampaignID,
                status: postStatus[status],
            };
            const response = await loggedInApiCall.post(url, payload, {
                headers: {
                    Accept: "application/json",
                },
            });
            return response;
        },
        onError: () => {
            snackbar.forceClose();
            snackbar.setSnackbarProps({
                type: "error",
                message: t("actionPlan.snackbar.creationError"),
            });
        },
    });

    return query;
};
