import { useQuery } from "react-query";
import { zodParseWithLog } from "src/api/apiErrorLogs";
import { loggedInApiCall } from "src/api/axiosClient/userApi";
import { z } from "zod";

export const getOnePillarKey = "getOneCsrStrategy" as const;

export const useGetOneCsrPillar = (pillarId: string | undefined) => {
    const query = useQuery({
        queryKey: [getOnePillarKey, pillarId],
        cacheTime: 0,
        enabled: pillarId !== undefined,
        queryFn: async () => {
            const url = `/api/steering/pillars/${pillarId}`;
            const req = loggedInApiCall.get(url);
            return req;
        },
        select: (request) => {
            const pillar = request.data;
            const data = zodParseWithLog(pillar, CsrPillarSchema, getOnePillarKey);
            return data;
        },
    });

    return {
        csrPillar: query.data,
        ...query,
    };
};

export type CsrIndicator = {
    "@id": IRI;
    id: string;
    name: string;
    abbreviationUnit: string;
    currentValue: string;
    shortTermTarget: string;
    longTermTarget: string;
};

const CsrIndicatorSchema: z.ZodType<CsrIndicator> = z.object({
    "@id": z.string(),
    id: z.string(),
    name: z.string(),
    abbreviationUnit: z.string(),
    currentValue: z.string(),
    shortTermTarget: z.string(),
    longTermTarget: z.string(),
});

export type CsrPillar = {
    "@id": string;
    id: string;
    name: string;
    themes?: number[];
    icon: string;
    indicators: CsrIndicator[];
};

const CsrPillarSchema: z.ZodType<CsrPillar> = z.object({
    "@id": z.string(),
    id: z.string(),
    name: z.string(),
    themes: z.number().array().optional(),
    icon: z.string(),
    indicators: CsrIndicatorSchema.array(),
});
