import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useState } from "react";
import { useGridColumns, GridColumnsType } from "src/utils/useGridColumns";
import { GridProduct, useGetAllProduct } from "src/api/client-api/products/useGetAllproduct";
import { useProductColumns } from "./useProductColumns";
import { MRT_PaginationState, MRT_SortingState } from "material-react-table";
import { useDelayedSearch } from "src/utils/useDelayedSearch";
import { useFoodpilotContext } from "src/context/FoodpilotContext";

type GammeSelection = string | undefined;

type ProductListContextInitialValues = {
    productPage: MRT_PaginationState;
    setProductPage: Dispatch<SetStateAction<MRT_PaginationState>>;

    sorting: MRT_SortingState;
    setSorting: Dispatch<SetStateAction<MRT_SortingState>>;

    totalItems: number | undefined;
    products: GridProduct[] | undefined;
    mrt_columns: GridColumnsType<GridProduct>;

    currentGamme: GammeSelection;
    setCurrentGamme: (arg1: GammeSelection) => void;

    searchProduct: string;
    delayedSearchProduct: string;
    setSearchProduct: (arg1: string) => void;
    resetSearch: () => void;

    gridKey: string;
};
const ProductListContext = createContext<ProductListContextInitialValues | null>(null);

type ProductListContextProviderProps = { children: ReactNode };
export const ProductListContextProvider = (props: ProductListContextProviderProps) => {
    const { children } = props;
    const [productPage, setProductPage] = useState<MRT_PaginationState>({
        pageIndex: 0,
        pageSize: 20,
    });
    const [sorting, setSorting] = useState<MRT_SortingState>([{ id: "title", desc: false }]);

    const [currentGamme, setCurrentGamme] = useState<GammeSelection>(undefined);

    const { currentCampaign, selectedCompany } = useFoodpilotContext();
    const search = useDelayedSearch();

    const { products, totalItems, isRefetching } = useGetAllProduct({
        cacheTime: 0,
        page: productPage.pageIndex + 1,
        itemsPerPage: productPage.pageSize,
        search: search.delayedSearch,
        productLine: currentGamme,
        campaignId: currentCampaign?.id,
        companyId: selectedCompany?.id,
        sorting: sorting,
    });

    const gridKey = "product-grid";

    const mrt_productColumns = useProductColumns(isRefetching);
    const mrt_columns = useGridColumns(mrt_productColumns, gridKey);

    const items: ProductListContextInitialValues = {
        mrt_columns,

        currentGamme,
        setCurrentGamme,

        productPage,
        setProductPage,

        sorting,
        setSorting,

        totalItems,
        products: products,

        searchProduct: search.search,
        setSearchProduct: search.setSearch,
        delayedSearchProduct: search.delayedSearch,
        resetSearch: search.resetSearch,

        gridKey: gridKey,
    };

    return <ProductListContext.Provider value={items}>{children}</ProductListContext.Provider>;
};

export const useProductListContext = () => {
    const context = useContext(ProductListContext);

    if (context) {
        return context;
    }

    throw new Error(`useProductListContext must be used within an ProductListContextProvider`);
};
