import React from "react";

import { Stack, Typography, Button, useTheme, Box, CircularProgress } from "@mui/material";
import { WhiteBox } from "@foodpilot/foods";

import { ArrowForward } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { ScatterScoreComparison } from "./ScatterScoreComparison";
import { ScatterScoreValue } from "./ScatterScoreValue";
import { ComingSoonBadge } from "src/components/ComingSoonBadge";
import { DeprecatedEmptyScore, doesScoreExist, Score } from "src/api/client-api/post/scoreSchema";
import { ReferenceScore } from "src/types/applicationTypes/referenceScore.ts";
import { ScoreComparison } from "src/components/Score/ScoreComparison.tsx";
import { Score as SureScore } from "src/types";

export type ScoreBoxProps = {
    title: string;
    score?: Score | null | DeprecatedEmptyScore;
    comparison?: Score[];
    difference?: number;
    isRefetching?: boolean;
    referenceScore: ReferenceScore | null;
    prevScore?: Score | null | DeprecatedEmptyScore;
    prevCampaignName?: string;
    variations: Record<number, number>;
    sureScore: SureScore;
};

export const ScatterScoreBox = (props: ScoreBoxProps) => {
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <WhiteBox sx={{ flex: "0 0 calc(33.33% - 14px)", minWidth: "fit-content" }}>
            <Box borderBottom={`1px solid ${theme.custom.grey[500]}`} sx={{ height: "70%" }}>
                <ScatterScoreBoxCore {...props} />
            </Box>
            <Stack direction="row" alignItems="center" gap="28px" padding="28px 32px">
                <Button
                    variant="ternary"
                    endIcon={<ArrowForward />}
                    sx={{
                        flex: 1,
                        justifyContent: "space-between",
                        minHeight: "40px",
                        border: `1px solid ${theme.custom.grey[500]}`,
                        borderRadius: "44px",
                        background: "linear-gradient(180deg, #FFF 0%, #FAFAFA 100%)",
                        color: theme.custom.grey[2800],
                        fontWeight: 700,
                        padding: "0px 20px",
                        pointerEvents: "none",
                        opacity: "0.36",
                    }}
                >
                    {t("Voir plus de détail")}
                </Button>

                <ComingSoonBadge />
            </Stack>
        </WhiteBox>
    );
};

export const ScatterScoreBoxCore = (props: ScoreBoxProps) => {
    const {
        title,
        score,
        difference,
        comparison,
        referenceScore,
        prevScore,
        variations,
        sureScore,
        prevCampaignName = "",
        isRefetching = false,
    } = props;
    const { t } = useTranslation();

    const scoreExists = doesScoreExist(score);
    const prevScoreExists = prevScore !== undefined && doesScoreExist(prevScore);

    const unit = scoreExists ? score.unit : undefined;
    const displayedVariationId = variations[sureScore.id];

    const isReferenceScore =
        referenceScore !== null &&
        referenceScore.value !== null &&
        referenceScore.value !== undefined &&
        scoreExists &&
        !isRefetching &&
        !displayedVariationId;

    return (
        <Stack padding="28px 32px" paddingBottom="37px" gap="40px">
            <Stack>
                <Typography variant="h3">{title}</Typography>
            </Stack>

            <Stack>
                {!isRefetching ?
                    <ScatterScoreValue
                        variant="h1"
                        score={score}
                        difference={difference}
                        sx={{
                            flexDirection: "row",
                            gap: "10px",
                            alignItems: "flex-end",
                        }}
                    />
                :   <CircularProgress />}

                {comparison && <ScatterScoreComparison values={comparison} />}
            </Stack>

            <Stack marginTop="-1rem">
                {prevScoreExists && scoreExists && !isRefetching && (
                    <ScoreComparison
                        referenceScoreValue={prevScore.score}
                        scoreValueToCompare={score.score}
                        unit={unit}
                        reportName={prevCampaignName}
                    />
                )}
            </Stack>

            <Stack marginTop="-1rem">
                {isReferenceScore && (
                    <ScoreComparison
                        referenceScoreValue={referenceScore.value ?? 0}
                        scoreValueToCompare={score.score}
                        unit={unit}
                        reportName={t("score_reference.the_reference")}
                    />
                )}
            </Stack>
        </Stack>
    );
};
